export default {
  FeedbackModal: {
    tit_group: {
      tit: "Feedback",
      decs: "Please feel free to leave any feedback about using the platform."
    },
    contents: {
      textarea: {
          placeholder: "Enter your feedback here..."
        }
    },
    footer: {
      button: {
          submit: "Submit",
          cancel: "Cancel"
      }
    },
    message: {
      send: "Thank you for your valuable feedback!"
    }
  },
  
};
