import React, { useEffect, useRef, useState } from "react";

import { useWhisper } from "@chengsokdara/use-whisper";
// import "./chatInput.css";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import {
  chatMessageGet,
  chatMessageSend,
  chatMessageAdd,
  chatMessageAddBot,
  addMessages,
  setStreamStatus,
  chatMessageFile,
  selectChatRoomId,
  keywordGetList,
  selectKeyword,
  selectChatMain,
  setChatRoomId,
  chatRoomCreate,
  chatRoomGet,
  sidebarSelRoom,
  setChatMode,
  selectChatSide,
  selectChatMode,
  selectDocViewer,
  setDocViewer,
  setToggleDocViewer,
} from "../../lib/features/chatMain/chatMainSlice";
import { selectToken, selectUser } from "../../lib/features/auth/authSlice";
import { useNavigate } from "react-router-dom/dist";
import { debounce } from "lodash";
import { default as greet_messages } from "../../data/greet_message.json";

const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;
const BE_HOST = process.env.REACT_APP_BE_HOST;

// const BASE_URL:string = 'http://155.230.135.140:50005';
const BASE_URL = BE_HOST ? BE_HOST : "https://snsys-dev.neoali.com";
const APP_STAGE = process.env.REACT_APP_STAGE;
function ChatInput({ first }) {
  const dispatch = useAppDispatch();
  const [inputValue, setInputValue] = useState("");
  const roomId = useAppSelector(selectChatRoomId);
  const [currentRoomId, setCurrentRoomId] = useState(roomId);
  const token = useAppSelector(selectToken);
  const user = useAppSelector(selectUser);
  const chatMain = useAppSelector(selectChatMain);
  const chatMode = useAppSelector(selectChatMode);
  const chatSide = useAppSelector(selectChatSide);
  const docViewer = useAppSelector(selectDocViewer);
  const [selectedFile, setSelectedFile] = useState(null);
  const keywordList = useAppSelector(selectKeyword);
  const [filteredKeywords, setFilteredKeywords] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isKeywordListVisible, setIsKeywordListVisible] = useState(true);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  const greet_message = [
    user?.user_lang == "EN"
      ? chatMode.mode_2 == "TS"
        ? greet_messages.EN.BWMS_troubleshooting
        : chatMode.mode_2 == "MT"
        ? greet_messages.EN.BWMS_maintenance
        : chatMode.mode_2 == "GI"
        ? greet_messages.EN.BWMS_generalinquery
        : greet_messages.EN.BWMS_except
      : chatMode.mode_2 == "TS"
      ? greet_messages.KO.BWMS_troubleshooting
      : chatMode.mode_2 == "MT"
      ? greet_messages.KO.BWMS_maintenance
      : chatMode.mode_2 == "GI"
      ? greet_messages.KO.BWMS_generalinquery
      : greet_messages.KO.BWMS_except,
  ];
  // console.log({ chatMode });

  // const ffmpeg = createFFmpeg({
  //     corePath: 'https://unpkg.com/@ffmpeg/core@0.10.0/dist/ffmpeg-core.js',
  //     log: true,
  // });

  // const handleInputText = (value) => {
  //   setInputValue(value);
  // };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        // 키워드 리스트 숨기기 로직 추가 (예: 상태 변경)
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleFileChange = (event) => {
    if (APP_STAGE === "DEV") {  
      console.log("handleFileChange");
    }
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files); // FileList를 상태로 설정
    } else {
      setSelectedFile(null); // 파일이 없을 경우 null로 설정
    }
  };

  useEffect(() => {
    // console.log(roomId)
    setCurrentRoomId(roomId);
  }, [roomId]);

  //   const handleFileUpload = async () => {
  //     if (!selectedFile) return;

  //     const formData = new FormData();
  //     // formData.append(selectedFile);
  //     // FileList에서 각 파일을 FormData에 추가합니다.
  //     Array.from(selectedFile).forEach((file) => {
  //       formData.append(`files`, file); // 각 파일에 고유한 이름을 지정
  //     });

  //     // setTimeout(() => {
  //     await dispatch(
  //       chatMessageFile({
  //         room_id: roomId,
  //         formData: formData,
  //         message_type: "image_url",
  //         token: token,
  //       })
  //     );
  //     // ).then(() => {
  //     await dispatch(
  //       chatMessageGet({ room_id: roomId, token: token, offset: 0 })
  //     );
  //     setSelectedFile(null);
  //     //   });
  //     // }, 3000);
  //   };

  const handleNewChat = () => {
    dispatch(
      chatRoomCreate({
        token,
        room_name: "새대화",
        room_type: "",
        chatbot_en_id: 1,
        chatbot_kr_id: 2,
        group_id: user.group_id,
        user_id: user.user_id,
      })
    ).then(() => {
      dispatch(chatRoomGet({ token }));
    });
  };

  const handleFileUpload = async () => {
    if (APP_STAGE === "DEV") {  
      console.log("handleFileUpload");
    }
    if (!selectedFile) return;

    const formData = new FormData();
    let isValid = true;

    // 이미지 파일 MIME 타입 리스트
    const imageTypes = ["image/jpeg", "image/png", "image/jpg"];

    // FileList에서 각 파일을 확인
    Array.from(selectedFile).forEach((file) => {
      if (imageTypes.includes(file.type)) {
        formData.append("files", file); // 이미지 파일만 추가
      } else {
        isValid = false; // 이미지 파일이 아닌 경우
      }
    });

    if (!isValid) {
      alert("이미지 파일 형식만 업로드 가능합니다 (JPEG, JPG, PNG)."); // 에러 메시지 표시
      return; // 함수 종료
    }
    

    // try {
    //   // 이미지 파일만 업로드
    //   await dispatch(
    //     chatMessageFile({
    //       room_id: roomId,
    //       formData: formData,
    //       message_type: "image_url",
    //       token: token,
    //     })
    //   );

    //   // 메시지 목록 다시 불러오기
    //   await dispatch(
    //     chatMessageGet({ room_id: roomId, token: token, offset: 0 })
    //   );
    //   setSelectedFile(null);
    // } catch (error) {
    //   console.error("파일 업로드 중 오류 발생:", error);
    // }

    const sendText = inputValue;
    setInputValue("");
    if (APP_STAGE === "DEV") {  
      console.log({ chatSide });
    }

    if (!chatMain.roomId) {
      if (APP_STAGE === "DEV") {  
        console.log("RoomId is null");
      }
      //room 생성 후 진행
      dispatch(
        chatRoomCreate({
          token,
          room_name: sendText,
          room_type: "chatbot",
          chat_type: chatMode.mode_1 + "-" + chatMode.mode_2,
        })
      ).then((payload) => {
        if (APP_STAGE === "DEV") {  
          console.log({ payload });
        }
        dispatch(chatRoomGet({ token })).then(async () => {
          dispatch(setChatRoomId(payload.payload.room.room_id));
          dispatch(sidebarSelRoom([0, 0]));
          if (chatMode.isNewChat) {
            dispatch(
              setChatMode({
                mode_1: chatMode.mode_1 || "BWMS",
                mode_2: chatMode.mode_2,
                isNewChat: false,
              })
            );
          }
          // console.log({ room_id: payload.payload.room.room_id })
          // console.log({ roomId:chatMain.roomId })

          const _roomId = payload.payload.room.room_id;
          // console.log({ roomId2:_roomId })

          // console.log({greet_message})
          dispatch(
            chatMessageSend({
              token,
              room_id: _roomId,
              message_text: greet_message[0].text,
              message_type: "greet",
              chat_type: chatMode.mode_1 + "-" + chatMode.mode_2,
            })
          );
          dispatch(chatMessageAddBot(greet_message[0].text));
          dispatch(chatMessageAdd(sendText));
          // console.log("send handleSendMessage fetch")
          //"idle" | "thinking" | "searching" | "generating" | "optimizing";
          
          dispatch(setStreamStatus("thinking"));
          await dispatch(
            chatMessageFile({
              room_id: _roomId,
              formData: formData,
              message_type: "image_url",
              token: token,
            })
          );
          dispatch(setStreamStatus("searching"));
          
          // console.log({ room_id: _roomId, token: token, offset: 0 })

          dispatch(
            chatMessageGet({ room_id: _roomId, token: token, offset: 0 })
          ).then((action) => {
            if (APP_STAGE === "DEV") {  
              console.log("input");
              console.log(
                action.payload.messages[action.payload.messages?.length - 1]
                  .references
              );
            }
            if (
              action.payload.messages[action.payload.messages?.length - 1]
                .references.length
            ) {
              const url =
                action.payload.messages[action.payload.messages?.length - 1]
                  .references[0].pdf_url;
              const filename =
                action.payload.messages[action.payload.messages?.length - 1]
                  .references[0].file_nm;

              const pageList = action.payload.messages[
                action.payload.messages?.length - 1
              ].references[0].pdf_page
                .replace(/[\[\]]/g, "")
                .split(",")
                .map(Number);

              const page = Math.min(...pageList);

              dispatch(
                setDocViewer({
                  docUrl: url,
                  docPage: page,
                  docTitle: filename,
                  isOn: docViewer.isOn,
                })
              );

              if (!docViewer.isOn) {
                document.body.classList.toggle("toggle-sidebar-doc");
                dispatch(setToggleDocViewer());
              }
            }
          });
        });
      });
    } else {
      if (APP_STAGE === "DEV") {  
        console.log({ roomId: chatMain.roomId });
      }
      dispatch(chatMessageAdd(sendText));
      // console.log("send handleSendMessage fetch")
      dispatch(setStreamStatus("thinking"));
      await dispatch(
        chatMessageFile({
          room_id: chatMain.roomId,
          formData: formData,
          message_type: "image_url",
          token: token,
        })
      );
      dispatch(setStreamStatus("searching"));

      if (APP_STAGE === "DEV") {  
        console.log({ room_id: roomId, token: token, offset: 0 });
      }

      dispatch(
        chatMessageGet({ room_id: roomId, token: token, offset: 0 })
      ).then((action) => {
        if (APP_STAGE === "DEV") {  
          console.log("input");
          console.log(
            action.payload.messages[action.payload.messages?.length - 1].references
          );
        }
        if (
          action.payload.messages[action.payload.messages?.length - 1].references
            .length
        ) {
          const url =
            action.payload.messages[action.payload.messages?.length - 1]
              .references[0].pdf_url;
          const filename =
            action.payload.messages[action.payload.messages?.length - 1]
              .references[0].file_nm;

          const pageList = action.payload.messages[
            action.payload.messages?.length - 1
          ].references[0].pdf_page
            .replace(/[\[\]]/g, "")
            .split(",")
            .map(Number);

          const page = Math.min(...pageList);

          dispatch(
            setDocViewer({
              docUrl: url,
              docPage: page,
              docTitle: filename,
              isOn: docViewer.isOn,
            })
          );

          if (!docViewer.isOn) {
            document.body.classList.toggle("toggle-sidebar-doc");
            dispatch(setToggleDocViewer());
          }
        }
      });
    }
  
  };

  const handleSendMessage = async () => {
    if (!inputValue.trim()) return;
    const sendText = inputValue;
    setInputValue("");
    if (APP_STAGE === "DEV") {  
      console.log({ chatSide });
    }

    if (!chatMain.roomId) {
      if (APP_STAGE === "DEV") {  
        console.log("RoomId is null");
      }
      //room 생성 후 진행
      dispatch(
        chatRoomCreate({
          token,
          room_name: sendText,
          room_type: "chatbot",
          chat_type: chatMode.mode_1 + "-" + chatMode.mode_2,
        })
      ).then((payload) => {
        if (APP_STAGE === "DEV") {  
          console.log({ payload });
        }
        dispatch(chatRoomGet({ token })).then(async () => {
          dispatch(setChatRoomId(payload.payload.room.room_id));
          dispatch(sidebarSelRoom([0, 0]));
          if (chatMode.isNewChat) {
            dispatch(
              setChatMode({
                mode_1: chatMode.mode_1 || "BWMS",
                mode_2: chatMode.mode_2,
                isNewChat: false,
              })
            );
          }
          // console.log({ room_id: payload.payload.room.room_id })
          // console.log({ roomId:chatMain.roomId })

          const _roomId = payload.payload.room.room_id;
          // console.log({ roomId2:_roomId })

          // console.log({greet_message})
          dispatch(
            chatMessageSend({
              token,
              room_id: _roomId,
              message_text: greet_message[0].text,
              message_type: "greet",
              chat_type: chatMode.mode_1 + "-" + chatMode.mode_2,
            })
          ).then(() => {
            dispatch(chatMessageAddBot(greet_message[0].text));
            dispatch(chatMessageAdd(sendText));
          });
          
          // console.log("send handleSendMessage fetch")
          //"idle" | "thinking" | "searching" | "generating" | "optimizing";
          
          dispatch(setStreamStatus("thinking"));
          const streamResponse = await fetch(BASE_URL + "/api/chatbot/stream", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            body: JSON.stringify({
              room_id: _roomId,
              message_text: sendText,
              message_type: "text",
              chat_type: chatMode.mode_1 + "-" + chatMode.mode_2,
            }),
          });


          const reader = streamResponse.body.getReader();
          const decoder = new TextDecoder();
          let done = false;
          let jsonText = "";
          let string = "";
          let first = true;
          let initialStatusHandled = false;
          let statusStep=0

          dispatch(setStreamStatus("searching"));
          let bufferedData = "";
          while (!done) {
            if (APP_STAGE === "DEV") {  
              console.log("loop");
            }
            const { value, done: readerDone } = await reader.read();
            done = readerDone;
            bufferedData += decoder.decode(value, { stream: true });
            
            if (!initialStatusHandled) {
              if (statusStep == 0 && bufferedData.includes("[Analyzing Dialog]")) {
                bufferedData = bufferedData.replace("[Analyzing Dialog]", "");
                dispatch(setStreamStatus("generating"));
                statusStep = 1;
              }else if (statusStep == 1 && bufferedData.includes("[Searching]")) {
                bufferedData = bufferedData.replace("[Searching]", "");
                dispatch(setStreamStatus("searching"));
                statusStep = 2;
              }else if (statusStep == 2 && bufferedData.includes("[Analyzing Dialog]")) {
                bufferedData = bufferedData.replace("[Analyzing Dialog]", "");
                dispatch(setStreamStatus("optimizing"));
                statusStep = 3;
              }else if (statusStep == 3) {
                dispatch(setStreamStatus("idle")); 
                initialStatusHandled = true;
              }

              if(chatMode.mode_2 == "GI"){
                dispatch(setStreamStatus("idle")); 
                initialStatusHandled = true;
              }
            }
            // console.log(bufferedData)
            const parts = bufferedData.split("}\n{");

            for (let i = 0; i < parts.length; i++) {
              let completeJson;

              if (i === 0) {
                completeJson = parts[i] + "}";
              } else if (i === parts.length - 1 && !done) {
                bufferedData = "{" + parts[i];
                break;
              } else {
                completeJson = "{" + parts[i] + "}";
              }

              try {
                const parmessages = JSON.parse(completeJson);
                string += parmessages.text;
                // console.log(parmessages.text)

                if (parmessages.extras) {
                  done = true;
                }

                bufferedData = "";

                if(initialStatusHandled){
                  dispatch(setStreamStatus("idle")); 
                  dispatch(
                    addMessages({
                    type: "basic",
                    isBot: true,
                    first: first,
                    message_text: string,
                    message_type: "text",
                    hasDoc: false,
                    hasImg: false,
                    sendTime: "",
                  })
                );
                first = false;
              }
            } catch (error) {
              if (APP_STAGE === "DEV") {  
                console.log("Error parsing JSON:", error);
              }
            }
            }
          }
          // console.log({ room_id: _roomId, token: token, offset: 0 })

          dispatch(
            chatMessageGet({ room_id: _roomId, token: token, offset: 0 })
          ).then((action) => {
            dispatch(setStreamStatus("idle")); 
            if (APP_STAGE === "DEV") {  
              console.log("input");
              console.log(
                action.payload.messages[action.payload.messages?.length - 1]
                .references
              );
            }
            if (
              action.payload.messages[action.payload.messages?.length - 1]
                .references.length
            ) {
              const url =
                action.payload.messages[action.payload.messages?.length - 1]
                  .references[0].pdf_url;
              const filename =
                action.payload.messages[action.payload.messages?.length - 1]
                  .references[0].file_nm;

              const pageList = action.payload.messages[
                action.payload.messages?.length - 1
              ].references[0].pdf_page
                .replace(/[\[\]]/g, "")
                .split(",")
                .map(Number);

              const page = Math.min(...pageList);

              dispatch(
                setDocViewer({
                  docUrl: url,
                  docPage: page,
                  docTitle: filename,
                  isOn: docViewer.isOn,
                })
              );

              if (!docViewer.isOn) {
                document.body.classList.toggle("toggle-sidebar-doc");
                dispatch(setToggleDocViewer());
              }
            }
          });
        });
      });
    } else {
      if (APP_STAGE === "DEV") {  
        console.log({ roomId: chatMain.roomId });
      }
      dispatch(chatMessageAdd(sendText));
      // console.log("send handleSendMessage fetch")
      dispatch(setStreamStatus("thinking"));
      const streamResponse = await fetch(BASE_URL + "/api/chatbot/stream", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          room_id: roomId,
          message_text: sendText,
          message_type: "text",
          chat_type: chatMode.mode_1 + "-" + chatMode.mode_2,
        }),
      });
      dispatch(setStreamStatus("searching"));

      if (APP_STAGE === "DEV") {  
        console.log("1 !!!!");
      }
      const reader = streamResponse.body.getReader();
      if (APP_STAGE === "DEV") {  
        console.log("2 !!!!");
      }
      const decoder = new TextDecoder();
      let done = false;
      let jsonText = "";
      let string = "";
      let first = true;
      let bufferedData = "";
      let initialStatusHandled = false;
      let statusStep=0

      if (APP_STAGE === "DEV") {  
        console.log("3 !!!!");
      }
      while (!done) {
        if (APP_STAGE === "DEV") {  
          console.log("loop !!!!");
        }
        const { value, done: readerDone } = await reader.read();
        done = readerDone;
        bufferedData += decoder.decode(value, { stream: true });

        if (!initialStatusHandled) {
          if (statusStep == 0 && bufferedData.includes("[Analyzing Dialog]")) {
            bufferedData = bufferedData.replace("[Analyzing Dialog]", "");
            dispatch(setStreamStatus("generating"));
            statusStep = 1;
          }else if (statusStep == 1 && bufferedData.includes("[Searching]")) {
            bufferedData = bufferedData.replace("[Searching]", "");
            dispatch(setStreamStatus("searching"));
            statusStep = 2;
          }else if (statusStep == 2 && bufferedData.includes("[Analyzing Dialog]")) {
            bufferedData = bufferedData.replace("[Analyzing Dialog]", "");
            dispatch(setStreamStatus("optimizing"));
            statusStep = 3;
          }else if (statusStep == 3) {
            dispatch(setStreamStatus("idle")); 
            initialStatusHandled = true;
          }

          if(chatMode.mode_2 == "GI"){
            dispatch(setStreamStatus("idle")); 
            initialStatusHandled = true;
          }
        }

        const parts = bufferedData.split("}\n{");

        for (let i = 0; i < parts.length; i++) {
          let completeJson;

          if (i === 0) {
            completeJson = parts[i] + "}";
          } else if (i === parts.length - 1 && !done) {
            bufferedData = "{" + parts[i];
            break;
          } else {
            completeJson = "{" + parts[i] + "}";
          }

          try {
            // console.log(completeJson)
            const parmessages = JSON.parse(completeJson);
            string += parmessages.text;
            // console.log(parmessages.text)

            if (parmessages.extras) {
              done = true; 
            }

            bufferedData = "";
            if(initialStatusHandled){
              dispatch(setStreamStatus("idle")); 
              dispatch(
                addMessages({
                type: "basic",
                isBot: true,
                first: first,
                message_text: string,
                message_type: "text",
                hasDoc: false,
                hasImg: false,
                sendTime: "",
              })
              );
              first = false;
            }
          } catch (error) {
            if (APP_STAGE === "DEV") {  
              console.log("Error parsing JSON:", error);
            }
          }
        }
      }
      if (APP_STAGE === "DEV") {  
        console.log({ room_id: roomId, token: token, offset: 0 });
      }

      dispatch(
        chatMessageGet({ room_id: roomId, token: token, offset: 0 })
      ).then((action) => {
        dispatch(setStreamStatus("idle")); 
        if (APP_STAGE === "DEV") {  
          console.log("input");
          // console.log(action.payload.messages)
          // console.log(
          //   action.payload.messages[action.payload.messages?.length - 1].references
          // );
        }
        if (
          action.payload.messages[action.payload.messages?.length - 1].references.length
        ) {
          const url =
            action.payload.messages[action.payload.messages?.length - 1]
              .references[0].pdf_url;
          const filename =
            action.payload.messages[action.payload.messages?.length - 1]
              .references[0].file_nm;

          const pageList = action.payload.messages[
            action.payload.messages?.length - 1
          ].references[0].pdf_page
            .replace(/[\[\]]/g, "")
            .split(",")
            .map(Number);

          const page = Math.min(...pageList);

          dispatch(
            setDocViewer({
              docUrl: url,
              docPage: page,
              docTitle: filename,
              isOn: docViewer.isOn,
            })
          );

          if (!docViewer.isOn) {
            document.body.classList.toggle("toggle-sidebar-doc");
            dispatch(setToggleDocViewer());
          }
        }
      });
    }
  };

  useEffect(() => {
    if (selectedFile) {
      handleFileUpload();
    }
  }, [selectedFile]);

  const handleClickSend = (e) => {
    e.preventDefault();
    handleSendMessage();
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && e.nativeEvent.isComposing === false) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const debouncedHandleInputText = useRef(
    debounce((value) => {
      dispatch(keywordGetList({ search: value, token }));
      if (value.length > 0) {
        const filtered = keywordList.keywords.filter((keyword) =>
          keyword.keyword_text.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredKeywords(filtered);
        setShowDropdown(filtered.length > 0);
      } else {
        setShowDropdown(false);
      }
    }, 300) // 300ms 후에 실행
  ).current;

  const handleInputText = (e) => {
    const value = e.target.value;
    setInputValue(value);
    debouncedHandleInputText(value); // 디바운스된 함수 호출
  };

  const handleKeywordClick = (keyword) => {
    setInputValue(keyword);
    setShowDropdown(false);
  };

  const moveServicePage = () => {
    const roomId = chatMain.roomId;
    navigate("/email-send/" + roomId);
  };
  // console.log({keywordList})
  return (
    <div className="sticky inset-x-0 bottom-0 flex items-center max-w-4lg w-full">
      <div className="relative z-10 flex w-full flex-col bg-background mx-auto max-w-3xl px-2 sm:px-3 md:px-4 pb-0 sm:pb-0 md:pb-0">
        <div
          className="relative rounded-t-xl bg-white text-gray-600"
          style={{ opacity: "1", height: "auto", willChange: "auto" }}
        >
          <div className="">
            {isKeywordListVisible && keywordList?.keywords?.length > 0 && (
              <div
                ref={dropdownRef}
                className="absolute w-full bottom-0 translate-y-[20px] mb-2 bg-blue-50 !border !focus-within:border-blue-600 !focus:border-blue-600 !border-blue-400 !border-b-0 bg-alpha-50  rounded-tl-lg rounded-tr-lg transition-colors  z-10 transition "
              >
                <div className="p-2">
                  {/* <h6 className="text-gray-700 font-bold mb-2">추천 키워드</h6> */}
                  <div className="flex flex-col gap-0 overflow-x-auto">
                    {keywordList.keywords.slice(0, 5).map((keyword, index) => (
                      <div
                        key={index}
                        role="button"
                        tabIndex="0"
                        className="flex-shrink-0 border-b text-blue-800 px-3 py-1 cursor-pointer hover:bg-blue-200 transition"
                        onClick={() => handleKeywordClick(keyword.keyword_text)}
                        onKeyPress={(e) => {
                          if (e.key === "Enter")
                            handleKeywordClick(keyword.keyword_text);
                        }}
                      >
                        <p className="truncate mb-0 text-sm">
                          {keyword.keyword_text}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="flex items-center justify-between gap-2 py-1.5 pl-3 pr-2 text-xs text-gray-900 md:text-sm">
            {!first ? (
              <a
                onClick={() => moveServicePage()}
                className="relative group/service w-full px-2 text-right text-xs text-gray-600 text-token-text-secondary empty:hidden md:px-[60px]"
              >
                {user.user_lang == "KO"
                  ? "그래도 문제가 해결되지 않으신가요? S&Sys 담당자에게 서비스를 신청하세요. 바로가기 >>"
                  : "Still not solving the problem? Apply for service. Go to >>"}
                {/* <span
                  className="absolute -top-1/2 left-1/2 transform -translate-y-1/2 ml-2 p-2 z-30 bg-black text-white 
                                    text-center text-xs rounded opacity-[0.001]  group-hover/service:opacity-100 
                                    transition-opacity duration-300 pointer-events-none"
                >
                  <span>(업데이트 예정) 서비스 페이지로 이동</span>
                </span> */}
              </a>
            ) : (
              <a
                onClick={() => moveServicePage()}
                className="relative w-full px-2 text-right text-xs text-gray-600 text-token-text-secondary empty:hidden md:px-[60px]"
              ></a>
            )}
          </div>
        </div>
        <div className="rounded-b-xl">
          <form className="bg-blue-50 !border !focus-within:border-blue-600 !focus:border-blue-600 !border-blue-400 bg-alpha-50 relative rounded-xl transition-colors">
            <div
              aria-hidden="true"
              className="animate-in fade-in slide-in-from-bottom absolute inset-x-0 z-10 m-auto flex w-fit items-center justify-center animate-out fade-out slide-out-to-bottom fill-mode-forwards pointer-events-none -top-16"
            >
              <button
                onClick={() => handleFileUpload}
                className="animate-slowBounce shadow-base hover:bg-muted size-7 items-center justify-center rounded-full text-gray-500 drop-shadow-md hidden"
                type="button"
                data-state="closed"
          
              >
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="size-5"
                >
                  <path
                    d="M3.13523 6.15803C3.3241 5.95657 3.64052 5.94637 3.84197 6.13523L7.5 9.56464L11.158 6.13523C11.3595 5.94637 11.6759 5.95657 11.8648 6.15803C12.0536 6.35949 12.0434 6.67591 11.842 6.86477L7.84197 10.6148C7.64964 10.7951 7.35036 10.7951 7.15803 10.6148L3.15803 6.86477C2.95657 6.67591 2.94637 6.35949 3.13523 6.15803Z"
                    fill="currentColor"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            <div className="bg-background relative z-10 grid rounded-xl">
              <label className="sr-only" for="chat-main-textarea">
                Chat input
              </label>
              <div className="flex min-w-0 flex-1 flex-col">
                <textarea
                  className="resize-none overflow-auto w-full flex-1 bg-transparent p-3 pb-1.5 text-sm outline-none placeholder-gray-500"
                  style={{
                    height: "42px",
                    minHeight: "42px",
                    maxHeight: "384px",
                  }}
                  rows="1"
                  maxLength="1000"
                  value={inputValue}
                  onChange={(e) => handleInputText(e)}
                  onKeyDown={handleKeyPress}
                  placeholder={
                    user.user_lang == "KO"
                      ? "질문을 입력해 주세요."
                      : "Please enter your question."
                  }
                  title={
                    user.user_lang == "KO"
                      ? "질문을 입력해 주세요."
                      : "Please enter your question."
                  }
                />
              </div>
              <div className="flex justify-end items-center gap-2 p-3">
                <label
                  htmlFor="fileUpload"
                  className="flex items-center cursor-pointer mb-1 me-1 flex h-8 w-8 items-center justify-center rounded-full bg-blue-500 text-white transition-colors hover:opacity-70 focus-visible:outline-none focus-visible:outline-black"
                >
                  <input
                    type="file"
                    id="fileUpload"
                    className="hidden" // input을 숨깁니다.
                    onChange={handleFileChange}
                    accept="image/*, .png, .jpg, .jpeg, .pdf"
                    disabled
                  />
                  <i className="bi bi-paperclip text-white  text-gray-600 hover:text-gray-800" />
                </label>
                <div className="ml-auto flex items-center gap-2"></div>
                <div
                  // disabled
                  className="relative group/voice mb-1 me-1 flex h-8 w-8 items-center justify-center rounded-full bg-blue-500 text-white transition-colors hover:opacity-70 focus-visible:outline-none focus-visible:outline-black "
                  title="음성입력"
                  // data-toggle="modal"
                  // data-target="#addVoiceRecordModal"
                  disabled
                >
                  <i className="bi bi-mic" />
                  {/* <span
                    className="absolute -top-1/2 left-1/2 transform -translate-y-1/2 w-20 ml-2 p-2 z-30 bg-black text-white 
                                            text-center text-xs rounded opacity-[0.001]  group-hover/voice:opacity-100 
                                            transition-opacity duration-300 pointer-events-none"
                  >
                    (업데이트 예정)
                  </span> */}
                </div>
                <button
                  onClick={handleClickSend}
                  className="mb-1 me-1 flex h-8 w-8 items-center justify-center rounded-full bg-blue-500 text-white transition-colors hover:opacity-70 "
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon-2xl"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15.1918 8.90615C15.6381 8.45983 16.3618 8.45983 16.8081 8.90615L21.9509 14.049C22.3972 14.4953 22.3972 15.2189 21.9509 15.6652C21.5046 16.1116 20.781 16.1116 20.3347 15.6652L17.1428 12.4734V22.2857C17.1428 22.9169 16.6311 23.4286 15.9999 23.4286C15.3688 23.4286 14.8571 22.9169 14.8571 22.2857V12.4734L11.6652 15.6652C11.2189 16.1116 10.4953 16.1116 10.049 15.6652C9.60265 15.2189 9.60265 14.4953 10.049 14.049L15.1918 8.90615Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
          </form>
        </div>
        {!first ? (
          <p className="py-2 text-center text-xs text-gray-500">
            {user.user_lang == "KO"
              ? "중요한 정보는 다시 확인하세요."
              : "Please double-check important information."}
          </p>
        ) : (
          <p className="py-2 text-center text-xs text-gray-500"></p>
        )}
      </div>
    </div>
  );
  //   return (
  //     <div className="input-field">
  //       {/* 키워드 리스트 표시 */}
  //       {keywordList && keywordList.length > 0 && (
  //         <div className="keyword-list">
  //           {keywordList.map((keyword, index) => (
  //             <div
  //               key={index}
  //               className="keyword-item"
  //               onClick={() => handleKeywordClick(keyword.keyword_text)} // 클릭 핸들러 추가
  //             >
  //               {keyword.keyword_text}
  //             </div>
  //           ))}
  //         </div>
  //       )}
  //       <div className="textarea-warp">
  //         <textarea
  //           className="textarea"
  //           rows="1"
  //           maxLength="1000"
  //           value={inputValue}
  //           onChange={(e) => handleInputText(e.target.value)}
  //           onKeyDown={handleKeyPress}
  //           placeholder="질문을 입력해 주세요."
  //           title="질문을 입력해 주세요."
  //         />
  //       </div>
  //       <div className="btn-wrap">
  //         <div className="btn-plus" title="더보기">
  //           <input
  //             type="file"
  //             style={{ display: "none" }}
  //             id="file-upload"
  //             onChange={handleFileChange}
  //             multiple
  //           />
  //           <label htmlFor="file-upload">
  //             <i className="bi bi-plus" />
  //           </label>
  //         </div>
  //         <div
  //           className="btn-mic"
  //           // onClick={handleClickRecording}
  //           title="음성입력"
  //           data-toggle="modal"
  //           data-target="#addVoiceRecordModal"
  //         >
  //           <i className="bi bi-mic" />
  //         </div>
  //         <div className="btn-send" title="전송" onClick={handleSendMessage}>
  //           <i className="bi bi-send" />
  //         </div>
  //       </div>
  //     </div>
  //   );
}

export function VoiceRecordModal(props) {
  const dispatch = useAppDispatch();
  const roomId = useAppSelector(selectChatRoomId);
  const token = useAppSelector(selectToken);
  const user = useAppSelector(selectUser);
  const chatMain = useAppSelector(selectChatMain);
  const chatMode = useAppSelector(selectChatMode);
  const docViewer = useAppSelector(selectDocViewer);

  const { modalName } = props.modalName;
  const [isRecording, setIsRecording] = useState(false);
  const [recordingText, setRecordingText] = useState("");
  const [state, setState] = useState("init"); //"init"|"recording"|"modifying"|"send";

  const greet_message = [
    user?.user_lang == "EN"
      ? chatMode.mode_2 == "TS"
        ? greet_messages.EN.BWMS_troubleshooting
        : chatMode.mode_2 == "MT"
        ? greet_messages.EN.BWMS_maintenance
        : chatMode.mode_2 == "GI"
        ? greet_messages.EN.BWMS_generalinquery
        : greet_messages.EN.BWMS_except
      : chatMode.mode_2 == "TS"
      ? greet_messages.KO.BWMS_troubleshooting
      : chatMode.mode_2 == "MT"
      ? greet_messages.KO.BWMS_maintenance
      : chatMode.mode_2 == "GI"
      ? greet_messages.KO.BWMS_generalinquery
      : greet_messages.KO.BWMS_except,
  ];

  const onTranscribe = async (blob) => {
    const base64 = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
    const body = JSON.stringify({ file: base64, model: "whisper-1" });
    const headers = { "Content-Type": "application/json" };
    const { default: axios } = await import("axios");
    const response = await axios.post("/api/whisper", body, {
      headers,
    });
    const { text } = await response.data;
    return {
      blob,
      text,
    };
  };

  const {
    recording,
    speaking,
    transcribing,
    transcript,
    pauseRecording,
    startRecording,
    stopRecording,
  } = useWhisper({
    response_format: "text", // output text instead of json
    temperature: 0.8, // random output
    whisperConfig: {
      language: "ko",
    },
    streaming: true,
    nonStop: false,
    // stopTimeout: 3000,
    apiKey: OPENAI_API_KEY, // YOUR_OPEN_AI_TOKEN
  });

  const handleClickRecording = () => {
    if (isRecording) {
      handlerStopRecording();
    } else {
      handlerStartRecording();
    }
  };

  const handlerStartRecording = () => {
    startRecording();
    setIsRecording(true);
    setState("recording");
  };

  const handlerStopRecording = () => {
    stopRecording();
    setIsRecording(false);
    setRecordingText(transcript.text);
    setState("modifying");
  };

  const handlerSendMessage = async () => {
    if (!recordingText.trim()) return;

    const sendText = recordingText;
    handlerStopRecording();
    setRecordingText("");

    if (!chatMain.roomId) {
      if (APP_STAGE === "DEV") {  
        console.log("RoomId is null");
      }
      //room 생성 후 진행
      dispatch(
        chatRoomCreate({
          token,
          room_name: sendText,
          room_type: "chatbot",
          chat_type: chatMode.mode_1 + "-" + chatMode.mode_2,
        })
      ).then((payload) => {
        if (APP_STAGE === "DEV") {  
          console.log({ payload });
        }
        dispatch(chatRoomGet({ token })).then(async () => {
          dispatch(setChatRoomId(payload.payload.room.room_id));
          dispatch(sidebarSelRoom([0, 0]));
          if (chatMode.isNewChat) {
            dispatch(
              setChatMode({
                mode_1: chatMode.mode_1 || "BWMS",
                mode_2: chatMode.mode_2,
                isNewChat: false,
              })
            );
          }
          const _roomId = payload.payload.room.room_id;
          dispatch(
            chatMessageSend({
              token,
              room_id: _roomId,
              message_text: greet_message[0].text,
              message_type: "greet",
              chat_type: chatMode.mode_1 + "-" + chatMode.mode_2,
            })
          );
          dispatch(chatMessageAddBot(greet_message[0].text));
          dispatch(chatMessageAdd(sendText));
          // console.log("send handleSendMessage fetch")
          try {
            const streamResponse = await fetch(
              BASE_URL + "/api/chatbot/stream",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: token,
                },
                body: JSON.stringify({
                  room_id: _roomId,
                  message_text: sendText,
                  message_type: "text",
                  chat_type: chatMode.mode_1 + "-" + chatMode.mode_2,
                }),
              }
            );

            if (!streamResponse.ok) {
              alert(
                `Error ${streamResponse.status}: ${streamResponse.statusText}`
              );
              throw new Error(
                `Error ${streamResponse.status}: ${streamResponse.statusText}`
              );
              return;
            }

            const reader = streamResponse.body.getReader();
            const decoder = new TextDecoder();
            let done = false;
            let jsonText = "";
            let string = "";
            let first = true;

            let bufferedData = "";
            while (!done) {
              if (APP_STAGE === "DEV") {  
                console.log("loop");
              }
              const { value, done: readerDone } = await reader.read();
              done = readerDone;
              bufferedData += decoder.decode(value, { stream: true });

              // console.log(bufferedData)
              const parts = bufferedData.split("}\n{");

              for (let i = 0; i < parts.length; i++) {
                let completeJson;

                if (i === 0) {
                  completeJson = parts[i] + "}";
                } else if (i === parts.length - 1 && !done) {
                  bufferedData = "{" + parts[i];
                  break;
                } else {
                  completeJson = "{" + parts[i] + "}";
                }

                try {
                  const parmessages = JSON.parse(completeJson);
                  string += parmessages.text;
                  // console.log(parmessages.text)

                  if (parmessages.extras) {
                    done = true;
                  }

                  bufferedData = "";

                  dispatch(
                    addMessages({
                      type: "basic",
                      isBot: true,
                      first: first,
                      message_text: string,
                      message_type: "text",
                      hasDoc: false,
                      hasImg: false,
                      sendTime: "",
                    })
                  );
                  first = false;
                } catch (error) {
                  if (APP_STAGE === "DEV") {  
                    console.log("Error parsing JSON:", error);
                  }
                }
              }
            }
            // console.log({ room_id: _roomId, token: token, offset: 0 })

            dispatch(
              chatMessageGet({ room_id: _roomId, token: token, offset: 0 })
            ).then((action) => {
              if (APP_STAGE === "DEV") {  
                console.log("input");
                console.log(
                  action.payload.messages[action.payload.messages?.length - 1]
                  .references
                );
              }
              if (
                action.payload.messages[action.payload.messages?.length - 1]
                  .references.length
              ) {
                const url =
                  action.payload.messages[action.payload.messages?.length - 1]
                    .references[0].pdf_url;
                const filename =
                  action.payload.messages[action.payload.messages?.length - 1]
                    .references[0].file_nm;

                const pageList = action.payload.messages[
                  action.payload.messages?.length - 1
                ].references[0].pdf_page
                  .replace(/[\[\]]/g, "")
                  .split(",")
                  .map(Number);

                const page = Math.min(...pageList);

                dispatch(
                  setDocViewer({
                    docUrl: url,
                    docPage: page,
                    docTitle: filename,
                    isOn: docViewer.isOn,
                  })
                );

                if (!docViewer.isOn) {
                  document.body.classList.toggle("toggle-sidebar-doc");
                  dispatch(setToggleDocViewer());
                }
              }
            });
          } catch (error) {
            if (APP_STAGE === "DEV") {  
              console.log("Error parsing JSON:", error);
            }
          }
        });
      });
    } else {
      if (APP_STAGE === "DEV") {  
        console.log({ roomId: chatMain.roomId });
      }
      dispatch(chatMessageAdd(sendText));
      // console.log("send handleSendMessage fetch")
      try {
        const streamResponse = await fetch(BASE_URL + "/api/chatbot/stream", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            room_id: roomId,
            message_text: sendText,
            message_type: "text",
            chat_type: chatMode.mode_1 + "-" + chatMode.mode_2,
          }),
        });
        if (APP_STAGE === "DEV") {  
          console.log("asdasdasdas: ", streamResponse.ok);
        }
        if (!streamResponse.ok) {
          alert(`Error ${streamResponse.status}: ${streamResponse.statusText}`);
          throw new Error(
            `Error ${streamResponse.status}: ${streamResponse.statusText}`
          );
          return;
        }

        if (APP_STAGE === "DEV") {  
          console.log("1 !!!!");
        }
        const reader = streamResponse.body.getReader();
        if (APP_STAGE === "DEV") {  
          console.log("2 !!!!");
        }
        const decoder = new TextDecoder();
        let done = false;
        let jsonText = "";
        let string = "";
        let first = true;
        let bufferedData = "";

        if (APP_STAGE === "DEV") {  
          console.log("3 !!!!");
        }
        while (!done) {
          if (APP_STAGE === "DEV") {  
            console.log("loop !!!!");
          }
          const { value, done: readerDone } = await reader.read();
          done = readerDone;
          bufferedData += decoder.decode(value, { stream: true });

          // console.log(bufferedData)
          const parts = bufferedData.split("}\n{");

          for (let i = 0; i < parts.length; i++) {
            let completeJson;
            if (APP_STAGE === "DEV") {  
              console.log("loop for!!!!");
            }

            if (i === 0) {
              completeJson = parts[i] + "}";
            } else if (i === parts.length - 1 && !done) {
              bufferedData = "{" + parts[i];
              break;
            } else {
              completeJson = "{" + parts[i] + "}";
            }

            try {
              // console.log(completeJson)
              const parmessages = JSON.parse(completeJson);
              string += parmessages.text;
              // console.log(parmessages.text)

              if (parmessages.extras) {
                done = true;
              }

              bufferedData = "";

              dispatch(
                addMessages({
                  type: "basic",
                  isBot: true,
                  first: first,
                  message_text: string,
                  message_type: "text",
                  hasDoc: false,
                  hasImg: false,
                  sendTime: "",
                })
              );
              first = false;
            } catch (error) {
              if (APP_STAGE === "DEV") {  
                console.log("Error parsing JSON:", error);
              }
            }
          }
        }
        if (APP_STAGE === "DEV") {  
          console.log({ room_id: roomId, token: token, offset: 0 });
        }

        dispatch(
          chatMessageGet({ room_id: roomId, token: token, offset: 0 })
        ).then((action) => {
          if (APP_STAGE === "DEV") {  
            console.log("input");
            console.log(
              action.payload.messages[action.payload.messages?.length - 1]
                .references
            );
          }
          if (
            action.payload.messages[action.payload.messages?.length - 1]
              .references.length
          ) {
            const url =
              action.payload.messages[action.payload.messages?.length - 1]
                .references[0].pdf_url;
            const filename =
              action.payload.messages[action.payload.messages?.length - 1]
                .references[0].file_nm;

            const pageList = action.payload.messages[
              action.payload.messages?.length - 1
            ].references[0].pdf_page
              .replace(/[\[\]]/g, "")
              .split(",")
              .map(Number);

            const page = Math.min(...pageList);

            dispatch(
              setDocViewer({
                docUrl: url,
                docPage: page,
                docTitle: filename,
                isOn: docViewer.isOn,
              })
            );

            if (!docViewer.isOn) {
              document.body.classList.toggle("toggle-sidebar-doc");
              dispatch(setToggleDocViewer());
            }
          }
        });
      } catch (error) {
        if (APP_STAGE === "DEV") {  
          console.log("Error parsing JSON:", error);
        }
      }
    }

    try {
      document.getElementsByClassName("modal fade show")[0].className =
        "modal fade";
      document.getElementsByClassName("modal-backdrop fade show")[0].className =
        "modal-backdrop fade";
    } catch {}
  };

  // 모달 닫기 핸들러 추가
  const handleCloseModal = () => {
    // 녹음 중이면 중지
    if (isRecording) {
      stopRecording();
    }
    
    // 상태 초기화
    setIsRecording(false);
    setRecordingText("");
    setState("init");
  };

  // useEffect를 사용하여 모달 닫기 이벤트 리스너 추가
  useEffect(() => {
    const modal = document.getElementById('addVoiceRecordModal');
    
    const handleHide = () => {
      handleCloseModal();
    };

    modal.addEventListener('hidden.bs.modal', handleHide);
    
    // 클린업 함수
    return () => {
      modal.removeEventListener('hidden.bs.modal', handleHide);
    };
  }, []);

  return (
    <div
      className="modal fade"
      id="addVoiceRecordModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="addVoiceRecordModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header border-b px-4 py-2 flex justify-between items-center">
            <h5
              className="modal-title text-lg font-semibold"
              id="addVoiceRecordModalLabel"
            >
              Voice Recording
            </h5>
            <button
              type="button"
              className="close text-gray-500 hover:text-gray-700"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleCloseModal}  
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body p-4">
            <div className="recording-info mb-4">
              <p className="text-sm text-gray-700">
                <strong>Transcribed Text:</strong> {transcript.text}
              </p>
              <div
                className={`recording-animation ${
                  isRecording ? "bg-red-500" : "bg-gray-300"
                } h-2 w-full rounded-full mt-2`}
              ></div>
              <button
                className={`mt-4 w-full py-2 px-4 rounded-md text-white ${
                  isRecording
                    ? "bg-red-600 hover:bg-red-700"
                    : "bg-[#1565c0] hover:bg-[#0d47a1]"
                }`}
                onClick={handleClickRecording}
              >
                {isRecording ? "Stop Recording" : "Start Recording"}
              </button>
            </div>
            <form className="form-group">
              {state === "modifying" && (
                <input
                  type="text"
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#1565c0]"
                  id="RecordingText"
                  onChange={(e) => {
                    setRecordingText(e.target.value);
                  }}
                  value={recordingText}
                />
              )}
            </form>
          </div>
          <div className="modal-footer border-t px-4 py-2 flex justify-end">
            <button
              type="button"
              className="btn btn-secondary mr-2"
              data-dismiss="modal"
              onClick={handleCloseModal}  
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary bg-[#1565c0] hover:bg-[#0d47a1]"
              onClick={handlerSendMessage}
            >
              Send message
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatInput;
