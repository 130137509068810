import React, { useEffect, useMemo, useState } from "react";

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../ui/table"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
    DropdownMenuSub,
    DropdownMenuSubTrigger,
    DropdownMenuSubContent,
} from "../../ui/dropdown-menu"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../../ui/dialog"

import { Plus, Search, Filter, EllipsisVertical, MoreVertical, Check, X, Trash2, UserPlus, Users } from "lucide-react"
import { Button } from "../../ui/button"
import { Input } from "../../ui/input"
import Pagination from "../Common/Pagination";
import { Checkbox } from "../../ui/checkbox";
import { adminChatbotAdd, adminGroupAdd, adminGroupUpdate } from "../../../lib/features/admin/adminSlice";
import { useAppDispatch, useAppSelector } from "../../../lib/hooks";

const ITEMS_PER_PAGE = 20;
const PlatformFeedbackTable = ({ feedbacksPlatform }: { feedbacksPlatform: any }) => {
    const dispatch = useAppDispatch();
    const token = useAppSelector((state) => state.authentication.token);

    const [currentPage, setCurrentPage] = useState(1);
    const [selectedFeedbackId, setSelectedFeedbackId] = useState<number | null>(null);
    const [detailFeedbackForm, setDetailFeedbackForm] = useState<any>({
        id: "",
        content: "",
        user_id: "",
        user_name: ""
      });
    const [newFeedbackForm, setNewFeedbackForm] = useState({
        content: "",
        user_id: "",
        user_name: ""
    });
    const [feedbackForm, setFeedbackForm] = useState(
        feedbacksPlatform.map((feedback: any) => ({
            id: feedback.feedback_id,
            content: feedback.feedback_text,
            user_id: feedback.user.user_id,
            user_name: feedback.user.user_name
      })) || []);
      
    const [isDialogOpen, setIsDialogOpen] = useState({
        modify: false,
        create: false,
        delete: false,
        detail: false,
    });
    const [sortConfig, setSortConfig] = useState({ key: "id", direction: "asc" });

    function formatDateTime(dateTimeString: string) {
        if (dateTimeString.length !== 14) {
          return "잘못된 입력 형식입니다.";
        }
    
        const year = dateTimeString.slice(0, 4);
        const month = dateTimeString.slice(4, 6);
        const day = dateTimeString.slice(6, 8);
        const hour = dateTimeString.slice(8, 10);
        const minute = dateTimeString.slice(10, 12);
        const second = dateTimeString.slice(12, 14);
    
        return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
      }
    // 전체 상태 정리
    // 활성, 잠김(이메일, 승인안됨, 비번오류, 이상감지)
    // function getStatus(group: any) {
    //     if (group.is_deleted) {
    //       return "탈퇴";
    //     } else if (!user.is_verified || !user.has_email_check || user.is_locked) {
    //       return "잠김";
    //     } else {
    //       return "활성";
    //     }
    // }
    useEffect(() => {
        setFeedbackForm(
            feedbacksPlatform.map((feedback: any) => ({
                id: feedback.feedback_id,
                content: feedback.feedback_text,
                user_id: feedback.user.user_id,
                user_name: feedback.user.user_name,
                reflection: feedback.feedback_reflection,
            })) || []
        );
    }, [feedbacksPlatform]);

    const sortedFeedbacks = useMemo(() => {
        return [...feedbackForm].sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key])
                return sortConfig.direction === "asc" ? -1 : 1;
            if (a[sortConfig.key] > b[sortConfig.key])
                return sortConfig.direction === "asc" ? 1 : -1;
            return 0;
        });
    }, [feedbackForm, sortConfig]);
    const paginatedFeedbacks = sortedFeedbacks.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        currentPage * ITEMS_PER_PAGE
    );

    const handleSort: any = (key: string) => {
        setSortConfig((currentConfig) => {
            if (currentConfig.key === key) {
                return {
                    ...currentConfig,
                    direction: currentConfig.direction === "asc" ? "desc" : "asc",
                };
            }
            return { key, direction: "asc" };
        });
    };

    
  const handleSubmit = () => {
    // dispatch(
    //     adminGroupUpdate({
    //     token: token as string,
    //     groupId: chatbotForm.id as number,
    //     groupName: chatbotForm.name as string,
    //     // groupDesc: chatbotForm.description as string,
    //     // is_admin: chatbotForm?.is_admin,
    //     is_chatbot_ts: chatbotForm?.is_chatbot_ts,
    //     is_chatbot_mt: chatbotForm?.is_chatbot_mt,
    //     is_chatbot_gi: chatbotForm?.is_chatbot_gi,
    //     is_main_manual: permissionForm?.is_main_manual,
    //     is_main_video: permissionForm?.is_main_video,
    //   })
    // ).then(() => {
    //   // userAllChange();
    //   // adminUserSignUpRequestGetChange();
    //   alert("Group information has been updated.");
    //   // setUserNm("");
    //   // setSelectedCompany(undefined);
    //   setIsDialogOpen((prev) => ({ ...prev, ["mod"]: false }));
    // });

    // setIsDialogOpen((prev) => ({ ...prev, ["su"]: false })); // Close the dialog after submitting
  };

//   const handleSubmitCreate = () => {
//     const pgroupId = pgroups.find((pgroups: any) => pgroups.pgroup_name === newChatbotForm.pgroup_name)?.pgroup_id;
//     const chatbotType = newChatbotForm.chatbot_type==="Troubleshooting"?"BWMS-TS":"BWMS-MT";
//     dispatch(
//         adminChatbotAdd({
//         token: token as string,
//         pgroupId: pgroupId as number,
//         chatbotType: chatbotType as string,
//         modelId: 1
//       })
//     ).then(() => {
//       alert("Chatbot information has been created.");
//       setIsDialogOpen((prev) => ({ ...prev, ["create"]: false }));
//       setNewChatbotForm({
//         pgroup_name: "",
//         pgroup_id: "",
//         chatbot_status: "",
//         chatbot_type: "Troubleshooting"
//     });
//     });
//   };

  
    return (
        <div className="bg-white shadow-md rounded-lg overflow-hidden p-4">
            <div className="border-b flex justify-between items-center">
                <h2 className="text-2xl font-semibold mb-4">PLATFORM FEEDBACKS</h2>
            </div>
            <Dialog open={isDialogOpen.detail} onOpenChange={() => setIsDialogOpen({ ...isDialogOpen, detail: false })}>
                
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Feedback Detail</DialogTitle>
                        <DialogDescription></DialogDescription>
                    </DialogHeader>
                    <div className="grid grid-cols-4 gap-1">
                        <div className="font-semibold col-span-1">User Name </div>
                        <div className="col-span-3">{detailFeedbackForm.user_name}</div>
                        <div className="font-semibold col-span-1">Contents</div>
                        <div className="col-span-3">{detailFeedbackForm.content}</div>
                    </div>
                    <DialogFooter>

                    </DialogFooter>
                </DialogContent>
            </Dialog>
                <Table>
                <TableHeader>
                    <TableRow>
                        {["id", "content", "user_name"].map((key) => (
                            <TableHead
                                key={key}
                                className="px-6 py-2 text-left text-xs text-nowrap font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                onClick={() => handleSort(key)}
                            >
                                {key === "id"
                                    ? "No."
                                    : key === "content"
                                        ? "Content"
                                        : key === "user_name"
                                        ? "User Name"
                                        : key === "user_id"
                                        ? "User ID"
                                        : key === "user_created_at"
                                        ? "User Created At"
                                        : key === "user_updated_at"
                                        ? "User Updated At"
                                        : ""}
                                {sortConfig.key === key && (
                                    <span className="ml-1">
                                        {sortConfig.direction === "asc" ? "▲" : "▼"}
                                    </span>
                                )}
                            </TableHead>
                        ))}
                        <TableHead>action</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {paginatedFeedbacks && paginatedFeedbacks.map((feedback) => (
                        <TableRow key={feedback.id}>
                            <TableCell>{feedback.id}</TableCell>
                            <TableCell  className="truncate max-w-xs">{feedback.content}</TableCell>
                            <TableCell>{feedback.user_name}</TableCell>
                            
                            <TableCell>
                                <DropdownMenu>
                                    <DropdownMenuTrigger>
                                        <EllipsisVertical className="h-4 w-4" />
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent>
                                        <DropdownMenuItem
                                            onClick={() =>{
                                                // setDetailChatbotForm(chatbot);
                                                // setSelectedChatbotId(chatbot.id);
                                                setDetailFeedbackForm(feedback);
                                                setIsDialogOpen({ ...isDialogOpen, detail: true }) 
                                            }}
                                        >Detail</DropdownMenuItem>
                                        {/* <DropdownMenuSeparator />
                                        <DropdownMenuItem>Delete</DropdownMenuItem> */}
                                    </DropdownMenuContent>
                                </DropdownMenu>

                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={feedbacksPlatform ? Math.ceil(feedbacksPlatform?.length / 20) : 1} totalNumber={feedbacksPlatform?.length} listName="Platform Feedback"/>
         
        </div>
    );
};

export default PlatformFeedbackTable;
