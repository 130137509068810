import React, { useEffect, useMemo, useState } from "react";


import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../ui/table"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
    DropdownMenuSub,
    DropdownMenuSubTrigger,
    DropdownMenuSubContent,
} from "../../ui/dropdown-menu"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../../ui/dialog"

import { Plus, Search, Filter, EllipsisVertical, MoreVertical, Check, X, Trash2, UserPlus, Users } from "lucide-react"
import { Button } from "../../ui/button"
import { Input } from "../../ui/input"
import Pagination from "../Common/Pagination";

const ITEMS_PER_PAGE = 20;
const APP_STAGE = process.env.REACT_APP_STAGE;

const ModelTable = ({ apilogs }: { apilogs: any }) => {
    const [currentPage, setCurrentPage] = useState(1);
    // const [newUserForm, setNewUserForm] = useState({
    //     name: "",
    //     email: "",
    //     company: "",
    //     password: "",
    //     role: "",
    //     group: "",
    // });
    const [apilogsForm, setApilogsForm] = useState(
        apilogs.map((logs: any) => ({
            id: logs.api_log_id,
            method: logs.request_method,
            url: logs.request_url,
            message: logs.response_message,
            status: logs.response_status_code,
            created_at: formatDateTime(logs.created_at),
      })) || []);

    const [sortConfig, setSortConfig] = useState({ key: "id", direction: "asc" });

    function formatDateTime(dateTimeString: string) {
        if (dateTimeString.length !== 14) {
          return "잘못된 입력 형식입니다.";
        }
    
        const year = dateTimeString.slice(0, 4);
        const month = dateTimeString.slice(4, 6);
        const day = dateTimeString.slice(6, 8);
        const hour = dateTimeString.slice(8, 10);
        const minute = dateTimeString.slice(10, 12);
        const second = dateTimeString.slice(12, 14);
    
        return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
      }
    // 전체 상태 정리
    // 활성, 잠김(이메일, 승인안됨, 비번오류, 이상감지)
    function getStatus(user: any) {
        if (user.is_deleted) {
          return "탈퇴";
        } else if (!user.is_verified || !user.has_email_check || user.is_locked) {
          return "잠김";
        } else {
          return "활성";
        }
    }
    useEffect(() => {
        setApilogsForm(
            apilogs.map((logs: any) => ({
                id: logs.api_log_id,
                method: logs.request_method,
                url: logs.request_url,
                message: logs.response_message,
                status: logs.response_status_code,
                created_at: formatDateTime(logs.created_at),
          })) || []
        );
      }, [apilogs]);


    const sortedApilogs = useMemo(() => {
        return [...apilogsForm].sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key])
                return sortConfig.direction === "asc" ? -1 : 1;
            if (a[sortConfig.key] > b[sortConfig.key])
                return sortConfig.direction === "asc" ? 1 : -1;
            return 0;
        });
    }, [apilogs, sortConfig]);
    const totalPages = Math.ceil(sortedApilogs.length / ITEMS_PER_PAGE);
    const paginatedApilogs = sortedApilogs.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        currentPage * ITEMS_PER_PAGE
    );

    const handleSort = (key: string) => {
        setSortConfig((currentConfig) => {
            if (currentConfig.key === key) {
                return {
                    ...currentConfig,
                    direction: currentConfig.direction === "asc" ? "desc" : "asc",
                };
            }
            return { key, direction: "asc" };
        });
    };
    if (APP_STAGE === "DEV") {  
      console.log({apilogs})
      console.log({paginatedApilogs})
    }

    return (
        <div className="bg-white shadow-md rounded-lg overflow-hidden p-4">
            <div className="border-b flex justify-between items-center">
                <h2 className="text-2xl font-semibold">모델 목록</h2>
            </div>
            <Table>
                <TableHeader>
                    <TableRow>
                        {["id", "method", "url", "message", "status", "created_at"].map((key) => (
                            <TableHead
                                key={key}
                                className="px-6 py-2 text-left text-xs text-nowrap font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                onClick={() => handleSort(key)}
                            >
                                {key === "id"
                                    ? "NO."
                                    : key === "method"
                                        ? "METHOD"
                                        : key === "url"
                                            ? "URL"
                                            : key === "message"
                                                ? "MESSAGE"
                                                : key === "status"
                                                    ? "STATUS"
                                                    : key === "created_at"
                                                        ? "CREATED AT"
                                                            : ""}
                                {sortConfig.key === key && (
                                    <span className="ml-1">
                                        {sortConfig.direction === "asc" ? "▲" : "▼"}
                                    </span>
                                )}
                            </TableHead>
                        ))}
                        <TableHead>작업</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {paginatedApilogs && paginatedApilogs.map((logs) => (
                        <TableRow key={logs.id}>
                            <TableCell>{logs.id}</TableCell>
                            <TableCell>{logs.method}</TableCell>
                            <TableCell>{logs.url}</TableCell>
                            <TableCell>{logs.message}</TableCell>
                            <TableCell>{logs.status}</TableCell>
                            <TableCell>{logs.created_at}</TableCell>
                            <TableCell>
                                <DropdownMenu>
                                    <DropdownMenuTrigger>
                                        <EllipsisVertical className="h-4 w-4" />
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent>
                                        <DropdownMenuItem
                                            onClick={() => {
                                                if (APP_STAGE === "DEV") {  
                                                  console.log("프로필 Click")
                                                }
                                            }}
                                            >프로필</DropdownMenuItem>
                                        <DropdownMenuItem>대화내역</DropdownMenuItem>
                                        <DropdownMenuItem>권한변경</DropdownMenuItem>
                                        <DropdownMenuItem>회사변경</DropdownMenuItem>
                                        {/* <DropdownMenuSub>
                                            <DropdownMenuSubTrigger>권한변경</DropdownMenuSubTrigger>
                                            <DropdownMenuSubContent>
                                                <DropdownMenuItem>일반 설정</DropdownMenuItem>
                                                <DropdownMenuItem>고급 설정</DropdownMenuItem>
                                            </DropdownMenuSubContent>
                                        </DropdownMenuSub> */}
                                        <DropdownMenuSeparator />
                                        <DropdownMenuItem>계정잠금</DropdownMenuItem>
                                    </DropdownMenuContent>
                                </DropdownMenu>

                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={apilogs ? Math.ceil(apilogs?.length / ITEMS_PER_PAGE) : 1} totalNumber={apilogs?.length} listName="API 로그" />
        </div>
    )
};

export default ModelTable;