import * as React from "react"

interface TextareaProps {
  label: string
  placeholder?: string
  id: string
  value: string
  className?: string
  isNoSpinner?: boolean
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  rows?: number
}

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, id, value, placeholder, label, isNoSpinner, onChange, rows = 5, ...props }, ref) => {
    const [isFocused, setIsFocused] = React.useState(false)
    const handleFocus = () => setIsFocused(true)
    const handleBlur = () => setIsFocused(false)

    return (
      <div className="relative mb-1 w-full">
        <textarea
          id={id}
          className={`peer w-full border border-gray-300 text-gray-900 placeholder-transparent hover:ring-2 hover:ring-blue-100 focus:outline-none focus:border-blue-500 rounded-md px-3 py-4 transition-all duration-200 resize-none ${
            isFocused ? 'border-blue-500 ring-2 ring-blue-200' : ''
          } ${isNoSpinner ? 'no-spinner' : ''} ${className}`}
          placeholder={placeholder}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={onChange}
          value={value}
          rows={rows}
          ref={ref}
          {...props}
        />
        <label
          htmlFor={id}
          className={`absolute left-3 transition-all duration-200 pointer-events-none ${
            isFocused || value
              ? 'top-1 text-xs text-blue-500'
              : 'top-4 text-base text-gray-500'
          } ${isFocused ? 'text-blue-500' : ''}`}
        >
          {label}
        </label>
      </div>
    )
  }
)
Textarea.displayName = "Textarea"
