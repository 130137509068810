import React from 'react';
// import './nav.css';
import NavNotice from './NavNotice';
import NavAvatar from './NavAvatar';
import NavMessage from './NavMessage';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from "../../lib/hooks";
import { sidebarSel } from "../../lib/features/admin/adminSlice";

function Nav() {
  const navigate = useNavigate();
  
  return (
    <nav className="ms-auto h-auto">
        <ul className="d-flex align-items-center">
            {/* <li onClick={()=>navigate('/chat')} style={{color:'gray-400'}}>Chatpage</li>
            <li onClick={()=>navigate('/dashboard')} style={{color:'gray-400'}}>admin</li> */}
            {/* <NavNotice /> */}
            {/* <NavMessage /> */}
            <NavAvatar />
        </ul>
    </nav>
  )
}

export default Nav;
