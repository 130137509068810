import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import CountrySelect from "react-flags-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import { useAppDispatch, useAppSelector } from "../lib/hooks";
import {
  selectChatMain,
  chatMessageGet,
} from "../lib/features/chatMain/chatMainSlice";

import { requestUser, selectUser } from "../lib/features/auth/authSlice";
import { selectToken } from "../lib/features/auth/authSlice";
import Header from "../components/dashboard/Header";
import ChatSideBar from "../components/main/ChatSideBar";
import { Input } from "../components/ui/input";
import { Textarea } from "../components/ui/textarea";

const UserServiceRequestPage = (props) => {
  const { roomId } = useParams();
  const chatMain = useAppSelector(selectChatMain);
  const token = useAppSelector(selectToken);
  const user = useAppSelector(selectUser);

  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState({
    imono: "",
    vesselName: "",
    company: "",
    yourName: "",
    // shipowner: "",
    // serviceCountry: null,
    // serviceDate: new Date(),
    reason: "", // This will be renamed to account application reason
    // attachment: null,
    shipEmail: "", // New field for email
    phoneNumber: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isSubmited, setIsSubmited] = useState(false);

  useEffect(() => {
    chatMessageGet({ room_id: roomId, token: token, offset: 0 });
  }, [roomId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCountryChange = (selectedOption) => {
    setFormData({ ...formData, serviceCountry: selectedOption });
  };

  const handleDateChange = (date) => {
    setFormData({ ...formData, serviceDate: date });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, attachment: e.target.files[0] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSuccessMessage("");
    setErrorMessage("");

    // Email validation to allow only one email
    if (!formData.imono) {
      setErrorMessage("Please enter the IMO number.");
      return;
    }
    if (!formData.company) {
      setErrorMessage("Please enter the company name.");
      return;
    }
    if (!formData.shipEmail.includes("@")) {
      setErrorMessage("Please enter a valid email address.");
      return;
    }
  
    // 검증 통과 시 에러 메시지 초기화
    setErrorMessage("");

    // Validation for other fields
    if (
      !formData.imono ||
      !formData.yourName ||
      !formData.company 
    ) {
      setErrorMessage("Please fill in all required fields.");
      setSuccessMessage("");
    }

    // Form submission
    console.log("Form Data:", formData);
    console.log("Room ID:", roomId);
    dispatch(requestUser({ data: formData }))
    .unwrap()
    .then(()=>{
      setErrorMessage("");
      setSuccessMessage("Submitted successfully!");
      setIsSubmited(true);
    }).catch((error)=>{
      console.log(error)
      setErrorMessage(error.message);
      setSuccessMessage("");
      setIsSubmited(false);
    })
  };

  console.log({errorMessage, successMessage, isSubmited})

  return (
    <div className="min-h-screen flex w-full flex-col bg-gray-50 sm:flex-row">
      <main className="flex-1 flex flex-col justify-center items-center w-full bg-white p-6">
        <aside className="composer-parent flex w-full h-full focus-visible:outline-0">
          <div className="container mx-auto flex flex-col lg:flex-col justify-center items-between max-w-4xl p-6 bg-white shadow-md rounded-md">
            <form
              onSubmit={handleSubmit}
              className="flex flex-col mb-8 md:mb-0 px-4 overflow-y-auto"
            >
              <h2 className="text-2xl font-semibold text-[#1565c0] mb-4">
                Sign Up Request
              </h2>
              
              {!isSubmited ? (
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-4">
                  <Input
                    label="IMO No."
                    comment="IMO No. is used as the login ID, and one primary account can be created per vessel."
                    type="number"
                    value={formData.imono}
                    placeholder="Enter your IMO No."
                    onChange={(ev) => setFormData({ ...formData, imono: ev.target.value })}
                    className="inputBox w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                    isNoSpinner={true}
                  />
                </div>
              
                <div className="flex items-center gap-4">
                  <Input
                    label="Company"
                    comment="Ship's owner company & management"
                    type="text"
                    value={formData.company}
                    placeholder="Enter your company"
                    onChange={(ev) => setFormData({ ...formData, company: ev.target.value })}
                    className="inputBox w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                    isNoSpinner={true}
                  />
                </div>
              
                <div className="flex items-center gap-4">
                  <Input
                    label="Ship's General Email"
                    type="email"
                    value={formData.shipEmail}
                    placeholder="example@domain.com"
                    onChange={(ev) => setFormData({ ...formData, shipEmail: ev.target.value })}
                    className="inputBox w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                    isNoSpinner={true}
                  />
                </div>
              
                <div className="flex items-center gap-4">
                  <Input
                    label="Vessel Name"
                    type="text"
                    value={formData.vesselName}
                    placeholder="Enter your vessel name"
                    onChange={(ev) => setFormData({ ...formData, vesselName: ev.target.value })}
                    className="inputBox w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                    isNoSpinner={true}
                  />
                </div>
              
                <div className="flex items-center gap-4">
                  <Input
                    label="Vessel Contact No."
                    type="text"
                    value={formData.phoneNumber}
                    placeholder="Enter your shipowner"
                    onChange={(ev) => setFormData({ ...formData, phoneNumber: ev.target.value })}
                    className="inputBox w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                    isNoSpinner={true}
                  />
                </div>
              
                <div className="flex items-center gap-4">
                  <Input
                    label="Your Name"
                    type="text"
                    value={formData.yourName}
                    placeholder="Enter your name"
                    onChange={(ev) => setFormData({ ...formData, yourName: ev.target.value })}
                    className="inputBox w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                    isNoSpinner={true}
                  />
                </div>
              
                <Textarea
                  label="Additional Comments"
                  value={formData.reason}
                  onChange={(ev) => setFormData({ ...formData, reason: ev.target.value })}
                  className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:border-blue-500 resize-none"
                  rows="5"
                  placeholder="Additional Comments"
                />
              </div>
            ) : (
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-4">
                  <Input
                    label="IMO No."
                    comment="IMO No. is used as the login ID, and one primary account can be created per vessel."
                    type="number"
                    value={formData.imono}
                    className="inputBox w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                    isNoSpinner={true}
                  />
                </div>
              
                <div className="flex items-center gap-4">
                  <Input
                    label="Company"
                    comment="Ship's owner company & management"
                    type="text"
                    value={formData.company}
                    className="inputBox w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                    isNoSpinner={true}
                  />
                </div>
              
                <div className="flex items-center gap-4">
                  <Input
                    label="Ship's General Email"
                    type="email"
                    value={formData.shipEmail}
                    className="inputBox w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                    isNoSpinner={true}
                  />
                </div>
              
                <div className="flex items-center gap-4">
                  <Input
                    label="Vessel Name"
                    type="text"
                    value={formData.vesselName}
                    className="inputBox w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                    isNoSpinner={true}
                  />
                </div>
              
                <div className="flex items-center gap-4">
                  <Input
                    label="Vessel Contact No."
                    type="text"
                    value={formData.phoneNumber}
                    className="inputBox w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                    isNoSpinner={true}
                  />
                </div>
              
                <div className="flex items-center gap-4">
                  <Input
                    label="Your Name"
                    type="text"
                    value={formData.yourName}
                    className="inputBox w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                    isNoSpinner={true}
                  />
                </div>
              
                <Textarea
                  label="Additional Comments"
                  value={formData.reason}
                  className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:border-blue-500 resize-none"
                  rows="5"
                  placeholder="Additional Comments"
                />
              </div>
            )}
              {errorMessage && (
                <p className="text-red-500 mb-4">{errorMessage}</p>
              )}
              {successMessage && (
                <p className="text-green-500 mb-4">{successMessage}</p>
              )}
              
              <button
                type="submit"
                className=" w-full px-4 py-3 mb-2 bg-blue-500 text-sm text-white text-center  rounded-lg bg-gradient-to-br from-gray-600 to-gray-600 hover:bg-gray-600 transition-colors"
                disabled={isSubmited}
              >
                {isSubmited ? "Sign-up request submitted" : "Request submit"}
              </button>
              
            </form>

            <div className="linkContainer w-full max-w-md flex justify-between items-center self-center mt-3 text-blue-500 underline cursor-pointer text-sm">
              <p className="mb-2 cursor-pointer hover:underline" onClick={() => props.mvPage("signin")}>
                Already have an account?
              </p>
              <p className="cursor-pointer hover:underline" onClick={() => props.mvPage("findpw")}>
                Forgot the password?
              </p>
            </div>
          </div>

        </aside>
      </main>
    </div>
  );
};

export default UserServiceRequestPage;
