import React, { useEffect, useState } from 'react'
import ChatSideBar from '../components/main/ChatSideBar'
import ChatMain from '../components/main/ChatMain'
import SideBarDoc from '../components/main/SideBarDoc'
import { VoiceRecordModal } from '../components/main/ChatInput';
import { useAppDispatch,useAppSelector } from '../lib/hooks';
import { 
  createNewChat,
  chatMessageGet,
  sidebarSelRoom,
  setChatRoomId
} from '../lib/features/chatMain/chatMainSlice';
import { selectToken } from "../lib/features/auth/authSlice";
import Header from '../components/dashboard/Header';

function ChatPage() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const token = useAppSelector(selectToken)
  const dispatch = useAppDispatch();

  useEffect(() => {
    // 새 대화를 생성하고 해당 대화로 이동
    const handleMenuClick = () => {
      dispatch(setChatRoomId(0));
      dispatch(sidebarSelRoom([0, 0]));
      dispatch(chatMessageGet({ room_id: 0, token: token }));
    }
    // handleMenuClick();
  }, []);

  return (
    // <div className="flex md:flex-row h-full w-full overflow-hidden transition-colors">
    <div className="min-h-screen-patched flex w-full flex-col bg-white sm:flex-row">
       {/* <Header /> */}
       
       {/* <Header isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen}/> */}
       {/* <div className="flex md:flex-row flex-grow"></div> */}
       <ChatSideBar isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen}/>

       <ChatMain isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen}/>
       {/* <div className="docContents text-base py-[18px] px-3 m-auto h-full md:px-5 lg:px-1 xl:px-5" > */}
        {/* <SideBarDoc /> */}
        <VoiceRecordModal  modalName="#addVoiceRecordModal"/> 
        {/* </div> */}
       {/* <PDFViewer /> */}
    </div>
  )
}

export default ChatPage
