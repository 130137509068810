import React, { useState } from 'react'
import Main from '../components/dashboard/Main'
import SideBar from '../components/dashboard/SideBar'
import Header from '../components/dashboard/Header'

function Dashboard() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  return (
    <div className="min-h-screen-patched sm:h-screen flex flex-col w-full bg-white sm:flex-row">
      {/* <Header />
      <SideBar /> */}
      <SideBar isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen}/>
      <Main  isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen} />
    </div>
  )
}

export default Dashboard
