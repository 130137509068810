import React, { useEffect, useRef, useState } from "react";
import "../../main/chatContents.css";
import Message from "./Message";

import {
    selectDashboardChatDetail
  } from "../../../lib/features/admin/adminSlice";
import { useAppSelector } from "../../../lib/hooks";

function ChatContents({mode, ids}) {
  const messageEndRef = useRef(null);

  const messages = useAppSelector(selectDashboardChatDetail);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollTop = messageEndRef.current.scrollHeight;
    }
  };

  return (
    <div className=" flex h-full overflow-y-auto">
      <div
        className=" text-base py-[0px] px-0 m-auto w-full h-full md:px-5 lg:px-1 xl:px-5 "
        ref={messageEndRef}
      >
        <div className="group/conversation-turn relative flex w-full h-full min-w-0 flex-col agent-turn justify-between ">
          <div className="group/conversation-turn relative flex w-full min-w-0 flex-col agent-turn  border-1 border-gray-200 rounded-lg p-2">
            <div className="chatMargin"></div>
            {messages &&
              messages.map((item, index) => (
                <Message key={index} model={item} messages={messages} ids={ids}/>
              ))}
            <div className="chatFootMargin" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatContents;
