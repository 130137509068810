import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Header from "../components/dashboard/Header";
import ChatSideBar from "../components/main/ChatSideBar";

function ServicePage() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const navigate = useNavigate();
  const { roomId } = useParams();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className="min-h-screen-patched flex w-full flex-col bg-white sm:flex-row">
       <ChatSideBar isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen}/>
      <main className="flex-1 flex flex-col justify-center items-center w-full bg-gray-100">
        <aside className="composer-parent flex h-full focus-visible:outline-0 w-full">
          {/* <Header isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen} /> */}
          <div className=" mx-auto flex flex-col lg:flex-row justify-between w-full p-8 bg-white shadow-lg rounded-lg">
            {/* Left side - Form section with increased width */}
            <div className="info w-full lg:w-1/2 lg:px-8 border-t lg:border-t-0 lg:border-l border-gray-300">
              <h2 className="text-2xl font-bold text-blue-600 mb-6">Select a Service</h2>

              {/* Grid layout for buttons - 2 columns, 3 rows */}
              <div className="grid grid-cols-1 gap-4">
                <button
                  onClick={() => handleNavigation(`/email-send/${roomId}`)}
                  className="w-full py-3 px-4 bg-blue-500 text-white rounded-lg text-lg hover:bg-blue-700 transition-colors"
                >
                  Service Engineer
                </button>
                <button
                  onClick={() => handleNavigation(`/email-send/${roomId}`)}
                  className="w-full py-3 px-4 bg-blue-500 text-white rounded-lg text-lg hover:bg-blue-700 transition-colors"
                >
                  Remote Service
                </button>
                <button
                  onClick={() => handleNavigation(`/email-send/${roomId}`)}
                  className="w-full py-3 px-4 bg-blue-500 text-white rounded-lg text-lg hover:bg-blue-700 transition-colors"
                >
                  Spare Parts
                </button>
                <button
                  onClick={() => handleNavigation(`/email-send/${roomId}`)}
                  className="w-full py-3 px-4 bg-blue-500 text-white rounded-lg text-lg hover:bg-blue-700 transition-colors"
                >
                  Training
                </button>
                <button
                  onClick={() => handleNavigation(`/email-send/${roomId}`)}
                  className="w-full py-3 px-4 bg-blue-500 text-white rounded-lg text-lg hover:bg-blue-700 transition-colors"
                >
                  Upgrade
                </button>
                <button
                  onClick={() => handleNavigation(`/email-send/${roomId}`)}
                  className="w-full py-3 px-4 bg-blue-500 text-white rounded-lg text-lg hover:bg-blue-700 transition-colors"
                >
                  Annual Service
                </button>
              </div>
            </div>

            {/* Right side - Information section with reduced width */}
            <div className="info w-full lg:w-1/2 lg:pl-8 border-t lg:border-t-0 lg:border-l border-gray-300">
              <h3 className="text-xl font-semibold text-gray-800 mb-4">Information</h3>
              <p className="p-4 bg-gray-100 border border-gray-300 rounded-lg">
                Select a service from the left to view more details about that service.
              </p>
            </div>
          </div>
        </aside>
      </main>
    </div>
  );
}

export default ServicePage;
