// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 파일 박스 스타일 */
.fileBox {
  border: 1px solid; /* 기본 색상 */
  padding: 2px 8px;
  margin: 2px;
  border-radius: 8px;
  background-color: #f0f8ff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: transform 0.2s, box-shadow 0.2s;
}

/* 호버 효과 */
.fileBox:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

/* 파일 이름 스타일 */
.fileBox .fileName {
  font-size: 12px;
  font-weight: bold;
}

/* 페이지 범위 스타일 */
.fileBox .pageRange {
  font-size: 12px; /* 페이지 범위 크기 증가 */
  color: #555; /* 페이지 범위 색상 */
  font-weight: bold; /* 볼드체 */
}
`, "",{"version":3,"sources":["webpack://./src/components/main/filebox.css"],"names":[],"mappings":"AAAA,cAAc;AACd;EACE,iBAAiB,EAAE,UAAU;EAE7B,gBAAgB;EAChB,WAAW;EACX,kBAAkB;EAClB,yBAAyB;EACzB,wCAAwC;EACxC,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,2CAA2C;AAC7C;;AAEA,UAAU;AACV;EACE,2BAA2B;EAC3B,yCAAyC;AAC3C;;AAEA,cAAc;AACd;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA,eAAe;AACf;EACE,eAAe,EAAE,iBAAiB;EAClC,WAAW,EAAE,cAAc;EAC3B,iBAAiB,EAAE,QAAQ;AAC7B","sourcesContent":["/* 파일 박스 스타일 */\n.fileBox {\n  border: 1px solid; /* 기본 색상 */\n  border-radius: 8px;\n  padding: 2px 8px;\n  margin: 2px;\n  border-radius: 8px;\n  background-color: #f0f8ff;\n  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  transition: transform 0.2s, box-shadow 0.2s;\n}\n\n/* 호버 효과 */\n.fileBox:hover {\n  transform: translateY(-2px);\n  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);\n}\n\n/* 파일 이름 스타일 */\n.fileBox .fileName {\n  font-size: 12px;\n  font-weight: bold;\n}\n\n/* 페이지 범위 스타일 */\n.fileBox .pageRange {\n  font-size: 12px; /* 페이지 범위 크기 증가 */\n  color: #555; /* 페이지 범위 색상 */\n  font-weight: bold; /* 볼드체 */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
