import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Input } from "../ui/input";
import { ArrowRightIcon } from "lucide-react";

const REACT_APP_BE_HOST = process.env.REACT_APP_BE_HOST;
const APP_STAGE = process.env.REACT_APP_STAGE;
function ResetPassword() {
    const { token } = useParams();
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            alert("Passwords do not match");
            return;
        }
        if (APP_STAGE === "DEV") {  
            console.log(token, newPassword);    
        }

        try {
            await axios.post(`${REACT_APP_BE_HOST}/api/auth/reset/reset-password/`, {
                token,
                new_password: newPassword,
            }).then((response)=>{
                if (APP_STAGE === "DEV") {  
                    console.log(response);
                }
                // alert("Password reset successfully");
                setSuccess(true);
            });
        } catch (error) {
            if (APP_STAGE === "DEV") {  
                console.log(error);
            }
            // alert("The token is invalid or expired");
        }
    };
    useEffect(()=>{
        if(success){
            setTimeout(()=>{
                navigate("/");
            }, 3000);
        }
    }, [success]);

    return (
    <div className="flex flex-col items-center justify-center h-screen w-full max-w-[400px] mx-auto">
        <div className="LoginContainer flex flex-col items-center justify-center h-screen w-full">
        <h3 className="text-2xl font-bold mb-4">Password Change</h3>
        <form onSubmit={handleSubmit} className="w-full">
            <div className="mb-3">
            {/* <label htmlFor="password" className="form-label">
                New Password
            </label> */}
            <Input
                label="New Password"
                type="password"
                className="form-control"
                id="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
            />
            </div>
            <div className="mb-3">
            {/* <label htmlFor="confirmPassword" className="form-label">
                Confirm Password
            </label> */}
            <Input
                label="Confirm Password"
                type="password"
                className="form-control"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
            />
            </div>
            {error && <p className="text-danger">{error&&"The token is invalid or expired"}</p>}
            {success && <p className="text-success text-sm ml-4">Password reset link has been sent to the email you registered with. redirecting to login page</p>}
            <button
                type="submit"
                className="w-full text-center px-4 py-3 mb-2 bg-blue-500 text-sm text-white rounded-lg bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
            >
                Change Password
            {/* <ArrowRightIcon className="w-4 h-4" /> */}
            </button>
        </form>
      </div>
    </div>
  );
}

export default ResetPassword;