import React, { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../lib/hooks"
import {
  adminUserAllGet,
  adminApiLogs,
  adminEmailLogs,
  adminFeedbackLikes,
  selectDashboardApiLog,
  selectDashboardEmailLog,
  selectDashboardFeedbackLike,
  selectDashboardUser,
} from "../../../lib/features/admin/adminSlice"
import { selectToken, selectStatus } from "../../../lib/features/auth/authSlice"
import { Tabs, TabsContent, TabsTrigger } from "../../ui/tabs"
import APILogTable from "../LogPage/APILogTable"
import DashboardTabs from "../Common/DashboardTabs"
import EmailLogTable from "../LogPage/EmailLogTable"


const ITEMS_PER_PAGE = 10
const APP_STAGE = process.env.REACT_APP_STAGE;

export default function AdminLogPage() {
  const dispatch = useAppDispatch();
  const token = useAppSelector(selectToken);
  const apiLogs = useAppSelector(selectDashboardApiLog);
  const emailLogs = useAppSelector(selectDashboardEmailLog);
  const [tabValue, setTabValue] = useState("users");

  useEffect(() => {
    dispatch(adminApiLogs({ token }));
    dispatch(adminEmailLogs({ token }));
  }, []);

  useEffect(() => {
    if(tabValue === "apilogs") {
      dispatch(adminApiLogs({ token }));
    } else if(tabValue === "emailLogs") {
      dispatch(adminEmailLogs({ token }));
    }
  }, [dispatch, token]);

  useEffect(() => {
    if(tabValue === "apilogs") {
      dispatch(adminApiLogs({ token }));
    } else if(tabValue === "emailLogs") {
      dispatch(adminEmailLogs({ token }));
    }
  }, [tabValue]);

  const tabData = [
    {tabName: "apilogs", name: "API LOGS", notifications: 0},
    {tabName: "emailLogs", name: "EMAIL LOGS", notifications: 0},
    // {tabName: "groups", name: "권한그룹", notifications: 0},
    // {tabName: "company", name: "회사그룹", notifications: 0},
    // {tabName: "request", name: "가입신청", notifications: 1},
  ];
  if (APP_STAGE === "DEV") {  
    console.log({apiLogs}, apiLogs||[])
  }
  return (
    <div className="w-full p-0 mx-0">
      <h1 className="text-2xl font-bold mb-6">LOGS MANAGEMENT DASHBOARD</h1>
      <Tabs defaultValue="apilogs">
        <DashboardTabs data={tabData} tabValue={tabValue} setTabValue={setTabValue}/>
        <TabsContent value="apilogs">
          <APILogTable apiLogs={apiLogs || []}  />
        </TabsContent>
        <TabsContent value="emailLogs">
          <EmailLogTable emailLogs={emailLogs || []}  />
        </TabsContent>
        {/* <TabsContent value="groups">
          <GroupTable groups={groups || []}  />
        </TabsContent>
        <TabsContent value="company">
          <CompanyTable companies={companys || []}  />
        </TabsContent>
        <TabsContent value="request">
          <SignUpRequestTable signUpRequests={signUpRequests || []}  />
        </TabsContent> */}
      </Tabs>

    </div>
  )
}
