import * as React from "react"

interface InputProps {
  label: string;
  comment?: string;
  placeholder?: string;
  type?: string;
  id: string;
  value: string;
  className?: string;
  isNoSpinner?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  commentColor?: string;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      id,
      value,
      type = "text",
      placeholder,
      label,
      isNoSpinner,
      onChange,
      onFocus,
      onBlur,
      onKeyDown,
      comment,
      commentColor,
      ...props
    },
    ref
  ) => {
    const [isFocused, setIsFocused] = React.useState(false);

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
      setIsFocused(true);
      onFocus && onFocus(event);
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      const relatedTarget = event.relatedTarget as HTMLElement;
      const isFocusInside = relatedTarget && event.currentTarget.contains(relatedTarget);
      if (!isFocusInside) {
        setIsFocused(false);
        onBlur && onBlur(event);
      }
    };

    const computedClassName = `
      peer h-14 w-full border text-gray-900 placeholder-transparent
      hover:ring-2 hover:ring-blue-100 focus:outline-none focus:border-blue-500
      rounded-md px-3 py-4 transition-all duration-200
      ${isFocused ? 'border-blue-500 ring-2 ring-blue-200' : ''}
      ${isNoSpinner ? 'no-spinner' : ''}
      ${className}
    `;

    return (
      <div className="relative mb-1 w-full">
        <input
          type={type}
          id={id}
          className={computedClassName}
          placeholder={placeholder}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={onKeyDown}
          onChange={onChange}
          value={value}
          ref={ref}
          autoComplete="off"
          {...props}
        />
        <label
          htmlFor={id}
          className={`absolute left-3 transition-all duration-200 pointer-events-none ${
            isFocused || value
              ? 'top-1 text-xs text-blue-500'
              : 'top-4 text-base text-gray-500'
          } ${isFocused ? 'text-blue-500' : ''}`}
        >
          {label}
        </label>
        {comment && (
          <label
            htmlFor={id}
            className={`text-xs ${commentColor ? commentColor : "text-gray-500"} pl-2`}
          >
            {comment}
          </label>
        )}
      </div>
    );
  }
);
Input.displayName = "Input";
