import React, { useEffect, useState } from "react";
import {
  adminGroupListGet,
  adminServiceListGet,
  selectDashboardUserServices,
  selectDashboardGroup,
  selectDashboardCompany,
  adminCompanyGet,
  adminCompanyAdd,
  adminUserEmailSend,
} from "../../../lib/features/admin/adminSlice";
import { useAppDispatch, useAppSelector } from "../../../lib/hooks";
import { selectToken } from "../../../lib/features/auth/authSlice";
import DashboardTabs from "../Common/DashboardTabs";
import { Tabs, TabsContent } from "../../ui/tabs";
import ServiceReqTable from "../ServiceReqPage/ServiceReq";

const APP_STAGE = process.env.REACT_APP_STAGE;

function AdminServicePage() {
  const dispatch = useAppDispatch();
  const token = useAppSelector(selectToken);
  const servicesRequest = useAppSelector(selectDashboardUserServices);

  const [tabValue, setTabValue] = useState("users");

  useEffect(() => {
    dispatch(adminServiceListGet({ token }));
  }, [dispatch, token]);

  const tabData = [
    {tabName: "serviceRequest", name: "Service Request", notifications: 0},
    // {tabName: "groups", name: "권한그룹", notifications: 0},
    // {tabName: "company", name: "회사그룹", notifications: 0},
    // {tabName: "request", name: "가입신청", notifications: 1},
  ];
  if (APP_STAGE === "DEV") {  
    console.log({servicesRequest})
  }

  return (
    <div className="w-full p-0 mx-0">
      <h1 className="text-2xl font-bold mb-6">Service Request Management</h1>
      <Tabs defaultValue="serviceRequest">
        <DashboardTabs data={tabData} tabValue={tabValue} setTabValue={setTabValue}/>
        <TabsContent value="serviceRequest">
          <ServiceReqTable token={token}/>
        </TabsContent>
      </Tabs>

    </div>
  );
}

export default AdminServicePage;
