import React, { useEffect, useMemo, useState } from "react";
import * as XLSX from "xlsx";
import { useAppDispatch, useAppSelector } from "../../../lib/hooks";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../ui/table";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DropdownMenuSub,
  DropdownMenuSubTrigger,
  DropdownMenuSubContent,
} from "../../ui/dropdown-menu";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../ui/dialog";

import {
  Plus,
  Search,
  Filter,
  EllipsisVertical,
  MoreVertical,
  Check,
  X,
  Trash2,
  UserPlus,
  Users,
  Download,
  Upload,
  ChevronDown,
  FileText,
} from "lucide-react";
import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import Pagination from "../Common/Pagination";
import {
  adminFaqFinetune,
  adminFileUpload,
  adminFaqDelete,
  adminFaqUpdate,
} from "../../../lib/features/admin/adminSlice";
import { Label } from "@radix-ui/react-dropdown-menu";
import { set } from "react-datepicker/dist/date_utils";
import PDFSelector  from "../../pdfViewer/PDFSelector";

const ITEMS_PER_PAGE = 20;
const APP_STAGE = process.env.REACT_APP_STAGE;

const FAQTable = ({
  faqdatas,
  token,
  adminFaqListChange,
}: {
  faqdatas: any;
  token: any;
  adminFaqListChange: any;
}) => {
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [isChangeFAQ, setIsChangeFAQ] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState({
    upload: false,
    download: false,
    finetune: false,
    delete: false,
    detailFAQ: false,
    selectManual: false,
  });
  const [selectedFaqId, setSelectedFaqId] = useState<any>(null);
  const [selectedFaqType, setSelectedFaqType] = useState<any>(null);

  const [newFAQForm, setNewFAQForm] = useState({
    question: "",
    manual: "",
    page: "",
    answer: "",
    type: "",
    lang: "",
  });
  const [detailFAQ, setDetailFAQ] = useState<any>({
    id: "",
    question: "",
    manual: "",
    page: "",
    answer: "",
    type: "",
    lang: "",
    is_reflected: false,
  });
  const [fileData, setFileData] = useState(null);
  const [parsedData, setParsedData] = useState<any>([]);
  const [fileName, setFileName] = useState("");

  const [FAQDataForm, setFAQDataForm] = useState(
    faqdatas.map((faq: any) => ({
      id: faq.faq_id,
      question: faq.faq_question,
      manual: faq.faq_manual || "-",
      page: faq.faq_manual?faq.faq_page:'-',
      answer: faq.faq_answer,
      type: faq.faq_type=="BWMS-TS"?"troubleshooting":faq.faq_type=="BWMS-MT"?"maintenance":"general_inquiry",
      lang: faq.faq_lang,
      is_reflected: faq.is_reflected,
    })) || []
  );

  const [sortConfig, setSortConfig] = useState({ key: "id", direction: "asc" });

  function formatDateTime(dateTimeString: string) {
    if (dateTimeString.length !== 14) {
      return "Invalid input format.";
    }

    const year = dateTimeString.slice(0, 4);
    const month = dateTimeString.slice(4, 6);
    const day = dateTimeString.slice(6, 8);
    const hour = dateTimeString.slice(8, 10);
    const minute = dateTimeString.slice(10, 12);
    const second = dateTimeString.slice(12, 14);

    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  }
  // 전체 상태 정리
  // 활성, 잠김(이메일, 승인안됨, 비번오류, 이상감지)
  function getStatus(user: any) {
    if (user.is_deleted) {
      return "Withdrawal";
    } else if (!user.is_verified || !user.has_email_check || user.is_locked) {
      return "Locked";
    } else {
      return "Active";
    }
  }

  const handleFileChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name); // 파일 이름 저장
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target) {
          const binaryStr = event.target.result;
          const workbook = XLSX.read(binaryStr, { type: "binary" });
          const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData = XLSX.utils.sheet_to_json(firstSheet);
          setParsedData(jsonData); // 파싱된 데이터 저장
        } else {
          if (APP_STAGE === "DEV") {  
            console.log("event.target null");
          }
        }
      };
      reader.readAsBinaryString(file); // 파일 읽기
      setFileData(file); // 파일 저장
    }
  };

  const handleUpload = () => {
    if (!fileData){
      alert("Please select the file.");
      return;
    }
    if (!selectedFaqType){
      alert("Please select the chatbot type.");
      return;
    }

    const formData = new FormData();
    formData.append("files", fileData); // 선택한 파일 추가
    // formData.append("chatbotId", chatbots[0]?.chatbot_id); // chatbotId 추가

    dispatch(
      adminFileUpload({
        token,
        fileData: formData,
        ctype: selectedFaqType=='troubleshooting' ? "ts" : selectedFaqType=='maintenance' ? "mt" : "gi",
      })
    ).then(() => {
      setFileName("");
      setFileData(null);
      adminFaqListChange();
      alert("File upload successfully!");
      setSelectedFaqType(null);
      setIsDialogOpen((prev: any) => ({ ...prev, ["upload"]: false }));

    });
  };

  useEffect(() => {
    if (faqdatas)
      setFAQDataForm(
        faqdatas.map((faq: any) => ({
          id: faq.faq_id,
          question: faq.faq_question,
          manual: faq.faq_manual || "-",
          page: faq.faq_manual?faq.faq_page:'-',
          answer: faq.faq_answer,
          type: faq.faq_type=="BWMS-TS"?"troubleshooting":faq.faq_type=="BWMS-MT"?"maintenance":"general_inquiry",
          lang: faq.faq_lang,
          is_reflected: faq.is_reflected,

        })) || []
      );
  }, [faqdatas]);

  const sortedFAQData = useMemo(() => {
    return [...FAQDataForm].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key])
        return sortConfig.direction === "asc" ? -1 : 1;
      if (a[sortConfig.key] > b[sortConfig.key])
        return sortConfig.direction === "asc" ? 1 : -1;
      return 0;
    });
  }, [FAQDataForm, sortConfig]);
  const totalPages = Math.ceil(sortedFAQData.length / ITEMS_PER_PAGE);
  const paginatedFAQData = sortedFAQData.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const handleFileDownload = () => {
    // const selType = selectedFaqType=='troubleshooting' ? "BWMS-TS" : selectedFaqType=='maintenance' ? "BWMS-MT" : "BWMS-GI";
    const transformedData = FAQDataForm.filter((faq: any) => faq.type==selectedFaqType).map((faq: any) => ({
      // id: faq.id,
      question_text: faq.question,
      answer_text: faq.answer,
      file_name: faq.manual=='-'?null:faq.manual,
      page: faq.page=='-'?null:faq.page,
      lang: faq.lang,
    }));
    const worksheet = XLSX.utils.json_to_sheet(transformedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "FAQs");
    XLSX.writeFile(workbook, "FAQs.xlsx");
  };

  const handleFinetune = () => {
    dispatch(
      adminFaqFinetune({
        token,
        ctype: selectedFaqType=='troubleshooting' ? "ts" : selectedFaqType=='maintenance' ? "mt" : "gi",
      })
    ).then(() => {
      adminFaqListChange();
      alert("Fine-tuning completed successfully!");
    });
    setIsDialogOpen((prev: any) => ({ ...prev, ["finetune"]: false }));
  };

  const handleDelete = (faq_id: any) => {
    dispatch(
      adminFaqDelete({
        token,
        faq_id,
      })
    ).then(() => {
      adminFaqListChange();
      alert("Delete completed successfully!");
    });
  };

  const handleSort = (key: string) => {
    setSortConfig((currentConfig) => {
      if (currentConfig.key === key) {
        return {
          ...currentConfig,
          direction: currentConfig.direction === "asc" ? "desc" : "asc",
        };
      }
      return { key, direction: "asc" };
    });
  };
  //   console.log({ FAQDataForm });
  //   console.log({ paginatedFAQData });
  const handleSelectManual = (finfo: any) => {
    if(finfo.type=="filename"){
      setFileName(finfo.pdf_name);
      setDetailFAQ((prev: any) => ({ ...prev, manual: finfo.pdf_name, page: finfo.page }));
    }
    if(finfo.type=="pagenum"){
      setDetailFAQ((prev: any) => ({ ...prev, page: finfo.page }));
    }
  }

  const deleteFaqBtn = (faq_id: any) => {
    setSelectedFaqId(faq_id);
    setIsDialogOpen((prev: any) => ({ ...prev, ["delete"]: true }));
  };

  const handleUpdateFAQ = () => {
    dispatch(
      adminFaqUpdate({
        token,
        faq_id: selectedFaqId,
        faq_question: detailFAQ.question,
        faq_answer: detailFAQ.answer,
        faq_manual: detailFAQ.manual,
        faq_page: parseInt(detailFAQ.page),
        faq_lang: detailFAQ.lang,
        faq_type: selectedFaqType=='troubleshooting' ? "BWMS-TS" : selectedFaqType=='maintenance' ? "BWMS-MT" : "BWMS-GI",
        faq_is_reflected: detailFAQ.is_reflected,
      })
    ).then(() => {
      adminFaqListChange();
      alert("Update completed successfully!");
    });
  };
  if(APP_STAGE=="DEV"){
    console.log({FAQDataForm});
  }

  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden p-4 max-w-screen-xl">
      <div className="border-b flex justify-between items-center">
        <h2 className="text-2xl font-semibold">FAQ List</h2>
        <div className="flex items-center gap-2">
          <button
            onClick={() => {
              setSelectedFaqType(null);
              setIsDialogOpen((prev: any) => ({ ...prev, ["upload"]: true }))}}
            className="flex items-center gap-2 px-4 py-2 mb-2 bg-blue-500 text-sm text-white rounded-full bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
          >
            <Upload className="h-4 w-4" /> Upload
          </button>
          <button
              onClick={() => {setIsDialogOpen((prev: any) => ({ ...prev, ["download"]: true }))}}
              className="flex items-center gap-2 px-4 py-2 mb-2 bg-blue-500 text-sm text-white rounded-full bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
            >
            <Download className="h-4 w-4" /> Download
          </button>
          <button
              onClick={() => {setIsDialogOpen((prev: any) => ({ ...prev, ["finetune"]: true }))}}
              className="flex items-center gap-2 px-4 py-2 mb-2 bg-blue-500 text-sm text-white rounded-full bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
            >
              <Download className="h-4 w-4" /> FineTune
          </button>
          <Dialog 
            
            open={isDialogOpen.detailFAQ} onOpenChange={() => setIsDialogOpen((prev: any) => ({ ...prev, ["detailFAQ"]: false }))} >
          <DialogContent className=" !max-w-6xl px-10">
            <DialogHeader>
              <DialogTitle>FAQ Detail</DialogTitle>
              <DialogDescription>show FAQ Detail</DialogDescription>
            </DialogHeader>

            {isChangeFAQ ?
                <div className="grid grid-cols-6 grid-rows-auto gap-2 text-base ">
                  <span className="font-semibold col-span-1">{"Question"}</span>
                  <span className="col-span-5">
                    <textarea
                      value={detailFAQ.question}
                      onChange={(e) => {
                        setDetailFAQ((prev: any) => ({ ...prev, question: e.target.value }));
                      }}
                      className="px-2 w-full border border-gray-500 rounded-md" />
                  </span>
                  <span className="font-semibold col-span-1">{"Answer"}</span>
                  <span className="col-span-5">
                    <textarea 
                      value={detailFAQ.answer}
                      onChange={(e) => {
                        setDetailFAQ((prev: any) => ({ ...prev, answer: e.target.value }));
                      }}
                      className="px-2 w-full border border-gray-500 rounded-md" />
                  </span>
                  <span className="font-semibold col-span-1">{"Manual"}</span>
                  <span className="col-span-5">{detailFAQ.manual}</span>
                  <span className="font-semibold col-span-1">{"Page"}</span>
                  <span className="col-span-5">{detailFAQ.page}</span>
                  <span className="font-semibold col-span-1">{"Language"}</span>
                  <span className="col-span-5">{detailFAQ.lang}</span>
                  <span className="font-semibold col-span-1">{"Status"}</span>
                  <span className="col-span-5">{detailFAQ.is_reflected}</span>
                </div>
              :
                <div className="grid grid-cols-6 grid-rows-auto gap-2 text-base ">
                  <span className="font-semibold col-span-1">{"Question"}</span>
                  <span className="col-span-5">{detailFAQ.question}</span>
                  <span className="font-semibold col-span-1">{"Answer"}</span>
                  <span className="col-span-5">{detailFAQ.answer}</span>
                  <span className="font-semibold col-span-1">{"Manual"}</span>
                  <span className="inline-flex items-center col-span-5 hover:bg-blue-100">
                    <FileText size={20} className="text-red-500" />
                    <label htmlFor={`file-${detailFAQ.manual}`} className="flex-grow cursor-pointer pl-2 my-0 truncate ">
                      {detailFAQ.manual}
                    </label>
                  </span>
                  <span className="font-semibold col-span-1">{"Page"}</span>
                  <span className="col-span-5">{detailFAQ.page}</span>
                  <span className="font-semibold col-span-1">{"Language"}</span>
                  <span className="col-span-5">{detailFAQ.lang}</span>
                  <span className="font-semibold col-span-1">{"Status"}</span>
                  <span className="col-span-5">{detailFAQ.is_reflected}</span>
                </div>
            }

            <DialogFooter>
              {/* <button
                onClick={() => {
                  // setIsOpenDialog({ ...isOpenDialog, userDetail: false })
                  setIsChangeUser(!isChangeUser);
                  if (isChangeUser) {
                    handleUpdateUser();
                  }
                  // console.log(item)
                }}
                className="flex items-center gap-2 px-4 py-2 mb-0 bg-blue-500 text-sm text-white rounded-md bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
              >
                {isChangeUser ? "Save" : "Change Info"}
              </button>
              <button
                onClick={() => {
                  setIsOpenDialog({ ...isOpenDialog, chatHistory: true })
                  handleDialogOpen("chatHistory", detailUserForm.id, detailUserForm.company_id, detailUserForm.group_id)
                }}
                className="flex items-center gap-2 px-4 py-2 mb-0 bg-blue-500 text-sm text-white rounded-md bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
              >
                Show Messages
              </button> */}
               <button
                onClick={() => {
                  if(isChangeFAQ){
                    setIsDialogOpen((prev: any) => ({ ...prev, ["selectManual"]: true }))
                  }
                }}
                disabled={!isChangeFAQ}
                className={`flex items-center gap-2 px-4 py-2 mb-0 bg-blue-500 text-sm text-white rounded-md transition-colors ${!isChangeFAQ ? "bg-gray-500" : "bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600"}`}
              >
                Select Manual
              </button>
              <button
                onClick={() => {
                  setIsChangeFAQ(!isChangeFAQ);
                  if(isChangeFAQ){
                    handleUpdateFAQ();
                  }
                }}
                className="flex items-center gap-2 px-4 py-2 mb-0 bg-blue-500 text-sm text-white rounded-md bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
              >
                {isChangeFAQ ? "Update FAQ" : "Change FAQ"}
              </button>
            </DialogFooter>
            </DialogContent>
          </Dialog>
          <Dialog open={isDialogOpen.selectManual} onOpenChange={() => {setIsDialogOpen((prev: any) => ({ ...prev, ["selectManual"]: false }))}}>
            <DialogContent className=" !max-w-full !max-h-full !w-[calc(100vw-100px)] !h-[calc(100vh-100px)]">
              <DialogHeader>
                <DialogTitle>Select Manual</DialogTitle>
                <DialogDescription>
                  Please select the manual file.
                </DialogDescription>
              </DialogHeader>
              <div className="flex justify-start items-start gap-2 w-full">
                <PDFSelector onSelecct={handleSelectManual} />
              </div>
              <div className="grid grid-cols-6 gap-2 py-2">
                
                {/* 선택한 파일 이름 표시 */}
                
                <span className="col-span-1 text-right font-semibold">
                  Selected File:
                </span>
                <span className="col-span-5">
                  {fileName}
                </span>
                <span className="col-span-1 text-right font-semibold">
                  Manual Page number:
                </span>
                <span className="col-span-5">
                  <input 
                    type="text" 
                    value={detailFAQ.page}
                    onChange={(e) => {
                      setDetailFAQ((prev: any) => ({ ...prev, page: e.target.value }));
                    }}
                    className="px-2 w-[100px] border border-gray-500 rounded-md" />
                  <span className="text-gray-500 pl-2">(ex: 1,2,4)</span>
                </span>
              </div>
              
              <DialogFooter>
                <button
                  type="submit"
                  onClick={() => {
                    setIsDialogOpen((prev: any) => ({ ...prev, ["selectManual"]: false }))
                  }}
                  className="flex items-center gap-2 px-4 py-2 mb-0 bg-blue-500 text-sm text-white rounded-md bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
                >
                  Select Manual
                </button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
          <Dialog open={isDialogOpen.upload} onOpenChange={() => {setIsDialogOpen((prev: any) => ({ ...prev, ["upload"]: false }))}}>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Add New FAQ File</DialogTitle>
                <DialogDescription>
                  Please enter the information of the new FAQ file.
                </DialogDescription>
              </DialogHeader>
              <div className="flex justify-start items-start gap-2 w-full">
                <Label className="text-sm font-medium text-gray-700">Chatbot Type</Label>
                {selectedFaqType && <p className="text-sm text-gray-500">: {selectedFaqType}</p>}
              </div>
              <div className="flex justify-start items-start gap-2 w-full">
                <DropdownMenu>
                  <DropdownMenuTrigger>
                    <button 
                      className="flex gap-2 px-4 py-2 mb-0 bg-blue-500 text-sm text-white rounded-md bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors">
                        {selectedFaqType ? selectedFaqType : "Select Type"} 
                        <ChevronDown className="h-4 w-4"/>
                    </button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    <DropdownMenuItem onClick={() => {setSelectedFaqType("troubleshooting")}}>Troubleshooting</DropdownMenuItem>
                    <DropdownMenuItem onClick={() => {setSelectedFaqType("maintenance")}}>Maintenance</DropdownMenuItem>
                    <DropdownMenuItem onClick={() => {setSelectedFaqType("general_inquiry")}}>general_inquiry</DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
                
              <div className="grid gap-2 py-2">
              
                <label
                  htmlFor="file-upload"
                  className="block text-sm font-medium text-gray-700"
                >
                  Upload Excel File
                </label>
                
                {/* 파일 선택 */}
                <input
                  id="file-upload"
                  type="file"
                  accept=".xlsx, .xls"
                  multiple={false}
                  onChange={handleFileChange} // 파일 선택 시 호출되는 함수
                  className="mt-1 block w-full text-sm text-gray-500
                          file:mr-4 file:py-2 file:px-4
                          file:rounded-md file:border-0
                          file:text-sm file:font-semibold
                          file:bg-blue-50 file:text-blue-700
                          hover:file:bg-blue-100"
                />
                {/* 선택한 파일 이름 표시 */}
                {fileName && <p>Selected File: {fileName}</p>}
              </div>
              <DialogFooter>
                <button
                  type="submit"
                  onClick={() => {
                    handleUpload();
                  }}
                  className="flex items-center gap-2 px-4 py-2 mb-0 bg-blue-500 text-sm text-white rounded-md bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
                >
                  Add FAQ
                </button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
          <Dialog open={isDialogOpen.download} onOpenChange={() => {setIsDialogOpen((prev: any) => ({ ...prev, ["download"]: false }))}}>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Download FAQ File</DialogTitle>
                <DialogDescription>
                  Do you want to download the FAQ file?
                </DialogDescription>
              </DialogHeader>
              <div className="flex justify-start items-start gap-2 w-full">
                <DropdownMenu>
                  <DropdownMenuTrigger>
                    <button 
                      className="flex gap-2 px-4 py-2 mb-0 bg-blue-500 text-sm text-white rounded-md bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors">
                        {selectedFaqType ? selectedFaqType : "Select Type"} 
                        <ChevronDown className="h-4 w-4"/>
                    </button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    <DropdownMenuItem onClick={() => {setSelectedFaqType("troubleshooting")}}>Troubleshooting</DropdownMenuItem>
                    <DropdownMenuItem onClick={() => {setSelectedFaqType("maintenance")}}>Maintenance</DropdownMenuItem>
                    <DropdownMenuItem onClick={() => {setSelectedFaqType("general_inquiry")}}>general_inquiry</DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
              <div className="flex justify-center items-center p-6">
                <div className="text-gray-700 text-center">
                  <p className="mb-4">
                    You can download the current FAQ file.
                  </p>
                </div>
              </div>
              <DialogFooter className="flex justify-end space-x-4">
                <button onClick={() => handleFileDownload()}>Download</button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
          <Dialog open={isDialogOpen.finetune} onOpenChange={() => {setIsDialogOpen((prev: any) => ({ ...prev, ["finetune"]: false }))}}>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>FineTune</DialogTitle>
                <DialogDescription>Do you want to FineTune?</DialogDescription>
              </DialogHeader>
              <div className="flex justify-start items-start gap-2 w-full">
                <Label className="text-sm font-medium text-gray-700">Chatbot Type</Label>
                {selectedFaqType && <p className="text-sm text-gray-500">: {selectedFaqType}</p>}
              </div>
              <div className="flex justify-start items-start gap-2 w-full">
                <DropdownMenu>
                  <DropdownMenuTrigger>
                    <button 
                      className="flex gap-2 px-4 py-2 mb-0 bg-blue-500 text-sm text-white rounded-md bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors">
                        {selectedFaqType ? selectedFaqType : "Select Type"} 
                        <ChevronDown className="h-4 w-4"/>
                    </button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    <DropdownMenuItem onClick={() => {setSelectedFaqType("troubleshooting")}}>Troubleshooting</DropdownMenuItem>
                    <DropdownMenuItem onClick={() => {setSelectedFaqType("maintenance")}}>Maintenance</DropdownMenuItem>
                    <DropdownMenuItem onClick={() => {setSelectedFaqType("general_inquiry")}}>general_inquiry</DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
              <div className="flex justify-center items-center p-6">
                <div className="text-gray-700 text-center">
                  <p className="mb-4">You can FineTune the current FAQ.</p>
                </div>
              </div>
              <DialogFooter className="flex justify-end space-x-4">
                <button 
                  onClick={() => handleFinetune()}
                  className="flex items-center gap-2 px-4 py-2 mb-0 bg-blue-500 text-sm text-white rounded-md bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
                >FineTune</button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </div>
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            {["question","answer", "manual", "page",  "type", "lang","is_reflected"].map((key) => (
              <TableHead
                key={key}
                className="px-6 py-2 text-left text-xs text-nowrap font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                onClick={() => handleSort(key)}
              >
                {key === "id"
                  ? "NO."
                  : key === "question"
                  ? "QUESTION"
                  : key === "manual"
                  ? "FileName"
                  : key === "page"
                  ? "PAGE"
                  : key === "answer"
                  ? "ANSWER"
                  : key === "type"
                  ? "TYPE"
                  : key === "lang"
                  ? "LANG"
                  : key === "is_reflected"
                  ? "Trained"
                  : ""}
                {sortConfig.key === key && (
                  <span className="ml-1">
                    {sortConfig.direction === "asc" ? "▲" : "▼"}
                  </span>
                )}
              </TableHead>
            ))}
            <TableHead>Action</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {paginatedFAQData &&
            paginatedFAQData.map((faq) => (
              <TableRow 
                key={faq.id}
                onClick={() => {
                  setDetailFAQ(faq); 
                  setIsChangeFAQ(false);
                  setSelectedFaqId(faq.id);
                  setIsDialogOpen((prev: any) => ({ ...prev, ["detailFAQ"]: true }))
                }}
              >
                {/* <TableCell>{faq.id}</TableCell> */}
                {/* <TableCell>{faq.id}</TableCell> */}
                <TableCell className="truncate max-w-xs">{faq.question}</TableCell>
                <TableCell className="truncate max-w-xs">{faq.answer}</TableCell>
                <TableCell>{faq.manual}</TableCell>
                <TableCell>{faq.page}</TableCell>
                <TableCell>{faq.type.toUpperCase()}</TableCell>
                <TableCell>{faq.lang}</TableCell>
                <TableCell>{faq.is_reflected?"O":"X"}</TableCell>
                <TableCell>
                  <DropdownMenu>
                    <DropdownMenuTrigger>
                      <EllipsisVertical className="h-4 w-4" />
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                      <DropdownMenuItem onClick={() => {setIsDialogOpen((prev: any) => ({ ...prev, ["delete"]: true })); deleteFaqBtn(faq.id)}}>
                        Delete
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </TableCell>
              </TableRow>
            ))}

        </TableBody>
      </Table>
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={faqdatas ? Math.ceil(faqdatas?.length / ITEMS_PER_PAGE) : 1}
        totalNumber={faqdatas?.length}
        listName="FAQ Data"
      />
    </div>
  );
};

export default FAQTable;
