import React, { useEffect, useMemo, useState } from "react";
import * as XLSX from "xlsx";
import { useAppDispatch, useAppSelector } from "../../../lib/hooks";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../ui/table";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DropdownMenuSub,
  DropdownMenuSubTrigger,
  DropdownMenuSubContent,
} from "../../ui/dropdown-menu";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../ui/dialog";

import {
  Plus,
  Search,
  Filter,
  EllipsisVertical,
  MoreVertical,
  Check,
  X,
  Trash2,
  UserPlus,
  Users,
  Download,
  Upload,
  ChevronDown,
  FileText,
  BrainCircuit,
} from "lucide-react";
import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import Pagination from "../Common/Pagination";
import {
  adminAbbreviationUpdate,
  adminAbbreviationList,
  selectDashboardAbbreviations,
  adminAbbreviationAdd,
  adminAbbreviationUpload,
  adminAbbreviationFinetune,
  adminAbbreviationDelete,
} from "../../../lib/features/admin/adminSlice";
import { Label } from "@radix-ui/react-dropdown-menu";
import { set } from "react-datepicker/dist/date_utils";
import PDFSelector  from "../../pdfViewer/PDFSelector";

const ITEMS_PER_PAGE = 20;
const APP_STAGE = process.env.REACT_APP_STAGE;

const AbbreviationTable = ({
  token,
}:{
  abbreviationdatas: any;
  token: any;
  adminAbbreviationListChange: any;
}) => {
  const dispatch = useAppDispatch();
  const abbreviationdatas = useAppSelector(selectDashboardAbbreviations);
  const [currentPage, setCurrentPage] = useState(1);
  const [isChangeAbbreviation, setIsChangeAbbreviation] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState({
    upload: false,
    download: false,
    finetune: false,
    delete: false,
    detailAbbreviation: false,
    add: false,
  });
  const [selectedAbbreviationId, setSelectedAbbreviationId] = useState<any>(null);
  const [selectedAbbreviationType, setSelectedAbbreviationType] = useState<any>(null);

  const [newAbbreviationForm, setNewAbbreviationForm] = useState({
    key: "",
    value: "",
    type: "",
  });
  const [detailAbbreviation, setDetailAbbreviation] = useState<any>({
    id: "",
    key: "",
    value: "",
    type: "",
  });
  const [fileData, setFileData] = useState(null);
  const [parsedData, setParsedData] = useState<any>([]);
  const [fileName, setFileName] = useState("");

  const [abbreviationDataForm, setAbbreviationDataForm] = useState(
    abbreviationdatas?.map((abbreviation: any) => ({
      id: abbreviation.abbreviation_id,
      key: abbreviation.abbreviation_text,
      value: abbreviation.abbreviation_full_text,
      type: abbreviation.abbreviation_type,
    })) || []
  );

  const [sortConfig, setSortConfig] = useState({ key: "id", direction: "asc" });

  function formatDateTime(dateTimeString: string) {
    if (dateTimeString.length !== 14) {
      return "Invalid input format.";
    }

    const year = dateTimeString.slice(0, 4);
    const month = dateTimeString.slice(4, 6);
    const day = dateTimeString.slice(6, 8);
    const hour = dateTimeString.slice(8, 10);
    const minute = dateTimeString.slice(10, 12);
    const second = dateTimeString.slice(12, 14);

    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  }
  // 전체 상태 정리
  // 활성, 잠김(이메일, 승인안됨, 비번오류, 이상감지)
  function getStatus(user: any) {
    if (user.is_deleted) {
      return "Withdrawal";
    } else if (!user.is_verified || !user.has_email_check || user.is_locked) {
      return "Locked";
    } else {
      return "Active";
    }
  }
  useEffect(() => {
    dispatch(adminAbbreviationList({token}));
  }, []);

  const handleFileChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target) {
          const binaryStr = event.target.result;
          const workbook = XLSX.read(binaryStr, { type: "binary" });
          const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData = XLSX.utils.sheet_to_json(firstSheet);
          setParsedData(jsonData);
        } else {
          if (APP_STAGE === "DEV") {  
            console.log("event.target null");
          }
        }
      };
      reader.readAsBinaryString(file);
      setFileData(file);
    }
  };

  const handleUpload = () => {
    if (!fileData){
      alert("Please select the file.");
      return;
    }
    const formData = new FormData();
    formData.append("files", fileData);

    dispatch(
      adminAbbreviationUpload({
        token,
        fileData: formData,
      })
    ).then(() => {
      setFileName("");
      setFileData(null);
      adminAbbreviationList({token});
      alert("File upload successfully!");
      setSelectedAbbreviationType(null);
      setIsDialogOpen((prev: any) => ({ ...prev, ["upload"]: false }));
    });
  };

  useEffect(() => {
    if (abbreviationdatas)
      setAbbreviationDataForm(
        abbreviationdatas.map((abbreviation: any) => ({
          id: abbreviation.abbreviation_id,
          key: abbreviation.abbreviation_text,
          value: abbreviation.abbreviation_full_text,
          type: abbreviation.abbreviation_type,
        })) || []
      );
  }, [abbreviationdatas]);

  const sortedAbbreviationData = useMemo(() => {
    return [...abbreviationDataForm].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key])
        return sortConfig.direction === "asc" ? -1 : 1;
      if (a[sortConfig.key] > b[sortConfig.key])
        return sortConfig.direction === "asc" ? 1 : -1;
      return 0;
    });
  }, [abbreviationDataForm, sortConfig]);
  const totalPages = Math.ceil(sortedAbbreviationData.length / ITEMS_PER_PAGE);
  const paginatedAbbreviationData = sortedAbbreviationData.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const handleFileDownload = () => {
    const transformedData = abbreviationDataForm.map((abbreviation: any) => ({
      key: abbreviation.key,
      description: abbreviation.value,
      type: abbreviation.type,
    }));
    const worksheet = XLSX.utils.json_to_sheet(transformedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Abbreviations");
    XLSX.writeFile(workbook, "Abbreviations.xlsx");
  };

  const handleFinetune = () => {
    dispatch(
      adminAbbreviationFinetune(
        {token: token as string}
      )
    ).then(() => {
      adminAbbreviationList({token});
      alert("Fine-tuning completed successfully!");
    });
    setIsDialogOpen((prev: any) => ({ ...prev, ["finetune"]: false }));
  };

  const handleDelete = (abbreviation_id: any) => {
    dispatch(
      adminAbbreviationDelete({
        token,
        abbreviation_id,
      })
    ).then(() => {
      adminAbbreviationList({token});
      alert("Delete completed successfully!");
    });
  };

  const handleSort = (key: string) => {
    setSortConfig((currentConfig) => {
      if (currentConfig.key === key) {
        return {
          ...currentConfig,
          direction: currentConfig.direction === "asc" ? "desc" : "asc",
        };
      }
      return { key, direction: "asc" };
    });
  };
  const handleSelectManual = (finfo: any) => {
    if(finfo.type=="filename"){
      setFileName(finfo.pdf_name);
      setDetailAbbreviation((prev: any) => ({ ...prev, manual: finfo.pdf_name, page: finfo.page }));
    }
    if(finfo.type=="pagenum"){
      setDetailAbbreviation((prev: any) => ({ ...prev, page: finfo.page }));
    }
  }

  const deleteAbbreviationBtn = (abbreviation_id: any) => {
    setSelectedAbbreviationId(abbreviation_id);
    setIsDialogOpen((prev: any) => ({ ...prev, ["delete"]: true }));
  };

  const handleUpdateAbbreviation = () => {
    dispatch(
      adminAbbreviationUpdate({
        token,
        abbreviation_id: selectedAbbreviationId,
        abbreviation_text: detailAbbreviation.key,
        abbreviation_full_text: detailAbbreviation.value,
        abbreviation_type: detailAbbreviation.type,
      })
    ).then(() => {
      adminAbbreviationList({token});
      alert("Update completed successfully!");
    });
  };

  const handleAddAbbreviation = () => {
    dispatch(
      adminAbbreviationAdd({
        token,
        abbreviation_text: newAbbreviationForm.key,
        abbreviation_full_text: newAbbreviationForm.value,
        abbreviation_type: newAbbreviationForm.type,
      })
    ).then(() => {
      adminAbbreviationList({token});
      alert("Add completed successfully!");
      setIsDialogOpen((prev: any) => ({ ...prev, ["add"]: false }));
      setNewAbbreviationForm({
        key: "",
        value: "",
        type: "",
      });
    });
  };

  if(APP_STAGE=="DEV"){
    console.log({abbreviationDataForm});
  }

  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden p-4 max-w-screen-xl">
      <div className="border-b flex justify-between items-center">
        <h2 className="text-2xl font-semibold">Abbreviation Data</h2>
        <div className="flex items-center gap-2">
          <button
              onClick={() => {
                setNewAbbreviationForm({
                  key: "",
                  value: "",
                  type: "",
                });
                setIsDialogOpen((prev: any) => ({ ...prev, ["add"]: true }))}}
              className="flex items-center gap-2 px-4 py-2 mb-2 bg-blue-500 text-sm text-white rounded-full bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
            >
            <Plus className="h-4 w-4" /> Add
          </button>
          <button
            onClick={() => {
              setIsDialogOpen((prev: any) => ({ ...prev, ["upload"]: true }))}}
            className="flex items-center gap-2 px-4 py-2 mb-2 bg-blue-500 text-sm text-white rounded-full bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
          >
            <Upload className="h-4 w-4" /> Upload
          </button>
          <button
              onClick={() => {setIsDialogOpen((prev: any) => ({ ...prev, ["download"]: true }))}}
              className="flex items-center gap-2 px-4 py-2 mb-2 bg-blue-500 text-sm text-white rounded-full bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
            >
            <Download className="h-4 w-4" /> Download
          </button>
          <button
              onClick={() => {setIsDialogOpen((prev: any) => ({ ...prev, ["finetune"]: true }))}}
              className="flex items-center gap-2 px-4 py-2 mb-2 bg-blue-500 text-sm text-white rounded-full bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
            >
              <BrainCircuit className="h-4 w-4" /> Apply
          </button>
          <Dialog 
            open={isDialogOpen.add} onOpenChange={() => setIsDialogOpen((prev: any) => ({ ...prev, ["add"]: false }))} >
          <DialogContent >
            <DialogHeader>
              <DialogTitle>Add New Abbreviation</DialogTitle>
              <DialogDescription>Please enter the information of the new Abbreviation.</DialogDescription>
            </DialogHeader>
            <div className="grid grid-cols-6 grid-rows-auto gap-2 text-base ">
              <span className="font-semibold col-span-1">{"Key"}</span>
              <span className="col-span-5">
                <input
                  value={newAbbreviationForm.key}
                  onChange={(e) => {
                    setNewAbbreviationForm((prev: any) => ({ ...prev, key: e.target.value }));
                  }}
                  className="px-2 w-full border border-gray-500 rounded-md" />
              </span>
              <span className="font-semibold col-span-1">{"Value"}</span>
              <span className="col-span-5">
                <textarea
                  value={newAbbreviationForm.value}
                  onChange={(e) => {
                    setNewAbbreviationForm((prev: any) => ({ ...prev, value: e.target.value }));
                  }}
                  className="px-2 w-full border border-gray-500 rounded-md" />
              </span>
              <span className="font-semibold col-span-1">{"Type"}</span>
              <span className="col-span-5">
                <DropdownMenu>
                  <DropdownMenuTrigger>
                    <span className="flex items-center gap-2 px-4 py-2 mb-0 bg-blue-500 text-sm text-white rounded-md bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors">
                      {newAbbreviationForm.type ? newAbbreviationForm.type : "Select Type"}
                      <ChevronDown className="h-4 w-4" />
                    </span>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    <DropdownMenuItem onClick={() => {setNewAbbreviationForm((prev: any) => ({ ...prev, type: "Tag" }))}}>Tag</DropdownMenuItem>
                    <DropdownMenuItem onClick={() => {setNewAbbreviationForm((prev: any) => ({ ...prev, type: "Abbreviation" }))}}>Abbreviation</DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </span>

            </div>
            <DialogFooter>
              <button
                onClick={() => {
                  handleAddAbbreviation();
                }}
                className={`flex items-center gap-2 px-4 py-2 mb-0 bg-blue-500 text-sm text-white rounded-md transition-colors bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600`}
              >
                Add Abbreviation
              </button>
            </DialogFooter>
            </DialogContent>
          </Dialog>
          <Dialog 
            open={isDialogOpen.detailAbbreviation} onOpenChange={() => setIsDialogOpen((prev: any) => ({ ...prev, ["detailAbbreviation"]: false }))} >
          <DialogContent className=" !max-w-6xl px-10">
            <DialogHeader>
              <DialogTitle>Abbreviation Detail</DialogTitle>
              <DialogDescription>show Abbreviation Detail</DialogDescription>
            </DialogHeader>

            {isChangeAbbreviation ?
                <div className="grid grid-cols-6 grid-rows-auto gap-2 text-base ">
                  <span className="font-semibold col-span-1">{"Key"}</span>
                  <span className="col-span-5">
                    <input
                      value={detailAbbreviation.key}
                      onChange={(e) => {
                        setDetailAbbreviation((prev: any) => ({ ...prev, key: e.target.value }));
                      }}
                      className="px-2 w-full border border-gray-500 rounded-md" />
                  </span>
                  <span className="font-semibold col-span-1">{"Value"}</span>
                  <span className="col-span-5">
                    <input
                      value={detailAbbreviation.value}
                      onChange={(e) => {
                        setDetailAbbreviation((prev: any) => ({ ...prev, value: e.target.value }));
                      }}
                      className="px-2 w-full border border-gray-500 rounded-md" />
                  </span>
                  <span className="font-semibold col-span-1">{"Type"}</span>
                  <span className="col-span-5">
                    <DropdownMenu>
                      <DropdownMenuTrigger>
                        <span className="flex items-center gap-2 px-4 py-2 mb-0 bg-blue-500 text-sm text-white rounded-md bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors">
                          {detailAbbreviation.type}
                          <ChevronDown className="h-4 w-4" />
                        </span>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent>
                        <DropdownMenuItem onClick={() => {setDetailAbbreviation((prev: any) => ({ ...prev, type: "Tag" }))}}>Tag</DropdownMenuItem>
                        <DropdownMenuItem onClick={() => {setDetailAbbreviation((prev: any) => ({ ...prev, type: "Abbreviation" }))}}>Abbreviation</DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </span>
                </div>
                
              :
                <div className="grid grid-cols-6 grid-rows-auto gap-2 text-base ">
                  <span className="font-semibold col-span-1">{"Key"}</span>
                  <span className="col-span-5">{detailAbbreviation.key}</span>
                  <span className="font-semibold col-span-1">{"Value"}</span>
                  <span className="col-span-5">{detailAbbreviation.value}</span>
                  <span className="font-semibold col-span-1">{"Type"}</span>
                  <span className="col-span-5">{detailAbbreviation.type}</span>
                </div>
            }

            <DialogFooter>
              <button
                onClick={() => {
                  setIsChangeAbbreviation(!isChangeAbbreviation);
                  if(isChangeAbbreviation){
                    handleUpdateAbbreviation();
                  }
                }}
                className="flex items-center gap-2 px-4 py-2 mb-0 bg-blue-500 text-sm text-white rounded-md bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
              >
                {isChangeAbbreviation ? "Update Abbreviation" : "Change Abbreviation"}
              </button>
            </DialogFooter>
            </DialogContent>
          </Dialog>
          <Dialog open={isDialogOpen.upload} onOpenChange={() => {setIsDialogOpen((prev: any) => ({ ...prev, ["upload"]: false }))}}>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Upload Abbreviation File</DialogTitle>
                <DialogDescription>
                  Please upload the Abbreviation file.
                </DialogDescription>
              </DialogHeader>
                
              <div className="grid gap-2 py-2">
              
                <label
                  htmlFor="file-upload"
                  className="block text-sm font-medium text-gray-700"
                >
                  Upload Excel File
                </label>
                
                {/* 파일 선택 */}
                <input
                  id="file-upload"
                  type="file"
                  accept=".xlsx, .xls"
                  multiple={false}
                  onChange={handleFileChange} // 파일 선택 시 호출되는 함수
                  className="mt-1 block w-full text-sm text-gray-500
                          file:mr-4 file:py-2 file:px-4
                          file:rounded-md file:border-0
                          file:text-sm file:font-semibold
                          file:bg-blue-50 file:text-blue-700
                          hover:file:bg-blue-100"
                />
                {/* 선택한 파일 이름 표시 */}
                {fileName && <p>Selected File: {fileName}</p>}
              </div>
              <DialogFooter>
                <button
                  type="submit"
                  onClick={() => {
                    handleUpload();
                  }}
                  className="flex items-center gap-2 px-4 py-2 mb-0 bg-blue-500 text-sm text-white rounded-md bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
                >
                  Upload
                </button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
          <Dialog open={isDialogOpen.download} onOpenChange={() => {setIsDialogOpen((prev: any) => ({ ...prev, ["download"]: false }))}}>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Download Abbreviation File</DialogTitle>
                <DialogDescription>
                  Do you want to download the Abbreviation file?
                </DialogDescription>
              </DialogHeader>
              <div className="flex justify-start items-start gap-2 w-full">
                
              </div>
              <div className="flex justify-center items-center p-6">
                <div className="text-gray-700 text-center">
                  <p className="mb-4">
                    You can download the current Abbreviation file.
                  </p>
                </div>
              </div>
              <DialogFooter className="flex justify-end space-x-4">
                <button onClick={() => handleFileDownload()}>Download</button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
          <Dialog open={isDialogOpen.finetune} onOpenChange={() => {setIsDialogOpen((prev: any) => ({ ...prev, ["finetune"]: false }))}}>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>FineTune</DialogTitle>
                <DialogDescription>Do you want to FineTune?</DialogDescription>
              </DialogHeader>
              
              <div className="flex justify-center items-center p-6">
                <div className="text-gray-700 text-center">
                  <p className="mb-4">You can FineTune the current Abbreviation.</p>
                </div>
              </div>
              <DialogFooter className="flex justify-end space-x-4">
                <button 
                  onClick={() => handleFinetune()}
                  className="flex items-center gap-2 px-4 py-2 mb-0 bg-blue-500 text-sm text-white rounded-md bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
                >FineTune</button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </div>
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            {["key","value", "type"].map((key) => (
              <TableHead
                key={key}
                className="px-6 py-2 text-left text-xs text-nowrap font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                onClick={() => handleSort(key)}
              >
                {key === "id"
                  ? "NO."
                  : key === "key"
                  ? "KEY"
                  : key === "value"
                  ? "VALUE"
                  : key === "type"
                  ? "TYPE"
                  : key === "lang"
                  ? "LANG"
                  : key === "is_reflected"
                  ? "Trained"
                  : ""}
                {sortConfig.key === key && (
                  <span className="ml-1">
                    {sortConfig.direction === "asc" ? "▲" : "▼"}
                  </span>
                )}
                
              </TableHead>
              
            ))}
            <TableHead>Action</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {paginatedAbbreviationData &&
            paginatedAbbreviationData.map((abbreviation) => (
              <TableRow 
                key={abbreviation.id}
                onClick={() => {
                  setDetailAbbreviation(abbreviation); 
                  setIsChangeAbbreviation(false);
                  setSelectedAbbreviationId(abbreviation.id);
                  setIsDialogOpen((prev: any) => ({ ...prev, ["detailAbbreviation"]: true }))
                }}
              >
                <TableCell className="truncate w-[200px]">{abbreviation.key}</TableCell>
                <TableCell className="truncate max-w-xs">{abbreviation.value}</TableCell>
                <TableCell className="truncate w-[50px]">{abbreviation.type.toUpperCase()}</TableCell>
                <TableCell className="truncate w-[50px]">
                  <DropdownMenu>
                    <DropdownMenuTrigger>
                      <EllipsisVertical className="h-4 w-4" />
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                      <DropdownMenuItem onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(abbreviation.id)}}>
                        Delete
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </TableCell>
              </TableRow>
            ))}

        </TableBody>
      </Table>
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={abbreviationdatas ? Math.ceil(abbreviationdatas?.length / ITEMS_PER_PAGE) : 1}
        totalNumber={abbreviationdatas?.length}
        listName="Abbreviation"
      />
    </div>
  );
};

export default AbbreviationTable;
