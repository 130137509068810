import React, { useEffect, useState, useCallback, useMemo } from "react"
import { useAppDispatch, useAppSelector } from "../../../lib/hooks"
import {
    adminUserAllGet,
    adminGroupListGet,
    adminModelListGet,
    adminFileListGet,
    adminFGroupListGet,
    adminFGroupAllListGet,
    adminFileListGetOnfid,
    adminPGroupListGet,
    adminPGroupFileListGet,
    adminChatbotListGet,
    adminPGroupAdd,
    adminPGroupDelete,
    adminFileAdd,
    adminFileDelete,
    selectDashboardUser,
    selectDashboardGroup,
    selectDashboardModel,
    selectDashboardFile,
    selectDashboardFGroup,
    selectDashboardFGroupAll,
    selectDashboardPGroup,
    selectDashboardPGroupFile,
    selectDashboardChatbot,
    resetPGroupFile,
    setPGroupFile,
    selectDashboardPGroupEdit,
    adminPGroupVersionDelete,
    adminPGroupVersionAdd,
    adminChatbotAdd,
    adminChatbotDelete,
} from "../../../lib/features/admin/adminSlice"
import { selectToken, selectStatus } from "../../../lib/features/auth/authSlice"
import { FaTrashAlt } from "react-icons/fa"
import TreeView from "../FilePage/FileTree"

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../ui/table"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
    DropdownMenuSub,
    DropdownMenuSubTrigger,
    DropdownMenuSubContent,
} from "../../ui/dropdown-menu"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../../ui/dialog"

import { Plus, Search, Filter, EllipsisVertical, MoreVertical, Check, X, Trash2, UserPlus, Users, ArrowDown, MoveDown, ChevronDown } from "lucide-react"
import { Button } from "../../ui/button"
import { Input } from "../../ui/input"
import Pagination from "../Common/Pagination";
import FileExplorer from "./FileExplorer";
import FileList from "./FileList";
import { fetchAdminPGroupVersionAdd } from "@/src/lib/features/admin/adminAPI"

type DirFile = {
    fgroup_id?: string
    fgroup_name?: string
    pdf_id?: string
    pdf_name?: string
    isFolder: boolean
    checkStatus?: "none" | "half" | "all"
    child?: DirFile[]
    files?: DirFile[]
}

type FileItem = {
    pdf_id: number
    pdf_display_nm: string
    pdf_mime_type: string
    fgroup_id: number
    pdf_size: number
    is_used: boolean
    is_deleted: boolean
    is_duplicate: boolean
    is_valid: boolean
    created_at: string
    updated_at: string

}

const ITEMS_PER_PAGE = 20;
const APP_STAGE = process.env.REACT_APP_STAGE;

const FileGroupTable = ({ }) => {
    const dispatch = useAppDispatch()
    const token = useAppSelector(selectToken)
    const status = useAppSelector(selectStatus)
    const files = useAppSelector(selectDashboardFile)
    const fgroups = useAppSelector(selectDashboardFGroup)
    const fgroupsAll = useAppSelector(selectDashboardFGroupAll)
    const pgroups = useAppSelector(selectDashboardPGroup)
    const pgroupFiles = useAppSelector(selectDashboardPGroupFile)
    const pgroupEdit = useAppSelector(selectDashboardPGroupEdit)
    const chatbots = useAppSelector(selectDashboardChatbot)

    const [selectFiles, setSelectFiles] = useState([])
    const [uploadFiles, setUploadFiles] = useState([])
    const [selectDirName, setSelectDirName] = useState("")
    const [newPGroupName, setNewPGroupName] = useState("")
    const [newPGroupType, setNewPGroupType] = useState("troubleshooting")
    const [activeTab, setActiveTab] = useState("file-management")

    const [directories, setDirectories] = useState<DirFile[]>([]);
    const [currentDirFiles, setCurrentDirFiles] = useState<FileItem[]>([]);
    const [selectedFolderId, setSelectedFolderId] = useState<string | null>(null);
    const [viewMode, setViewMode] = useState(true);

    const [fileStructure, setFileStructure] = useState<DirFile[] | null>(null)
    const [isDialogOpen, setIsDialogOpen] = useState({
        add: false
    })
    const updateNodeIsOpen = useCallback((tree: any, id: string, isOpen: boolean) => {

        if (tree.fgroup_id == id) {
            return { ...tree, isOpen: !tree.isOpen };
        }
        if (tree.child) {
            return {
                ...tree,
                child: tree.child.map((childNode: any) => updateNodeIsOpen(childNode, id, isOpen))
            };
        }
        return tree;
    }, []);


    const addIsOpenToTree = useCallback((tree: any) => {
        if (!tree) return null;
        return {
            ...tree,
            isOpen: false,
            isFolder: true,
            checkStatus: "none",
            child: tree.child ? tree.child.map((item: any) => addIsOpenToTree(item)) : []
        };
    }, []);

    useEffect(() => {
        if (token) {
            // dispatch(adminUserAllGet({ token }))
            // dispatch(adminGroupListGet({ token }))
            // dispatch(adminModelListGet({ token }))
            dispatch(adminFileListGet({ token }))
            dispatch(adminFGroupListGet({ token }))
            dispatch(adminFGroupAllListGet({ token }))
            dispatch(adminPGroupListGet({ token }))
            dispatch(adminChatbotListGet({ token }))


        }
    }, [dispatch, token])

    // function convertToTreeNode(inputNode: any, pgroupFiles: any): DirFile {
    //     // Folder Node Conversion
    //     const folderNode: DirFile = {
    //         fgroup_id: inputNode.fgroup_id?.toString(),
    //         fgroup_name: inputNode.fgroup_name,
    //         isFolder: true,
    //         checkStatus: "none",  // 초기값 설정
    //         child: [],
    //         files: []
    //     };

    //     // File Conversion within folderNode
    //     inputNode.files?.forEach((file: any) => {
    //         const fileNode: DirFile = {
    //             pdf_id: file.pdf_id.toString(),
    //             pdf_name: file.pdf_nm,
    //             isFolder: false,
    //             checkStatus: "none"  // 파일의 기본 checkStatus는 "none"
    //         };
    //         folderNode.files?.push(fileNode);
    //     });

    //     // Recursively convert children folders and determine checkStatus for each child folder
    //     inputNode.child?.forEach((childNode: any) => {
    //         const childFolderNode = convertToTreeNode(childNode, pgroupFiles);
    //         folderNode.child?.push(childFolderNode);
    //     });

    //     // Calculate checkStatus for files in the current folder based on pgroupFiles
    //     const fileCount = folderNode.files?.length || 0;
    //     const includedFileCount = folderNode.files?.filter(
    //         file => pgroupFiles.some((pfile: any) => pfile.pdf_id.toString() === file.pdf_id?.toString())
    //     ).length || 0;

    //     // Set current folder's checkStatus based on its files
    //     let currentFolderStatus: "none" | "half" | "all" = "none";
    //     if (fileCount > 0) {
    //         if (includedFileCount === fileCount) {
    //             currentFolderStatus = "all";
    //         } else if (includedFileCount > 0) {
    //             currentFolderStatus = "half";
    //         }
    //     }

    //     // Determine checkStatus for current folder based on both child folders' statuses and files' status
    //     const childStatuses = folderNode.child?.map(child => child.checkStatus) || [];

    //     if (childStatuses.length > 0) {
    //         if (childStatuses.every(status => status === "all") && currentFolderStatus === "all") {
    //             folderNode.checkStatus = "all";
    //         } else if (childStatuses.some(status => status === "half" || status === "all") || currentFolderStatus === "half") {
    //             folderNode.checkStatus = "half";
    //         } else {
    //             folderNode.checkStatus = "none";
    //         }
    //     } else {
    //         // If there are no child folders, use the current folder's status based on its own files
    //         folderNode.checkStatus = currentFolderStatus;
    //     }

    //     return folderNode;
    // }
    function convertToTreeNode(inputNode: any, pgroupFiles: any): DirFile {
        // Folder Node Conversion
        const folderNode: DirFile = {
            fgroup_id: inputNode.fgroup_id?.toString(),
            fgroup_name: inputNode.fgroup_name,
            isFolder: true,
            checkStatus: "none", // 초기값 설정
            child: [],
            files: [],
        };
    
        // File Conversion within folderNode
        inputNode.files?.forEach((file: any) => {
            const fileNode: DirFile = {
                pdf_id: file.pdf_id.toString(),
                pdf_name: file.pdf_nm,
                isFolder: false,
                checkStatus: pgroupFiles.some(
                    (pfile: any) => pfile.pdf_id.toString() === file.pdf_id.toString()
                )
                    ? "all" // 파일이 선택된 경우
                    : "none", // 파일이 선택되지 않은 경우
            };
            folderNode.files?.push(fileNode);
        });
    
        // Recursively convert children folders and calculate their checkStatus
        inputNode.child?.forEach((childNode: any) => {
            const childFolderNode = convertToTreeNode(childNode, pgroupFiles);
            folderNode.child?.push(childFolderNode);
        });
    
        // Calculate checkStatus for current folder
        const fileStatuses = folderNode.files?.map((file) => file.checkStatus) || [];
        const childStatuses = folderNode.child?.map((child) => child.checkStatus) || [];
    
        // Determine if all files are checked
        const allFilesChecked = fileStatuses.every((status) => status === "all");
        const someFilesChecked = fileStatuses.some((status) => status === "all");
    
        // Determine if all child folders are checked
        const allChildrenChecked = childStatuses.every((status) => status === "all");
        const someChildrenChecked = childStatuses.some((status) => status === "all" || status === "half");
    
        // Update current folder's checkStatus
        if (folderNode.files?.length === 0 && folderNode.child?.length === 0) {
            // If there are no files or child folders, set to "none"
            folderNode.checkStatus = "none";
        } else if (allFilesChecked && allChildrenChecked) {
            folderNode.checkStatus = "all";
        } else if (someFilesChecked || someChildrenChecked) {
            folderNode.checkStatus = "half";
        } else {
            folderNode.checkStatus = "none";
        }
    
        return folderNode;
    }
    

    useEffect(() => {
        if (fgroupsAll?.tree && pgroupFiles) {
            const treeNode = convertToTreeNode(fgroupsAll.tree, pgroupFiles.pdf);
            if (APP_STAGE === "DEV") {
                console.log({ treeNode })
            }
            setFileStructure([treeNode])
        }
    }, [fgroupsAll?.tree, pgroupFiles?.pdf])
    useEffect(() => {
        if (fgroupsAll?.tree) {
            const processedTree = addIsOpenToTree(fgroupsAll.tree);
            setDirectories(processedTree ? [processedTree] : []);
        }
    }, [fgroupsAll?.tree, addIsOpenToTree]);

    useEffect(() => {
        if (fgroups?.dirFile) {
            setCurrentDirFiles(fgroups?.dirFile || []);
        }
    }, [fgroups?.dirFile]);

    const handleGetDirFile = useCallback((fgroupId: number) => {
        dispatch(adminFileListGetOnfid({ token: token as string, fgroupId: fgroupId }));
    }, [dispatch, token, fgroups]);

    const handleClickPGroup = (pgroupId: number) => {
        // dispatch(adminFGroupAllListGet({ token: token as string}))
        // dispatch(adminPGroupFileListGet({ token: token as string, pgroup_id: pgroupId }))
    }
    const handleClickPGroupVersion = (pgroupId: number, vid: number) => {
        dispatch(adminPGroupFileListGet({ token: token as string, pgroup_id: pgroupId, pgroup_vid: vid }))
    }
    const handleClickCreateChatbot = (pgroupId: number, vid: number) => {
        dispatch(
            adminPGroupFileListGet({
              token: token as string,
              pgroup_id: pgroupId as number,
              pgroup_vid: vid as number,
            })
          ).then((res) => {
            if(res.payload.pdf.length === 0){
                alert("학습할 파일이 없습니다. 파일을 먼저 지정해주세요.");
                return;
            }else{
                dispatch(adminChatbotAdd({ token: token as string, pgroupId, vid:vid, chatbotType: "BWMS-TS", modelId: 1 }))
            }
            
          })
        
    }
    const handleClickDeleteChatbot = (pgroupId: number, vid: number) => {

        dispatch(adminChatbotDelete({ token: token as string, chatbotId: 0, pgroupId, vid }))
    }
    const handleAddPGroup = (pgroupName: string) => {
        dispatch(adminPGroupAdd({ token: token as string, pgroupName, pgroupType: newPGroupType })).then(() => {
            dispatch(adminPGroupListGet({ token: token as string }))
        }).then(
            () => {
                setNewPGroupName("")
                setNewPGroupType("troubleshooting")
                setIsDialogOpen({...isDialogOpen, ["add"]: false})
            }
        )
    }
    const handleAddPGroupVersion = (pgroupId: number, pgroupName: string, pgroupVersion: number) => {
        dispatch(adminPGroupVersionAdd({ token: token as string, pgroupId, pgroupName, pgroupVersion })).then(() => {
            dispatch(adminPGroupListGet({ token: token as string }))
        })
        setNewPGroupName("")
    }

    const handleDeletePGroup = (pgroupId: number) => {
        // 삭제 전 사용자에게 확인 메시지 표시
        if (window.confirm('이 파일 그룹을 삭제하시겠습니까? 하위 그룹이 모두 삭제됩니다.')) {
            dispatch(adminPGroupDelete({ token: token as string, pgroupId }))
                .then((response) => {
                    // 성공적으로 삭제된 경우
                    dispatch(adminPGroupListGet({ token: token as string }));
                    alert('파일 그룹이 성공적으로 삭제되었습니다.');
                })
                .catch((error) => {
                    // 삭제 실패 시 에러 메시지 표시
                    console.error('Failed to delete group:', error);
                    alert('파일 그룹 삭제 중 오류가 발생했습니다: ' + 
                          (error.message || '알 수 없는 오류'));
                });
        }
    }
    
    const handleDeletePGroupVersion = (pgroupId: number, vid: number) => {
        // 삭제 전 사용자에게 확인 메시지 표시
        if (window.confirm('이 버전 그룹을 삭제하시겠습니까? 해당 그룹이 완전히 삭제됩니다.')) {
            dispatch(adminPGroupVersionDelete({ token: token as string, pgroupId, vid }))
                .then((response) => {
                    // 성공적으로 삭제된 경우
                    dispatch(adminPGroupListGet({ token: token as string }));
                    alert('버전 그룹이 성공적으로 삭제되었습니다.');
                })
                .catch((error) => {
                    // 삭제 실패 시 에러 메시지 표시
                    console.error('Failed to delete version group:', error);
                    alert('버전 그룹 삭제 중 오류가 발생했습니다: ' + 
                          (error.message || '알 수 없는 오류'));
                });
        }
    }

    const [currentPage, setCurrentPage] = useState(1);
    const [newPGroupForm, setNewPGroupForm] = useState({
        name: "",
        status: ""
    });
    const [pgroupForm, setPGroupForm] = useState(
        pgroups.reduce((acc: any, pgroup: any) => {
            if(acc.find((item: any) => item.id === pgroup.pgroup_id)){
                acc.find((item: any) => item.id === pgroup.pgroup_id).versions.push({vid: pgroup.pgroup_vid, status: pgroup.pgroup_status})
            }else{
                acc.push({
                    id: pgroup.pgroup_id,
                    name: pgroup.pgroup_name, 
                    status: "INIT",
                    version: 0,
                    versions: [{vid: pgroup.pgroup_vid, status: pgroup.pgroup_status}]
                });
            }
            return acc;
        }, []) || []
    );

    const [sortConfig, setSortConfig] = useState({ key: "id", direction: "asc" });
    // console.log({ fgroupsAll })

    function formatDateTime(dateTimeString: string) {
        if (dateTimeString.length !== 14) {
            return "잘못된 입력 형식입니다.";
        }

        const year = dateTimeString.slice(0, 4);
        const month = dateTimeString.slice(4, 6);
        const day = dateTimeString.slice(6, 8);
        const hour = dateTimeString.slice(8, 10);
        const minute = dateTimeString.slice(10, 12);
        const second = dateTimeString.slice(12, 14);

        return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    }
    // 전체 상태 정리
    // 활성, 잠김(이메일, 승인안됨, 비번오류, 이상감지)
    function getStatus(user: any) {
        if (user.is_deleted) {
            return "탈퇴";
        } else if (!user.is_verified || !user.has_email_check || user.is_locked) {
            return "잠김";
        } else {
            return "활성";
        }
    }
    useEffect(() => {
        setPGroupForm(
            pgroups.reduce((acc: any, pgroup: any) => {
                if(acc.find((item: any) => item.id === pgroup.pgroup_id)){
                    acc.find((item: any) => item.id === pgroup.pgroup_id).versions.push({vid: pgroup.pgroup_vid, status: pgroup.pgroup_status})
                }else{
                    acc.push({
                        id: pgroup.pgroup_id,
                        name: pgroup.pgroup_name, 
                        status: "INIT",
                        version: 0,
                        versions: [{vid: pgroup.pgroup_vid, status: pgroup.pgroup_status}]
                    });
                }
                return acc;
            }, []) || []
        );
    }, [pgroups]);

    useEffect(() => {
        // console.log("pgroupFiles", pgroupFiles)
    }, [pgroupFiles])


    const sortedPGroups = useMemo(() => {
        return [...pgroupForm].sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key])
                return sortConfig.direction === "asc" ? -1 : 1;
            if (a[sortConfig.key] > b[sortConfig.key])
                return sortConfig.direction === "asc" ? 1 : -1;
            return 0;
        });
    }, [pgroupForm, sortConfig]);
    const totalPages = Math.ceil(sortedPGroups.length / ITEMS_PER_PAGE);
    const paginatedPGroups = sortedPGroups.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        currentPage * ITEMS_PER_PAGE
    );

    const handleSort = (key: string) => {
        setSortConfig((currentConfig) => {
            if (currentConfig.key === key) {
                return {
                    ...currentConfig,
                    direction: currentConfig.direction === "asc" ? "desc" : "asc",
                };
            }
            return { key, direction: "asc" };
        });
    };
    if(APP_STAGE === "DEV"){
        console.log({ fileStructure, currentDirFiles, pgroupFiles, paginatedPGroups })
    }
    return (
        <div className="bg-white shadow-md rounded-lg overflow-hidden p-4">
            <div className="border-b flex justify-between items-center">
                <h2 className="text-2xl font-semibold">PDF Groups</h2>
                <button
                    onClick={() => {setIsDialogOpen({...isDialogOpen, ["add"]: true})}}
                    className="flex items-center gap-2 px-4 py-2 mb-2 bg-blue-500 text-sm text-white rounded-full bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
                ><Plus className="h-4 w-4" /> New Group
                </button>
                <Dialog  open={isDialogOpen.add} onOpenChange={() => {setIsDialogOpen((prev: any) => ({ ...prev, ["add"]: false }))}}>
                    <DialogContent>
                        <DialogHeader>
                            <DialogTitle>새 파일그룹 추가</DialogTitle>
                            <DialogDescription>새로운 파일그룹의 정보를 입력하세요.</DialogDescription>
                        </DialogHeader>
                        <form onSubmit={(e) => {
                            e.preventDefault()
                            const formData = new FormData(e.currentTarget)
                            // addGroup(formData.get('name'), formData.get('description'))
                        }}>
                            <div className="flex justify-start items-start gap-2 w-full">
                                <DropdownMenu>
                                <DropdownMenuTrigger>
                                    <button 
                                    className="flex gap-2 px-4 py-2 mb-0 bg-blue-500 text-sm text-white rounded-md bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors">
                                        {newPGroupType ? newPGroupType : "Select Type"} 
                                        <ChevronDown className="h-4 w-4"/>
                                    </button>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent>
                                    <DropdownMenuItem onClick={() => {setNewPGroupType("troubleshooting")}}>Troubleshooting</DropdownMenuItem>
                                    <DropdownMenuItem onClick={() => {setNewPGroupType("maintenance")}}>Maintenance</DropdownMenuItem>
                                </DropdownMenuContent>
                                </DropdownMenu>
                            </div>
                            <div className="grid gap-2 py-2">
                                <div className="flex items-center gap-4">
                                    <Input
                                        label="파일그룹"
                                        type="text"
                                        id="name"
                                        placeholder="파일그룹"
                                        value={newPGroupForm.name}
                                        onChange={(e) => setNewPGroupForm({ ...newPGroupForm, name: e.target.value })}
                                        isNoSpinner={true}
                                    />
                                </div>
                            </div>
                            <DialogFooter>
                                <button
                                    type="submit"
                                    onClick={() => handleAddPGroup(newPGroupForm.name)}
                                    className="flex items-center gap-2 px-4 py-2 mb-0 bg-blue-500 text-sm text-white rounded-md bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
                                >파일그룹 추가</button>
                            </DialogFooter>
                        </form>
                    </DialogContent>
                </Dialog>

            </div>
            <Table>
                <TableHeader>
                    <TableRow>
                        {["id", "name", "version", "status"].map((key) => (
                            <TableHead
                                key={key}
                                className="px-6 py-2 text-left text-xs text-nowrap font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                onClick={() => handleSort(key)}
                            >
                                {key === "id"
                                    ? "No."
                                    : key === "name"
                                        ? "파일그룹"
                                        : key === "version"
                                        ? "버전"
                                        : key === "status"
                                        ? "상태"
                                        : ""}
                                {sortConfig.key === key && (
                                    <span className="ml-1">
                                        {sortConfig.direction === "asc" ? "▲" : "▼"}
                                    </span>
                                )}
                            </TableHead>
                        ))}
                        <TableHead>작업</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {paginatedPGroups && paginatedPGroups.map((pgroup) => (
                        <>
                        <TableRow key={pgroup.id}>
                            <TableCell>{pgroup.id}</TableCell>
                            <TableCell>
                                <span
                                    className="cursor-pointer inline-flex items-center gap-2 font-semibold text-gray-600 hover:text-blue-800"
                                    onClick={() => {}}
                                ><ChevronDown className="h-4 w-4" /> {pgroup.name}</span>
                            </TableCell>
                            <TableCell className="italic">{pgroup.version}</TableCell>
                            <TableCell>{pgroup.status}</TableCell>
                            <TableCell>
                                <DropdownMenu>
                                    <DropdownMenuTrigger>
                                        <EllipsisVertical className="h-4 w-4" />
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent>
                                        <DropdownMenuItem
                                            onClick={() => handleAddPGroupVersion(pgroup.id, pgroup.name, 0)}
                                        >Version Add</DropdownMenuItem>
                                        <DropdownMenuItem
                                            onClick={() => handleDeletePGroup(pgroup.id)}
                                            className="text-red-500"
                                            disabled={pgroup.versions.length > 1}
                                        >Group Delete</DropdownMenuItem>
                                    </DropdownMenuContent>
                                </DropdownMenu>

                            </TableCell>
                        </TableRow>
                        {pgroup.versions.length &&
                            pgroup.versions.map((version: any) => (
                                <TableRow key={"vid"+version.vid} className="bg-gray-100">
                                    <TableCell></TableCell>
                                    <TableCell>
                                        <span
                                            className="pl-4 cursor-pointer text-blue-600 hover:text-blue-800"
                                            onClick={() => handleClickPGroupVersion(pgroup.id, version.vid)}
                                        >{pgroup.name+" v."+version.vid}</span>
                                    </TableCell>
                                    <TableCell className="italic">{"v."+version.vid}</TableCell>
                                    <TableCell>{version.status}</TableCell>
                                    <TableCell>
                                        <DropdownMenu>
                                            <DropdownMenuTrigger>
                                                <EllipsisVertical className="h-4 w-4" />
                                            </DropdownMenuTrigger>
                                            <DropdownMenuContent>
                                                
                                                <DropdownMenuItem
                                                    onClick={() => handleClickCreateChatbot(pgroup.id, version.vid)}
                                                    disabled={version.status==="IDLE"?false:true}
                                                    className={version.status==="IDLE"?"":"text-gray-300"}
                                                >챗봇 생성</DropdownMenuItem>
                                                <DropdownMenuItem
                                                    onClick={() => handleClickDeleteChatbot(pgroup.id, version.vid)}
                                                    disabled={version.status==="INACTIVE"?false:true}
                                                    className={version.status==="INACTIVE"?"":"text-gray-300"}
                                                >챗봇 삭제</DropdownMenuItem>
                                                <DropdownMenuItem
                                                    onClick={() => handleAddPGroupVersion(pgroup.id, pgroup.name, version.vid)}
                                                >그룹 복제</DropdownMenuItem>
                                                <DropdownMenuItem
                                                    onClick={() => handleDeletePGroupVersion(pgroup.id, version.vid)}
                                                    disabled={version.status==="IDLE"?true:false}
                                                    className={version.status==="IDLE"?"":"text-gray-300"}
                                                >그룹 삭제</DropdownMenuItem>
                                            </DropdownMenuContent>
                                        </DropdownMenu>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                        </>
                    ))}
                </TableBody>
            </Table>
            <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={pgroups ? Math.ceil(pgroups?.length / 20) : 1} totalNumber={pgroups?.length} listName="파일그룹" />

            {pgroupFiles && (
                <div className="mt-4">
                    <div className="flex justify-between items-center">
                        <h3 className="text-lg font-semibold mb-2">
                            Selected Group: {pgroupFiles.pgroup.pgroup_name}
                        </h3>
                        <button
                            onClick={() => { 
                                setViewMode(!viewMode) 
                                if(viewMode){
                                    dispatch(resetPGroupFile())
                                }else{
                                    dispatch(setPGroupFile({token: token as string, pgroup_id: pgroupFiles.pgroup.pgroup_id, pgroup_vid: pgroupFiles.pgroup.pgroup_vid, pdfIds: pgroupEdit.pdfIds})).then(()=>{
                                        ;//dispatch(adminPGroupListGet({ token: token as string }))
                                    }).then(()=>{
                                        dispatch(adminPGroupListGet({ token: token as string }))
                                        dispatch(adminPGroupFileListGet({ token: token as string, pgroup_id: pgroupFiles.pgroup.pgroup_id, pgroup_vid: pgroupFiles.pgroup.pgroup_vid}))
                                    })
                                }
                            }}
                            className={`flex items-center gap-2 px-4 py-2 mb-2 bg-blue-500 text-sm text-white rounded-full bg-gradient-to-br ${viewMode ? "from-cyan-600 to-blue-600 hover:bg-blue-600" : "from-indigo-600 to-blue-600 hover:bg-blue-600"} transition-colors`}
                        >{viewMode ? "Change Manuals" : "Apply Manuals"}
                        </button>
                    </div>
                    <div className="w-full p-1 overflow-auto">
                        {/* <FileList
                        currentDirFiles={[]}
                        setCurrentDirFiles={()=>{}}
                        currentFiles={pgroupFiles.pdf}
                        setCurrentFiles={()=>{}}
                        onNewFolder={()=>{}}
                        onRename={()=>{}}
                        onDelete={()=>{}}
                        onUpload={()=>{}}
                        selection={true}
                    /> */}
                        <FileExplorer
                            dir={fileStructure || []}
                            setDirFiles={setDirectories}
                            dirFiles={currentDirFiles}
                            setFiles={setCurrentDirFiles}
                            handleGetDirFile={handleGetDirFile}
                            token={token as string}
                            selection={true}
                            viewer={viewMode}
                            pgroupFiles={pgroupFiles.pdf}
                        />
                    </div>
                </div>
            )}
        </div>
    )
};

export default FileGroupTable;