import React, { useEffect, useRef, useState } from 'react'
import { FileText, FolderOpen, FolderPlus, Edit2, Trash2, Upload, FileChartColumn, CheckCircle, XCircle, XCircleIcon } from 'lucide-react'
import UploadModal from './UploadModal'
import { useAppDispatch, useAppSelector } from '../../../lib/hooks'
import { addPGroupFile, removePGroupFile, selectDashboardPGroupEdit } from '../../../lib/features/admin/adminSlice'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DropdownMenuSub,
  DropdownMenuSubTrigger,
  DropdownMenuSubContent,
} from "../../ui/dropdown-menu";

type DirFile = {
  fgroup_id?: string
  fgroup_name?: string
  pdf_id?: string
  pdf_name?: string
  isFolder: boolean
  checkStatus?: "none"|"half"|"all"
  child?: DirFile[]
  files?: DirFile[]
}
type FileItem = {
  pdf_id: number
  pdf_display_nm: string
  pdf_mime_type: string
  fgroup_id: number
  pdf_size: number
  is_used: boolean
  is_deleted: boolean
  is_duplicate: boolean
  is_valid: boolean
  created_at: string
  updated_at: string

}

declare module 'react' {
  interface InputHTMLAttributes<T> extends HTMLAttributes<T> {
    webkitdirectory?: string;
    mozdirectory?: string;
    directory?: string;
  }
}

type FileListProps = {
  currentDirFiles: DirFile[]
  setCurrentDirFiles: (files: DirFile[]) => void
  currentFiles: FileItem[]
  setCurrentFiles: (files: FileItem[]) => void
  onNewFolder?: (name: string) => void
  onRename?: (type: "dir" | "file", id: string, newName: string) => void
  onDelete?: (ids: string[], isDir: boolean) => void
  onUpload?: (files: File[]) => void
  onValidate?: (files: FileItem[]) => void
  selection?: boolean
  viewer?: boolean
  pgroupFiles?: FileItem[]
}

const APP_STAGE = process.env.REACT_APP_STAGE;

export default function FileList({ currentDirFiles, setCurrentDirFiles, currentFiles, setCurrentFiles, onNewFolder, onRename, onDelete, onUpload, onValidate, selection, viewer, pgroupFiles }: FileListProps) {
  const dispatch = useAppDispatch()
  const pgroupEdit = useAppSelector(selectDashboardPGroupEdit)

  const [selectedDirs, setSelectedDirs] = useState<{id:string,state:"all"|"half"|"none"}[]>([])
  const [selectedFiles, setSelectedFiles] = useState<string[]>([])
  const [isRenaming, setIsRenaming] = useState(false)
  const [typeRenaming, setTypeRenaming] = useState<"dir" | "file" | null>(null)
  const [renamingId, setRenamingId] = useState<string | null>(null)
  const [newName, setNewName] = useState('')
  const [isCreatingFolder, setIsCreatingFolder] = useState(false)
  const [newFolderName, setNewFolderName] = useState('')

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false)
  const [filesToUpload, setFilesToUpload] = useState<File[]>([])
  const [editingId, setEditingId] = useState<string | null>(null)
  const [editingName, setEditingName] = useState('')
  const editInputRef = useRef<HTMLInputElement>(null)
  const [selectedList, setSelectedList] = useState<{dir:string[],file:string}[]>([])

  const selectedFileIdsRef = useRef<string[]>([]); // useRef로 선택된 파일 ID 저장

  useEffect(() => {
    setSelectedFiles([])
    setSelectedDirs([])
    if (selection && pgroupFiles && currentFiles) {
    
      // currentFiles와 pgroupFiles의 pdf_id만 추출
      const currentFileIds = currentFiles?.map(file => file.pdf_id?.toString() || '');
      const pgroupFileIds = viewer?pgroupFiles?.map(file => file.pdf_id?.toString() || '') : (selection?pgroupEdit!.pdfIds.map((item: any) => item.toString()) : []);
      // 교집합 구하기
      const dirIds: {id:string,state:"all"|"half"|"none"}[] = currentDirFiles.map(
        (file: any) => ({id:file.fgroup_id || '',state:file.checkStatus || 'none'}))
      setSelectedDirs(dirIds)

      const selFiles = pgroupFileIds.filter((id: any) => currentFileIds.includes(id));
      setSelectedFiles(selFiles);
    
      // // 기존 선택 파일 ID와 비교하여 다를 때만 업데이트
      // if (JSON.stringify(selFiles) !== JSON.stringify(selectedFileIdsRef.current)) {
      //   setSelectedFiles(selFiles);
      //   selectedFileIdsRef.current = selFiles; // useRef를 최신 선택 ID로 업데이트
      // }
    }
    
  }, [currentFiles, currentDirFiles]);

  useEffect(() => {
    // console.log("Updated selectedFiles:", selectedFiles);
  }, [selectedFiles, selectedDirs]);
  
  // 파일 선택 핸들러에서 useRef와 상태를 함께 업데이트
  const handleSelectFile = (id: string) => {
    
    if(selectedFiles.includes(id)){
      selection&&dispatch(removePGroupFile(parseInt(id)))
    }else{
      selection&&dispatch(addPGroupFile(parseInt(id)))
    }
    

    setSelectedFiles(prev => {
      const newSelected = prev.includes(id) ? prev.filter(fileId => fileId !== id) : [...prev, id];
      selectedFileIdsRef.current = newSelected; // 선택 ID를 useRef에도 업데이트
      return newSelected;
    });
    
    
  };

  const handleSelectDir = (id: string) => {
    setSelectedDirs(prev =>
      prev.find(dir => dir.id === id) ? prev.filter(dir => dir.id !== id) : [...prev, {id,state:"all"}]
    )
  }
  // const handleSelectFile = (id: string) => {
  //   setSelectedFiles(prev =>
  //     prev.includes(id) ? prev.filter(fileId => fileId !== id) : [...prev, id]
  //   )
  // }

  useEffect(() => {
    // setSelectedDirs([])
    // if (!viewer) {
      
    //   setSelectedFiles([])
    // }
    setIsRenaming(false)
    setTypeRenaming(null)
    setRenamingId(null)
    setEditingId(null)
    setNewName('')
    setIsCreatingFolder(false)
    setNewFolderName('')
    setFilesToUpload([])
    setIsUploadModalOpen(false)
  }, [currentDirFiles, currentFiles, pgroupFiles])
  const handleRename = () => {
    
    if (selectedDirs.length+selectedFiles.length === 1) {
      setTypeRenaming(selectedDirs.length === 1 ? "dir" : "file");
      
      if (selectedDirs.length === 1) {
        setRenamingId(selectedDirs[0].id)
        setEditingId(selectedDirs[0].id)
        setNewName(currentDirFiles.find(file => file.fgroup_id === selectedDirs[0].id)?.fgroup_name || '')
        setIsRenaming(true)
      }
      if (selectedFiles.length === 1) {
        setRenamingId(selectedFiles[0])
        setEditingId(selectedFiles[0])
        setNewName(currentFiles.find(file => file.pdf_id === parseInt(selectedFiles[0]))?.pdf_display_nm || '')
        setIsRenaming(true)
      }
    }
    
  }

  const handleRenameSubmit = (type: "dir" | "file") => {
    if (renamingId && newName && onRename) {
      onRename(type, renamingId, newName)
      setIsRenaming(false)
      setRenamingId(null)
      setNewName('')
    }
  }

  const handleDelete = (type: "dir" | "file") => {
    if (type === "dir" && selectedDirs.length > 0 && onDelete) {
      onDelete(selectedDirs.map(dir => dir.id), true)
      setSelectedDirs([])
    } else if (type === "file" && selectedFiles.length > 0 && onDelete) {
      onDelete(selectedFiles, false)
      setSelectedFiles([])
    }
  }

  const handleValidate = () => {
    if (selectedFiles.length > 0 && onValidate) {
      onValidate(selectedFiles.map(file => currentFiles.find(f => f.pdf_id === parseInt(file))) as FileItem[])
      setSelectedFiles([])
    }
  }

  // const handleNewFolderSubmit = () => {
  //   if (newFolderName && onNewFolder) {
  //     onNewFolder(newFolderName)
  //     setIsCreatingFolder(false)
  //     setNewFolderName('')
  //   }
  // }
  // const FileAndFolderUpload = () => {
  //   // 파일 또는 폴더 선택 시 처리하는 함수
  //   const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //     const files = event.target.files;

  //     if (files) {
  //       const fileList = Array.from(files);
  //       fileList.forEach((file) => {
  //         if (APP_STAGE === "DEV") {  
  //           console.log(`선택된 파일: ${file.name}`);
  //         }
  //       });
  //     }
  //   };
  // }

    const handleFolderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      // 중복된 폴더명 및 파일명 확인용 배열
      const duplicateFolders: string[] = [];
      const duplicateFiles: string[] = [];

      filesToUpload.forEach((file) => {
        // 폴더명 추출 (webkitRelativePath에서 파일명 제거)
        const fullPath = file.webkitRelativePath;
        const directoryPath = fullPath.substring(0, fullPath.lastIndexOf('/'));

        // 파일명 추출
        const fileName = file.name;

        // 폴더명 중복 검사
        if (directoryPath && currentDirFiles.find(file => file.fgroup_id === directoryPath)) {
          duplicateFolders.push(directoryPath);
        }

        // 파일명 중복 검사
        if (currentFiles.find(file => file.pdf_display_nm === fileName)) {
          duplicateFiles.push(fileName);
        }
      });

      // 중복된 폴더 및 파일명을 콘솔에 출력
      if (duplicateFolders.length > 0) {
        if (APP_STAGE === "DEV") {  
          console.log('중복된 폴더명:', duplicateFolders);
        }
      }

      if (duplicateFiles.length > 0) {
        if (APP_STAGE === "DEV") {  
          console.log('중복된 파일명:', duplicateFiles);
        }
      }

      if (duplicateFolders.length === 0 && duplicateFiles.length === 0) {
        if (APP_STAGE === "DEV") {  
          console.log('중복된 폴더나 파일이 없습니다.');
        }
        // 파일 업로드 로직 실행
      } else {
        if (APP_STAGE === "DEV") {  
          console.log('중복된 항목이 있어 업로드를 취소합니다.');
        }
      }


      if (event.target.files) {
        setFilesToUpload(Array.from(event.target.files))
        setIsUploadModalOpen(true)
      }
    };

    const handleFileChange   = (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;
      console.log("this is files")

      if (files) {
        const fileList = Array.from(files);
        const pdfFiles: File[] = [];
        const folderFiles: { file: File; folderPath: string }[] = [];

        fileList.forEach((file) => {
          // 폴더 내 파일인지 확인 (webkitRelativePath가 있으면 폴더 내 파일)
          if (file.webkitRelativePath) {
            // 파일의 폴더 경로를 추출
            const folderPath = file.webkitRelativePath.substring(0, file.webkitRelativePath.lastIndexOf('/'));

            // 중첩된 폴더 포함한 파일 처리
            folderFiles.push({ file, folderPath });
          }

          // PDF 파일 필터링
          if (file.type === 'application/pdf') {
            pdfFiles.push(file);
          }
        });

        // 폴더 내 파일 처리 로직
        if (folderFiles.length > 0) {
          if (APP_STAGE === "DEV") {  
            console.log('폴더 내 파일들:', folderFiles);
          }
        }

        // PDF 파일 처리 로직
        if (pdfFiles.length > 0) {
          if (APP_STAGE === "DEV") {  
            console.log('PDF 파일들:', pdfFiles);
          }
        }

        if (event.target.files) {
          setFilesToUpload(fileList)
          setIsUploadModalOpen(true)
        }
      }
    };

    const handleUploadConfirm = () => {
      if (onUpload && filesToUpload.length > 0) {
        onUpload(filesToUpload)
        setIsUploadModalOpen(false)
        setFilesToUpload([])
      }
    }


    const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        const dirIds: {id:string,state:"all"|"half"|"none"}[] = currentDirFiles.map(
          (file: any) => ({id:file.fgroup_id || '',state:file.checkStatus || 'none'}))
        const fileIds = currentFiles.map(file => file.pdf_id?.toString() || '')
        setSelectedDirs(dirIds)
        setSelectedFiles(fileIds)
      } else {
        setSelectedDirs([])
        setSelectedFiles([])
      }
    }

    const handleEditSubmit = (type: "dir" | "file") => {
      if (editingId && !renamingId) {
        if (APP_STAGE === "DEV") {  
          console.log("this is editingId")
        }
        if (editingName.trim() === '') {
          // If the name is empty, remove the new folder or cancel renaming
          // if (type === "dir") {
          //   console.log("editingId", editingId)
          //   if (editingId && editingId.toString().startsWith('new-folder-')) {
          //     setCurrentDirFiles(currentDirFiles.filter(file => file.fgroup_id !== editingId))
          //   }
          // }
        } else {
          // Update the file name
          // setCurrentFiles(currentFiles.map(file => 
          //   file.pdf_id === parseInt(editingId) ? { ...file, original_nm: editingName.trim() } : file
          // ))
          // Call onNewFolder or onRename based on whether it's a new folder or not
          if (editingId && editingId.toString().startsWith('new-folder-')) {
            onNewFolder?.(editingName.trim())
          } else {
            if (type === "dir") {
              if (APP_STAGE === "DEV") {  
                console.log("this is dir")
              }
              onRename?.("dir", editingId, editingName.trim())
            }
            if (type === "file") {
              if (APP_STAGE === "DEV") {  
                console.log("this is file")
              }
              onRename?.("file", editingId, editingName.trim())
            }
          }
        }
        setIsCreatingFolder(false)

      }else if (renamingId) {
          if (editingName.trim() === '') {
            // If the name is empty, remove the new folder or cancel renaming
            
          } else {
            // Update the file name
            if (typeRenaming === "dir") {
              if (APP_STAGE === "DEV") {  
                console.log("this is renamingId dir")
              }
              // setCurrentDirFiles(currentDirFiles.filter(file => file.fgroup_id !== editingId))
              onRename?.("dir", renamingId, editingName.trim())
            }
            if (typeRenaming === "file") {
              if (APP_STAGE === "DEV") {  
                console.log("this is renamingId file")
              }
              // setCurrentFiles(currentFiles.filter(file => file.pdf_id !== parseInt(editingId)))
              onRename?.("file", renamingId, editingName.trim())
            }
            // Call onNewFolder or onRename based on whether it's a new folder or not

          }
        
        setEditingId(null)
        setEditingName('')
        setTypeRenaming(null)
        setRenamingId(null)
    }
  }

  const handleNewFolder = () => {
    setIsCreatingFolder(true)
    setEditingId('new-folder-1')
  }

  const handleStartEditing = (id: string, initialName: string) => {
    setEditingId(id)
    setEditingName(initialName)
  }

  const handleEditKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    console.log("this is handleEditKeyDown")
    if (e.key === 'Enter') {
      if (editingId) {
        if(renamingId && typeRenaming === "dir") {
          handleEditSubmit("dir")
        } else if (renamingId && typeRenaming === "file") {
          handleEditSubmit("file")
        } else {
          handleEditSubmit("dir")
        }
      }
    } else if (e.key === 'Escape') {
      if (editingId?.startsWith('new-folder-')) {
        setCurrentDirFiles(currentDirFiles.filter(file => file.fgroup_id !== editingId))
      }
      setEditingId(null)
      setEditingName('')
    }
  }

    useEffect(() => {
      if (editingId && editInputRef.current) {
        editInputRef.current.focus()
      }
    }, [editingId])

    const buttonBaseStyle = "flex items-center gap-1 px-3 py-2 mb-0 bg-blue-500 text-sm text-white rounded-md bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors";
    const disabledStyle = "opacity-50 cursor-not-allowed";
    
    if(APP_STAGE === "DEV"){
      console.log({currentFiles, currentDirFiles, selectedFiles, selectedDirs})
    }
    return (
      <div className="bg-white rounded-lg shadow-md p-4 text-sm">
        {!selection && <div className="flex flex-wrap gap-2 mb-4">
          <button
            onClick={handleNewFolder}
            className={`${buttonBaseStyle} text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-500`}
            aria-label="새 폴더 만들기"
            disabled={!onNewFolder}
          >
            <FolderPlus size={18} className="mr-2" />
            New Folder
          </button>

          <button
            onClick={() => handleRename()}
            disabled={(selectedDirs.length === 0 && selectedFiles.length === 0) || !onRename}
            className={`${buttonBaseStyle} text-gray-700 bg-gray-100 hover:bg-gray-200 focus:ring-gray-500 ${(selectedDirs.length === 0 &&selectedFiles.length === 0) || !onRename ? disabledStyle : ''}`}
            aria-label="선택한 항목 이름 변경"
          >
            <Edit2 size={18} className="mr-2" />
            Rename
          </button>

          <DropdownMenu>
            <DropdownMenuTrigger>
              <button
                disabled={(selectedDirs.length === 0 && selectedFiles.length === 0) || !onDelete}
                className={`${buttonBaseStyle} text-white bg-red-600 hover:bg-red-700 focus:ring-red-500 ${(selectedDirs.length === 0 && selectedFiles.length === 0) || !onDelete ? disabledStyle : ''}`}
              >
                <Trash2 size={18} className="mr-2" /> Delete
              </button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem >
                <button
                  onClick={() =>handleDelete("dir")}
                  disabled={selectedDirs.length === 0 || !onDelete}
                  className={`flex items-center my-auto gap-2 px-2 text-sm text-gray-700 rounded-full transition-colors ${selectedDirs.length === 0 || !onDelete ? disabledStyle : ''}`}
                  aria-label="선택한 폴더 삭제"
                >
                  <Trash2 size={18} className="mr-2" />
                  Delete Folder ({selectedDirs.length})
                </button>
              </DropdownMenuItem>
              <DropdownMenuItem >
              <button
                onClick={() =>handleDelete("file")}
                disabled={selectedFiles.length === 0 || !onDelete}
                className={`flex items-center my-auto gap-2 px-2 text-sm text-gray-700 rounded-full transition-colors ${selectedFiles.length === 0 || !onDelete ? disabledStyle : ''}`}
                aria-label="선택한 파일 삭제"
              >
                <Trash2 size={18} className="mr-2" />
                Delete File ({selectedFiles.length})
              </button>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
          

          


          <DropdownMenu>
            <DropdownMenuTrigger>
              <button
                className={`${buttonBaseStyle} text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-500`}
              >
                <Upload className="h-4 w-4" /> Upload
              </button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem >
                <label
                    htmlFor="file-upload"
                    onClick={(e) => {e.stopPropagation();}}
                    className={`flex items-center my-auto gap-2 px-2 text-sm text-gray-700 rounded-full transition-colors`}
                  >
                    Upload File
                  </label>
              </DropdownMenuItem>
              <DropdownMenuItem >
              <label
                  htmlFor="folder-upload"
                  onClick={(e) => e.stopPropagation()}
                  className={`flex items-center  my-auto  gap-2 px-2 text-sm text-gray-700 rounded-full transition-colors`}
                >
                  Upload Folder
                </label>
                
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>

          <input
            id="file-upload"
            type="file"
            multiple
            className="hidden"
            onChange={handleFileChange}
          />
          <input
            id="folder-upload"
            type="file"
            webkitdirectory="true"
            multiple
            className="hidden"
            onChange={handleFolderChange}
          />
          
          <button
            onClick={() => handleValidate()}
            disabled={selectedFiles.length === 0 || !onValidate}
            className={`${buttonBaseStyle} text-white bg-red-600 hover:bg-red-700 focus:ring-red-500 ${selectedFiles.length === 0 || !onValidate ? disabledStyle : ''}`}
            aria-label="선택한 파일 검증"
          >
            <CheckCircle size={18} className="mr-2" />
            Validate File ({selectedFiles.length})
          </button>
        </div>
        }
        <div className={`${selection ? 'min-h-[900px]' : 'min-h-[800px]'} overflow-y-auto`}>
          <div className="sticky top-0 bg-gray-100 px-3 py-1 mb-2 border-b flex items-center">
            <input
              type="checkbox"
              id="select-all"
              checked={
                selectedDirs.length + selectedFiles.length ===
                currentDirFiles.length + currentFiles.length &&
                (currentDirFiles.length + currentFiles.length > 0)
              }
              onChange={handleSelectAll}
              className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500 mr-3"
              disabled={viewer}
            />
            <label htmlFor="select-all" className="font-bold self-center m-0 pl-4">File Name</label>
          </div>
          {isCreatingFolder &&
            <div
                  key={'new'}
                  className={`flex items-center justify-between px-3 py-0 mb-1 rounded-md transition-colors ${1 ? 'bg-blue-50' : 'hover:bg-gray-50'
                    }`}
                >
                  <div className="flex items-center space-x-3 flex-grow">
                    <input
                      type="checkbox"
                      // id={`file-${file.fgroup_id}`}
                      // checked={isSelected}
                      checked={true}
                      // onChange={() => handleSelectDir(file.fgroup_id)}
                      className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                    />
                    <FolderOpen size={20} className="text-yellow-500" />
                    <input
                          ref={editInputRef}
                          type="text"
                          value={editingName}
                          onChange={(e) => setEditingName(e.target.value)}
                          onBlur={() => handleEditSubmit("dir")}
                          onKeyDown={handleEditKeyDown}
                          className="flex-grow px-2 py-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                          autoFocus
                        />
                    
                    
                  </div>
                </div>
          }
          {(currentDirFiles.length === 0 && currentFiles.length === 0) ? (
            <div className="h-full flex items-center justify-center">
              <div className="text-center text-gray-500">
                <FolderOpen size={48} className="mx-auto mb-2" />
                <p>No files in this directory.</p>
              </div>
            </div>
          ) : (
            [...currentDirFiles]
              .sort((a, b) => (a.fgroup_name || a.pdf_name || '').localeCompare(b.fgroup_name || b.pdf_name || ''))
              .map((file) => {
              const isSelected = selectedDirs.find(dir => dir.id === file.fgroup_id)?.state === "all"
             
              return (
                <div
                  key={file.fgroup_id}
                  className={`flex items-center justify-between px-3 py-0 mb-1 rounded-md transition-colors ${isSelected ? 'bg-blue-50' : 'hover:bg-gray-50'
                    }`}
                >
                  <div className="flex items-center space-x-3 flex-grow">
                    <input
                      type="checkbox"
                      id={`file-${file.fgroup_id}`}
                      checked={isSelected}
                      onChange={() => handleSelectDir(file.fgroup_id || '')}
                      className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                      disabled={viewer}
                    />
                    {file.isFolder ? (
                      <FolderOpen size={20} className="text-yellow-500" />
                    ) : (
                      <FileText size={20} className="text-blue-500" />
                    )}
                    {typeRenaming === "dir" && renamingId === file.fgroup_id ? (
                        <input
                          ref={editInputRef}
                          type="text"
                          value={editingName}
                          onChange={(e) => setEditingName(e.target.value)}
                          onBlur={() => handleEditSubmit("dir")}
                          onKeyDown={handleEditKeyDown}
                          className="flex-grow px-2 py-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                          autoFocus
                        />
                      ) : (
                      <label htmlFor={`file-${file.fgroup_id}`} className="flex-grow cursor-pointer my-0 truncate hover:bg-blue-100">
                        {file.fgroup_name}
                      </label>
                    )}
                  </div>
                </div>
              )
            })
          )}
          {currentFiles && currentFiles.length > 0 &&
            [...currentFiles]
            .sort((a, b) => a.pdf_display_nm.localeCompare(b.pdf_display_nm))
            .map((file) => {
              const isSelected = selectedFiles.includes(file.pdf_id.toString())
              return (
                <div
                  key={file.pdf_id.toString()}
                  className={`flex items-center justify-between px-3 py-0 mb-1 rounded-md transition-colors ${isSelected ? 'bg-blue-100' : 'hover:bg-gray-100'
                    }`}
                >
                  <div className="flex items-center space-x-3 flex-grow">
                    <input
                      type="checkbox"
                      id={`file-${file.pdf_id.toString()}`}
                      checked={isSelected}
                      onChange={() => handleSelectFile(file.pdf_id.toString())}
                      className={`w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500 
                        ${viewer && isSelected ? 'bg-blue-500 text-white border-blue-500' : ''}`}
                      disabled={viewer}
                    />
                    {file.pdf_mime_type === "pdf" ? (
                      <div className='relative'>
                        <FileChartColumn size={20} className="text-red-500" />
                        {file.is_valid? <CheckCircle size={12} className="rounded-full bg-white text-green-700 absolute bottom-0 right-0" />
                        : <XCircleIcon size={12} className="rounded-full bg-white text-red-700 absolute bottom-0 right-0" />}
                      </div>
                    ) : (
                      <FileChartColumn size={20} className="text-blue-500" />
                    )}
                    {isRenaming && renamingId === file.pdf_id.toString() ? (
                      <input
                        type="text"
                        value={newName}
                        onChange={(e) => setNewName(e.target.value)}
                        onBlur={() => handleRenameSubmit("file")}
                        onKeyPress={(e) => e.key === 'Enter' && handleRenameSubmit("file")}
                        className="flex-grow px-2 py-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                        autoFocus
                      />
                    ) : ( 
                      <label htmlFor={`file-${file.pdf_id.toString()}`} className="flex-grow cursor-pointer my-0 truncate hover:bg-blue-100 select-none">
                        {file.pdf_display_nm}
                      </label>
                    )}
                  </div>
                </div>
              )
            })
          }
        </div>
        <UploadModal
          isOpen={isUploadModalOpen}
          onClose={() => setIsUploadModalOpen(false)}
          files={filesToUpload}
          onConfirm={handleUploadConfirm}

        />
      </div>
    )
  }

