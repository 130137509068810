import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { authLogin } from "../../lib/features/auth/authSlice";
import { useAppDispatch } from "../../lib/hooks";
import { Input } from "../ui/input";
import axios from "axios";

const REACT_APP_BE_HOST = process.env.REACT_APP_BE_HOST;
const APP_STAGE = process.env.REACT_APP_STAGE;

function FindPW(props) {
  const [imoNo, setImoNo] = useState("");
  const [password, setPassword] = useState("");
  const [imoNoError, setImoNoError] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onButtonClickViaEmail = () => {
    setImoNoError("");

    if ("" === imoNo) {
      setImoNoError("Please enter your IMO No.");
      return;
    }

    // if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(imoNo)) {
    //   setImoNoError("Please enter a valid IMO No.");
    //   return;
    // }

    // dispatch(authLogin({ useremail: email, password: password }));
    
    axios.post(`${REACT_APP_BE_HOST}/api/auth/reset/request-password-reset/?imo_no=${imoNo}`).then((response)=>{
      if (APP_STAGE === "DEV") {  
      console.log(response);
    }
      setIsSuccess(true);
    }).catch((error)=>{
      if (APP_STAGE === "DEV") {  
        console.log(error);
        
      }
      setImoNoError("Something wrong with your IMO No.");
    });
    
  };
  const onButtonClickViaAdmin = () => {
    setImoNoError("");

    if ("" === imoNo) {
      setImoNoError("Please enter your IMO No.");
      return;
    }

    // if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(imoNo)) {
    //   setImoNoError("Please enter a valid IMO No.");
    //   return;
    // }

    // dispatch(authLogin({ useremail: email, password: password }));
    try {
      axios.post(`${REACT_APP_BE_HOST}/api/auth/reset/request-password-reset/?imo_no=${imoNo}`).then((response)=>{
        if (APP_STAGE === "DEV") {  
        console.log(response);
      }
        setIsSuccess(true);
      });
    } catch (error) {
      if (APP_STAGE === "DEV") {  
        console.log(error);
      }
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen w-full max-w-sm">
      <div className="text-4xl font-bold mb-4">Find your Password</div>
      <div className="text-md ml-2 mb-4">Enter the IMO No., and the request will be sent to <br/>the registered email.</div>
      <div className="w-full max-w-md">
        <div className="inputContainer mb-2 w-full max-w-md">

          <Input
            label="IMO No."
            type="text"
            value={imoNo}
            placeholder="Enter your IMO No. here"
            onChange={(ev) => setImoNo(ev.target.value)}
            className="inputBox w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
            isNoSpinner={true}
          />
          <label className="text-red-600 text-sm">{imoNoError}</label>
        </div>

        {isSuccess && (
          <div className="text-green-600 text-sm">
            The link has been sent to your email.
          </div>
        )}

        <div className="mt-1">
          <button
            className="w-full px-4 py-3 mb-2 bg-blue-500 text-sm text-white rounded-lg bg-gradient-to-br from-cyan-500 to-blue-600 hover:from-cyan-700 hover:to-blue-700 hover:shadow-lg transition-colors !text-center"
            onClick={onButtonClickViaEmail}
          >
            Receive link via email
          </button>
          <button
            className="w-full px-4 py-3 mb-2 text-sm text-white rounded-lg bg-gradient-to-br from-gray-600 to-gray-600 hover:bg-blue-600 transition-colors !text-center"
            onClick={onButtonClickViaAdmin}
            disabled={true}
          >
            Request from the administrator
          </button>
        </div>
      </div>

      <div className="linkContainer w-full max-w-md flex justify-between mt-6 text-blue-500 underline cursor-pointer text-sm">
        <p className="mb-2 cursor-pointer hover:underline" onClick={() => props.mvPage("signin")}>
          Already have an account?
        </p>
        <p className="cursor-pointer hover:underline" onClick={() => props.mvPage("user-service")}>
          Sign up request
        </p>
      </div>
    </div>
  );
}

export default FindPW;
