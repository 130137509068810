import React, { useEffect, useState } from 'react'
import TreeView from './FileTree'
import FileList from './FileList'
import axios from 'axios';

import { useAppDispatch } from "../../../lib/hooks"
import {
  adminFGroupAdd,
  adminFGroupListGet,
  adminFGroupUpdate,
  adminFGroupDelete,
  adminFileUpdate,
  adminFileDelete,
  adminFileListGetOnfid,
} from "../../../lib/features/admin/adminSlice"

type DirFile = {
  fgroup_id?: string
  fgroup_name?: string
  pdf_id?: string
  pdf_name?: string
  isFolder: boolean
  child?: DirFile[]
  files?: DirFile[]
}

type FileItem = {
  pdf_id: number
  pdf_display_nm: string
  pdf_mime_type: string
  fgroup_id: number
  pdf_size: number
  is_deleted: boolean
  is_duplicate: boolean
  created_at: string
  updated_at: string

}
const APP_STAGE = process.env.REACT_APP_STAGE;

export default function FileExplorer({
  dir,
  setDirFiles,
  dirFiles,
  setFiles,
  handleGetDirFile,
  token,
  selection,
  viewer,
  pgroupFiles
}: {
  dir: DirFile[]
  setDirFiles: React.Dispatch<React.SetStateAction<DirFile[]>>
  dirFiles: FileItem[]
  setFiles: React.Dispatch<React.SetStateAction<FileItem[]>>
  handleGetDirFile: (id: number) => void
  token: string
  selection: boolean
  pgroupFiles?: FileItem[]
  viewer?: boolean
}) {
  const dispatch = useAppDispatch()
  const [selectedFolder, setSelectedFolder] = useState<DirFile>(dir?.[0] || {} as DirFile)
  const [selectedFile, setSelectedFile] = useState<FileItem | null>(null)

  const findNodeById = (tree: DirFile[], fgroup_id: string): DirFile | null => {
    for (const node of tree) {
      if (node.fgroup_id === fgroup_id) {
        return node
      }
      if (node.child) {
        const foundNode = findNodeById(node.child, fgroup_id)
        if (foundNode) {
          return foundNode
        }
      }
    }
    return null
  }

  useEffect(() => {
    dispatch(adminFileListGetOnfid({
      token,
      fgroupId: parseInt(selectedFolder?.fgroup_id || '')
    }))
    const updatedNode = findNodeById(dir || [], selectedFolder?.fgroup_id || '')
    if (updatedNode) {
      setSelectedFolder(updatedNode)
      handleGetDirFile(parseInt(updatedNode.fgroup_id || ''))
    }

  }, [])



  const handleFolderSelect = (node: DirFile) => {
    setSelectedFolder(node)
    handleGetDirFile(parseInt(node.fgroup_id || ''))  // 폴더 선택 시 해당 폴더 안의 파일 리스트를 가져옴
  }

  const handleNewFolder = async (name: string) => {
    // TODO: 폴더명 중복 체크 필요 
    try {
      const result = await dispatch(adminFGroupAdd({
        token,
        fgroup_name: name,
        fgroup_pid: parseInt(selectedFolder.fgroup_id || '')
      })).unwrap()

      if (result.success) {
        // 새로운 폴더를 현재 디렉토리에 추가
        const newFolder: DirFile = {
          fgroup_id: result.fgroup_id,
          fgroup_name: name,
          isFolder: true,
          child: []
        }

        setDirFiles(prev => updateFiles(prev, selectedFolder.fgroup_id || '', folder => ({
          ...folder,
          child: [...(folder.child || []), newFolder]
        })))

      }
      dispatch(adminFGroupListGet({ token }))
      dispatch(adminFileListGetOnfid({
        token,
        fgroupId: parseInt(selectedFolder.fgroup_id || '')
      }))

    } catch (error) {
      console.error('Failed to create folder:', error)
    }
  }

  const handleRename = async (type: "dir" | "file", id: string, newName: string) => {
    // TODO: 폴더명, 파일명 중복 체크 필요 
    if (APP_STAGE === "DEV") {  
      console.log("this is handleRename")
    }
    try {
      if (type === "dir") {
        const result = await dispatch(adminFGroupUpdate({
          token,
          fgroupId: parseInt(id),
          fgroupName: newName
        })).unwrap()

        
        await dispatch(adminFGroupListGet({ token }))
        await dispatch(adminFileListGetOnfid({
          token,
          fgroupId: parseInt(selectedFolder.fgroup_id || '')
        }))
        if (APP_STAGE === "DEV") {  
          console.log("get new file list", dir)
        }
        
        if (dir) {
          const updatedNode = findNodeById(dir, selectedFolder.fgroup_id || '');
          if (updatedNode) {
            setSelectedFolder(updatedNode);
            handleGetDirFile(parseInt(updatedNode.fgroup_id || ''));
            }
        }
      
      } else {
        const result = await dispatch(adminFileUpdate({
          token,
          fileId: parseInt(id),
          fileName: newName
        })).unwrap()

        if (result.success) {
          dispatch(adminFileListGetOnfid({
            token,
            fgroupId: parseInt(selectedFolder.fgroup_id || '')
          })).then(() => {
            setSelectedFolder(selectedFolder)
            handleGetDirFile(parseInt(selectedFolder.fgroup_id || ''))  
          })
        }

      }
      
      
    } catch (error) {
      console.error('Failed to rename:', error)
    }
  }
  const handleUpload = async (files: File[]) => {
    // TODO: 파일 업로드 중복 체크 필요 

    // Helper function to find or create folder
    const findOrCreateFolder = async (folderPath: string, parentFolderId: string) => {
      const folderNames = folderPath.split('/');
      let currentParentId = parentFolderId;

      for (const name of folderNames) {
        let existingFolder = selectedFolder.child?.find(folder => folder.fgroup_name === name);

        if (!existingFolder) {
          // Folder doesn't exist, create it
          const result = await dispatch(adminFGroupAdd({
            token,
            fgroup_name: name,
            fgroup_pid: parseInt(currentParentId)
          })).unwrap();

          existingFolder = {
            fgroup_id: result.fgroup_id,
            fgroup_name: name,
            isFolder: true,
            child: []
          };

          // Update `selectedFolder.child` with the new folder structure
          if (!selectedFolder.child) selectedFolder.child = [];
          selectedFolder.child.push(existingFolder);
        }

        currentParentId = existingFolder.fgroup_id || '';
      }

      return currentParentId;
    };

    // Process each file based on its `webkitRelativePath`
    for (const file of files) {
      if(file.type !== "application/pdf"){
        continue;
      }
      let targetFolderId = selectedFolder.fgroup_id;

      if (file.webkitRelativePath) {
        // The file is part of a subdirectory
        const folderPath = file.webkitRelativePath.substring(0, file.webkitRelativePath.lastIndexOf('/'));
        targetFolderId = await findOrCreateFolder(folderPath, selectedFolder.fgroup_id || '');
      }

      // Upload the file to the determined `targetFolderId`
      const formData = new FormData();
      // formData.append("fgroupId", targetFolderId);
      formData.append("files", file);

      try {
        const response = await axios.post('/api/file/upload?fgroupId=' + targetFolderId, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': token
          },
          timeout: 60000,
        });

        if (response.status === 200) {
          if (APP_STAGE === "DEV") {  
            console.log(`Upload successful for file: ${file.name}`);
          }
        } else {
          if (APP_STAGE === "DEV") {  
            console.error(`Upload failed for file: ${file.name}`, response.statusText);
          }
        }
      } catch (error) {
        if (APP_STAGE === "DEV") {  
          console.error(`Error uploading file: ${file.name}`, error); 
        }
      }
    }

    dispatch(adminFileListGetOnfid({
      token,
      fgroupId: parseInt(selectedFolder.fgroup_id || '')
    }))
    const updatedNode = findNodeById(dir || [], selectedFolder.fgroup_id || '')
    if (updatedNode) {
      setSelectedFolder(updatedNode)
      handleGetDirFile(parseInt(updatedNode.fgroup_id || ''))
    }
  };


  const handleDelete = async (ids: string[], isFolder: boolean) => {
    try {
      for (const id of ids) {
        if (isFolder) {
          await dispatch(adminFGroupDelete({
            token,
            fgroupId: parseInt(id)
          })).unwrap()
        } else {
          await dispatch(adminFileDelete({
            token,
            fileId: parseInt(id)
          })).unwrap()
        }
      }

      // 폴더/파일 삭제 후 목록 새로고침
      if (isFolder) {
        setDirFiles(prev => deleteFiles(prev, ids))
      } else {
        handleGetDirFile(parseInt(selectedFolder.fgroup_id || ''))
      }
    } catch (error) {
      console.error('Failed to delete:', error)
    }
  }

  // 파일/폴더 트리 업데이트 헬퍼 함수
  const updateFiles = (files: DirFile[], id: string, updateFn: (file: DirFile) => DirFile): DirFile[] => {
    return files.map(file => {
      if (file.fgroup_id === id) {
        return updateFn(file)
      }
      if (file.child) {
        return { ...file, child: updateFiles(file.child, id, updateFn) }
      }
      return file
    })
  }

  const deleteFiles = (files: DirFile[], ids: string[]): DirFile[] => {
    return files.filter(file => !ids.includes(file.fgroup_id || '')).map(file => {
      if (file.child) {
        return { ...file, child: deleteFiles(file.child, ids) }
      }
      return file
    })
  }

  return (
    <div className="flex h-screen bg-gray-100">
      <div className="w-1/3 p-1 overflow-auto">
        <TreeView
          data={dir || []}
          onSelect={handleFolderSelect}
          selectedId={selectedFolder?.fgroup_id || ''}
          selection={selection}
          viewer={viewer}
          pgroupFiles={pgroupFiles}
        />
      </div>
      <div className="flex flex-col w-2/3 gap-2">
        <div className="w-full p-1 overflow-auto">
          <FileList
            currentDirFiles={selectedFolder?.child || []}
            setCurrentDirFiles={setDirFiles}
            currentFiles={dirFiles}
            setCurrentFiles={setFiles}
            onNewFolder={handleNewFolder}
            onRename={handleRename}
            onDelete={(ids: string[], isDir: boolean) => handleDelete(ids, isDir)}
            onUpload={handleUpload}
            selection={selection}
            viewer={viewer}
            pgroupFiles={pgroupFiles}
          // selectedFolderId={selectedFolder.fgroup_id}
          />
        </div>
        {/* <div className="w-full h-[200px] p-1 overflow-auto bg-red-500">
          
        </div>
        <div className="w-full h-[200px] p-1 overflow-auto bg-blue-500">
          
        </div> */}
      </div>
    </div>
  )
}