export default {
    solution:{
        tit_group:{
            tit:"S&SYS AI CUSTOMER SUPPORT",
            desc:"We Provides Real-Time AI Services for Our Valued Customers"
        }
    },
    info_list:{
        eco:{
            tit:"BWMS\nSolution",
            desc:"실질적인 연료 절감방안,\n환경친화적 시스템을 제시하여\n최적의 상태로 운항이 가능토록 지원합니다."
        },
        automation:{
            tit:"Automation\nSolution",
            desc:"안정되고 입증된 시스템 성능과\n효율적인 조작 손쉬운 유지보수로\n고객의 욕구를 충족시킵니다.",
            update: "업데이트 예정"
        },
        power:{
            tit:"Switchboard\nSolution",
            desc:"첨단기술과 경험으로 환경 및 안전조건을\n충족하도록 설계하고 선박전기 계통용\n각종 솔루션을 공급하고 있습니다.",
            update: "업데이트 예정"
        },
    }

}