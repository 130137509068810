import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { ko, en } from './locales';

i18n
  .use(initReactI18next)
  .init({
    resources: { 
        ko:{translation:ko},
        en:{translation:en}
    },
    fallbackLng: 'en',
    interpolation: { escapeValue: false },
    detection: { 
      order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
    }
  });

export default i18n;