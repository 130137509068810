import React, { useEffect, useState } from "react";
import {
  setDocViewer,
  setToggleDocViewer,
  meesageLike,
  messageFeedbackText,
  selectDocViewer,
  chatMessageGet,
} from "../../../lib/features/chatMain/chatMainSlice";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../lib/hooks";
// import "../../../main/filebox.css";
import { debounce } from "lodash";
import { selectToken } from "../../../lib/features/auth/authSlice";
import Markdown from 'react-markdown'

//   wrapperElement?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
//     'data-color-mode'?: 'light' | 'dark';
//   };
//   onScroll?: (e: React.UIEvent<HTMLDivElement>) => void;
//   onMouseOver?: (e: React.MouseEvent<HTMLDivElement>) => void;
//   rehypeRewrite?: RehypeRewriteOptions['rewrite'];
// } & ReactMarkdownProps;

function MessageBasic(props) {
  const { type, isBot, text, sendTime, _id, feedback, reference} = props.model;
  const ids = props.ids;
  const roomId = props.roomId;
  const navigate = useNavigate();
  const [likeState, setLikeState] = useState({ like: false, dislike: false });
  const [review, setReview] = useState("");
  const [isReview, setIsReview] = useState(false);
  const [reviewSubmitted, setReviewSubmitted] = useState(false);
  const token = useAppSelector(selectToken);
  const docViewer = useAppSelector(selectDocViewer);
  const dispatch = useAppDispatch();

  const result = {};

  useEffect(()=>{},[text])

  reference&&reference.forEach((ref) => { 
    const fileName = ref.file_nm;

      // pdf_page 문자열에서 대괄호 제거 및 정수로 변환
      const pages = ref.pdf_page
        .replace(/[\[\]]/g, "")
        .split(",")
        .map(Number);

      // 최소값과 최대값 계산
      const minPage = Math.min(...pages);
      const maxPage = Math.max(...pages);

      // 결과 객체에 추가
      if (!result[fileName]) {
        result[fileName] = {
          file_nm: fileName,
          min: minPage,
          max: maxPage,
          pages: pages,
          pdf_url: ref.pdf_url,
        };
      } else {
        result[fileName].min = Math.min(result[fileName].min, minPage);
        result[fileName].max = Math.max(result[fileName].max, maxPage);
      } 
    });

  const resultArray = Object.values(result);

  // console.log(resultArray);

  const [feedbackState, setFeedbackState] = useState(
    reference&&reference.map((item) => {
      // console.log(item.reference_rating)
      return { like: item.reference_rating==1?true:false, dislike: item.reference_rating==-1?true:false }
    }) || []
  );

  const handleClickDocView = debounce((url, page, filename) => {
    console.log({url, page, filename})
    dispatch(
      setDocViewer({
        docUrl: url,
        docPage: page+1,
        docTitle: filename,
        isOn: docViewer.isOn,
      })
    );

    if (!docViewer.isOn) {
      document.body.classList.toggle("toggle-sidebar-doc");
      dispatch(setToggleDocViewer());
    }
  }, 500);

  const handleLike = (key, sentence, evi_idx) => {
    dispatch(
      meesageLike({
        like: 1,
        token,
        roomId,
        ref_doc_idx:evi_idx,
        message_id: _id,
      })
    ).then(()=>dispatch(chatMessageGet({ room_id: roomId, token: token, offset: 0 })))
    setFeedbackState(feedbackState.map((item, idx)=>{
      return idx==evi_idx?(item.dislike?{like:true, dislike:false}:{...item, like:!item.like}):item 
    }))
  };

  const handleEmailSend = () => {
    navigate(`/service/${roomId}`);
    // roomId
  };

  const handleDislike = (key, sentence, evi_idx) => {
    dispatch(
      meesageLike({
        like: 0,
        token,
        roomId,
        ref_doc_idx:evi_idx,
        message_id: _id,
      })
    ).then(()=>dispatch(chatMessageGet({ room_id: roomId, token: token, offset: 0 })))
    setFeedbackState(feedbackState.map((item, idx)=>{
      return idx==evi_idx?(item.like?{dislike:true, like:false}:{...item, dislike:!item.dislike}):item 
    }))
  };
  const handleReviewChange = (event) => {
    setReview(event.target.value);
  };

  const handleTextReview = () => {
    setIsReview(!isReview);
  };

  const handleReviewSubmit = () => {
    if (review.trim() !== "") {
      dispatch(
        messageFeedbackText({
          message_id: _id,
          token: token,
          feedback_text: review,
        })
      );
      setReviewSubmitted(true);
      setIsReview(!isReview);
    }
  };

  // let item_ref = <></>;
  // if (reference) {
  //   console.log(reference.length?reference[0]:"")
  //   item_ref = reference.map((evi, idx) => (
  //     <Popover
  //       content={evi.reference_formatted_text.replace(/(\n|\r\n)/g, "<br>")}
  //       // content={evi.reference_formatted_text}
  //       handleDocView={() => handleClickDocView(evi.pdf_url, evi.pdf_page)}
  //     >
  //       {"[ref-" + idx + "]"}
  //     </Popover>
  //   ));

  //   // console.log(item_ref)
  // }
  // console.log(text_new)

  //.replace(/\[ref-(\d+)\]/g, '<a href="#ref-$1">[ref-$1]</a>').replace(/(\n|\r\n)/g, '<br>')
  //   console.log("split")
  //   console.log(text
  //     .replace(/(\n|\r\n)/g, "<br>")
  //     .split(/\[ref-(\d+)\]/g))
  // console.log(text)
  var list_ref = JSON.parse(text)
  //text.replace(/(\n|\r\n)/g, "<br>").split(/\[key-(\d+)\]/g);
  var regex = /^[0-9]+$/;

  function organizeContent(arr) {
    // console.log(arr)
    let result = []; // 최종 결과 저장
    let currentContent = null; // 현재 처리 중인 컨텐츠
    let currentNumbers = []; // 현재 컨텐츠에 할당될 숫자

    arr.forEach((item) => {
      if (item.text === "") {
        // 빈 문자열은 무시
        return;
      }

      if (!isNaN(item) && item.length <= 2) {
        // 숫자이고 2자리 이하일 때
        currentNumbers.push(item);
      } else {
        // 새로운 컨텐츠가 나오면 이전 컨텐츠와 숫자를 저장
        if (currentContent) {
          result.push([
            currentContent,
            currentNumbers.length > 0 ? currentNumbers : null,
          ]);
        }
        // 새 컨텐츠로 갱신
        currentContent = item;
        currentNumbers = []; // 숫자 배열 초기화
      }
    });

    // 마지막 컨텐츠 처리
    if (currentContent) {
      result.push([
        currentContent,
        currentNumbers.length > 0 ? currentNumbers : null,
      ]);
    }

    return result;
  }

  var display_text = list_ref;//organizeContent(list_ref);

  //   console.log(ReactDOMServer.renderToString(item_ref[0]))
  // console.log({reference,display_text})
  // console.log({reference, _id, ids });
  return (
    <div
      className={
        isBot
          ? `messageBox-bot flex max-w-full flex-col flex-grow mb-10 ${_id===ids.messageId ? "border-3 border-red-200 rounded-lg" : ""}`
          : "messageBox-me flex max-w-full flex-col flex-grow mb-10"
      }
    >
      <div className={isBot ? "messageBody-bot" : "messageBody-me"}>
        {isBot ? (
          <div className="messageText ">
            {display_text &&
              display_text.map((data, idx) => {
                
                const key = data.key||null;
                const evi_idx = data.index;
                const sentence = data.text.replace(/\<key>|<\/key>/g, "");//.replace(/(\n|\r\n)/g, "<br>");
                
                // console.log({key, sentence})
                return (
                  <PopoverMessage
                    key={idx}
                    data={sentence}
                    documents={key}
                    handleViewer={handleClickDocView}
                    handleLike={() => handleLike(key, sentence, evi_idx)}
                    handleDislike={() => handleDislike(key, sentence, evi_idx)}
                    likeState={feedbackState[evi_idx]}
                    checked={evi_idx!==-1&&reference[evi_idx]?.reference_id===ids.feedbackId ? true : false}
                  />
                );
              })}
            {resultArray.map((item, idx) => (
              <div
                key={idx}
                className="fileBox border border-blue-300 rounded-md"
                onClick={() => handleClickDocView(item.pdf_url, item.min, item.file_nm)}
              >
                <span className="flex size-fit text-xs p-1 mt-1 justify-center items-center self-center">{item.file_nm}</span>
                <span className="pageRange">
                  page: {item.min + 1}-{item.max + 1}
                </span>
              </div>
            ))}
          </div>
        ) : (
          <div className="messageText">{list_ref[0].text}</div>
        )}

        {/* <div
          className="messageText"
          dangerouslySetInnerHTML={{
            __html: text
              .replace(/(\n|\r\n)/g, "<br>")
              .replace(/\[ref-(\d+)\]/g, ReactDOMServer.renderToStaticMarkup(item_ref[0])),
          }}
        >
        </div> */}
        {/* <div className="messageViewDoc">
          {item_ref}
        </div> */}
      </div>
    </div>
  );
}

const PopoverMessage = ({
  data,
  documents,
  handleViewer,
  handleLike,
  handleDislike,
  likeState,
  checked
}) => {
  const [isPopoverVisible, setPopoverVisible] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  const handleMouseEnter = () => {
    if (timeoutId) {
      clearTimeout(timeoutId); // 기존 타이머 제거
    }
    setPopoverVisible(true);
  };

  const handleMouseLeave = () => {
    const id = setTimeout(() => {
      setPopoverVisible(false);
      setDropdownVisible(false);
    }, 200); // 200ms 지연 후 숨기기
    setTimeoutId(id);
  };

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };
  // console.log({data, documents, checked})
  return (
    <div
      onMouseEnter={documents!==null?()=>handleMouseEnter():()=>{}}
      onMouseLeave={documents!==null?()=>handleMouseLeave():()=>{}}
      className="relative inline-block w-full"
    >
      <Markdown
        // source={"TRO 수치가 낮을 때의 문제 해결을 위한 자세한 절차는 다음과 같습니다:\n\n1. **TRO Optic Color 확인**:\n- 로컬 TRO LCD에 표시된 수치가 실제로 낮은지 확인합니다.\n- TRO 감지 기간 동안 큐벳에 투명한 샘플수가 있는지 확인합니다. 이는 TRO 센서가 정상임을 의미합니다.\n- 만약 TRO LCD에 0에 가까운 수치가 표시되었음에도 큐벳 내부에 붉은색 샘플수가 보인다면, optic 센서의 불량 가능성이 높습니다.\n2. **TRO 샘플링 흐름 확인**:\n- TRO Optic 큐벳 내에서 샘플수의 주입과 배출이 정상적으로 이루어지고 있는지 확인합니다.\n- 큐벳 내부에서 샘플수가 정상적으로 채워지지 않거나 흐름이 불안정하면, TRO 샘플수 불량이나 TRO 흡입/배출 밸브 또는 배관 불량을 의심할 수 있습니다.\n3. **공기 펌프에서의 TRO 샘플수 점검**:\n- 공기 펌프의 배출측을 분리하고 흐름이 안정적인지 확인합니다.\n- 밸브는 정상이지만 펌프 출구측 흐름이 불안정하다면, 주관에서의 저압(low pressure)을 점검해야 합니다.\n\n4. **시약 라인 점검**:\n- 샘플수는 정상이지만 optic에서 붉은색이 감지되지 않는다면, 시약 주입 불량을 의심할 수 있습니다.\n- TRO reagent 펌프의 해머 작동과 체크 밸브 고착 여부를 점검합니다.\n\n 5. **TRO 재주입 체크밸브 점검**:\n- 밸러스팅 작업이 수행될 때 TRO 값에 비례하여 정류기(rectifier)에서의 출력이 증가하는지 확인합니다.\n- TRO 값의 증가가 매우 늦다면 재주입 라인에 있는 체크 밸브의 고착을 의심할 수 있습니다.\n이러한 절차를 통해 문제를 해결할 수 있습니다. 추가적인 도움이 필요하시면 언제든지 문의해 주세요."}
        className={`markdown-container ${checked?"bg-red-300":""}`}
        style={{color:"black", backgroundColor:checked?"":"#f8f8f8", padding:"12px 16px", fontSize: "14px"}}
      >{data}</Markdown>
      
      {/* Popover */}
      {documents!==null &&(
        <div className={`relative opacity-80`}>
          <div class={`absolute bg-gray-100 top-0 right-0 -translate-y-full items-center justify-start rounded-xl p-1 z-5 mt-1 bg-token-main-surface-primary md:border md:border-token-border-light flex ${checked?"":""}`}>
            <div class="flex items-center">
              <div class="flex" data-aria-hidden="true" aria-hidden="true">
                <span class="" data-state="closed">
                  <button
                    // onClick={handleLike}
                    className={`rounded-lg text-token-text-secondary hover:bg-token-main-surface-secondary ${
                      likeState && likeState.like
                        ? "bg-blue-500 text-white"
                        : ""
                    }`} // likeState.like이 true일 때 색상 변경
                    aria-label="좋은 응답"
                  >
                    <span class="flex h-[30px] w-[30px] items-center justify-center">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon-md-heavy"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M12.1318 2.50389C12.3321 2.15338 12.7235 1.95768 13.124 2.00775L13.5778 2.06447C16.0449 2.37286 17.636 4.83353 16.9048 7.20993L16.354 8.99999H17.0722C19.7097 8.99999 21.6253 11.5079 20.9313 14.0525L19.5677 19.0525C19.0931 20.7927 17.5124 22 15.7086 22H6C4.34315 22 3 20.6568 3 19V12C3 10.3431 4.34315 8.99999 6 8.99999H8C8.25952 8.99999 8.49914 8.86094 8.6279 8.63561L12.1318 2.50389ZM10 20H15.7086C16.6105 20 17.4008 19.3964 17.6381 18.5262L19.0018 13.5262C19.3488 12.2539 18.391 11 17.0722 11H15C14.6827 11 14.3841 10.8494 14.1956 10.5941C14.0071 10.3388 13.9509 10.0092 14.0442 9.70591L14.9932 6.62175C15.3384 5.49984 14.6484 4.34036 13.5319 4.08468L10.3644 9.62789C10.0522 10.1742 9.56691 10.5859 9 10.8098V19C9 19.5523 9.44772 20 10 20ZM7 11V19C7 19.3506 7.06015 19.6872 7.17071 20H6C5.44772 20 5 19.5523 5 19V12C5 11.4477 5.44772 11 6 11H7Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </span>
                  </button>
                </span>
                <span class="" data-state="closed">
                  <button
                    // onClick={handleDislike}
                    className={`rounded-lg text-token-text-secondary hover:bg-token-main-surface-secondary ${
                      likeState && likeState.dislike
                        ? "bg-red-500 text-white"
                        : ""
                    }`}
                    aria-label="별로인 응답"
                  >
                    <span class="flex h-[30px] w-[30px] items-center justify-center">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon-md-heavy"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M11.8727 21.4961C11.6725 21.8466 11.2811 22.0423 10.8805 21.9922L10.4267 21.9355C7.95958 21.6271 6.36855 19.1665 7.09975 16.7901L7.65054 15H6.93226C4.29476 15 2.37923 12.4921 3.0732 9.94753L4.43684 4.94753C4.91145 3.20728 6.49209 2 8.29589 2H18.0045C19.6614 2 21.0045 3.34315 21.0045 5V12C21.0045 13.6569 19.6614 15 18.0045 15H16.0045C15.745 15 15.5054 15.1391 15.3766 15.3644L11.8727 21.4961ZM14.0045 4H8.29589C7.39399 4 6.60367 4.60364 6.36637 5.47376L5.00273 10.4738C4.65574 11.746 5.61351 13 6.93226 13H9.00451C9.32185 13 9.62036 13.1506 9.8089 13.4059C9.99743 13.6612 10.0536 13.9908 9.96028 14.2941L9.01131 17.3782C8.6661 18.5002 9.35608 19.6596 10.4726 19.9153L13.6401 14.3721C13.9523 13.8258 14.4376 13.4141 15.0045 13.1902V5C15.0045 4.44772 14.5568 4 14.0045 4ZM17.0045 13V5C17.0045 4.64937 16.9444 4.31278 16.8338 4H18.0045C18.5568 4 19.0045 4.44772 19.0045 5V12C19.0045 12.5523 18.5568 13 18.0045 13H17.0045Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </span>
                  </button>
                </span>
                {documents && documents[0] !== undefined ? (
                  <span class="" data-state="closed">
                    <button
                      onClick={toggleDropdown}
                      class="rounded-lg text-token-text-secondary hover:bg-token-main-surface-secondary"
                      aria-label="문서 목록 보기"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 text-gray-700"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 12h6m2 8H7a2 2 0 01-2-2V6a2 2 0 012-2h7l5 5v11a2 2 0 01-2 2z"
                        />
                      </svg>
                    </button>
                  </span>
                ) : (null
                  // <span class="" data-state="closed">
                  //   <button
                  //     // onClick={toggleDropdown}
                  //     class="rounded-lg text-token-text-secondary hover:bg-token-main-surface-secondary"
                  //     aria-label="문서 목록 보기"
                  //   >
                  //     <svg
                  //       xmlns="http://www.w3.org/2000/svg"
                  //       className="h-6 w-6 text-gray-300"
                  //       fill="none"
                  //       viewBox="0 0 24 24"
                  //       stroke="currentColor"
                  //       strokeWidth={2}
                  //     >
                  //       <path
                  //         strokeLinecap="round"
                  //         strokeLinejoin="round"
                  //         d="M9 12h6m2 8H7a2 2 0 01-2-2V6a2 2 0 012-2h7l5 5v11a2 2 0 01-2 2z"
                  //       />
                  //     </svg>
                  //   </button>
                  // </span>
                )}
              </div>
            </div>
          </div>

          {/* 드롭다운 문서 목록 */}
          {isDropdownVisible && (
            <div className="absolute right-0 mt-1 w-56 bg-white border border-gray-300 rounded-lg shadow-lg z-50">
              <ul className="py-0">
                {documents &&
                  documents.map((doc, index) => {
                    let pdfPages = Array.isArray(doc.pdf_page)
                      ? doc.pdf_page
                      : [doc.pdf_page];

                    // console.log({pdf_page: pdfPages});

                    return (
                      pdfPages &&
                      pdfPages.map((p, pidx) => (
                        <li
                          key={`${index}-${pidx}`}
                          className="px-2 py-1 hover:bg-gray-100"
                          onClick={() => handleViewer(doc.pdf_url, p)}
                        >
                          <span className="font-semibold">
                            {doc.file_nm + " - " + "page " + p}
                          </span>
                        </li>
                      ))
                    );
                  })}
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MessageBasic;
