import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import { useNavigate } from "react-router-dom";
import { passwordChange, selectToken, userMe } from "../../lib/features/auth/authSlice";
import { Input } from "../ui/input";
import { ArrowRightIcon } from "lucide-react";
function PasswordChange() {
  const [password, setPassword] = useState("");
  const token = useAppSelector(selectToken);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  // Validate password and confirm password
  const validatePasswords = () => {
    if (password.length < 8){
      setError("Password must be at least 8 characters long.");
      return false;
    }
    const hasLetter = /[a-zA-Z]/.test(password); // 영어 문자가 포함되어 있는지
    const hasNumber = /\d/.test(password);       // 숫자가 포함되어 있는지

    if (!hasLetter || !hasNumber) {
      setError("Password must contain at least one letter and one number.");
      return false;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match!");
      return false;
    }

    return true;
  };

  // Handle password change submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null); // Reset error state

    if (!validatePasswords()) {
      return;
    }
    dispatch(passwordChange({ password, token })).then((response) => {
      dispatch(userMe({ token })).then((response) => {
        navigate("/menu");
      });
    });
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen w-full">
        <div className="LoginContainer flex flex-col items-center justify-center h-screen w-1/2">
        <h3 className="text-2xl font-bold mb-4">Password Change</h3>
        <h4 className="text-sm mb-4">Welcome! To start using your account, please change your password.</h4>
        <form onSubmit={handleSubmit}>
            <div className="mb-3">
            {/* <label htmlFor="password" className="form-label">
                New Password
            </label> */}
            <Input
                label="New Password"
                type="password"
                className="form-control"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
            />
            </div>
            <div className="mb-3">
            {/* <label htmlFor="confirmPassword" className="form-label">
                Confirm Password
            </label> */}
            <Input
                label="Confirm Password"
                type="password"
                className="form-control"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
            />
            </div>
            {error && <p className="text-danger">{error}</p>}
            <button
            type="submit"
            className="flex justify-end items-center w-full text-center px-4 py-3 mb-2 bg-blue-500 text-sm text-white rounded-lg bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors gap-4"
            >
            Change Password
            <ArrowRightIcon className="w-4 h-4" />
            </button>
        </form>
      </div>
    </div>
  );
}

export default PasswordChange;
