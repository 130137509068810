import React from "react";
import { Badge } from "../../ui/badge";
import { TabsList, TabsTrigger } from "../../ui/tabs";

const DashboardTabs = ({ data, tabValue, setTabValue }: { data: any, tabValue: string, setTabValue: (value: string) => void }) => (
  <TabsList className="mb-4">
    {data&&data.map((item: any)=>
    <TabsTrigger 
      key={item.tabName}
      value={item.tabName} 
      className={`${tabValue === item.tabName ? "bg-blue-200" : "bg-gray-200"}`}
      onClick={() => setTabValue(item.tabName)}
    >
      {item.name}
      {item.notifications > 0 && (
      <div className="relative">
        <Badge variant="outline" className="absolute bg-red-500 text-white top-0 right-0 transform translate-x-full -translate-y-full bg-gradient-to-br from-cyan-500 to-blue-500"
          >
          {item.notifications}
        </Badge>
        </div>
      )}
    </TabsTrigger>
    )}
  </TabsList>
);

export default DashboardTabs;
