import React, { useEffect, useState } from 'react'
import { ChevronRight, ChevronDown, Folder, File, FolderCheck, FolderMinus  } from 'lucide-react'
import { useAppDispatch, useAppSelector } from "../../../lib/hooks"
import {
  adminUserAllGet,
  adminGroupListGet,
  adminModelListGet,
  adminFileListGet,
  adminFGroupListGet,
  adminFileListGetOnfid,
  adminPGroupListGet,
  adminPGroupFileListGet,
  adminChatbotListGet,
  adminPGroupAdd,
  adminPGroupDelete,
  adminFileAdd,
  adminFileDelete,
  selectDashboardUser,
  selectDashboardGroup,
  selectDashboardModel,
  selectDashboardFile,
  selectDashboardFGroup,
  selectDashboardPGroup,
  selectDashboardPGroupFile,
  selectDashboardChatbot,
} from "../../../lib/features/admin/adminSlice"
import { selectToken, selectStatus } from "../../../lib/features/auth/authSlice"


type TreeNode = {
  fgroup_id?: string
  fgroup_name?: string
  pdf_id?: string
  pdf_name?: string
  isFolder: boolean
  checkStatus?: "none"|"half"|"all"
  child?: TreeNode[],
  files?: TreeNode[]
}

type TreeNodeProps = {
  node: TreeNode
  level: number
  onSelect: (node: TreeNode) => void
  selectedId: string | null
}
 
const TreeNode: React.FC<TreeNodeProps> = ({ node, level, selectedId, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false)
  const hasChildren = node.child && node.child.length > 0
  const isSelected = node.fgroup_id === selectedId

  const handleClick = () => {
    if (node.isFolder) {
      setIsOpen(!isOpen)
      onSelect(node)
    }
  }

  return (
    <div style={{ marginLeft: `${level==0?0:20}px` }}>
      <div 
        className={`flex items-center py-1 px-1 rounded cursor-pointer ${isSelected ? 'bg-blue-100' : 'hover:bg-gray-100'}`}
        onClick={handleClick}
        role="treeitem"
        aria-expanded={hasChildren ? isOpen : undefined}
        aria-selected={isSelected}
      >
        {hasChildren ? (
          <span className="mr-1 focus:outline-none flex-shrink-0">
            {isOpen ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
          </span>
        ) : (
          <span className="w-4 h-4 mr-1 flex-shrink-0" />
        )}
        {node.isFolder ? 
         node.checkStatus === "all" ? <FolderCheck size={16} className="mr-2 text-blue-100 fill-blue-500 flex-shrink-0" /> 
         : node.checkStatus === "half" ? <FolderMinus size={16} className="mr-2 text-blue-500 flex-shrink-0" /> 
         : <Folder size={16} className="mr-2 text-blue-300 flex-shrink-0" />
         : <Folder size={16} className="mr-2 text-blue-300 flex-shrink-0" />}
        <span className="flex-grow truncate">{node.fgroup_name}</span>
      </div>
      {isOpen && hasChildren && (
        <div role="group">
          {(node.child?.filter(child => child.isFolder) || [])
            .sort((a, b) => (a?.fgroup_name || a?.pdf_name || '').localeCompare(b?.fgroup_name || b?.pdf_name || ''))
            .map((childNode) => (
            <TreeNode 
              key={childNode.fgroup_id} 
              node={childNode} 
              level={level + 1} 
              selectedId={selectedId}
              onSelect={onSelect}
            />
          ))}
        </div>
      )}
    </div>
  )
}

type TreeViewProps = {
  data: TreeNode[]
  onSelect: (node: TreeNode) => void
  selectedId: string | null
  selection?: boolean
  viewer?: boolean
  pgroupFiles?: any[]
}


export default function TreeView({ data, onSelect, selectedId, selection, viewer, pgroupFiles }: TreeViewProps) {
  return (
    <div className="p-4 bg-white rounded-lg shadow overflow-y-auto text-sm" role="tree">
      {data.map((node) => (
        <TreeNode 
          key={node.fgroup_id} 
          node={node} 
          level={0} 
          onSelect={onSelect}
          selectedId={selectedId}
        />
      ))}
    </div>
  )
}