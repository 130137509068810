import React, { useEffect, useMemo, useState } from "react";

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../ui/table"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
    DropdownMenuSub,
    DropdownMenuSubTrigger,
    DropdownMenuSubContent,
} from "../../ui/dropdown-menu"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../../ui/dialog"

import { Plus, Search, Filter, EllipsisVertical, MoreVertical, Check, X, Trash2, UserPlus, Users, FileChartColumn } from "lucide-react"
import { Button } from "../../ui/button"
import { Input } from "../../ui/input"
import Pagination from "../Common/Pagination";
import { Checkbox } from "../../ui/checkbox";
import { adminChatbotAdd, adminChatbotGroupListGet, adminChatbotListGet, adminChatbotTrain, adminChatbotUpdate, adminGroupAdd, adminGroupUpdate, adminPGroupFileListGet } from "../../../lib/features/admin/adminSlice";
import { useAppDispatch, useAppSelector } from "../../../lib/hooks";

const APP_STAGE = process.env.REACT_APP_STAGE;
const ITEMS_PER_PAGE = 20;
const ChatbotTable = ({ chatbots, pgroups }: { chatbots: any, pgroups: any }) => {
    const dispatch = useAppDispatch();
    const token = useAppSelector((state) => state.authentication.token);
    const pgroupFiles = useAppSelector((state) => state.adminPage.dashboard.pgroupFile);

    const [currentPage, setCurrentPage] = useState(1);
    const [selectedChatbotId, setSelectedChatbotId] = useState<number | null>(null);
    const [detailChatbotForm, setDetailChatbotForm] = useState<any>({
        id: "",
        pgroup_name: "",
        chatbot_status: "",
        chatbot_type: ""
      });
    const [newChatbotForm, setNewChatbotForm] = useState({
        pgroup_name: "",
        pgroup_id: "",
        chatbot_status: "",
        chatbot_type: "Troubleshooting"
    });
    const [chatbotForm, setChatbotForm] = useState(
        chatbots.map((chatbot: any) => ({
            id: chatbot.chatbot_id,
            pgroup_name: chatbot.pgroup.pgroup_name,
            chatbot_status: chatbot.chatbot_status,
            chatbot_type: chatbot.chatbot_type,
            pgroup_id: chatbot.pgroup.pgroup_id,
            pgroup_vid: chatbot.pgroup.pgroup_vid,
            // name: group.group_name ? group.group_name : "",
            // is_admin: group.permission.is_admin,
            // is_chatbot_ts: group.permission.is_chatbot_ts,
            // is_chatbot_mt: group.permission.is_chatbot_mt,
            // is_chatbot_gi: group.permission.is_chatbot_gi,
            // is_main_manual: group.permission.is_main_manual,
            // is_main_video: group.permission.is_main_video,
            // description: group.group_desc ? group.group_desc : ""
      })) || []);
      
    const [isDialogOpen, setIsDialogOpen] = useState({
        modify: false,
        create: false,
        delete: false,
        detail: false,
    });
    const [sortConfig, setSortConfig] = useState({ key: "id", direction: "asc" });

    function formatDateTime(dateTimeString: string) {
        if (dateTimeString.length !== 14) {
          return "잘못된 입력 형식입니다.";
        }
    
        const year = dateTimeString.slice(0, 4);
        const month = dateTimeString.slice(4, 6);
        const day = dateTimeString.slice(6, 8);
        const hour = dateTimeString.slice(8, 10);
        const minute = dateTimeString.slice(10, 12);
        const second = dateTimeString.slice(12, 14);
    
        return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
      }
    // 전체 상태 정리
    // 활성, 잠김(이메일, 승인안됨, 비번오류, 이상감지)
    // function getStatus(group: any) {
    //     if (group.is_deleted) {
    //       return "탈퇴";
    //     } else if (!user.is_verified || !user.has_email_check || user.is_locked) {
    //       return "잠김";
    //     } else {
    //       return "활성";
    //     }
    // }
    useEffect(() => {
        if (chatbots) {
            setChatbotForm(
                chatbots.map((chatbot: any) => ({
                    id: chatbot.chatbot_id,
                    pgroup_name: chatbot.pgroup.pgroup_name,
                    chatbot_status: chatbot.chatbot_status,
                    chatbot_type: chatbot.chatbot_type,
                    pgroup_id: chatbot.pgroup.pgroup_id,
                    pgroup_vid: chatbot.pgroup.pgroup_vid,
                })) || []
            );
        }
    }, [chatbots]);



    const sortedChatbots = useMemo(() => {
        return [...chatbotForm].sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key])
                return sortConfig.direction === "asc" ? -1 : 1;
            if (a[sortConfig.key] > b[sortConfig.key])
                return sortConfig.direction === "asc" ? 1 : -1;
            return 0;
        });
    }, [chatbotForm, sortConfig]);
    // const totalPages = Math.ceil(sortedGroups.length / ITEMS_PER_PAGE);
    const paginatedChatbots = sortedChatbots.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        currentPage * ITEMS_PER_PAGE
    );

    const handleSort: any = (key: string) => {
        setSortConfig((currentConfig) => {
            if (currentConfig.key === key) {
                return {
                    ...currentConfig,
                    direction: currentConfig.direction === "asc" ? "desc" : "asc",
                };
            }
            return { key, direction: "asc" };
        });
    };

    
  const handleSubmit = () => {
    // dispatch(
    //     adminGroupUpdate({
    //     token: token as string,
    //     groupId: chatbotForm.id as number,
    //     groupName: chatbotForm.name as string,
    //     // groupDesc: chatbotForm.description as string,
    //     // is_admin: chatbotForm?.is_admin,
    //     is_chatbot_ts: chatbotForm?.is_chatbot_ts,
    //     is_chatbot_mt: chatbotForm?.is_chatbot_mt,
    //     is_chatbot_gi: chatbotForm?.is_chatbot_gi,
    //     is_main_manual: permissionForm?.is_main_manual,
    //     is_main_video: permissionForm?.is_main_video,
    //   })
    // ).then(() => {
    //   // userAllChange();
    //   // adminUserSignUpRequestGetChange();
    //   alert("Group information has been updated.");
    //   // setUserNm("");
    //   // setSelectedCompany(undefined);
    //   setIsDialogOpen((prev) => ({ ...prev, ["mod"]: false }));
    // });

    // setIsDialogOpen((prev) => ({ ...prev, ["su"]: false })); // Close the dialog after submitting
  };

  const handleStartChatbot = (chatbotId: number) => {
    const currentStatus = chatbotForm.filter((chatbot: any) => chatbot.id === chatbotId)[0].chatbot_status;
    if(currentStatus === "ACTIVE"){
      alert("Chatbot is already active.");
      return;
    }else if(currentStatus === "TRAINING"){
      alert("Chatbot is training.");
      return;
    }else if(currentStatus === "IDLE"){
      alert("Chatbot is idle.");
      return;
    }else if(currentStatus === "FAILED"){
      alert("Chatbot is failed.");
      return;
    }

      dispatch(
        adminChatbotUpdate({
        token: token as string,
        chatbotId: chatbotId as number,
        chatbotAction: "ACTIVE" as string,
      })
    ).then(() => {
      alert("Chatbot information has been updated.");
      dispatch(adminChatbotListGet({ token: token as string }));
    });
  }

  const handleStopChatbot = (chatbotId: number) => {
    const currentStatus = chatbotForm.filter((chatbot: any) => chatbot.id === chatbotId)[0].chatbot_status;
    if(currentStatus === "READY"){
      alert("Chatbot is already inactive.");
      return;
    }else if(currentStatus === "TRAINING"){
      alert("Chatbot is training.");
      return;
    }else if(currentStatus === "IDLE"){
      alert("Chatbot is idle.");
      return;
    }else if(currentStatus === "FAILED"){
      alert("Chatbot is failed.");
      return;
    }
    dispatch(adminChatbotGroupListGet({ token: token as string })
    ).then((res) => {
      console.log(res.payload)
      if(res.payload.filter((chatbot: any) => chatbot.chatbot_id === chatbotId).length !== 0){
        alert("Chatbot is being used in another group. Please stop the chatbot in the other group first in chatbot group tab.");
        return;
      }else{

        dispatch(
            adminChatbotUpdate({
            token: token as string,
            chatbotId: chatbotId as number,
            chatbotAction: "READY" as string,
          })
        ).then(() => {
          alert("Chatbot information has been updated.");
          dispatch(adminChatbotListGet({ token: token as string }));
        });
      }
    })

  }

  const handleSubmitCreate = () => {
    const pgroupId = pgroups.find((pgroups: any) => pgroups.pgroup_name === newChatbotForm.pgroup_name)?.pgroup_id;
    const chatbotType = newChatbotForm.chatbot_type==="Troubleshooting"?"BWMS-TS":"BWMS-MT";
    dispatch(
        adminChatbotAdd({
        token: token as string,
        pgroupId: pgroupId as number,
        vid: 0,
        chatbotType: chatbotType as string,
        modelId: 1
      })
    ).then(() => {
      alert("Chatbot information has been created.");
      setIsDialogOpen((prev) => ({ ...prev, ["create"]: false }));
      setNewChatbotForm({
        pgroup_name: "",
        pgroup_id: "",
        chatbot_status: "",
        chatbot_type: "Troubleshooting"
    });
    });
  };

  const handleTrainChatbot = (chatbotId: number) => {
    // 먼저 로컬 상태를 TRAINING으로 업데이트
    
    if(process.env.NODE_ENV === "DEV"){
      console.log(chatbotForm.filter((chatbot: any) => chatbot.id === chatbotId))
    }
    
    dispatch(
      adminPGroupFileListGet({
        token: token as string,
        pgroup_id: chatbotForm.filter((chatbot: any) => chatbot.id === chatbotId)[0].pgroup_id as number,
        pgroup_vid: chatbotForm.filter((chatbot: any) => chatbot.id === chatbotId)[0].pgroup_vid as number,
      })
    ).then((res) => {
      if(res.payload.pdf.length === 0){
        alert("학습할 파일이 없습니다. 파일을 먼저 지정해주세요.");
        return;
      }else{
        setChatbotForm((prevForm: any) => 
          prevForm.map((chatbot: any) => 
            chatbot.id === chatbotId 
              ? { ...chatbot, chatbot_status: "TRAINING" } 
              : chatbot
          )
        );

        // 그 다음 API 호출
        dispatch(
          adminChatbotTrain({
            token: token as string,
            chatbotId: chatbotId as number,
          })
        ).then(() => {
          alert("챗봇 학습이 완료 되었습니다.");
          // 서버에서 최신 상태를 가져옴
          dispatch(adminChatbotListGet({ token: token as string }));
        });
      }
    });
  }

  const handleDetailChatbotFile = ({pgroupId, pgroupVid}: {pgroupId: number, pgroupVid: number}) => {
    dispatch(
        adminPGroupFileListGet({
        token: token as string,
        pgroup_id: pgroupId as number,
        pgroup_vid: pgroupVid as number,
      })
    ).then(() => {
      //alert("Chatbot information has been updated.");
    });
  }


//   console.log({detailChatbotForm})
  
    return (
        <div className="bg-white shadow-md rounded-lg overflow-hidden p-4">
            <div className="border-b flex justify-between items-center">
                <h2 className="text-2xl font-semibold mb-4">CHATBOTS</h2>
                {/* <button
                    onClick={() => { 
                        // setPermissionForm({ ...permissionForm, id: 0, name: "", is_admin: false, is_chatbot_ts: false, is_chatbot_mt: true, is_chatbot_gi: false, is_main_manual: false, is_main_video: false, description: "" }) 
                        setIsDialogOpen({ ...isDialogOpen, create: true })
                    }}
                    className="flex items-center gap-2 px-4 py-2 mb-2 bg-blue-500 text-sm text-white rounded-full bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
                ><Plus className="h-4 w-4" /> Create New
                </button> */}
                
                <Dialog
                    open={isDialogOpen.modify}
                    onOpenChange={() => {setIsDialogOpen({ ...isDialogOpen, modify: false })
                }
                
                }
                >
                    {/* <DialogTrigger>
                        
                    </DialogTrigger> */}
                    <DialogContent>
                        <DialogHeader>
                            <DialogTitle>Modify Chatbot</DialogTitle>
                            <DialogDescription>Please enter the information for the chatbot.</DialogDescription>
                        </DialogHeader>
                        <form onSubmit={(e) => {
                            e.preventDefault()
                            const formData = new FormData(e.currentTarget)
                            // addGroup(formData.get('name'), formData.get('description'))
                        }}>
                            <div className="flex flex-col gap-2">

                            </div>
                            <DialogFooter>
                                <button
                                    type="submit"
                                    onClick={()=>{}}
                                    className="flex items-center gap-2 px-4 py-2 mb-0 bg-blue-500 text-sm text-white rounded-md bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
                                >Modify Chatbot</button>
                            </DialogFooter>
                        </form>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={isDialogOpen.detail}
                    onOpenChange={() => {setIsDialogOpen({ ...isDialogOpen, detail: false })
                }
                }
                >
                    {/* <DialogTrigger>
                        
                    </DialogTrigger> */}
                    <DialogContent className="!max-w-6xl px-10">
                        <DialogHeader>
                            <DialogTitle>Detail Chatbot</DialogTitle>
                            <DialogDescription>Detail information for the chatbot.</DialogDescription>
                        </DialogHeader>
                        <div>
                            <span className="font-semibold p-2">Contain File List</span>
                            <div className="flex flex-col gap-2">
                                <div className={`min-h-[300px] max-h-[400px] overflow-y-auto`}>
                                    <Table>
                                        <TableHeader>
                                            <TableRow>
                                                <TableHead></TableHead>
                                                <TableHead>File Name</TableHead>
                                                <TableHead>Status</TableHead>
                                                <TableHead></TableHead>
                                            </TableRow>
                                        </TableHeader>
                                        <TableBody>
                                    {pgroupFiles?.pdf.map((file: any) => (
                                    <TableRow
                                        key={file.pdf_id}
                                    >   
                                            <TableCell className="w-8">
                                                <FileChartColumn size={20} className="text-red-500" />
                                            </TableCell>
                                            <TableCell className="flex items-center space-x-3 flex-grow text-sm truncate max-w-lg">
                                                {file.pdf_original_nm}
                                            </TableCell>
                                            <TableCell>
                                                <span className={`text-white rounded-md px-2 py-1 text-sm ${file.pgroup_check ? "bg-green-500" : "bg-red-500"}`}>
                                                    {file.pgroup_check ? "O" : "X"}
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                            </TableCell>
                                    </TableRow>
                                    ))}
                                    </TableBody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-4 grid-rows-auto gap-2 text-base ">
                            {[{ label: "Name", value: detailChatbotForm.pgroup_name },
                            { label: "VERSION", value: "v."+detailChatbotForm.pgroup_vid },
                            // { label: "Version", value: detailChatbotForm.pgroup_vid },
                            { label: "Type", value: detailChatbotForm.chatbot_type?detailChatbotForm.chatbot_type==="BWMS-TS"?"Troubleshooting":"Maintenance":"" },
                            { label: "Status", value: detailChatbotForm.chatbot_status }].map((item) => (
                                <>
                                  <span className="font-semibold col-span-1">{item.label}</span>
                                  
                                  <span className="col-span-3">
                                    {item.value!==null ? item.value : "-"}
                                  </span>
                                </>
                              ))}
                        </div>
                        <DialogFooter>

                        </DialogFooter>
                    </DialogContent>
                </Dialog>

                </div>
                <Table>
                <TableHeader>
                    <TableRow>
                        {["id", "pgroup_name", "type", "chatbot_status"].map((key) => (
                            <TableHead
                                key={key}
                                className="px-6 py-2 text-left text-xs text-nowrap font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                onClick={() => handleSort(key)}
                            >
                                {key === "id"
                                    ? "No."
                                    : key === "pgroup_name"
                                        ? "Name"
                                        : key === "type"
                                        ? "Type"
                                        : key === "chatbot_status"
                                        ? "Status"
                                        : ""}
                                {sortConfig.key === key && (
                                    <span className="ml-1">
                                        {sortConfig.direction === "asc" ? "▲" : "▼"}
                                    </span>
                                )}
                            </TableHead>
                        ))}
                        <TableHead>action</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {paginatedChatbots && paginatedChatbots.map((chatbot) => (
                        <TableRow key={chatbot.id}>
                            <TableCell className="w-10">{chatbot.id}</TableCell>
                            <TableCell>{chatbot.pgroup_name} 
                                <span className="ml-2 px-2 py-1 border border-1 rounded-full shadow-sm ">
                                    {"v."+chatbot.pgroup_vid} 
                                </span>
                            </TableCell>
                            <TableCell  className="w-[100px]">
                                <span className={`border border-gray-300 rounded-full shadow-sm px-2 py-1 ${chatbot.chatbot_type === "BWMS-TS" ? "text-blue-700" : chatbot.chatbot_type === "BWMS-MT" ? "text-green-700" : chatbot.chatbot_type === "BWMS-GI" ? "text-red-700" : "text-gray-700"}`}>
                                    {chatbot.chatbot_type === "BWMS-TS" ? "Troubleshooting" : chatbot.chatbot_type === "BWMS-MT" ? "Maintenance" : chatbot.chatbot_type === "BWMS-GI" ? "General Inquiry" : ""}
                                </span>
                            </TableCell>
                            <TableCell className="w-[100px]">
                                <span className={`border border-gray-300 rounded-full shadow-sm px-2 py-1 
                                  ${chatbot.chatbot_status === "READY" ? "text-green-500" 
                                  : chatbot.chatbot_status === "TRAINING" ? "text-blue-500"
                                  : chatbot.chatbot_status === "FAILED" ? "text-red-500"
                                  : chatbot.chatbot_status === "ACTIVE" ? "text-green-500"
                                  : chatbot.chatbot_status === "INACTIVE" ? "text-gray-500"
                                  : "text-gray-500"}`}>{chatbot.chatbot_status}</span>
                            </TableCell>
                            
                            <TableCell className="w-[50px]">
                                <DropdownMenu>
                                    <DropdownMenuTrigger>
                                        <EllipsisVertical className="h-4 w-4" />
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent>
                                        {/* <DropdownMenuItem
                                            onClick={() =>{
                                                handleUpdateChatbotName(chatbot.id)
                                                // setIsDialogOpen({ ...isDialogOpen, modify: true }) 
                                            }}
                                        >이름 변경</DropdownMenuItem> */}
                                        <DropdownMenuItem
                                            onClick={() =>{
                                                setDetailChatbotForm(chatbot);
                                                setSelectedChatbotId(chatbot.id);
                                                handleDetailChatbotFile({pgroupId: chatbot.pgroup_id, pgroupVid: chatbot.pgroup_vid});
                                                setIsDialogOpen({ ...isDialogOpen, detail: true }) 
                                            }}
                                        >세부정보</DropdownMenuItem>
                                        <DropdownMenuItem
                                            onClick={() =>{
                                                if(chatbot.chatbot_status !== "TRAINING" && chatbot.chatbot_status !== "ACTIVE"){
                                                    handleTrainChatbot(chatbot.id)
                                                }
                                            }}
                                            className={`${chatbot.chatbot_status !== "TRAINING" && chatbot.chatbot_status !== "ACTIVE" ? "" : "text-gray-300"}`}
                                            disabled={chatbot.chatbot_status === "TRAINING" || chatbot.chatbot_status === "ACTIVE"}
                                        >학습 or 재학습</DropdownMenuItem>
                                        <DropdownMenuSeparator />
                                        <DropdownMenuItem
                                            onClick={() =>{
                                                if(chatbot.chatbot_status === "READY"){
                                                    handleStartChatbot(chatbot.id)
                                                }
                                            }}
                                            className={`${chatbot.chatbot_status === "READY" ? "" : "text-gray-300"}`}
                                            disabled={chatbot.chatbot_status !== "READY"}
                                        >시작</DropdownMenuItem>
                                        <DropdownMenuItem
                                            onClick={() =>{
                                                if(chatbot.chatbot_status === "ACTIVE"){
                                                    handleStopChatbot(chatbot.id)
                                                }
                                            }}
                                            className={`${chatbot.chatbot_status === "ACTIVE" ? "" : "text-gray-300"}`}
                                            disabled={chatbot.chatbot_status !== "ACTIVE"}
                                        >정지</DropdownMenuItem>
                                    </DropdownMenuContent>
                                </DropdownMenu>

                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={chatbots ? Math.ceil(chatbots?.length / 20) : 1} totalNumber={chatbots?.length} listName="Chatbot Group"/>
         
        </div>
    );
};

export default ChatbotTable;
