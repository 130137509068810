import React, { useEffect, useMemo, useState } from "react";

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../ui/table"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
    DropdownMenuSub,
    DropdownMenuSubTrigger,
    DropdownMenuSubContent,
} from "../../ui/dropdown-menu"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../../ui/dialog"

import { Plus, Search, Filter, EllipsisVertical, MoreVertical, Check, X, Trash2, UserPlus, Users } from "lucide-react"
import { Button } from "../../ui/button"
import { Input } from "../../ui/input"
import Pagination from "../Common/Pagination";


const ITEMS_PER_PAGE = 20;
const ChatbotGroup = ({ groups }: { groups: any }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [newGroupForm, setNewGroupForm] = useState({
        name: "",
        grade: "",
        description: ""
    });
    const [groupForm, setGroupForm] = useState(
        groups.map((group: any) => ({
            id: group.group_id,
            name: group.group_name,
            grade: group.group_grd,
            description: group.group_desc
      })) || []);

    const [sortConfig, setSortConfig] = useState({ key: "id", direction: "asc" });

    function formatDateTime(dateTimeString: string) {
        if (dateTimeString.length !== 14) {
          return "잘못된 입력 형식입니다.";
        }
    
        const year = dateTimeString.slice(0, 4);
        const month = dateTimeString.slice(4, 6);
        const day = dateTimeString.slice(6, 8);
        const hour = dateTimeString.slice(8, 10);
        const minute = dateTimeString.slice(10, 12);
        const second = dateTimeString.slice(12, 14);
    
        return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
      }
    // 전체 상태 정리
    // 활성, 잠김(이메일, 승인안됨, 비번오류, 이상감지)
    // function getStatus(group: any) {
    //     if (group.is_deleted) {
    //       return "탈퇴";
    //     } else if (!user.is_verified || !user.has_email_check || user.is_locked) {
    //       return "잠김";
    //     } else {
    //       return "활성";
    //     }
    // }
    useEffect(() => {
        setGroupForm(
            groups.map((group: any) => ({
                id: group.group_id,
                name: group.group_name,
                grade: group.group_grd,
                description: group.group_desc
          })) || []
        );
      }, [groups]);


    const sortedGroups = useMemo(() => {
        return [...groupForm].sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key])
                return sortConfig.direction === "asc" ? -1 : 1;
            if (a[sortConfig.key] > b[sortConfig.key])
                return sortConfig.direction === "asc" ? 1 : -1;
            return 0;
        });
    }, [groups, sortConfig]);
    const totalPages = Math.ceil(sortedGroups.length / ITEMS_PER_PAGE);
    const paginatedGroups = sortedGroups.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        currentPage * ITEMS_PER_PAGE
    );

    const handleSort = (key: string) => {
        setSortConfig((currentConfig) => {
            if (currentConfig.key === key) {
                return {
                    ...currentConfig,
                    direction: currentConfig.direction === "asc" ? "desc" : "asc",
                };
            }
            return { key, direction: "asc" };
        });
    };

    return (
        <div className="bg-white shadow-md rounded-lg overflow-hidden p-4">
            <div className="border-b flex justify-between items-center">
                <h2 className="text-2xl font-semibold mb-4">챗봇그룹 목록</h2>
                <Dialog>
                    <DialogTrigger asChild>
                        <button
                            onClick={() => { }}
                            className="flex items-center gap-2 px-4 py-2 mb-2 bg-blue-500 text-sm text-white rounded-full bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
                        ><Plus className="h-4 w-4" /> 권한그룹 생성
                        </button>
                    </DialogTrigger>
                    <DialogContent>
                        <DialogHeader>
                            <DialogTitle>새 권한그룹 추가</DialogTitle>
                            <DialogDescription>새로운 그룹의 정보를 입력하세요.</DialogDescription>
                        </DialogHeader>
                        <form onSubmit={(e) => {
                            e.preventDefault()
                            const formData = new FormData(e.currentTarget)
                            // addGroup(formData.get('name'), formData.get('description'))
                        }}>
                            <div className="grid gap-2 py-2">
                                <div className="flex items-center gap-4">
                                    <Input
                                        label="그룹이름"
                                        type="number"
                                        id="name"
                                        placeholder="그룹이름"
                                        value={newGroupForm.name}
                                        onChange={(e) => setNewGroupForm({ ...newGroupForm, name: e.target.value })}
                                        isNoSpinner={true}
                                    />
                                </div>
                                <div className="flex items-center gap-4">
                                    <Input
                                        label="이메일"
                                        type="email"
                                        id="email"
                                        placeholder="example@email.com"
                                        value={newGroupForm.grade}
                                        onChange={(e) => setNewGroupForm({ ...newGroupForm, grade: e.target.value })}
                                    />
                                </div>
                                <div className="flex items-center gap-4">
                                    <Input
                                        label="비밀번호"
                                        type="password"
                                        id="password"
                                        placeholder="비밀번호"
                                        value={newGroupForm.description}
                                        onChange={(e) => setNewGroupForm({ ...newGroupForm, description: e.target.value })}
                                    />
                                </div>
                            </div>
                            <DialogFooter>
                                <button
                                    type="submit"
                                    onClick={() => { }}
                                    className="flex items-center gap-2 px-4 py-2 mb-0 bg-blue-500 text-sm text-white rounded-md bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
                                >그룹 추가</button>
                            </DialogFooter>
                        </form>
                    </DialogContent>
                </Dialog>
                </div>
                <Table>
                <TableHeader>
                    <TableRow>
                        {["id", "name", "grade", "description"].map((key) => (
                            <TableHead
                                key={key}
                                className="px-6 py-2 text-left text-xs text-nowrap font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                onClick={() => handleSort(key)}
                            >
                                {key === "id"
                                    ? "No."
                                    : key === "name"
                                        ? "그룹이름"
                                        : key === "grade"
                                            ? "권한"
                                            : key === "description"
                                                ? "설명"
                                                : ""}
                                {sortConfig.key === key && (
                                    <span className="ml-1">
                                        {sortConfig.direction === "asc" ? "▲" : "▼"}
                                    </span>
                                )}
                            </TableHead>
                        ))}
                        <TableHead>작업</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {paginatedGroups && paginatedGroups.map((group) => (
                        <TableRow key={group.id}>
                            <TableCell>{group.id}</TableCell>
                            <TableCell>{group.name}</TableCell>
                            <TableCell>{group.grade}</TableCell>
                            <TableCell>{group.description}</TableCell>
                            
                            <TableCell>
                                <DropdownMenu>
                                    <DropdownMenuTrigger>
                                        <EllipsisVertical className="h-4 w-4" />
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent>
                                        <DropdownMenuItem>권한수정</DropdownMenuItem>
                                        <DropdownMenuSeparator />
                                        <DropdownMenuItem>권한삭제</DropdownMenuItem>
                                    </DropdownMenuContent>
                                </DropdownMenu>

                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={groups ? Math.ceil(groups?.length / 20) : 1} totalNumber={groups?.length} listName="권한그룹"/>
         
        </div>
    );
};

export default ChatbotGroup;