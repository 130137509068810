import React, { useEffect, useState } from 'react'
import ChatSideBar from '../components/main/ChatSideBar'
import ChatMain from '../components/main/ChatMain'
import SideBarDoc from '../components/main/SideBarDoc'
import { VoiceRecordModal } from '../components/main/ChatInput';
import { useAppDispatch,useAppSelector } from '../lib/hooks';
import { 
  createNewChat,
  chatMessageGet,
  sidebarSelRoom,
  setChatRoomId
} from '../lib/features/chatMain/chatMainSlice';
import { selectToken } from "../lib/features/auth/authSlice";
import Header from '../components/dashboard/Header';
import ChatHistory from '../components/main/ChatHistory';

function ChatPage() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const token = useAppSelector(selectToken)
  const dispatch = useAppDispatch();

  useEffect(() => {
    // 새 대화를 생성하고 해당 대화로 이동
    const handleMenuClick = () => {
      dispatch(setChatRoomId(0));
      dispatch(sidebarSelRoom([0, 0]));
      dispatch(chatMessageGet({ room_id: 0, token: token }));
    }
    // handleMenuClick();
  }, []);

  return (
    <div className="min-h-screen-patched flex w-full flex-col bg-white sm:flex-row">
       <ChatSideBar isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen}/>
       <ChatHistory />

       
    </div>
  )
}

export default ChatPage
