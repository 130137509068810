import React from 'react';
import './header.css';
import './Logo';
import Logo from './Logo';
import SearchBar from './SearchBar';
import Nav from './Nav';
import { useNavigate } from 'react-router-dom';
// import i18n from 'i18next';
// import { useTranslation, initReactI18next } from 'react-i18next';

// const resources = {
//     en: {
//       translation: {
//         greeting: "Hello",
//         changeLanguage: "Change Language"
//       }
//     },
//     ko: {
//       translation: {
//         greeting: "안녕하세요",
//         changeLanguage: "언어 변경"
//       }
//     }
//   };

// i18n
//   .use(initReactI18next) // i18n을 react에 바인딩
//   .init({
//     resources,
//     lng: "en", // 기본 언어
//     keySeparator: false, 
//     interpolation: {
//       escapeValue: false // React는 자동으로 XSS를 방지
//     }
//   });

function Header({isOpen, setIsOpen}) {
    const navigate = useNavigate();
    // const { t } = useTranslation(); 

    // const changeLanguage = (lng) => {
    //     i18n.changeLanguage(lng);
    // };

    return (
        <header 
            id='header' 
            className={`header fixed left-0 right-0 bg-white shadow-md`}
        >
            <div className="flex items-center justify-between py-4 px-0">
                <Logo isOpen={isOpen} setIsOpen={setIsOpen} />
                <div className="flex items-center space-x-4">
                    <Nav />
                </div>
            </div>
        </header>
    );
}

export default Header;