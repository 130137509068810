import React from "react";

const Pagination = ({ currentPage, setCurrentPage, totalPages, totalNumber, listName }: { currentPage: number, setCurrentPage: (page: number) => void, totalPages: number, totalNumber: number, listName: string }) => {
    const getPageNumbers = () => {
        const delta = 2; // 현재 페이지 기준 좌우로 보여줄 페이지 수
        const range = [];
        const rangeWithDots = [];

        for (
            let i = Math.max(2, currentPage - delta);
            i <= Math.min(totalPages - 1, currentPage + delta);
            i++
        ) {
            range.push(i);
        }

        if (currentPage - delta > 2) {
            rangeWithDots.push(1, '...');
        } else {
            rangeWithDots.push(1);
        }

        rangeWithDots.push(...range);

        if (currentPage + delta < totalPages - 1) {
            rangeWithDots.push('...', totalPages);
        } else if (totalPages > 1) {
            rangeWithDots.push(totalPages);
        }

        return rangeWithDots;
    };
    // console.log({totalNumber, currentPage, totalPages, listName});

    return (
        <div className="flex justify-center py-4">
            <div>
                <p className="text-nowrap text-sm text-gray-700">
                <span className="font-medium">Total {listName}: {totalNumber} Entries</span> 
                </p>
            </div>
            <div className="flex justify-center w-full">
                <nav
                    className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                    aria-label="Pagination"
                >
                    <button
                        onClick={() => setCurrentPage(Math.max(1, currentPage - 1))}
                        className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                    >
                        이전
                    </button>
                    {getPageNumbers().map((number, index) => (
                        <button
                            key={index}
                            onClick={() => setCurrentPage(number as number)}
                            className={`relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium ${currentPage === number
                                ? "z-10 bg-indigo-50 border-indigo-500 text-indigo-600"
                                : "text-gray-500 hover:bg-gray-50"
                                }`}
                        >
                            {number}
                        </button>
                    ))}
                    <button
                        onClick={() => setCurrentPage(Math.min(totalPages, currentPage + 1))}
                        className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                    >
                        다음
                    </button>
                </nav>
            </div>
        </div>
    );
};

export default Pagination;