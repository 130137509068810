import React, { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../lib/hooks";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../ui/table";
import * as XLSX from "xlsx";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DropdownMenuSub,
  DropdownMenuSubTrigger,
  DropdownMenuSubContent,
} from "../../ui/dropdown-menu";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../ui/dialog";

import {
  Plus,
  Search,
  Filter,
  EllipsisVertical,
  MoreVertical,
  Check,
  X,
  Trash2,
  UserPlus,
  Users,
  Download,
  Upload,
} from "lucide-react";
import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import Pagination from "../Common/Pagination";
import {
  adminFileKeywordUpload,
  adminKeywordAdd,
  adminKeywordDelete,
} from "../../../lib/features/admin/adminSlice";

const ITEMS_PER_PAGE = 20;
const APP_STAGE = process.env.REACT_APP_STAGE;

const KeywordTable = ({
  keyworddatas,
  token,
  adminKeywordListChange,
}: {
  keyworddatas: any;
  token: any;
  adminKeywordListChange: any;
}) => {
  const dispatch = useAppDispatch();
  const [fileData, setFileData] = useState(null);
  const [parsedData, setParsedData] = useState<any>([]);
  const [fileName, setFileName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [newKeywordForm, setNewKeywordForm] = useState({
    text: "",
  });
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedKeywordId, setSelectedKeywordId] = useState<any>(null);

  const [KeywordDataForm, setKeywordDataForm] = useState(
    keyworddatas.map((keyword: any) => ({
      id: keyword.keyword_id,
      text: keyword.keyword_text,
    })) || []
  );

  const [sortConfig, setSortConfig] = useState({ key: "id", direction: "asc" });

  const handleAddKeyword = () => {
    if (newKeywordForm.text) {
      dispatch(
        adminKeywordAdd({
          token,
          keyword: newKeywordForm.text,
          // chatbotId: chatbots[0]?.chatbot_id,
        })
      ).then(() => {
        alert("추가 되었습니다!!");
        setNewKeywordForm({
          ...newKeywordForm,
          text: "",
        });
        adminKeywordListChange();
      });
    }
  };

  function formatDateTime(dateTimeString: string) {
    if (dateTimeString.length !== 14) {
      return "잘못된 입력 형식입니다.";
    }

    const year = dateTimeString.slice(0, 4);
    const month = dateTimeString.slice(4, 6);
    const day = dateTimeString.slice(6, 8);
    const hour = dateTimeString.slice(8, 10);
    const minute = dateTimeString.slice(10, 12);
    const second = dateTimeString.slice(12, 14);

    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  }
  // 전체 상태 정리
  // 활성, 잠김(이메일, 승인안됨, 비번오류, 이상감지)
  function getStatus(user: any) {
    if (user.is_deleted) {
      return "탈퇴";
    } else if (!user.is_verified || !user.has_email_check || user.is_locked) {
      return "잠김";
    } else {
      return "활성";
    }
  }
  useEffect(() => {
    setKeywordDataForm(
      keyworddatas.map((keyword: any) => ({
        id: keyword.keyword_id,
        text: keyword.keyword_text,
      })) || []
    );
  }, [keyworddatas]);

  const sortedKeywordData = useMemo(() => {
    return [...KeywordDataForm].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key])
        return sortConfig.direction === "asc" ? -1 : 1;
      if (a[sortConfig.key] > b[sortConfig.key])
        return sortConfig.direction === "asc" ? 1 : -1;
      return 0;
    });
  }, [KeywordDataForm, sortConfig]);

  const totalPages = Math.ceil(sortedKeywordData.length / ITEMS_PER_PAGE);
  const paginatedKeywordData = sortedKeywordData.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const handleDelete = (keyword_id: any) => {
    dispatch(
      adminKeywordDelete({
        token,
        keyword_id,
      })
    ).then(() => {
      adminKeywordListChange();
      alert("삭제 completed successfully!");
    });
  };

  const handleFileChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name); // 파일 이름 저장
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target) {
          const binaryStr = event.target.result;
          const workbook = XLSX.read(binaryStr, { type: "binary" });
          const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData = XLSX.utils.sheet_to_json(firstSheet);
          setParsedData(jsonData); // 파싱된 데이터 저장
        } else {
          if (APP_STAGE === "DEV") {    
            console.log("event.target null");
          }
        }
      };
      reader.readAsBinaryString(file); // 파일 읽기
      setFileData(file); // 파일 저장
    }
  };

  const handleFileUpload = (e: any) => {
    if (!fileData) return;

    const formData = new FormData();
    formData.append("files", fileData);
    //   formData.append("chatbotId", chatbots[0]?.chatbot_id)
    dispatch(
      adminFileKeywordUpload({
        token,
        fileData: formData,
        //   chatbotId: chatbots[0]?.chatbot_id,
      })
    ).then(() => {
      setFileName("");
      setFileData(null);

      adminKeywordListChange();
      alert("추가 되었습니다!!");
    });
  };

  const handleFileDownload = () => {
    const transformedData = paginatedKeywordData.map((keyword) => ({
      KEYWORD: keyword.text,
    }));
    const worksheet = XLSX.utils.json_to_sheet(transformedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Keywords");
    XLSX.writeFile(workbook, "Keywords.xlsx");
  };

  const handleSort = (key: string) => {
    setSortConfig((currentConfig) => {
      if (currentConfig.key === key) {
        return {
          ...currentConfig,
          direction: currentConfig.direction === "asc" ? "desc" : "asc",
        };
      }
      return { key, direction: "asc" };
    });
  };

  const deleteKeywordBtn = (keyword_id: any) => {
    setSelectedKeywordId(keyword_id);
    setIsDialogOpen(true);
  };

  if (APP_STAGE === "DEV") {  
    console.log({ KeywordDataForm });
    console.log({ paginatedKeywordData });
  }

  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden p-4">
      <div className="border-b flex justify-between items-center">
        <h2 className="text-2xl font-semibold">Keyword 데이터 목록</h2>
        <div className="flex items-center gap-2">
          <Dialog>
            <DialogTrigger asChild>
              <button
                onClick={() => {}}
                className="flex items-center gap-2 px-4 py-2 mb-2 bg-blue-500 text-sm text-white rounded-full bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
              >
                <Plus className="h-4 w-4" /> Add
              </button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>새 Keyword 추가</DialogTitle>
                <DialogDescription>
                  새로운 Keyword의 정보를 입력하세요.
                </DialogDescription>
              </DialogHeader>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  const formData = new FormData(e.currentTarget);
                  // addGroup(formData.get('name'), formData.get('description'))
                }}
              >
                <div className="grid gap-2 py-2">
                  <div className="flex items-center gap-4">
                    <Input
                      label="키워드"
                      type="text"
                      id="text"
                      placeholder="키워드"
                      value={newKeywordForm.text}
                      onChange={(e) =>
                        setNewKeywordForm({
                          ...newKeywordForm,
                          text: e.target.value,
                        })
                      }
                      isNoSpinner={true}
                    />
                  </div>
                </div>
                <DialogFooter>
                  <button
                    type="submit"
                    onClick={() => {
                      handleAddKeyword();
                    }}
                    className="flex items-center gap-2 px-4 py-2 mb-0 bg-blue-500 text-sm text-white rounded-md bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
                  >
                    Keyword 추가
                  </button>
                </DialogFooter>
              </form>
            </DialogContent>
          </Dialog>
          <Dialog>
            <DialogTrigger asChild>
              <button
                onClick={() => {}}
                className="flex items-center gap-2 px-4 py-2 mb-2 bg-blue-500 text-sm text-white rounded-full bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
              >
                <Upload className="h-4 w-4" /> Upload
              </button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>새 Keyword 파일 추가</DialogTitle>
                <DialogDescription>
                  새로운 파일 Keyword의 정보를 입력하세요.
                </DialogDescription>
              </DialogHeader>

              <div className="grid gap-2 py-2">
                <label
                  htmlFor="file-upload"
                  className="block text-sm font-medium text-gray-700"
                >
                  Upload Excel File
                </label>
                {/* 파일 선택 */}
                <input
                  id="file-upload"
                  type="file"
                  accept=".xlsx, .xls"
                  multiple={false}
                  onChange={handleFileChange} // 파일 선택 시 호출되는 함수
                  className="mt-1 block w-full text-sm text-gray-500
          file:mr-4 file:py-2 file:px-4
          file:rounded-md file:border-0
          file:text-sm file:font-semibold
          file:bg-blue-50 file:text-blue-700
          hover:file:bg-blue-100"
                />
                {/* 선택한 파일 이름 표시 */}
                {fileName && <p>Selected File: {fileName}</p>}
              </div>
              <DialogFooter>
                <button
                  type="submit"
                  onClick={() => handleFileUpload}
                  className="flex items-center gap-2 px-4 py-2 mb-0 bg-blue-500 text-sm text-white rounded-md bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
                >
                  Keyword 추가
                </button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
          <Dialog>
            <DialogTrigger asChild>
              <button
                onClick={() => {}}
                className="flex items-center gap-2 px-4 py-2 mb-2 bg-blue-500 text-sm text-white rounded-full bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
              >
                <Download className="h-4 w-4" /> Download
              </button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Keyword 파일 다운로드</DialogTitle>
                <DialogDescription>
                  Keyword 파일 다운로드를 하시겠습니까?
                </DialogDescription>
              </DialogHeader>
              <div className="flex justify-center items-center p-6">
                <div className="text-gray-700 text-center">
                  <p className="mb-4">
                    현재 Keyword 파일을 다운로드 할 수 있습니다.
                  </p>
                </div>
              </div>
              <DialogFooter className="flex justify-end space-x-4">
                <button onClick={() => handleFileDownload()}>다운로드</button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </div>
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            {["id", "text"].map((key) => (
              <TableHead
                key={key}
                className="px-6 py-2 text-left text-xs text-nowrap font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                onClick={() => handleSort(key)}
              >
                {key === "id" ? "NO." : key === "text" ? "TEXT" : ""}
                {sortConfig.key === key && (
                  <span className="ml-1">
                    {sortConfig.direction === "asc" ? "▲" : "▼"}
                  </span>
                )}
              </TableHead>
            ))}
            <TableHead>작업</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {paginatedKeywordData &&
            paginatedKeywordData.map((keyword) => (
              <TableRow key={keyword.id}>
                <TableCell>{keyword.id}</TableCell>
                <TableCell>{keyword.text}</TableCell>
                <TableCell>
                  <DropdownMenu>
                    <DropdownMenuTrigger>
                      <EllipsisVertical className="h-4 w-4" />
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                      <DropdownMenuItem
                        onClick={() => deleteKeywordBtn(keyword.id)}
                      >
                        삭제
                      </DropdownMenuItem>
                      {/* <DropdownMenuItem>대화내역</DropdownMenuItem>
                      <DropdownMenuItem>권한변경</DropdownMenuItem>
                      <DropdownMenuItem>회사변경</DropdownMenuItem> */}
                      {/* <DropdownMenuSub>
                                            <DropdownMenuSubTrigger>권한변경</DropdownMenuSubTrigger>
                                            <DropdownMenuSubContent>
                                                <DropdownMenuItem>일반 설정</DropdownMenuItem>
                                                <DropdownMenuItem>고급 설정</DropdownMenuItem>
                                            </DropdownMenuSubContent>
                                        </DropdownMenuSub> */}
                      {/* <DropdownMenuSeparator /> */}
                      {/* <DropdownMenuItem>계정잠금</DropdownMenuItem> */}
                    </DropdownMenuContent>
                  </DropdownMenu>
                </TableCell>
              </TableRow>
            ))}
          {isDialogOpen && (
            <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
              <DialogTrigger asChild>
                <button
                  onClick={() => {}}
                  className="flex items-center gap-2 px-4 py-2 mb-2 bg-blue-500 text-sm text-white rounded-full bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
                >
                  <Download className="h-4 w-4" /> 삭제
                </button>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>삭제</DialogTitle>
                  <DialogDescription>삭제 하시겠습니까?</DialogDescription>
                </DialogHeader>
                <div className="flex justify-center items-center p-6">
                  <div className="text-gray-700 text-center">
                    <p className="mb-4">현재 Keyword을 삭제 할 수 있습니다.</p>
                  </div>
                </div>
                <DialogFooter className="flex justify-end space-x-4">
                  <button onClick={() => handleDelete(selectedKeywordId)}>
                    삭제
                  </button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          )}
        </TableBody>
      </Table>
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={
          keyworddatas ? Math.ceil(keyworddatas?.length / ITEMS_PER_PAGE) : 1
        }
        totalNumber={keyworddatas?.length}
        listName="Keyword Data"
      />
    </div>
  );
};

export default KeywordTable;
