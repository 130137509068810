import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import { useNavigate } from 'react-router-dom';
import { authLogout, deleteUser, passwordChange, passwordReminder, selectToken, selectUser, userMe } from "../../lib/features/auth/authSlice";
import { Input } from "../ui/input";
import { ArrowRightIcon } from "lucide-react";
function PasswordChange() {
  const [password, setPassword] = useState("");
  const token = useAppSelector(selectToken);
  const user = useAppSelector(selectUser);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);

  const formatter = new Intl.DateTimeFormat('ko-KR', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
    timeZone: 'Asia/Seoul'
  });

  const parts = formatter.formatToParts(new Date());
  const formattedCurrentTime = `${parts[0].value}${parts[2].value}${parts[4].value}${parts[6].value}${parts[8].value}${parts[10].value}`;

  // console.log(formattedCurrentTime);

  const [condition, setCondition] = useState({
    isFirst: user.pw_updated_at==="None" ? true: false,
    isExpired: user.pw_expiry_at<formattedCurrentTime ? true: false,
    reminderCount: user.pw_reminder_count,
  });

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  // Validate password and confirm password
  const validatePasswords = () => {
    if (password.length < 8){
      setError("Password must be at least 8 characters long.");
      return false;
    }
    const hasLetter = /[a-zA-Z]/.test(password); // 영어 문자가 포함되어 있는지
    const hasNumber = /\d/.test(password);       // 숫자가 포함되어 있는지

    if (!hasLetter || !hasNumber) {
      setError("Password must contain at least one letter and one number.");
      return false;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match!");
      return false;
    }

    return true;
  };

  // Handle password change submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null); // Reset error state

    if (!validatePasswords()) {
      return;
    }
    try {
      console.log({token});
      dispatch(passwordChange({ password, token })).then(() => {
        console.log({token});
        dispatch(userMe(token)).then(() => {
          navigate("/");
        });        
      });
    } catch (error) {
      setError("비밀번호 변경 실패");
    }
  };
  const handleReminder = async (e) => {
    e.preventDefault();
    await dispatch(passwordReminder(token));
    await dispatch(userMe(token)).then(() => {
      navigate("/");
    });
  }

  console.log({user,condition, first: user.pw_updated_at, expired: user.pw_expiry_at, last: user.login_last_at});
  console.log({condition});
  return (
    <div className="flex flex-col items-center justify-center h-screen w-full">
        <div className="LoginContainer flex flex-col items-center justify-center h-screen w-1/2">
        <h3 className="text-2xl font-bold mb-4">Password Change</h3>
        <h4 className="text-sm mb-4">
          {condition.isFirst ? "Welcome! To start using your account, please change your password." : 
          condition.isExpired ? 
          condition.reminderCount >= 2 ? <span className="text-center">Your password has expired. <br/>You have delayed changing it twice. Please update your password now to regain access.</span> 
          : <span className="text-center">Your password is expired. <br/>Please change your password.</span> : ""}
        </h4>
        <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <Input
                  label="New Password"
                  type="password"
                  className="form-control"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
              />
            </div>
            <div className="mb-3">
            {/* <label htmlFor="confirmPassword" className="form-label">
                Confirm Password
            </label> */}
            <Input
                label="Confirm Password"
                type="password"
                className="form-control"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
            />
            </div>
            {error && <p className="text-danger">{error}</p>}
            
            {
            condition.isFirst ? <button
              type="submit"
              className="flex justify-end items-center w-full text-center px-4 py-3 mb-2 bg-blue-500 text-sm text-white rounded-lg bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors gap-4"
              >
              Change Password
              <ArrowRightIcon className="w-4 h-4" />
              </button> 
            :condition.isExpired ? 
            condition.reminderCount >= 2 ? 
            <div>
              <button
                type="submit"
                className="flex justify-end items-center w-full text-center px-4 py-3 mb-2 bg-blue-500 text-sm text-white rounded-lg bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors gap-4"
                >
                Change Password
                <ArrowRightIcon className="w-4 h-4" />
                </button> 
            </div>
              : <div>
                  <button
                    type="submit"
                    className="flex justify-end items-center w-full text-center px-4 py-3 mb-2 bg-blue-500 text-sm text-white rounded-lg bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors gap-4"
                    >
                    Change Password
                    <ArrowRightIcon className="w-4 h-4" />
                    </button> 
                    <button
                      type="button"
                      onClick={handleReminder}
                      className="flex justify-end items-center w-full text-center px-4 py-3 mb-2 bg-gray-500 text-sm text-gray-800 rounded-lg bg-gradient-to-br from-gray-300 to-gray-300 hover:bg-blue-600 transition-colors gap-4"
                      >
                      Reminder after 3 months
                  </button> 
                </div>
            : ""}
        </form>
      </div>
    </div>
  );
}

export default PasswordChange;
