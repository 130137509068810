import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { authLogin } from "../../lib/features/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import {
  selectUser,
  selectToken,
  selectStatus,
  selectErrorMsg,
} from "../../lib/features/auth/authSlice";
import { Input } from "../ui/input";

const APP_STAGE = process.env.REACT_APP_STAGE;

const Login = (props) => {
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const [idError, setIdError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [generalError, setGeneralError] = useState(""); // General error message

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const status = useAppSelector(selectStatus);
  const errorMsg = useAppSelector(selectErrorMsg);
  const user = useAppSelector(selectUser);
  const token = useAppSelector(selectToken);

  useEffect(() => {
    if (errorMsg) {
      setGeneralError(errorMsg);
    }
  }, [errorMsg]);

  const onButtonClick = () => {
    setIdError("");
    setPasswordError("");
    setGeneralError("");

    if ("" === id) {
      setIdError("Please enter your IMO No.");
      return;
    }

    // if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(id)) {
    //   setIdError("Please enter a valid IMO No.");
    //   return;
    // }

    if ("" === password) {
      setPasswordError("Please enter a password");
      return;
    }

    if (password.length < 4) {
      setPasswordError("The password must be 4 characters or longer");
      return;
    }

    // Dispatching login action
    dispatch(authLogin({ id: id, password: password }))
      .unwrap()
      .then((response) => {
        // Handle successful login
        if (APP_STAGE === "DEV") {  
          console.log({ group_id: response.user.group_id });
        }
        // if (response.user.group_id == 1) {
        //   navigate("/dashboard");
        //   return;
        // }
        navigate("/menu");
      })
      .catch((error) => {
        // Handle login failure
        setGeneralError("Login failed: Incorrect email or password");
      });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onButtonClick();
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen w-full max-w-sm">
      <div className="text-5xl font-bold mb-6">Login</div>
      <div className="w-full max-w-md">
        <div className=" w-full max-w-md gap-2 mb-4">
          <Input
            label="IMO No."
            type="text"
            value={id}
            placeholder="Enter your IMO No. here"
            onChange={(ev) => setId(ev.target.value)}
            onKeyDown={handleKeyPress}
            className="inputBox w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
            isNoSpinner={true}
          />
          {idError && (
            <label className="errorLabel text-red-500 text-xs mt-2">
              {idError}
            </label>
          )}


          <Input
            label="password"
            type="password"
            value={password}
            placeholder="Enter your password here"
            onChange={(ev) => setPassword(ev.target.value)}
            onKeyDown={handleKeyPress}
            className="inputBox w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
            isNoSpinner={true}
          />
          {passwordError && (
            <label className="errorLabel text-red-500 text-xs mt-2">
              {passwordError}
            </label>
          )}
        </div>
        {generalError && (
          <div className="errorContainer w-full max-w-md text-center text-red-500 text-sm mb-4">
            {generalError}
          </div>
        )}
        <div className="mt-1">
          <button
            className=" w-full px-4 py-3 mb-2 text-sm text-white rounded-lg bg-gradient-to-br from-cyan-500 to-blue-600 hover:from-cyan-700 hover:to-blue-700 hover:shadow-lg transition-colors "
            onClick={onButtonClick}
          >
            Log in
          </button>
        </div>
      </div>

      <div className="linkContainer w-full max-w-md flex justify-between mt-6 text-blue-500 underline cursor-pointer text-sm">
        {/* <div onClick={() => props.mvPage("signup")}>Create account?</div> */}
        <div onClick={() => props.mvPage("user-service")}>Sign up request</div>
        <div onClick={() => props.mvPage("findpw")}>Forgot the password?</div>
      </div>
    </div>
  );
};

export default Login;
