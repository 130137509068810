import React, { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../lib/hooks"
import {
  adminUserAllGet,
  adminApilogs,
  adminFeedbackPlatform,
  adminFeedbackMessage,
  selectDashboardApiLog,
  selectDashboardFeedbackPlatform,
  selectDashboardFeedbackMessage,
  selectDashboardUser,
} from "../../../lib/features/admin/adminSlice"
import { selectToken, selectStatus } from "../../../lib/features/auth/authSlice"
import { Tabs, TabsContent, TabsTrigger } from "../../ui/tabs"
import DashboardTabs from "../Common/DashboardTabs"
import PlatformFeedbackTable from "../FeedbackPage/PlatformFeedbackTable"
import MessageFeedbackTable from "../FeedbackPage/MessageFeedbackTable"

const ITEMS_PER_PAGE = 10
const APP_STAGE = process.env.REACT_APP_STAGE;
export default function AdminFeedbackPage() {
  const dispatch = useAppDispatch();
  const token = useAppSelector(selectToken);
  const feedbacksPlatform = useAppSelector(selectDashboardFeedbackPlatform);
  const feedbacksMessage = useAppSelector(selectDashboardFeedbackMessage);
  const [tabValue, setTabValue] = useState("platformFeedback");

  useEffect(() => {
    if(tabValue === "platformFeedback"){
      dispatch(adminFeedbackPlatform({ token }));
    }
    if(tabValue === "chatbotFeedback"){
      dispatch(adminFeedbackMessage({ token }));
    }
  }, [dispatch, token]);

  useEffect(() => {
    if(tabValue === "platformFeedback"){
      dispatch(adminFeedbackPlatform({ token }));
    }
    if(tabValue === "chatbotFeedback"){
      dispatch(adminFeedbackMessage({ token }));
    }
  }, [tabValue]);

  const tabData = [
    {tabName: "platformFeedback", name: "PLATFORM", notifications: 0},
    {tabName: "chatbotFeedback", name: "CHATBOT", notifications: 0},
    // {tabName: "groups", name: "권한그룹", notifications: 0},
    // {tabName: "company", name: "회사그룹", notifications: 0},
    // {tabName: "request", name: "가입신청", notifications: 1},
  ];
  if (APP_STAGE === "DEV") {  
    // console.log({apilogs})
  }
  return (
    <div className="w-full p-0 mx-0">
      <h1 className="text-2xl font-bold mb-6">FEEDBACK DASHBOARD</h1>
      <Tabs defaultValue="platformFeedback">
        <DashboardTabs data={tabData} tabValue={tabValue} setTabValue={setTabValue}/>
        <TabsContent value="platformFeedback">
          <PlatformFeedbackTable feedbacksPlatform={feedbacksPlatform?.feedbacks || []}  />
        </TabsContent>
        <TabsContent value="chatbotFeedback">
          <MessageFeedbackTable feedbacksMessage={feedbacksMessage?.feedbacks || []}  />
        </TabsContent>
        {/* <TabsContent value="groups">
          <GroupTable groups={groups || []}  />
        </TabsContent>
        <TabsContent value="company">
          <CompanyTable companies={companys || []}  />
        </TabsContent>
        <TabsContent value="request">
          <SignUpRequestTable signUpRequests={signUpRequests || []}  />
        </TabsContent> */}
      </Tabs>

    </div>
  )
}
