import React from 'react'

import {
    sidebarSel
} from "../../lib/features/admin/adminSlice";
import {
    sidebarSelRoom
} from "../../lib/features/chatMain/chatMainSlice";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";

// function NavItem(props) {
//     const item = props.item;
//     const key = props.parentKey ? props.parentKey + '-' + props.index : String(props.index);

//     const dispatch = useAppDispatch();
//     const handleMenuClick = (index_1, index_2) => {
//         dispatch(sidebarSel([index_1, index_2]))
//     }
//     return (
//         <>
//             <li className="nav-heading">{item.label}</li>
//             {item.items.map((menu, sub_idx) =>
//                 <li className="nav-item" onClick={()=>handleMenuClick(props.index, sub_idx)}>
//                     <a className="nav-link collapsed" href="#">
//                         <i className={menu.icon}></i>
//                         <span>{menu.label}</span>
//                     </a>
//                 </li>
//             )}
//         </>
//     )
// }

function NavItem({ item, index }) {
    const dispatch = useAppDispatch();
  
    const handleMenuClick = (index_1, index_2) => {
      dispatch(sidebarSel([index_1, index_2]));
    //   dispatch(sidebarSelRoom([index_1, index_2]));
    };
  
    return (
      <div className="relative mt-5 first:mt-0 last:mb-5">
            <div className="sticky top-0 z-20 bg-token-sidebar-surface-primary">
                <span className="flex h-9 items-center">
                    <h3 className="pb-2 pt-3 px-2 text-xs font-semibold text-ellipsis overflow-hidden break-all text-token-text-primary  uppercase">
                      {item.label}
                    </h3>
                </span>
            </div>
            <ol className="pl-2">
                {
                    item.items && item.items.map((menu, sub_idx) =>
                        <li 
                          key={`${index}-${sub_idx}`}
                          className="nav-item relative bg-gradient-to-br hover:from-gray-200 hover:to-gray-50 transition-colors">
                            <div className="group relative rounded-lg active:opacity-90 hover:bg-token-sidebar-surface-secondary  uppercase">
                                <a 
                                  className="nav-link collapsed flex items-center gap-2 p-2" 
                                  onClick={() => handleMenuClick(index, sub_idx)}>
                                    <span>{menu.label}</span>
                                </a>
                            </div>

                        </li>
                    )
                }
             </ol>
      </div>

    );
  }
      {/* <>
        <li className="nav-heading">{item.label}</li>
        {item.items && item.items.map((menu, sub_idx) => (
          <li key={`${index}-${sub_idx}`} className="nav-item" onClick={() => handleMenuClick(index, sub_idx)}>
            <a className="nav-link collapsed" href={menu.href || '#'}>
              <i className={menu.icon}></i>
              <span>{menu.label}</span>
            </a>
          </li>
        ))}
      </> */}
export default NavItem;
