import React, { useEffect, useMemo, useState } from "react";

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../ui/table"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
    DropdownMenuSub,
    DropdownMenuSubTrigger,
    DropdownMenuSubContent,
} from "../../ui/dropdown-menu"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../../ui/dialog"

import { Plus, Search, Filter, EllipsisVertical, MoreVertical, Check, X, Trash2, UserPlus, Users } from "lucide-react"
import { Button } from "../../ui/button"
import { Input } from "../../ui/input"
import Pagination from "../Common/Pagination";
import { Checkbox } from "../../ui/checkbox";
import { adminChatbotGroupUpdate, adminGroupAdd, adminGroupUpdate } from "../../../lib/features/admin/adminSlice";
import { useAppDispatch, useAppSelector } from "../../../lib/hooks";

const ITEMS_PER_PAGE = 20;
const APP_STAGE = process.env.REACT_APP_STAGE;

const ChatbotGroupTable = ({ chatbotGroups, userGroups, chatbotList }: { chatbotGroups: any, userGroups: any, chatbotList: any }) => {
    const dispatch = useAppDispatch();
    const token = useAppSelector((state) => state.authentication.token);

    const [currentPage, setCurrentPage] = useState(1);
    const [newChatbotGroupForm, setNewChatbotGroupForm] = useState({
        name: "",
    });
    const [modifySelectedChatbotGroup, setModifySelectedChatbotGroup] = useState(null)
    const [modifyChatbotGroupForm, setModifyChatbotGroupForm] = useState({
        groupId: "",
        groupName: "",
        chatbots: {
            "BWMS-TS": {
                id: "",
                name: ""
            },
            "BWMS-MT": {
                id: "",
                name: ""
            }
        }
    });
    const [chatbotCandList, setChatbotCandList] = useState(chatbotList.reduce((acc: any, item: any) => {
        let group = acc.find((g: any) => g.chatbot_type === item.chatbot_type);
        if (!group) {
          group = { chatbot_type: item.chatbot_type, chatbot_name: item.pgroup.pgroup_name, chatbots:[] };
          acc.push(group);
        }
      
        // chatbots 객체에 cgroup_type을 키로 하여 정보 추가
        if (item.chatbot_status === "ACTIVE") {
          group.chatbots.push({
            id: item.chatbot_id,
            pid: item.pgroup_id,
            pvid: item.pgroup_vid,
            name: item.pgroup.pgroup_name
          });
        }
      
        return acc;
      }, []) || []);
    const [chatbotForm, setChatbotForm] = useState(
        chatbotGroups.reduce((acc: any, item: any) => {
            // 그룹 ID를 기준으로 기존 그룹을 찾거나 새 그룹을 추가
            let group = acc.find((g: any) => g.groupId === item.group_id);
            if (!group) {
              group = { groupId: item.group_id, groupName: userGroups.find((ug: any) => ug.group_id === item.group_id)?.group_name, chatbots: {} };
              acc.push(group);
            }
            
            
            // chatbots 객체에 cgroup_type을 키로 하여 정보 추가
            if (item.chatbot_status === "ACTIVE") {
              group.chatbots[item.cgroup_type] = {
                id: item.chatbot_id,
              name: chatbotList.find((chatbot: any) => chatbot.chatbot_id === item.chatbot_id)?.pgroup.pgroup_name,
              pid: chatbotList.find((chatbot: any) => chatbot.chatbot_id === item.chatbot_id)?.pgroup_id,
                pvid: chatbotList.find((chatbot: any) => chatbot.chatbot_id === item.chatbot_id)?.pgroup_vid
              };
            }
            return acc;
          }, []) || []);
      
    const [isDialogOpen, setIsDialogOpen] = useState({
        modify: false,
        create: false,
        detail: false
    });
    const [sortConfig, setSortConfig] = useState({ key: "id", direction: "asc" });

    function formatDateTime(dateTimeString: string) {
        if (dateTimeString.length !== 14) {
          return "잘못된 입력 형식입니다.";
        }
    
        const year = dateTimeString.slice(0, 4);
        const month = dateTimeString.slice(4, 6);
        const day = dateTimeString.slice(6, 8);
        const hour = dateTimeString.slice(8, 10);
        const minute = dateTimeString.slice(10, 12);
        const second = dateTimeString.slice(12, 14);
    
        return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
      }
    // 전체 상태 정리
    // 활성, 잠김(이메일, 승인안됨, 비번오류, 이상감지)
    // function getStatus(group: any) {
    //     if (group.is_deleted) {
    //       return "탈퇴";
    //     } else if (!user.is_verified || !user.has_email_check || user.is_locked) {
    //       return "잠김";
    //     } else {
    //       return "활성";
    //     }
    // }
    useEffect(() => {
        setChatbotForm(
            chatbotGroups.reduce((acc: any, item: any) => {
                // 그룹 ID를 기준으로 기존 그룹을 찾거나 새 그룹을 추가
                let group = acc.find((g: any) => g.groupId === item.group_id);
                if (!group) {
                    group = { groupId: item.group_id, groupName: userGroups.find((ug: any) => ug.group_id === item.group_id)?.group_name, chatbots: {} };
                  acc.push(group);
                }
              
                // chatbots 객체에 cgroup_type을 키로 하여 정보 추가
                group.chatbots[item.cgroup_type] = {
                  id: item.chatbot_id,
                  name: chatbotList.find((chatbot: any) => chatbot.chatbot_id === item.chatbot_id)?.pgroup.pgroup_name,
                  pid: chatbotList.find((chatbot: any) => chatbot.chatbot_id === item.chatbot_id)?.pgroup_id,
                  pvid: chatbotList.find((chatbot: any) => chatbot.chatbot_id === item.chatbot_id)?.pgroup_vid
                };
              
                return acc;
            }, []) || []
        );
      }, [chatbotGroups]);


    const sortedChatbots = useMemo(() => {
        return [...chatbotForm].sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key])
                return sortConfig.direction === "asc" ? -1 : 1;
            if (a[sortConfig.key] > b[sortConfig.key])
                return sortConfig.direction === "asc" ? 1 : -1;
            return 0;
        });
    }, [chatbotForm, sortConfig]);
    // const totalPages = Math.ceil(sortedGroups.length / ITEMS_PER_PAGE);
    const paginatedChatbots = sortedChatbots.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        currentPage * ITEMS_PER_PAGE
    );

    const handleSort: any = (key: string) => {
        setSortConfig((currentConfig) => {
            if (currentConfig.key === key) {
                return {
                    ...currentConfig,
                    direction: currentConfig.direction === "asc" ? "desc" : "asc",
                };
            }
            return { key, direction: "asc" };
        });
    };
  const handleChatbotChange = (cgroup_id: any, chatbotId: any, chatbotType: any) => {
    dispatch(
        adminChatbotGroupUpdate({
            token: token as string,
            cgroup_id: cgroup_id as number,
            chatbot_id: chatbotId as number,
            chatbot_type: chatbotType as string,
        })
    ).then(() => {
        // 성공 시 UI 업데이트를 위해 현재 폼 상태 업데이트
        setChatbotForm((prevForm: any) => {
            return prevForm.map((group: any) => {
                if (group.groupId === cgroup_id) {
                    // 선택된 그룹을 찾아 해당 챗봇 타입 정보 업데이트
                    const selectedChatbot = chatbotCandList
                        .find((c: any) => c.chatbot_type === chatbotType)?.chatbots
                        .find((c: any) => c.id === chatbotId);
                    
                    if (selectedChatbot) {
                        return {
                            ...group,
                            chatbots: {
                                ...group.chatbots,
                                [chatbotType]: {
                                    id: selectedChatbot.id,
                                    name: selectedChatbot.name,
                                    pid: selectedChatbot.pid,
                                    pvid: selectedChatbot.pvid
                                }
                            }
                        };
                    }
                }
                return group;
            });
        });
        
        // 수정 다이얼로그 상태도 업데이트
        if (modifySelectedChatbotGroup === cgroup_id) {
            const selectedChatbot = chatbotCandList
                .find((c: any) => c.chatbot_type === chatbotType)?.chatbots
                .find((c: any) => c.id === chatbotId);
                
            if (selectedChatbot) {
                setModifyChatbotGroupForm(prev => ({
                    ...prev,
                    chatbots: {
                        ...prev.chatbots,
                        [chatbotType]: {
                            id: selectedChatbot.id,
                            name: selectedChatbot.name
                        }
                    }
                }));
            }
        }
        
        alert("챗봇 정보가 업데이트되었습니다.");
    });
  }

    
  const handleSubmit = () => {
    // dispatch(
    //     adminGroupUpdate({
    //     token: token as string,
    //     groupId: chatbotForm.id as number,
    //     groupName: chatbotForm.name as string,
    //     // groupDesc: chatbotForm.description as string,
    //     // is_admin: chatbotForm?.is_admin,
    //     is_chatbot_ts: chatbotForm?.is_chatbot_ts,
    //     is_chatbot_mt: chatbotForm?.is_chatbot_mt,
    //     is_chatbot_gi: chatbotForm?.is_chatbot_gi,
    //     is_main_manual: permissionForm?.is_main_manual,
    //     is_main_video: permissionForm?.is_main_video,
    //   })
    // ).then(() => {
    //   // userAllChange();
    //   // adminUserSignUpRequestGetChange();
    //   alert("Group information has been updated.");
    //   // setUserNm("");
    //   // setSelectedCompany(undefined);
    //   setIsDialogOpen((prev) => ({ ...prev, ["mod"]: false }));
    // });

    // setIsDialogOpen((prev) => ({ ...prev, ["su"]: false })); // Close the dialog after submitting
  };

  const handleSubmitCreate = () => {
    // dispatch(
    //     adminGroupAdd({
    //     token: token as string,
    //     groupName: permissionForm.name as string,
    //     groupDesc: permissionForm.description as string,
    //     is_admin: permissionForm?.is_admin,
    //     is_chatbot_ts: permissionForm?.is_chatbot_ts,
    //     is_chatbot_mt: permissionForm?.is_chatbot_mt,
    //     is_chatbot_gi: permissionForm?.is_chatbot_gi,
    //     is_main_manual: permissionForm?.is_main_manual,
    //     is_main_video: permissionForm?.is_main_video,
    //   })
    // ).then(() => {
    //   // userAllChange();
    //   // adminUserSignUpRequestGetChange();
    //   alert("Group information has been created.");
    //   setIsDialogOpen((prev) => ({ ...prev, ["create"]: false }));
    // });
  };
  if (APP_STAGE === "DEV") {  
  console.log({chatbotForm, chatbotCandList});
  }
  
    return (
        <div className="bg-white shadow-md rounded-lg overflow-hidden p-4">
            <div className="border-b flex justify-between items-center">
                <h2 className="text-2xl font-semibold mb-4">CHATBOT GROUPS</h2>
                <Dialog
                    open={isDialogOpen.modify}
                    onOpenChange={() => {setIsDialogOpen({ ...isDialogOpen, modify: false })
                    if (APP_STAGE === "DEV") {  
                      console.log({isDialogOpen})
                    }}}
                >
                    <DialogContent>
                        <DialogHeader>
                            <DialogTitle>Modify Group</DialogTitle>
                            <DialogDescription>Please enter the information for the modify group.</DialogDescription>
                        </DialogHeader>
                        <div className="grid grid-cols-4 grid-rows-auto gap-2 text-base ">
                            {[{ label: "Name", value: modifyChatbotGroupForm.groupName },
                            { label: "TroubleShooting", value: modifyChatbotGroupForm.chatbots["BWMS-TS"]?.name },
                            { label: "Maintenance", value: modifyChatbotGroupForm.chatbots["BWMS-MT"]?.name }].map((item) => (
                                <>
                                    <span className="font-semibold col-span-2">{item.label}</span>
                                    <span className="col-span-2">
                                        {/* {item.value ? item.value : "-"} */}
                                        {
                                        item.label === "TroubleShooting" || item.label === "Maintenance" ?
                                        <DropdownMenu>
                                            <DropdownMenuTrigger asChild>
                                            <span
                                                className={`col-span-3 size-fit ${item.value === "pending"
                                                ? "bg-yellow-500 text-white rounded-md px-2 py-1 text-sm"
                                                : item.value === "in progress"
                                                    ? "bg-blue-500 text-white rounded-md px-2 py-1 text-sm"
                                                    : item.value === "in trouble"
                                                    ? "bg-red-500 text-white rounded-md px-2 py-1 text-sm"
                                                    : "bg-green-500 text-white rounded-md px-2 py-1 text-sm"}`}
                                                onClick={item.label === "TroubleShooting" ? () => { } : () => { }}
                                            >
                                                {item.value ? item.value : "-"}
                                            </span>
                                            </DropdownMenuTrigger>
                                            <DropdownMenuContent>
                                            {item.label=="TroubleShooting" ?
                                                chatbotCandList.filter((chatbot: any) => chatbot.chatbot_type === "BWMS-TS")[0].chatbots.map((chatbot: any) => (
                                                    <DropdownMenuItem 
                                                        key={chatbot.id}
                                                        className='border-b border-gray-200'
                                                        onClick={() => {handleChatbotChange(modifyChatbotGroupForm.groupId, chatbot.id, "BWMS-TS")}}>
                                                        {chatbot.name} v.{chatbot.pvid} 
                                                    </DropdownMenuItem>
                                                ))
                                                :
                                                chatbotCandList.filter((chatbot: any) => chatbot.chatbot_type === "BWMS-MT")[0].chatbots.map((chatbot: any) => (
                                                    <DropdownMenuItem 
                                                        key={chatbot.id}
                                                        className='border-b border-gray-200'
                                                        onClick={() => {handleChatbotChange(modifyChatbotGroupForm.groupId, chatbot.id, "BWMS-MT")}}>
                                                        {chatbot.name} v.{chatbot.pvid} 
                                                    </DropdownMenuItem>
                                                ))
                                            }
                                            </DropdownMenuContent>
                                        </DropdownMenu>
                                        :
                                        <span>{item.value ? item.value : "-"}</span>
                                        }
                                    </span>
                                  
                                </>
                              ))}
                        </div>
                        <DialogFooter>
                            {/* <button
                                type="submit"
                                onClick={()=>{}}
                                className="flex items-center gap-2 px-4 py-2 mb-0 bg-blue-500 text-sm text-white rounded-md bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
                            >Modify Group</button> */}
                        </DialogFooter>
                    </DialogContent>
                </Dialog>
                </div>
                <Table>
                <TableHeader>
                    <TableRow>
                        {["id", "groupName", "TroubleShooting", "Maintenance"].map((key) => (
                            <TableHead
                                key={key}
                                className="px-6 py-2 text-left text-xs text-nowrap font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                onClick={() => handleSort(key)}
                            >
                                {key === "id"
                                    ? "No."
                                    : key === "groupName"
                                        ? "GroupName"
                                        : key === "TroubleShooting"
                                        ? "TroubleShooting"
                                        : key === "Maintenance"
                                        ? "Maintenance"
                                        : ""}
                                {sortConfig.key === key && (
                                    <span className="ml-1">
                                        {sortConfig.direction === "asc" ? "▲" : "▼"}
                                    </span>
                                )}
                            </TableHead>
                        ))}
                        <TableHead>action</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {paginatedChatbots && paginatedChatbots.map((chatbot, idx) => (
                        <TableRow key={chatbot.id}>
                            <TableCell>{chatbot.groupId}</TableCell>
                            <TableCell>{chatbot.groupName}</TableCell>
                            <TableCell><span className="text-xs border border-gray-300 rounded-full px-2 py-1">{chatbot.chatbots["BWMS-TS"] ? chatbot.chatbots["BWMS-TS"].name : ""}</span>  {chatbot.chatbots["BWMS-TS"] ?  <span className="text-xs border border-gray-300 rounded-full px-2 py-1"> V.{chatbot.chatbots["BWMS-TS"].pvid} </span> : ""} </TableCell>
                            <TableCell><span className="text-xs border border-gray-300 rounded-full px-2 py-1">{chatbot.chatbots["BWMS-MT"] ? chatbot.chatbots["BWMS-MT"].name : ""}</span>  {chatbot.chatbots["BWMS-MT"] ?  <span className="text-xs border border-gray-300 rounded-full px-2 py-1"> V.{chatbot.chatbots["BWMS-MT"].pvid} </span> : ""} </TableCell>
                            <TableCell>
                                <DropdownMenu>
                                    <DropdownMenuTrigger>
                                        <EllipsisVertical className="h-4 w-4" />
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent>
                                        <DropdownMenuItem
                                            onClick={() =>{
                                                // setPermissionForm(group)
                                                setModifyChatbotGroupForm(chatbot)
                                                setModifySelectedChatbotGroup(chatbot.groupId)
                                                setIsDialogOpen({ ...isDialogOpen, modify: true }) 
                                            }}
                                        >Modify</DropdownMenuItem>
                                    </DropdownMenuContent>
                                </DropdownMenu>

                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={chatbotForm ? Math.ceil(chatbotForm?.length / 20) : 1} totalNumber={chatbotForm?.length} listName="Chatbot Group"/>
         
        </div>
    );
};

export default ChatbotGroupTable;
