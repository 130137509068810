import React from 'react'
import ChatSideBar from '../components/main/ChatSideBar'
import PDFFileViewer from '../components/main/PDFFileViwer'
function PDFPage() {
  return (
    <div className="min-h-screen-patched flex w-full flex-col bg-white sm:flex-row">
       <ChatSideBar isOpen={true} setIsOpen={()=>{}}/>
       <PDFFileViewer />
    </div>
  )
}

export default PDFPage
