import React, { useEffect, useState } from 'react'
import {
setDocViewer,
selectStreamStatus,
selectDocViewer,
} from "../../../lib/features/chatMain/chatMainSlice";
import { useAppDispatch, useAppSelector } from "../../../lib/hooks";
import StreamLoading from '../../main/StreamLoading';

const APP_STAGE = process.env.REACT_APP_STAGE;

function MessageWait(props) {
    const {type, isBot, text, sendTime} = props.model
    const dispatch = useAppDispatch();
    const streamStatus = useAppSelector(selectStreamStatus)
    const [currentStatus, setCurrentStatus] = useState(streamStatus)

    const handleClickDocView = (url, page) => {
        dispatch(setDocViewer({
            docUrl: url,
            docPage: page
        }));
    }
    // console.log("MessageWait", streamStatus)
    useEffect(()=>{
        if (APP_STAGE === "DEV") {  
            console.log("Stream status changed:", streamStatus);
        }
        setCurrentStatus(streamStatus)
    },[streamStatus])
    return (
    <>
    {isBot?
        <div className="massageBox-bot">
            {/* <Spinner /> */}
            <StreamLoading streamStatus={currentStatus}/>
            {/* <div className="sendTime">{sendTime}</div> */}
        </div>
    :
        <div className="massageBox-me">
            <div className="messageText">
                {text}
            </div>
            <div className="sendTime">{sendTime}</div>
        </div>
    }   
    </>
  )
}

function Spinner() {
    return (
        <div className="spinner-container">
            <div className="spinner"></div>
        </div>
    );
}

export default MessageWait
