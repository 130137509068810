import React, { useEffect, useState, useCallback } from "react"
import { useAppDispatch, useAppSelector } from "../../../lib/hooks"
import {
  adminUserAllGet,
  adminGroupListGet,
  adminModelListGet,
  adminFileListGet,
  adminFGroupListGet,
  adminFileListGetOnfid,
  adminPGroupListGet,
  adminPGroupFileListGet,
  adminChatbotListGet,
  adminPGroupAdd,
  adminPGroupDelete,
  adminFileAdd,
  adminFileDelete,
  adminCompanyGet,
  selectDashboardUser,
  selectDashboardGroup,
  selectDashboardModel,
  selectDashboardFile,
  selectDashboardFGroup,
  selectDashboardPGroup,
  selectDashboardPGroupFile,
  selectDashboardChatbot,
  selectDashboardCompany,
  selectDashboardChatbotGroup,
  adminChatbotGroupListGet,
} from "../../../lib/features/admin/adminSlice"
import { selectToken, selectStatus } from "../../../lib/features/auth/authSlice"
import { FaTrashAlt } from "react-icons/fa"
import TreeView from "../FilePage/FileTree"
import { FileText, Check, FolderOpen, FolderPlus, Edit2, Trash2 } from 'lucide-react'
import FileExplorer from "../FilePage/FileExplorer"


// import UserTabs from "../UserPage/UserTabs";
// import UserTable from "../UserPage/UserTable";
// import GroupTable from "../UserPage/GroupTable";
// import CompanyTable from "../UserPage/CompanyTable";
// import Pagination from "../UserPage/Pagination";
import { Tabs, TabsContent, TabsTrigger } from "../../ui/tabs"
import DashboardTabs from "../Common/DashboardTabs"
import FileTable from "../FilePage/FileTable"
import FileGroupTable from "../FilePage/FileGroupTable"
import ChatbotTable from "../ChatbotPage/ChatbotTable"
import ChatbotGroupTable from "../ChatbotPage/ChatbotGroupTable"
const APP_STAGE = process.env.REACT_APP_STAGE;

export default function AdminChatbotPage() {
  const dispatch = useAppDispatch();
  const token = useAppSelector(selectToken);
  const allUsers = useAppSelector(selectDashboardUser);
  const groups = useAppSelector(selectDashboardGroup);
  const companys = useAppSelector(selectDashboardCompany);
  const chatbots = useAppSelector(selectDashboardChatbot);
  const chatbotGroups = useAppSelector(selectDashboardChatbotGroup);
  const [currentPage, setCurrentPage] = useState(1);
  const [tabValue, setTabValue] = useState("users");

  useEffect(() => {
    dispatch(adminUserAllGet({ token }));
    dispatch(adminGroupListGet({ token }));
    dispatch(adminCompanyGet({ token }));
    dispatch(adminChatbotListGet({ token }));
    dispatch(adminChatbotGroupListGet({ token }));
  }, [dispatch, token]);

  useEffect(()=>{
    setCurrentPage(1);
  }, [tabValue]);
  
  const tabData = [
    {tabName: "chatbots", name: "CHATBOTS", notifications: 3},
    {tabName: "groups", name: "CHATBOT GROUPS", notifications: 0},
    // {tabName: "chatbots", name: "CHATBOT GROUPS", notifications: 1},
    // {tabName: "request", name: "가입신청", notifications: 5},
  ];
  if (APP_STAGE === "DEV") {  
    console.log(allUsers);
  }
  return (
    <div className="w-full p-0 mx-0">
      <h1 className="text-2xl font-bold mb-6">CHATBOT MANAGEMENT DASHBOARD</h1>
      <Tabs defaultValue="chatbots">
        <DashboardTabs data={tabData} tabValue={tabValue} setTabValue={setTabValue}/>
        {/*  <TabsContent value="files">
          <FileTable />
        </TabsContent>
        {/* <TabsContent value="pgroups">
          <FileGroupTable />
        </TabsContent> */}
        <TabsContent value="chatbots">
          <ChatbotTable chatbots={chatbots || []} />
        </TabsContent>
        <TabsContent value="groups">
            <ChatbotGroupTable chatbotGroups={chatbotGroups || []} userGroups={groups || []} />
          </TabsContent>
        {/*<TabsContent value="groups">
          <GroupTable groups={groups || []}  />
        </TabsContent>
        <TabsContent value="company">
          <CompanyTable companies={companys || []}  />
        </TabsContent> */}
      </Tabs>

    </div>
  );
};
