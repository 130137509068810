import * as React from "react";

interface CheckboxProps {
  label: string;
  comment?: string;
  id: string;
  checked: boolean;
  className?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  commentColor?: string;
}

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ className, id, checked, label, onChange, comment, commentColor, ...props }, ref) => {
    const [isFocused, setIsFocused] = React.useState(false);
    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => setIsFocused(false);

    return (
      <div className="relative mb-1 w-full flex items-start">
        <input
          type="checkbox"
          id={id}
          className={`w-5 h-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500 transition-all duration-200 ${className}`}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={onChange}
          checked={checked}
          ref={ref}
          {...props}
        />
        <label
          htmlFor={id}
          className={`ml-2 transition-all duration-200 ${isFocused ? "text-blue-500" : ""}`}
        >
          {label}
        </label>
        {comment && (
          <span className={`text-xs ml-2 ${commentColor || "text-gray-500"}`}>
            {comment}
          </span>
        )}
      </div>
    );
  }
);
Checkbox.displayName = "Checkbox";
