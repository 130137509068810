export default {
    sideButton:{
        toggleSidebar: {
            tooltip: "사이드바 토글"
        },
        newChat: {
            name: "새채팅",
            tooltip: "새로운 채팅 시작"
        },
        history: {
            name: "이전채팅",
            tooltip: "이전 채팅보기"
        },
        troubleshooting:{
            name: "트러블슈팅",
            tooltip: "View manuals list"
        },
        maintenance:{
            name: "메인터넌스",
            tooltip: "View manuals list"
        },
        generalinquery:{
            name: "일반문의",
            tooltip: "View manuals list"
        },
        manuals: {
            name: "매뉴얼",
            tooltip: "매뉴얼 목록보기"
        },
        video: {
            name: "비디오 가이드",
            tooltip: "비디오 목록보기"
        },
        backtoMenu:{
            name: "메뉴",
            tooltip: "메뉴로 이동"
        },
        changeLang:  {
            name: {
                en: "영",
                ko: "한"
            },
            tooltip: "언어 변경"
        },
        feedback: {
            name: "피드백",
            tooltip: "피드백 남기기"
        },
        service: {
            name: "서비스 요청",
            tooltip: "서비스 요청"
        },
        pic: {
            name: "담당자 정보",
            tooltip: "담당자 정보 보기"
        }
    }
  };