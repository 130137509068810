import { createAppSlice } from "../../createAppSlice";
import type { PayloadAction } from "@reduxjs/toolkit";
// import { fetchNovelIndex, fetchNovelStory, fetchImagePrompt, fetchImageGen } from "./novelEditorAPI";
import {
  fetchChatMessageSend,
  fetchChatMessageGet,
  fetchChatRoomGet,
  fetchChatRoomCreate,
  fetchChatRoomDelete,
  fetchChatMessageFile,
  fetchMessageIsLike,
  fetchMessageFeedbackText,
  fetchkeywordSearchList,
} from "./chatMainAPI";

import chatNavModel from "../../../data/chatNavItem";

export interface FeedBackType {
  feedback_id: number;
  user_id: number;
  message_id: number;
  is_like: number;
  is_dislike: number;
  feedback_text?: string;
}

// 모델 정의

export interface ChatMessageReference {
  reference_id: number;
  reference_formatted_text: string;
  reference_doc_id: number;
  pdf_id: number;
  pdf_page: number;
  pdf_url: string;
  file_nm: string;
  reference_rating: number;
}

export interface ChatMessage {
  _id?: number;
  type?: string; //"basic"|"image"|"video"|"carousel"|"selection"
  isBot: boolean;
  hasDoc?: boolean;
  hasImg?: boolean;
  // url: string;
  // evidence?: ChatMessageEvidence[];
  reference?: ChatMessageReference[];
  // pageNum: number;
  sendTime?: string;
  feedback?: FeedBackType;
  text?: string | null;
  imageUrl?: string;
  videoUrl?: string;
}

export interface ChatMessageBasic extends ChatMessage {
  text: string;
}
export interface ChatMessageImage extends ChatMessage {
  imageUrl: string;
}
export interface ChatMessageVideo extends ChatMessage {
  videoUrl: string;
}
export interface ChatMessageCarousel extends ChatMessage {
  data: string; // 캐러셀 데이터 추가
}
export interface ChatMessageSelection extends ChatMessage {
  data: string; //선택지 데이터 구조 추가
}

export interface messageCreateFile {
  token: string;
  room_id: string;
  formData: FormData;
}

export interface messageIsLike {
  token: string;
  // message_id: number;
  // is_like: boolean;
  // is_dislike: boolean;
}

export interface messageFeedbackText {
  token: string;
  message_id: number;
  feedback_text: string;
}
export interface keywordSearchList {
  token: string;
  search: string;
}

export interface ChatHistory {
  room_id: number;
  room_name: string;
  cgroup_id: number;
  cgroup_type: string;
  chatbot_id: number;
  created_at: string;
  updated_at: string;
}

export interface ChatMain {
  title: string;
  status: "loading" | "failed" | "idle";
  streamStatus: "idle" | "thinking" | "searching" | "generating" | "optimizing";
  roomId: number | null;
  messages: ChatMessage[];
  chatHistory: ChatHistory[];
  error?: any;
  
}
export interface Keyword {
  status: "loading" | "failed" | "idle";
  keywords: any[];
}
export interface ChatList {
  chatName: string;
  isActive: boolean;
  icon: string;
  to: string;
}
export interface ChatSideMenu {
  label: string;
  chatList: ChatList[];
}
export interface ChatSide {
  chatMenu: ChatSideMenu[];
}

export interface User {
  auth: null;
  name: string;
  company: string;
  email: string;
  isAdmission: boolean;
}
export interface ChatMessageSend {
  // roomId: number,
  // text: string,
  // type: string
  room_id: number;
  text: string;
  chatbot_model_id: number;
  retiever_model_id: number;
}

export interface DocViewer {
  isOn: boolean;
  docUrl: string;
  docPage: number;
  docTitle: string;
}
export interface ChatMode {
  mode_1: string;
  mode_2: string;
  isNewChat: boolean;
}

export interface chatMainSliceState {
  index: number[];
  chatMode: ChatMode;
  chatMain: ChatMain;
  keywordList: Keyword;
  chatSide: ChatSide;
  docViewer: DocViewer;
}

export interface roomGet {
  token: string;
}
export interface roomCreate {
  token: string;
  room_name: string;
  room_type: string;
  chat_type: string;
}
export interface roomDelete {
  token: string;
  room_id: number;
}
export interface messageGet {
  token: string;
  room_id: number;
}
export interface messageCreate {
  token: string;
  room_id: string;
  message_text: string;
  message_type: string;
}
export interface messageDelete {
  token: string;
}

const initialState: chatMainSliceState = {
  index: [0, 0],
  chatMode: {
    mode_1: "BWMS",
    mode_2: "",
    isNewChat: false,
  },
  chatMain: {
    title: "",
    status: "idle",
    streamStatus: "idle",
    roomId: null,
    messages: [],
    chatHistory: [],
  },
  docViewer: {
    isOn: false,
    docUrl: "",
    docPage: -1,
    docTitle: "",
  },
  chatSide: {
    chatMenu: [], //chatNavModel,
  },
  keywordList: {
    status: "idle",
    keywords: [],
  },
};

export const chatMainSlice = createAppSlice({
  name: "chatMain",
  initialState,
  reducers: (create) => ({
    keywordGetList: create.asyncThunk(
      async (keywordSearchListData: keywordSearchList) => {
        const response = await fetchkeywordSearchList(keywordSearchListData);

        return response;
      },
      {
        pending: (state) => {
          
          state.chatMain.status = "loading";
        },
        fulfilled: (state, action) => {
          state.keywordList.status = "idle";
          state.keywordList.keywords = action.payload;
          //응답 받아서 챗봇 응답까지 표시

          
        },
        rejected: (state) => {
          state.chatMain.status = "failed";
          // 이과정 실패하면 x 같은것 표시하기
        },
      }
    ),
    messageFeedbackText: create.asyncThunk(
      async (messageFeedbackTextData: messageFeedbackText) => {
        const response = await fetchMessageFeedbackText(
          messageFeedbackTextData
        );

        return response;
      },
      {
        pending: (state) => {
          
          state.chatMain.status = "loading";
        },
        fulfilled: (state, action) => {
          state.chatMain.status = "idle";
          //응답 받아서 챗봇 응답까지 표시

          
        },
        rejected: (state) => {
          state.chatMain.status = "failed";
          // 이과정 실패하면 x 같은것 표시하기
        },
      }
    ),
    meesageLike: create.asyncThunk(
      async (messageLikeData: messageIsLike) => {
        const response = await fetchMessageIsLike(messageLikeData);

        return response;
      },
      {
        pending: (state) => {
          
          state.chatMain.status = "loading";
        },
        fulfilled: (state, action) => {
          state.chatMain.status = "idle";
          //응답 받아서 챗봇 응답까지 표시

          
        },
        rejected: (state) => {
          state.chatMain.status = "failed";
          // 이과정 실패하면 x 같은것 표시하기
        },
      }
    ),
    chatMessageFile: create.asyncThunk(
      async (messageSendData: messageCreateFile) => {
        const response = await fetchChatMessageFile(messageSendData);

        return response;
      },
      {
        pending: (state) => {
          
          state.chatMain.status = "loading";
        },
        fulfilled: (state, action) => {
          state.chatMain.status = "idle";
          //응답 받아서 챗봇 응답까지 표시

          
        },
        rejected: (state) => {
          state.chatMain.status = "failed";
          // 이과정 실패하면 x 같은것 표시하기
        },
      }
    ),
    addMessages: create.asyncThunk(
      async (messageGetData: any) => {
        return messageGetData;
        // 메시지 추가 로직
      },
      {
        pending: (state) => {
          state.chatMain.status = "loading";
        },
        fulfilled: (state, action) => {
          state.chatMain.status = "idle";

          const message = action.payload;
          if (message.first) {
            state.chatMain.messages = [
              ...state.chatMain.messages,
              {
                type: "basic",
                isBot: true,
                text: JSON.stringify([
                  { text: message.message_text, key: null },
                ]),
                hasDoc: message.message_pdf_name ? true : false,
                hasImg: false,
                // url: message.message_pdf_url,
                // evidence: [],
                // pageNum: message.message_pdf_page,
                sendTime: message.createdAt,
              },
            ];
          } else {
            state.chatMain.messages[state.chatMain.messages.length - 1] = {
              type: "basic",
              isBot: true,
              text: JSON.stringify([{ text: message.message_text, key: null }]),
              hasDoc: message.message_pdf_name ? true : false,
              // evidence: [],
              reference: [],
              hasImg: false,
              // url: message.message_pdf_url,
              // pageNum: message.message_pdf_page,
              sendTime: message.createdAt,
            };
          }
        },
        rejected: (state) => {
          state.chatMain.status = "failed";
        },
      }
    ),
    chatMessageGet: create.asyncThunk(
      async (messageGetData: messageGet) => {
        const response = await fetchChatMessageGet(messageGetData);
        

        return response;
      },
      {
        pending: (state) => {
          state.chatMain.status = "loading";
        },
        fulfilled: (state, action) => {
          state.chatMain.status = "idle";
          const newMessage_bot = (action.payload.messages || []).map(
            (item: any) => {
              let type = "basic";
              let hasImg = false;
              if (item.message_type == "image_url") {
                type = "image";
                hasImg = true;
              }

              return {
                _id: item.message_id,
                type: type,
                isBot: item.is_chatbot,
                hasDoc: item.message_pdf_name ? true : false,
                hasImg: hasImg,
                // url: item.message_pdf_url,
                // pageNum: item.message_pdf_page,
                reference: item.references.map(
                  (ref: ChatMessageReference, ref_idx: number) => {
                    return {
                      reference_id: ref.reference_id,
                      reference_formatted_text: ref.reference_formatted_text,
                      reference_doc_id: ref.reference_doc_id,
                      pdf_id: ref.pdf_id,
                      pdf_page: ref.pdf_page,
                      pdf_url: ref.pdf_url,
                      file_nm: ref.file_nm,
                      reference_rating: ref.reference_rating,
                    };
                  }
                ),
                text: item.message_text,
                imageUrl: item.message_base64,
                sendTime: item.created_at,
                feedback: item.feedback,
              };
            }
          );
          state.chatMain.messages = [...newMessage_bot].sort(
            (a, b) => a._id - b._id
          );
        },
        rejected: (state) => {
          state.chatMain.status = "failed";
        },
      }
    ),
    chatMessageSend: create.asyncThunk(
      async (messageSendData: messageCreate, { rejectWithValue }) => {
        try {
          const response = await fetchChatMessageSend(messageSendData);
          return response;
        } catch (error: unknown) {
          if (error instanceof Error) {
            // error가 Error 타입일 때만 접근
            return rejectWithValue(
              error.message || "메시지 전송에 실패했습니다. 다시 시도해주세요."
            );
          } else if (
            typeof error === "object" &&
            error !== null &&
            "status" in error
          ) {
            const typedError = error as { status: number; message: string }; // 타입 좁히기
            if (typedError.status === 429) {
              return rejectWithValue(
                "오늘의 사용량을 초과했습니다. 내일 다시 시도해주세요."
              );
            }
            return rejectWithValue(
              typedError.message || "알 수 없는 에러가 발생했습니다."
            );
          }
          return rejectWithValue("예기치 않은 에러가 발생했습니다.");
        }
      },
      {
        pending: (state) => {
          
          state.chatMain.status = "loading";
        },
        fulfilled: (state, action) => {
          state.chatMain.status = "idle";
          //응답 받아서 챗봇 응답까지 표시
          // const message = action.payload.messages;
          // state.chatMain.messages = [
          //   ...state.chatMain.messages,
          //   {
          //     type: "basic",
          //     isBot: true,
          //     text: message.message_text,
          //     hasDoc: message.message_pdf_name ? true : false,
          //     hasImg: false,
          //     evidence: [],
          //     // url: message.message_pdf_url,
          //     // pageNum: message.message_pdf_page,
          //     sendTime: message.createdAt,
          //   },
          // ];
          
        },
        rejected: (state, action) => {
          state.chatMain.status = "failed";
          state.chatMain.error = action.payload;
          // 이과정 실패하면 x 같은것 표시하기
        },
      }
    ),
    // chatMessageAdd: create.reducer((state, action: PayloadAction<string>) => {
    //   let today = new Date();
    //   //데이터 넣어서 로딩 돌리고,

    //   state.chatMain.messages =  [...state.chatMain.messages, {
    //     _id: state.chatMain.messages.length,
    //     type: "basic",
    //     hasDoc: false,
    //     hasImg: false,
    //     url: "",
    //     pageNum: 0,
    //     text: action.payload,
    //     isBot: false,
    //     sendTime: today.getDate().toString()+" "+today.getTime().toString()
    //   } as ChatMessageBasic,
    //   {
    //     _id: state.chatMain.messages.length,
    //     type: "waiting",
    //     hasDoc: false,
    //     hasImg: false,
    //     url: "",
    //     pageNum: 0,
    //     text: action.payload,
    //     isBot: true,
    //     sendTime: today.getDate().toString()+" "+today.getTime().toString()
    //   } as ChatMessage
    // ];
    // }),
    chatMessageAdd: create.asyncThunk(
      async (messsage: string) => {
        const response = messsage;

        return response;
      },
      {
        pending: (state) => {
          state.chatMain.status = "loading";
        },
        fulfilled: (state, action) => {
          let today = new Date();
          state.chatMain.status = "idle";
          state.chatMain.messages = [
            ...state.chatMain.messages,
            {
              _id: state.chatMain.messages.length,
              type: "basic",
              hasDoc: false,
              hasImg: false,
              //url: "",
              //pageNum: 0,
              evidence: [],
              text: JSON.stringify([{ text: action.payload, key: null }]),
              isBot: false,
              sendTime:
                today.getDate().toString() + " " + today.getTime().toString(),
            } as ChatMessageBasic,
            // {
            //   _id: state.chatMain.messages.length,
            //   type: "waiting",
            //   hasDoc: false,
            //   hasImg: false,
            //   // url: "",
            //   // pageNum: 0,
            //   evidence: [],
            //   text: action.payload,
            //   isBot: true,
            //   sendTime:
            //     today.getDate().toString() + " " + today.getTime().toString(),
            // } as ChatMessage,
          ];
        },
        rejected: (state) => {
          state.chatMain.status = "failed";
        },
      }
    ),
    chatMessageAddBot: create.asyncThunk(
      async (messsage: string) => {
        const response = messsage;

        return response;
      },
      {
        pending: (state) => {
          state.chatMain.status = "loading";
        },
        fulfilled: (state, action) => {
          let today = new Date();
          state.chatMain.status = "idle";
          state.chatMain.messages = [
            ...state.chatMain.messages,
            {
              _id: state.chatMain.messages.length,
              type: "basic",
              hasDoc: false,
              hasImg: false,
              //url: "",
              //pageNum: 0,
              evidence: [],
              text: JSON.stringify([{ text: action.payload, key: null }]),
              isBot: true,
              sendTime:
                today.getDate().toString() + " " + today.getTime().toString(),
            } as ChatMessageBasic,
          ];
        },
        rejected: (state) => {
          state.chatMain.status = "failed";
        },
      }
    ),
    setChatRoomId: create.reducer(
      (state, action: PayloadAction<number | null>) => {
        if (action.payload == null) {
          state.chatMain.messages = [];
        }
        state.chatMain.roomId = action.payload;
      }
    ),
    setStreamStatus: create.reducer(
      (state, action: PayloadAction<string>) => {
        state.chatMain.streamStatus = action.payload as "idle" | "thinking" | "searching" | "generating" | "optimizing";
      }
    ),

    chatRoomGet: create.asyncThunk(
      async (roomGetData: messageGet) => {
        const response = await fetchChatRoomGet(roomGetData);
        

        return response;
      },
      {
        pending: (state) => {
          state.chatMain.status = "loading";
        },
        fulfilled: (state, action) => {
          state.chatMain.status = "idle";
          state.chatMain.chatHistory = action.payload.reverse().map((item: any) => {
            return {
              room_id: item.room_id,
              room_name: item.room_name,
              cgroup_id: item.cgroup_id,
              cgroup_type: item.cgroup.cgroup_type,
              chatbot_id: item.cgroup.chatbot_id,
              created_at: item.created_at,
              updated_at: item.updated_at,
            };
          });

          const newSidebar = {
            label: "BWMS Troubleshooting",
            chatList: action.payload.reverse().map((item: any, idx: number) => {
              return {
                chatName: item.room_name,
                roomId: item.room_id,
                isActive: false,
                icon: "bi bi-menu-button-wide",
                to: "/",
              };
            }),
          };
          state.chatSide.chatMenu = [newSidebar];
        },
        rejected: (state) => {
          state.chatMain.status = "failed";
        },
      }
    ),
    chatRoomCreate: create.asyncThunk(
      async (roomSendData: roomCreate) => {
        const response = await fetchChatRoomCreate(roomSendData);
        
        return response;
      },
      {
        pending: (state) => {
          
          state.chatMain.status = "loading";
        },
        fulfilled: (state, action) => {
          state.chatMain.status = "idle";
        },
        rejected: (state) => {
          state.chatMain.status = "failed";
          // 이과정 실패하면 x 같은것 표시하기
        },
      }
    ),
    chatRoomDelete: create.asyncThunk(
      async (roomSendData: roomDelete) => {
        const response = await fetchChatRoomDelete(roomSendData);
        
        return response;
      },
      {
        pending: (state) => {
          
          state.chatMain.status = "loading";
        },
        fulfilled: (state, action) => {
          state.chatMain.status = "idle";
        },
        rejected: (state) => {
          state.chatMain.status = "failed";
          // 이과정 실패하면 x 같은것 표시하기
        },
      }
    ),

    sidebarSelRoom: create.reducer((state, action: PayloadAction<number[]>) => {
      state.chatMain.title = state.chatMain.chatHistory.find(item=>item.room_id==state.chatMain.roomId)?.room_name || "New";
    }),

    setDocViewer: create.reducer((state, action: PayloadAction<DocViewer>) => {
      state.docViewer = action.payload;
    }),

    setToggleDocViewer: create.reducer((state) => {
      
      state.docViewer.isOn = !state.docViewer.isOn;
    }),

    setChatMode: create.reducer((state, action: PayloadAction<ChatMode>) => {
      state.chatMode = action.payload;
    }),
    resetChatMain: create.reducer((state) => {
      return initialState;
    }),


    // chatMessageSend: create.reducer((state, action: PayloadAction<string>) => {
    //     let today = new Date();
    //     state.chatMain.messages = [...state.chatMain.messages,  {
    //       _id: state.chatMain.messages.length+1,
    //       type: "basic",
    //       isBot: false,
    //       sendTime: today.toLocaleString()
    //     }];
    //   }),
  }),

  selectors: {
    selectIndex: (state) => state.index,
    selectChatMode: (state) => state.chatMode,
    selectChatSide: (state) => state.chatSide,
    selectChatMain: (state) => state.chatMain,
    selectKeyword: (state) => state.keywordList,
    selectChatRoomId: (state) => state.chatMain.roomId,
    selectDocViewer: (state) => state.docViewer,
    selectStreamStatus: (state) => state.chatMain.streamStatus,
    selectChatHistory: (state) => state.chatMain.chatHistory,
  },
});

export const {
  resetChatMain,
  chatMessageGet,
  chatMessageSend,
  chatMessageAdd,
  chatMessageAddBot,
  chatRoomGet,
  chatRoomCreate,
  chatRoomDelete,
  setChatRoomId,
  setDocViewer,
  addMessages,
  chatMessageFile,
  setToggleDocViewer,
  setStreamStatus,
  sidebarSelRoom,
  messageFeedbackText,
  meesageLike,
  setChatMode,
  keywordGetList,
} = chatMainSlice.actions;
export const {
  selectIndex,
  selectChatMode,
  selectKeyword,
  selectChatSide,
  selectChatMain,
  selectChatRoomId,
  selectDocViewer,
  selectStreamStatus,
  selectChatHistory,
} = chatMainSlice.selectors;
