import React, { useEffect, useState, useCallback } from "react"
import { useAppDispatch, useAppSelector } from "../../../lib/hooks"
import {
  adminUserAllGet,
  adminGroupListGet,
  adminModelListGet,
  adminFileListGet,
  adminFGroupListGet,
  adminFileListGetOnfid,
  adminPGroupListGet,
  adminPGroupFileListGet,
  adminChatbotListGet,
  adminPGroupAdd,
  adminPGroupDelete,
  adminFileAdd,
  adminFileDelete,
  selectDashboardUser,
  selectDashboardGroup,
  selectDashboardModel,
  selectDashboardFile,
  selectDashboardFGroup,
  selectDashboardPGroup,
  selectDashboardPGroupFile,
  selectDashboardChatbot,
} from "../../../lib/features/admin/adminSlice"
import { selectToken, selectStatus } from "../../../lib/features/auth/authSlice"
import { FaTrashAlt } from "react-icons/fa"
import TreeView from "../FilePage/FileTree"

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../ui/table"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
    DropdownMenuSub,
    DropdownMenuSubTrigger,
    DropdownMenuSubContent,
} from "../../ui/dropdown-menu"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../../ui/dialog"

import { Plus, Search, Filter, EllipsisVertical, MoreVertical, Check, X, Trash2, UserPlus, Users } from "lucide-react"
import { Button } from "../../ui/button"
import { Input } from "../../ui/input"
import Pagination from "../Common/Pagination";
import FileExplorer from "./FileExplorer";

type DirFile = {
    fgroup_id?: string
    fgroup_name?: string
    pdf_id?: string
    pdf_name?: string
    isFolder: boolean
    child?: DirFile[]
    files?: DirFile[]
  }
  type FileItem = {
    pdf_id: number
    pdf_display_nm: string
    pdf_mime_type: string
    fgroup_id: number
    pdf_size: number
    is_used: boolean
    is_deleted: boolean
    is_duplicate: boolean
    is_valid: boolean
    created_at: string
    updated_at: string
  
  }

const ITEMS_PER_PAGE = 20;
const FileTable = ({  }) => {
    const dispatch = useAppDispatch()
    const token = useAppSelector(selectToken)
    const status = useAppSelector(selectStatus)
    const files = useAppSelector(selectDashboardFile)
    const fgroups = useAppSelector(selectDashboardFGroup)
    const pgroups = useAppSelector(selectDashboardPGroup)
    const pgroupFiles = useAppSelector(selectDashboardPGroupFile)
    const chatbots = useAppSelector(selectDashboardChatbot)
  
    const [selectFiles, setSelectFiles] = useState([])
    const [uploadFiles, setUploadFiles] = useState([])
    const [selectDirName, setSelectDirName] = useState("")
    const [newPGroupName, setNewPGroupName] = useState("")
    const [activeTab, setActiveTab] = useState("file-management")
  
    const [directories, setDirectories] = useState<DirFile[]>([]);
    const [currentDirFiles, setCurrentDirFiles] = useState<FileItem[]>([]);
    const [selectedFolderId, setSelectedFolderId] = useState<string | null>(null);

    
    const updateNodeIsOpen = useCallback((tree: any, id: string, isOpen: boolean) => {
    
    if (tree.fgroup_id == id) {
      return { ...tree, isOpen:!tree.isOpen };
    }
    if (tree.child) {
      return {
        ...tree,
        child: tree.child.map((childNode: any) => updateNodeIsOpen(childNode, id, isOpen))
      };
    }
    return tree;
  }, []);

    
      const addIsOpenToTree = useCallback((tree: any) => {
        if (!tree) return null;
        return {
          ...tree,            
          isOpen: false,  
          isFolder: true,    
          child: tree.child ? tree.child.map((item: any) => addIsOpenToTree(item)) : []
        };
      }, []);
    
      useEffect(() => {
        if (token) {
          dispatch(adminUserAllGet({ token }))
          dispatch(adminGroupListGet({ token }))
          dispatch(adminModelListGet({ token }))
          dispatch(adminFileListGet({ token }))
          dispatch(adminFGroupListGet({ token }))
          dispatch(adminPGroupListGet({ token }))
          dispatch(adminChatbotListGet({ token }))
        }
      }, [dispatch, token])
    
      useEffect(() => {
        if (fgroups?.tree) {
          const processedTree = addIsOpenToTree(fgroups.tree);
          setDirectories(processedTree ? [processedTree] : []);
        }
      }, [fgroups?.tree, addIsOpenToTree]);
    
      useEffect(() => {
        if (fgroups?.dirFile) {
          setCurrentDirFiles(fgroups.dirFile || []);
        }
      }, [fgroups]);

    const handleGetDirFile = useCallback((fgroupId: number) => {
        dispatch(adminFileListGetOnfid({ token: token as string, fgroupId: fgroupId }));
    }, [dispatch, token, fgroups]);
    return (
        <div className="space-y-4 h-full">
            {directories && directories.length > 0 && (
            <FileExplorer 
                dir={directories} 
                setDirFiles={setDirectories as React.Dispatch<React.SetStateAction<DirFile[]>>} 
                dirFiles={currentDirFiles}
                setFiles={setCurrentDirFiles}
                handleGetDirFile={handleGetDirFile}
                token={token as string}
                selection={false}
            />
            )}
        </div>
    )
};

export default FileTable;