import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../lib/hooks";
import {
  adminFaqList,
  adminFaqAdd,
  adminFaqFinetune,
  selectDashboardTestFeedbacks,
  selectDashboardChatbot,
  adminChatbotListGet,
  adminFileUpload,
  adminTestFeedbacks,
  selectDashboardFaqs,
} from "../../../lib/features/admin/adminSlice";
import {
  selectToken,
  selectStatus,
} from "../../../lib/features/auth/authSlice";
import * as XLSX from "xlsx";

export default function AdminFaqPage() {
  const dispatch = useAppDispatch();
  const token = useAppSelector(selectToken);
  const status = useAppSelector(selectStatus);
  const faqs = useAppSelector(selectDashboardFaqs);
  const testFeedbacks = useAppSelector(selectDashboardTestFeedbacks);
  const chatbots = useAppSelector(selectDashboardChatbot);
  const [faqQuestion, setFaqQuestion] = useState("");
  const [faqManual, setFaqManual] = useState("");
  const [faqPage, setFaqPage] = useState("");
  const [faqAnswer, setFaqAnswer] = useState("");
  const [activeTab, setActiveTab] = useState("faqs");
  const [fileData, setFileData] = useState(null);
  const [parsedData, setParsedData] = useState([]);
  const [fileName, setFileName] = useState("");

  useEffect(() => {
    dispatch(adminFaqList({ token }));
    dispatch(adminTestFeedbacks({ token }));
    dispatch(adminChatbotListGet({ token }));
  }, [dispatch, token]);

  const handleAddFaq = () => {
    dispatch(
      adminFaqAdd({
        token,
        faq_question: faqQuestion,
        faq_manual: faqManual,
        faq_page: faqPage,
        chatbotId: chatbots[0]?.chatbot_id,
      })
    ).then(() => {
      setFaqQuestion("");
      setFaqManual("");
      setFaqPage("");
      dispatch(adminFaqList({ token }));
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name); // 파일 이름 저장
      const reader = new FileReader();
      reader.onload = (event) => {
        const binaryStr = event.target.result;
        const workbook = XLSX.read(binaryStr, { type: "binary" });
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(firstSheet);
        setParsedData(jsonData); // 파싱된 데이터 저장
      };
      reader.readAsBinaryString(file); // 파일 읽기
      setFileData(file); // 파일 저장
    }
  };

  const handleFinetune = () => {
    dispatch(
      adminFaqFinetune({
        token,
      })
    ).then(() => {
      alert("Fine-tuning completed successfully!");
    });
  };

  const handleUpload = () => {
    if (!fileData) return;

    const formData = new FormData();
    formData.append("files", fileData); // 선택한 파일 추가
    formData.append("chatbotId", chatbots[0]?.chatbot_id); // chatbotId 추가

    dispatch(
      adminFileUpload({
        token,
        fileData: formData,
        chatbotId: chatbots[0]?.chatbot_id,
      })
    ).then(() => {
      alert("File upload successfully!");
    });
  };

  const handleFileDownload = () => {
    const transformedData = faqs.map((faq) => ({
      QUESTION: faq.faq_question,
      "RELATED MANUAL": faq.faq_manual,
      PAGE: faq.faq_page,
      ANSWER: faq.faq_answer,
    }));
    const worksheet = XLSX.utils.json_to_sheet(transformedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "FAQs");
    XLSX.writeFile(workbook, "FAQs.xlsx");
  };

  return (
    <div className="container mx-auto p-4 space-y-8">
      <h1 className="text-3xl font-bold mb-6">Admin Dashboard</h1>
      <div className="mb-4">
        <ul className="flex space-x-4 border-b">
          {["FAQs", "Add FAQ", "File Management", "Feedbacks"].map((tab) => (
            <li key={tab}>
              <button
                className={`py-2 px-4 ${
                  activeTab === tab.toLowerCase().replace(" ", "-")
                    ? "border-b-2 border-blue-500 text-blue-500"
                    : "text-gray-500 hover:text-gray-700"
                }`}
                onClick={() =>
                  setActiveTab(tab.toLowerCase().replace(" ", "-"))
                }
              >
                {tab}
              </button>
            </li>
          ))}
        </ul>
      </div>

      {activeTab === "faqs" && (
        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-2xl font-semibold mb-4">FAQ List</h2>
          <p className="text-gray-600 mb-4">Manage your FAQs here</p>
          {status === "loading" ? (
            <p>Loading...</p>
          ) : (
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      ID
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Created At
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Question
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Manual
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Page
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      ChatbotId
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Answer
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {faqs?.map((faq) => (
                    <tr key={faq.faq_id}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {faq.faq_id}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {new Date(faq.created_at).toLocaleString()}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {faq.faq_question}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {faq.faq_manual}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {faq.faq_page}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {faq.chatbot_id}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {faq.faq_answer}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}

      {activeTab === "add-faq" && (
        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-2xl font-semibold mb-4">Add New FAQ</h2>
          <p className="text-gray-600 mb-4">Create a new FAQ entry</p>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleAddFaq();
            }}
            className="space-y-4"
          >
            <div className="space-y-2">
              <label
                htmlFor="question"
                className="block text-sm font-medium text-gray-700"
              >
                Question
              </label>
              <input
                id="question"
                type="text"
                value={faqQuestion}
                onChange={(e) => setFaqQuestion(e.target.value)}
                required
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            <div className="space-y-2">
              <label
                htmlFor="manual"
                className="block text-sm font-medium text-gray-700"
              >
                Manual
              </label>
              <input
                id="manual"
                type="text"
                value={faqManual}
                onChange={(e) => setFaqManual(e.target.value)}
                required
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            <div className="space-y-2">
              <label
                htmlFor="page"
                className="block text-sm font-medium text-gray-700"
              >
                Page
              </label>
              <input
                id="page"
                type="text"
                value={faqPage}
                onChange={(e) => setFaqPage(e.target.value)}
                required
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            <div className="space-y-2">
              <label
                htmlFor="answer"
                className="block text-sm font-medium text-gray-700"
              >
                Answer
              </label>
              <input
                id="answer"
                type="text"
                value={faqAnswer}
                onChange={(e) => setFaqAnswer(e.target.value)}
                required
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            <button
              type="submit"
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Add FAQ
            </button>
          </form>
        </div>
      )}

      {activeTab === "file-management" && (
        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-2xl font-semibold mb-4">File Management</h2>
          <p className="text-gray-600 mb-4">Upload and download FAQ files</p>
          <div className="space-y-4">
            <div className="space-y-2">
              <label
                htmlFor="file-upload"
                className="block text-sm font-medium text-gray-700"
              >
                Upload Excel File
              </label>
              {/* 파일 선택 */}
              <input
                id="file-upload"
                type="file"
                accept=".xlsx, .xls"
                multiple={false}
                onChange={handleFileChange} // 파일 선택 시 호출되는 함수
                className="mt-1 block w-full text-sm text-gray-500
          file:mr-4 file:py-2 file:px-4
          file:rounded-md file:border-0
          file:text-sm file:font-semibold
          file:bg-blue-50 file:text-blue-700
          hover:file:bg-blue-100"
              />
              {/* 선택한 파일 이름 표시 */}
              {fileName && <p>Selected File: {fileName}</p>}
            </div>

            {/* 파싱된 데이터 미리보기 */}
            {parsedData.length > 0 && (
              <div className="bg-white shadow rounded-lg p-4">
                <h2 className="text-xl font-semibold mb-4">File Preview</h2>
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      {Object.keys(parsedData[0]).map((key) => (
                        <th
                          key={key}
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {key}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {parsedData.map((row, index) => (
                      <tr key={index}>
                        {Object.values(row).map((value, idx) => (
                          <td key={idx} className="px-6 py-4 whitespace-nowrap">
                            {value}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}

            {/* 파일 업로드 버튼 */}
            <button
              onClick={handleUpload}
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Upload File
            </button>

            {/* 파일 다운로드 버튼 */}
            <button
              onClick={handleFileDownload}
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mt-4"
            >
              Download FAQs as Excel
            </button>

            <button
              onClick={handleFinetune}
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mt-4"
            >
              FAQ Finetune
            </button>
          </div>
        </div>
      )}

      {activeTab === "feedbacks" && (
        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-2xl font-semibold mb-4">User Feedbacks</h2>
          <p className="text-gray-600 mb-4">View user feedback and ratings</p>
          {status === "loading" ? (
            <p>Loading...</p>
          ) : (
            testFeedbacks.map((userFeedback) => (
              <div key={userFeedback.user_id} className="mb-8">
                <h3 className="text-lg font-semibold mb-2">
                  User ID: {userFeedback.user_id}
                </h3>
                {userFeedback.feedback_groups.map((group) => (
                  <div key={group.room_id} className="mb-4">
                    <h4 className="text-md font-medium mb-2">
                      Room ID: {group.room_id}
                    </h4>
                    <div className="overflow-x-auto">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Feedback ID
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Rating
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Filename
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Page Number
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Rated Text
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Created At
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Updated At
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {group.feedbacks.map((feedback) => (
                            <tr key={feedback.test_feedback_id}>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {feedback.test_feedback_id}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {feedback.rating}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {feedback.filename}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {feedback.pagenum}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {feedback.rated_text || "N/A"}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {new Date(feedback.created_at).toLocaleString()}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {new Date(feedback.updated_at).toLocaleString()}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ))}
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
}
