import { createAppSlice } from "../../createAppSlice";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  fetchLogin,
  fetchLogout,
  fetchRegister,
  fetchUser,
  fetchUserLangChange,
  requestUserAPI,
  fetchUserPasswordChange,
  fetchServiceEmailSend,
} from "./authAPI";
import internal from "stream";
import { Token } from "typescript";

// 모델 정의
export interface Group {
  group_id: number;
  group_name: string;
  group_dscription: string;
  createAt: string;
  updateAt: string;
}

export interface PersonInCharge {
  pic_id: number;
  pic_name: string;
  pic_name_en: string;
  pic_email: string;
  pic_phone: string;
}

export interface Vessel {
  vessel_id: number;
  imo_no: string;
  ship_name: string;
  PersonInCharge: PersonInCharge;
}

export interface User {
  user_id: number;
  username: string;
  email: string;

  eml_check: boolean;
  eml_lock: boolean;
  lock_cms: boolean;
  login_check: boolean;
  del_check: boolean;
  user_lang: string;

  userLogs: string[];
  group: Group;
  Vessel: Vessel;

  login_last_at: string;
  createdAt: string;
  updatedAt: string;
}

export interface authState {
  isAuthenticated: boolean;
  status: "loading" | "failed" | "idle";
  token: string | null;
  user: User | null;
  errorMsg: string | null;
}

const initialState: authState = {
  isAuthenticated: false,
  status: "idle",
  errorMsg: "",
  token: null,
  user: null,
};

export interface authLogin {
  id: string;
  password: string;
}
export interface requestUserData {
  data: any;
}
export interface serviceEmailSendData {
  token: string;
  data: any;
  files: any;
  roomId: any;
}
export interface authRegister {
  useremail: string;
  username: string;
  password: string;
  lang: string;
}

export interface UserLangChangeData {
  token: string;
  lang: string;
}
export interface UserLangChangePassword {
  token: string;
  password: string;
}

// localStorage.setItem('token', token);
// localStorage.getItem('token');

export const authSlice = createAppSlice({
  name: "authentication",
  initialState,
  reducers: (create) => ({
    passwordChange: create.asyncThunk(
      async (data: UserLangChangePassword) => {
        const response = await fetchUserPasswordChange(data);

        return response;
      },
      {
        pending: (state) => {
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          if (action.payload.user) {
            state.status = "idle";
            // state.user = action.payload.user;
            // state.isAuthenticated = true;
          } else {
            state.status = "failed";
            // state.user = null;
            // state.isAuthenticated = false;
            // state.errorMsg = action.payload.detail;
          }
        },
        rejected: (state, action) => {

          state.status = "failed";
          // state.isAuthenticated = false;
          // state.token = null;
          // localStorage.removeItem("token");
        },
      }
    ),

    userMe: create.asyncThunk(
      async (token: string) => {
        const response = await fetchUser(token);

        return response;
      },
      {
        pending: (state) => {
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          if (action.payload.user) {
            state.status = "idle";
            state.user = action.payload.user;
            state.isAuthenticated = true;
          } else {
            state.status = "failed";
            state.user = null;
            state.isAuthenticated = false;
            state.errorMsg = action.payload.detail;
          }
        },
        rejected: (state, action) => {

          state.status = "failed";
          state.isAuthenticated = false;
          state.token = null;
          localStorage.removeItem("token");
        },
      }
    ),

    serviceEmailSend: create.asyncThunk(
      async (data: serviceEmailSendData) => {
        const response = await fetchServiceEmailSend(data);

        return response;
      },
      {
        pending: (state) => {
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          state.status = "idle";
        },
        rejected: (state, action) => {

          state.status = "failed";
        },
      }
    ),

    requestUser: create.asyncThunk(
      async (requestUserData: requestUserData) => {
        const response = await requestUserAPI(requestUserData.data);

        return response;
      },
      {
        pending: (state) => {
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          state.status = "idle";
        },
        rejected: (state, action) => {

          state.status = "failed";
        },
      }
    ),

    authLogin: create.asyncThunk(
      async (authData: authLogin) => {
        const response = await fetchLogin(authData);


        return response;
      },
      {
        pending: (state) => {
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          if (action.payload.user) {
            state.status = "idle";
            state.user = action.payload.user;
            state.token = action.payload.token;
            state.isAuthenticated = true;
            state.token && localStorage.setItem("token", state.token);
          } else {
            state.status = "failed";
            state.user = null;
            state.isAuthenticated = false;
            state.errorMsg = action.payload.detail;
          }
        },
        rejected: (state) => {
          state.status = "failed";
        },
      }
    ),
    authLogout: create.asyncThunk(
      async (token: string) => {
        const response = await fetchLogout(token);

        return response;
      },
      {
        pending: (state) => {

          state.status = "loading";
        },
        fulfilled: (state, action) => {
          localStorage.removeItem("token");
          return initialState;
        },
        rejected: (state) => {
          state.status = "failed";
        },
      }
    ),
    setToken: create.asyncThunk(
      async (token: string) => {
        return token;
      },
      {
        pending: (state) => {
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          state.status = "idle";
          state.token = action.payload;
          // state.user = null;
          // localStorage.removeItem('token');
          //응답 받아서 챗봇 응답까지 표시
          // state.massages = [...state.massages, action.payload[action.payload.length - 1]]
        },
        rejected: (state) => {
          state.status = "failed";
          // 이과정 실패하면 x 같은것 표시하기
        },
      }
    ),

    userRegister: create.asyncThunk(
      async (authData: authRegister) => {
        const response = await fetchRegister(authData);

        return response;
      },
      {
        pending: (state) => {


          state.status = "loading";
        },
        fulfilled: (state, action) => {
          state.status = "idle";


          // state.user = null;
          // localStorage.removeItem('token');
          //응답 받아서 챗봇 응답까지 표시
          // state.massages = [...state.massages, action.payload[action.payload.length - 1]]
        },
        rejected: (state) => {
          state.status = "failed";
          // 이과정 실패하면 x 같은것 표시하기
        },
      }
    ),
    userLangChange: create.asyncThunk(
      async (userData: UserLangChangeData) => {
        const response = await fetchUserLangChange(userData);

        return response;
      },
      {
        pending: (state) => {


          state.status = "loading";
        },
        fulfilled: (state, action) => {
          state.status = "idle";
          state.user!.user_lang = action.payload.lang;


          // state.user = null;
          // localStorage.removeItem('token');
          //응답 받아서 챗봇 응답까지 표시
          // state.massages = [...state.massages, action.payload[action.payload.length - 1]]
        },
        rejected: (state) => {
          state.status = "failed";
          // 이과정 실패하면 x 같은것 표시하기
        },
      }
    ),
  }),

  selectors: {
    // selectIndex: (state) => state.index,
    selectUser: (state) => state.user,
    selectToken: (state) => state.token,
    selectStatus: (state) => state.status,
    selectErrorMsg: (state) => state.errorMsg,
    selectIsAuthenticated: (state) => state.isAuthenticated,
    // selectChatSide: (state) => state.chatSide,
    // selectChatMain: (state) => state.chatMain,
    // selectChatRoomId: (state) => state.chatMain.roomId,
  },
});

export const {
  authLogin,
  authLogout,
  userRegister,
  userMe,
  userLangChange,
  setToken,
  requestUser,
  passwordChange,
  serviceEmailSend,
} = authSlice.actions;
export const {
  // selectIndex,
  selectUser,
  selectToken,
  selectStatus,
  selectIsAuthenticated,
  selectErrorMsg,

  // selectChatSide,
  // selectChatMain,
  // selectChatRoomId
} = authSlice.selectors;
