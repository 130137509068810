export default {
    chatMenu: {
      title: {
        tit: "지원 및 서비스",
        list: [
            "기술적인 문제를 빠르게 진단하고 전문가가 해결해 드립니다.",
            "장비를 적절히 유지하는 방법에 대한 지침을 제공합니다.",
            "S&SYS 제품, 서비스 또는 기타 문의 사항에 대해 질문해 주세요.",
            "서비스 또는 예비 부품을 편리하게 요청할 수 있습니다."
        ]
      }
    },
    info_list: {
        title: "원하는 메뉴를 선택해 주세요",
        troubleshooting: {
            name: "트러블슈팅",
        },
        maintenance: {
            name: "유지보수 및 운영 가이드",
        },
        generalinquiry: {
            name: "일반 문의",
            update: "곧 업데이트 예정"
        },
        servicerequest: {
            name: "서비스 요청",
        },
    }
};
