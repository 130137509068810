import React, { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../lib/hooks"
import {
  adminKeywordList,
  adminKeywordAdd,
  selectDashboardChatbot,
  adminFileKeywordUpload,
  adminChatbotListGet,
  selectDashboardKeywords,
} from "../../../lib/features/admin/adminSlice"
import { selectToken, selectStatus } from "../../../lib/features/auth/authSlice"
import * as XLSX from "xlsx"

export default function AdminKeywordPage() {
  const dispatch = useAppDispatch()
  const token = useAppSelector(selectToken)
  const status = useAppSelector(selectStatus)
  const keywords = useAppSelector(selectDashboardKeywords)
  const chatbots = useAppSelector(selectDashboardChatbot)
  const [keywordText, setKeywordText] = useState("")
  const [activeTab, setActiveTab] = useState("keywords")

  useEffect(() => {
    if (token) {
      dispatch(adminChatbotListGet({ token }))
      dispatch(adminKeywordList({ token }))
    }
  }, [dispatch, token])

  const handleAddKeyword = () => {
    dispatch(
      adminKeywordAdd({
        token,
        keyword_text: keywordText,
        chatbotId: chatbots[0]?.chatbot_id,
      })
    ).then(() => {
      setKeywordText("")
      dispatch(adminKeywordList({ token }))
    })
  }

  const handleFileUpload = (e) => {
    const files = e.target.files
    if (files) {
      const formData = new FormData()
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i])
      }
      formData.append("chatbotId", chatbots[0]?.chatbot_id)
      dispatch(
        adminFileKeywordUpload({
          token,
          fileData: formData,
          chatbotId: chatbots[0]?.chatbot_id,
        })
      ).then(() => {
        dispatch(adminKeywordList({ token }))
      })
    }
  }

  const handleFileDownload = () => {
    const transformedData = keywords.map((keyword) => ({
      KEYWORD: keyword.keyword_text,
    }))
    const worksheet = XLSX.utils.json_to_sheet(transformedData)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, "Keywords")
    XLSX.writeFile(workbook, "Keywords.xlsx")
  }

  return (
    <div className="container mx-auto p-0 space-y-8">
      <h1 className="text-3xl font-bold mb-4">Admin Keyword Management</h1>
      <div className="mb-2">
        <ul className="flex space-x-4 border-b">
          {["Keywords", "Add Keyword", "File Management"].map((tab) => (
            <li key={tab}>
              <button
                className={`py-0 px-4 ${
                  activeTab === tab.toLowerCase().replace(" ", "-")
                    ? "border-b-2 border-blue-500 text-blue-500"
                    : "text-gray-500 hover:text-gray-700"
                }`}
                onClick={() => setActiveTab(tab.toLowerCase().replace(" ", "-"))}
              >
                {tab}
              </button>
            </li>
          ))}
        </ul>
      </div>

      {activeTab === "keywords" && (
        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-2xl font-semibold mb-4">Keyword List</h2>
          <p className="text-gray-600 mb-4">Manage your keywords here</p>
          {status === "loading" ? (
            <p>Loading...</p>
          ) : (
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
                    <th className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Keyword</th>
                    <th className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created At</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {keywords?.map((keyword) => (
                    <tr key={keyword.keyword_id}>
                      <td className="px-6 py-2 whitespace-nowrap">{keyword.keyword_id}</td>
                      <td className="px-6 py-2 whitespace-nowrap">{keyword.keyword_text}</td>
                      <td className="px-6 py-2 whitespace-nowrap">{new Date(keyword.created_at).toLocaleString()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}

      {activeTab === "add-keyword" && (
        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-2xl font-semibold mb-4">Add New Keyword</h2>
          <p className="text-gray-600 mb-4">Create a new keyword entry</p>
          <form onSubmit={(e) => { e.preventDefault(); handleAddKeyword(); }} className="space-y-4">
            <div className="space-y-2">
              <label htmlFor="keyword" className="block text-sm font-medium text-gray-700">Keyword</label>
              <input
                id="keyword"
                type="text"
                value={keywordText}
                onChange={(e) => setKeywordText(e.target.value)}
                required
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            <button
              type="submit"
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Add Keyword
            </button>
          </form>
        </div>
      )}

      {activeTab === "file-management" && (
        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-2xl font-semibold mb-4">File Management</h2>
          <p className="text-gray-600 mb-4">Upload and download keyword files</p>
          <div className="space-y-4">
            <div className="space-y-2">
              <label htmlFor="file-upload" className="block text-sm font-medium text-gray-700">Upload Excel File</label>
              <input
                id="file-upload"
                type="file"
                accept=".xlsx, .xls"
                multiple
                onChange={handleFileUpload}
                className="mt-1 block w-full text-sm text-gray-500
                file:mr-4 file:py-2 file:px-4
                file:rounded-md file:border-0
                file:text-sm file:font-semibold
                file:bg-blue-50 file:text-blue-700
                hover:file:bg-blue-100"
              />
            </div>
            <button
              onClick={handleFileDownload}
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Download Keywords as Excel
            </button>
          </div>
        </div>
      )}
    </div>
  )
}