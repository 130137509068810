import React from "react";
// import "./dashboard.css";
import AdminChatbotPage from "./pages/AdminChatbotPage";
import AdminLogPage from "./pages/AdminLogPage";
import AdminUserPage from "./pages/AdminUserPage";
import AdminModelPage from "./pages/AdminModelPage";
import AdminFaqPage from "./pages/AdminFaqPage";
import AdminKeywordPage from "./pages/AdminKeywordPage";
import AdminModelChangePage from "./pages/AdminModelChangePage";
import AdminFilePage from "./pages/AdminFilePage";
import AdminDataPage from "./pages/AdminDataPage";
import AdminFeedbackPage from "./pages/AdminFeedbackPage";
import AdminServicePage from "./pages/AdminServicePage";

function Dashboard(props) {
  const menu = props.menu;
  const menuIndex = props.menuIndex;

  return (
    <section
      className="p-4 w-full section"
      style={{
        // height: "90vh",
        overflowY: "scroll",
        scrollbarWidth: "none",
        scrollBehavior: "smooth",
      }}
    >
      {menu.label == "관리" ? (
        <>
          {menu.items[menuIndex].label == "Users" ? (
            <AdminUserPage />
          ) : null}
          {menu.items[menuIndex].label == "Files" ? (
            <AdminFilePage />
          ) : null}
          {menu.items[menuIndex].label == "Chatbots" ? (
            <AdminChatbotPage />
          ) : null}
          {menu.items[menuIndex].label == "Service" ? (
            <AdminServicePage />
          ) : null}
          {/* {menu.items[menuIndex].label == 2 ? <AdminModelPage /> : null} */}
          {menu.items[menuIndex].label == "Logs" ? (
            <AdminLogPage />
          ) : null}
          {menu.items[menuIndex].label == "Datas" ? (
            <AdminDataPage />
          ) : null}
          {menu.items[menuIndex].label == "Feedbacks" ? (
            <AdminFeedbackPage />
          ) : null}
          {menu.items[menuIndex].label ==
          "Model" ? (
            <AdminModelPage />
          ) : null}
          {/* {menu.items[menuIndex].label == "유저 가입 신청 관리" ? (
            <AdminServicePage />
          ) : null} */}
        </>
      ) : null}
      {/* {" / "+ menu.label + " / " + menu.items[menuIndex].label} */}

      <div className="row">
        <div className="col-lg-8">
          <div className="row"></div>
        </div>
        <div className="col-lg-4"></div>
      </div>
    </section>
  );
}

export default Dashboard;
