import React, { useEffect, useRef, useState } from "react";
import { authLogout, selectUser, selectToken, userLangChange } from "../../lib/features/auth/authSlice";
import { selectChatSide, setChatRoomId, sidebarSelRoom, chatMessageGet } from "../../lib/features/chatMain/chatMainSlice";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import { useNavigate } from "react-router-dom";

const APP_STAGE = process.env.REACT_APP_STAGE;

function NavHistory({sidebar}) {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const token = useAppSelector(selectToken);
  const chatHistoryItem = useAppSelector(selectChatSide);

  const [currentLanguage, setCurrentLanguage] = useState(user?.user_lang);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/");
    }
  }, [user]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    // Add event listener when dropdown is open
    if (dropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownOpen]);

  const handleSignOut = () => {
    dispatch(authLogout(token));
  };

  const handleLanguageChange = (language) => {
    setCurrentLanguage(language);
    changeLanguage(language);
    dispatch(userLangChange({ token, lang: language }));
  };

  const changeLanguage = (language) => {
    if (APP_STAGE === "DEV") {  
      console.log("Selected language:", language);
    }
  };

  const handleMenuClick = (list_idx, room_idx) => {
    dispatch(setChatRoomId(room_idx));
    const idx = chatHistoryItem.chatMenu[0].chatList.findIndex(item=>item.roomId==room_idx)
    dispatch(sidebarSelRoom([0, idx]));
    dispatch(chatMessageGet({ room_id: room_idx, token: token }));
  }

  return (
    <>
      {user ? (
        <li className="relative">
          {/* <button
            className="flex items-center space-x-2 focus:outline-none"
            
          >
            <span className="text-sm font-medium">{user.user_name[0]}</span>
          </button> */}
          <button 
            onClick={() => setDropdownOpen(!dropdownOpen)}
            class="focus-visible:ring-offset-background inline-flex shrink-0 cursor-pointer items-center justify-center gap-1.5 whitespace-nowrap text-nowrap border font-medium outline-none ring-blue-600 transition-all focus-visible:ring-2 focus-visible:ring-offset-1 disabled:pointer-events-none disabled:cursor-not-allowed disabled:ring-0 has-[:focus-visible]:ring-2 [&amp;>svg]:pointer-events-none [&amp;>svg]:size-4 [&amp;_svg]:shrink-0 border-transparent bg-transparent text-gray-900 disabled:border-transparent disabled:bg-transparent disabled:text-gray-400 px-1 text-sm has-[>kbd]:gap-3 has-[>kbd]:pr-[6px] rounded-lg hover:border-gray-150 hover:bg-gray-150 focus:border-gray-150 focus:bg-gray-150 focus-visible:border-gray-150 focus-visible:bg-gray-150 data-[state=open]:border-gray-150 data-[state=open]:bg-gray-150 size-8 has-[>svg]:px-0" aria-label="Chat History" type="button" aria-haspopup="dialog" aria-expanded="false" aria-controls="radix-:r1bc:" data-state="closed">
            <span aria-hidden="true">
              <svg data-testid="geist-icon" height="16" stroke-linejoin="round" viewBox="0 0 16 16" width="16" style={{color: "currentcolor"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.35066 2.06247C5.96369 1.78847 6.62701 1.60666 7.32351 1.53473L7.16943 0.0426636C6.31208 0.1312 5.49436 0.355227 4.73858 0.693033L5.35066 2.06247ZM8.67651 1.53473C11.9481 1.87258 14.5 4.63876 14.5 8.00001C14.5 11.5899 11.5899 14.5 8.00001 14.5C4.63901 14.5 1.87298 11.9485 1.5348 8.67722L0.0427551 8.83147C0.459163 12.8594 3.86234 16 8.00001 16C12.4183 16 16 12.4183 16 8.00001C16 3.86204 12.8589 0.458666 8.83059 0.0426636L8.67651 1.53473ZM2.73972 4.18084C3.14144 3.62861 3.62803 3.14195 4.18021 2.74018L3.29768 1.52727C2.61875 2.02128 2.02064 2.61945 1.52671 3.29845L2.73972 4.18084ZM1.5348 7.32279C1.60678 6.62656 1.78856 5.96348 2.06247 5.35066L0.693033 4.73858C0.355343 5.4941 0.131354 6.31152 0.0427551 7.16854L1.5348 7.32279ZM8.75001 4.75V4H7.25001V4.75V7.875C7.25001 8.18976 7.3982 8.48615 7.65001 8.675L9.55001 10.1L10.15 10.55L11.05 9.35L10.45 8.9L8.75001 7.625V4.75Z" fill="currentColor">
                </path>
              </svg>
            </span>
          </button>

          {/* Dropdown Menu */}
          {dropdownOpen && (
            <ul 
              ref={dropdownRef}
              className={`absolute p-2 w-52 bg-white border border-gray-200 rounded-lg shadow-lg z-50 ${sidebar?"translate-x-full right-0 top-0 -ml-4":"right-0"}`}>
              <li className="px-2 py-1">
                <h6 className="text-gray-700 font-bold">Chat History</h6>
              </li>

              {chatHistoryItem.chatMenu&&
                chatHistoryItem.chatMenu[0].chatList.slice(0,20).map((item, idx)=>
                  <li
                    className="px-2 py-1 hover:bg-gray-100 cursor-pointer"
                    onClick={() => handleMenuClick(idx, item.roomId)}
                  >
                    {/* <i className="bi bi-chat-dots mr-2 "/> */}
                    <p className="truncate mb-0">{item.chatName}</p>
                  </li>
                )}
                {chatHistoryItem.chatMenu&&
                chatHistoryItem.chatMenu[0].chatList.length>20&&
                  <li
                    className="px-2 py-1 hover:bg-gray-100 cursor-pointer"
                    onClick={()=>{}}
                  >
                    {/* <i className="bi bi-chat-dots mr-2"></i> */}
                    <hr className="my-1"/>
                    <span className='text-right'>... 더 보기 </span>
                  </li>
                }
              

              
              
            </ul>
          )}
        </li>
      ) : (
        <></>
      )}
    </>
  );
}

export default NavHistory;
