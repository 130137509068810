import React, { useEffect, useState } from 'react'
import {
  setDocViewer,
  setToggleDocViewer,

  selectChatMain,
  selectChatMode,
  setChatMode,
  selectDocViewer,
  setChatRoomId,
} from "../../lib/features/chatMain/chatMainSlice";
import {useAppDispatch, useAppSelector } from "../../lib/hooks";
import NavAvatar from '../dashboard/NavAvatar';
import logo from '../../images/snsys_logo.png';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function ChatHeader(props) {
  const dispatch = useAppDispatch();
  const chatMain = useAppSelector(selectChatMain);
  const chatMode = useAppSelector(selectChatMode);

  const [chatTitle1, setChatTitle1] = useState(chatMode.mode_1);
  const [chatTitle2, setChatTitle2] = useState(chatMode.mode_2=="TS"?"Troubleshooting"
                                                  :chatMode.mode_2=="MT"?"Maintenance"
                                                  :chatMode.mode_2=="GI"?"General Inquery"
                                                  :chatMode.mode_2=="SR"?"Service Request"
                                                  :""
  );
  const navigator = useNavigate();
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState('EN');

  

  useEffect(()=>{
    setChatTitle1(chatMode.mode_1);
    setChatTitle2((chatMode.mode_2=="TS"?"Troubleshooting"
      :chatMode.mode_2=="MT"?"Maintenance"
      :chatMode.mode_2=="GI"?"General Inquery"
      :chatMode.mode_2=="SR"?"Service Request"
      :""))
  }, [chatMode])
  // const handleToggleDocSideBar = () => {
  //     document.body.classList.toggle('toggle-sidebar-doc');
  //     dispatch(setToggleDocViewer());
  // };
  const handleLanguageChange = () => {
    const newLang = currentLanguage === 'EN' ? 'KO' : 'EN';
    setCurrentLanguage(newLang);
    i18n.changeLanguage(newLang.toLowerCase());
  };

  const handleNewChatSelChat = (menu) => {
    navigator('/chat')
    dispatch(setChatRoomId(null))
    dispatch(setChatMode({
      mode_1: chatMode.mode_1,
      mode_2: menu,
      isNewChat: false
    }));
    dispatch(setDocViewer({
      docUrl: "",
      docPage: 0,
      docTitle: "",
      isOn: false,
      }))
  };
  const handleMoveMenu = () =>{
    navigator('/menu')
    dispatch(setChatRoomId(null))
    dispatch(setDocViewer({
      docUrl: "",
      docPage: 0,
      docTitle: "",
      isOn: false,
      }))
  }
  const handleNewChat = () => {
    navigator('/chat')
    dispatch(setChatRoomId(null))
    dispatch(setChatMode({
      mode_1: chatMode.mode_1,
      mode_2: chatMode.mode_2!=""? chatMode.mode_2: "",
      isNewChat: true
    }));
    dispatch(setDocViewer({
      docUrl: "",
      docPage: 0,
      docTitle: "",
      isOn: false,
      }))
  };

  return (
    <header className="bg-background relative z-10 ml-[0px] flex h-12 w-full shrink-0 items-center justify-between gap-1 px-0 sm:mx-0 sm:w-full sm:px-0 pb-2 border-b border-gray-100 sm:hidden">
      <nav className="flex w-full flex-1 items-center sm:gap-1 ">
        <div className="flex w-full justify-between items-center gap-1 sm:justify-start">
          
          <div className="flex flex-col items-center justify-center ml-0 relative group/tooltip sm:ml-2 ">
          {!props.newChat && 
            <div className="flex flex-row w-8">
              <button 
                onClick={()=>handleNewChatSelChat("")}
                className="focus-visible:ring-offset-background inline-flex shrink-0 cursor-pointer items-center justify-center gap-1.5 whitespace-nowrap text-nowrap font-medium outline-none ring-blue-600 transition-all focus-visible:ring-2 focus-visible:ring-offset-1 disabled:pointer-events-none disabled:cursor-not-allowed disabled:ring-0 has-[:focus-visible]:ring-2 [&amp;>svg]:pointer-events-none [&amp;>svg]:size-4 [&amp;_svg]:shrink-0 border-transparent bg-transparent text-gray-900 disabled:border-transparent disabled:bg-transparent disabled:text-gray-400 px-1 text-sm has-[>kbd]:gap-3 has-[>kbd]:pr-[6px] rounded-lg hover:border-gray-150 hover:bg-gray-150 focus:border-gray-150 focus:bg-gray-150 focus-visible:border-gray-150 focus-visible:bg-gray-150 data-[state=open]:border-gray-150 data-[state=open]:bg-gray-150 size-8 has-[>svg]:px-0">
                <span aria-hidden="true">
                  <i class="bi bi-arrow-left"></i>
                </span>
              </button>
              <h1 className="sm:hidden  min-w-0 items-center gap-1 text-xs sm:text-sm sm:font-bold m-0 ml-4 text-gray-900" data-testid="chat-title">
                {chatTitle1}<br/>{chatTitle2}
              </h1>
            </div>
            }
          </div>
          
          
          <div className="hidden sm:flex justify-center">
          {!props.newChat && 
            <h1 className="self-center min-w-0 items-center gap-1 text-xs sm:text-sm sm:font-bold m-0 ml-4 text-gray-900" data-testid="chat-title">
              {chatTitle1+ " > " +chatTitle2}
            </h1>
            }
          </div>

           <div 
              className="absolute left-1/2 transform -translate-x-1/2 top-0 w-24 cursor-pointer items-center transition-all px-1 size-8 " 
              onClick={()=>handleNewChatSelChat("")}
           >
            <img 
              src={logo} 
              className="cursor-pointer gap-1.5 w-full h-full object-contain"
            />
          </div>
          <div className="flex gap-1 sm:hidden w-32 justify-end">
          <HeaderButton 
            onClick={handleLanguageChange} 
            icon={
              <i class="bi bi-globe"/>
            }
            tooltip={"언어변경"}
          />
          <HeaderButton 
            onClick={handleMoveMenu} 
            icon={
              <i class="bi bi-house"/>
            }
            tooltip={"Home"}
          />
          <HeaderButton 
            onClick={handleNewChat} 
            icon={
              <i class="bi bi-x-lg"/>
            }
            tooltip={"Reset Chat"}
          />
            <NavAvatar />
          </div>
        </div>
      </nav>
      {/* <div className="ml-auto flex items-center gap-2">
      </div> */}
    </header>
  )
}


function HeaderButton({onClick, icon, text, tooltip}) {
  return (<div 
          onClick={()=>onClick()}
          className="flex flex-col items-center justify-center relative group/tooltip">
             <div className="
              flex 
              justify-center 
              items-center 
              w-6
              h-6
              sm:w-8
              sm:h-8
              !border
              border-blue-400
              focus:border-blue-400
              focus-visible:border-blue-400
              disabled:border-blue-300
              group-hover:border-blue-400
              focus-visible:ring-offset-background
              inline-flex
              shrink-0
              cursor-pointer
              whitespace-nowrap
              text-nowrap
              font-medium
              ring-blue-600
              transition-all
              [&amp;>svg]:pointer-events-none
              [&amp;>svg]:size-4
              [&amp;_svg]:shrink-0
              text-gray-900
              focus:bg-gray-100
              focus-visible:bg-gray-100
              px-1
              has-[>kbd]:gap-3
              has-[>kbd]:pr-[6px]
              rounded-lg
              hover:bg-gray-150
              shadow-[0px_1px_2px_0px_hsla(0,0%,0%,0.04)]
              has-[>svg]:px-0"
              href="/chat">
       <span aria-hidden="true" className=''>
         {icon}
       </span>
   </div>
          <span className="absolute top-1/2 left-0 transform translate-y-1/2 -translate-x-full  ml-2 w-32 p-2  z-30 bg-black text-white 
                      text-center text-xs rounded opacity-[0.001]  group-hover/tooltip:opacity-100 
                      transition-opacity duration-300 pointer-events-none">
            <span className="">{tooltip}</span>
          </span>
        </div>)
}

export default ChatHeader
