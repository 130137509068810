import React, { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../lib/hooks";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../ui/table";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DropdownMenuSub,
  DropdownMenuSubTrigger,
  DropdownMenuSubContent,
} from "../../ui/dropdown-menu";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../ui/dialog";

import {
  Plus,
  Search,
  Filter,
  EllipsisVertical,
  MoreVertical,
  Check,
  X,
  Trash2,
  UserPlus,
  Users,
} from "lucide-react";
import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import Pagination from "../Common/Pagination";
import { adminUserEmailSend } from "../../../lib/features/admin/adminSlice";
import { Textarea } from "../../ui/textarea";

const ITEMS_PER_PAGE = 20;
const APP_STAGE = process.env.REACT_APP_STAGE;

const SignUpRequestTable = ({
  signUpRequests,
  token,
  adminUserSignUpRequestGetChange,
}: {
  signUpRequests: any;
  token: any;
  adminUserSignUpRequestGetChange: any;
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useAppDispatch();
  const [sortConfig, setSortConfig] = useState({ key: "id", direction: "asc" });
  const [selectedCompany, setSelectedCompany] = useState<number | undefined>(
    undefined
  );
  const [userNm, setUserNm] = useState("");
  const [emailRE, setEmail] = useState<string>("");
  const [requestId, setRequestId] = useState<number>(0);
  const companies = useAppSelector((state) => state.adminPage.dashboard.company);
  const users = useAppSelector((state) => state.adminPage.dashboard.user);

  const [approveForm, setApproveForm] = useState({
    id: "",
    imo: "",
    name: "",
    email: "",
    company: "",
    contactNumber: "",
    vesselName: "",
    reason: "",
    is_email_sent: false
  });

  function formatDateTime(dateTimeString: string) {
    if (dateTimeString.length !== 14) {
      return "잘못된 입력 형식입니다.";
    }

    const year = dateTimeString.slice(0, 4);
    const month = dateTimeString.slice(4, 6);
    const day = dateTimeString.slice(6, 8);
    const hour = dateTimeString.slice(8, 10);
    const minute = dateTimeString.slice(10, 12);
    const second = dateTimeString.slice(12, 14);

    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  }
  // 전체 상태 정리
  // 활성, 잠김(이메일, 승인안됨, 비번오류, 이상감지)
  function getStatus(req: any) {
    if (req.registration_req_status == "pending") {
      return "Pending";
    } else if (req.registration_req_status == "approved") {
      return "Approved";
    } else if (req.registration_req_status == "in progress") {
      return "In Progress";
    } else if (req.registration_req_status == "rejected") {
      return "Rejected";
    } else if (req.registration_req_status == "on hold") {
      return "On Hold";
    } else if (req.registration_req_status == "registered") {
      return "Registered";
    } else {
      return "Unknown";
    }
  }
  const [reqForm, setReqForm] = useState(
    signUpRequests.map((req: any) => ({
      id: req.registration_req_id,
      imo: req.registration_req_imo_no,
      name: req.registration_req_your_name,
      email: req.registration_req_email,
      company: req.registration_req_company,
      contactNumber: req.registration_req_contact_num,
      vesselName: req.registration_req_vessel_name,
      is_email_sent: req.is_email_sent,
      reqDate: formatDateTime(req.created_at.replace(/\D/g, "")).slice(0, 10),
      processedDate: formatDateTime(req.updated_at.replace(/\D/g, "")).slice(0, 10),
      status: getStatus(req),
      reason: req.registration_req_reason,
    })) || []
  );
  useEffect(() => {
    setReqForm(
      signUpRequests.map((req: any) => ({
        id: req.registration_req_id,
        imo: req.registration_req_imo_no,
        name: req.registration_req_your_name,
        email: req.registration_req_email,
        company: req.registration_req_company,
        contactNumber: req.registration_req_contact_num,
        vesselName: req.registration_req_vessel_name,
        is_email_sent: req.is_email_sent,
        reqDate: formatDateTime(req.created_at.replace(/\D/g, "")).slice(0, 10),
        processedDate: formatDateTime(req.updated_at.replace(/\D/g, "")).slice(0, 10),
        status: getStatus(req),
        reason: req.registration_req_reason,
      })) || []
    );
  }, [signUpRequests]);

  const [isDialogOpen, setIsDialogOpen] = useState({
    su: false,
  });

  const handleDialogClose = (type: string) => {
    setIsDialogOpen((prev) => ({ ...prev, [type]: false }));
  };

  const handleDialogOpen = (
    type: string,
    request: any,
  ) => {
    switch (type) {
      case "su":
        if (request.is_email_sent) {
          alert("이미 가입승인 이메일을 보냈습니다.");
          setIsDialogOpen((prev) => ({ ...prev, [type]: false }));
          break;
        }
        setIsDialogOpen((prev) => ({ ...prev, [type]: true }));
        setApproveForm(request);
        break;
    }
  };

  const handleSubmit = () => {
    if (approveForm.imo == "" || approveForm.name == "" || approveForm.email == "") {
      alert("Please fill in all the fields.");
      return;
    }
    const companyName: string = companies.some((company: any) => company.company_name.toLowerCase() === approveForm.company.toLowerCase()) ?
      companies.find(
        (company: any) => company.company_name.toLowerCase() === approveForm.company.toLowerCase()
      ).company_name
      : approveForm.company;

    dispatch(
      adminUserEmailSend({
        token,
        company_name: companyName,
        login_id: approveForm.imo,
        email: approveForm.email,
        request_id: Number(approveForm.id),
      })
    ).then(() => {
      // UI 업데이트
      setReqForm((prev: any) =>
        prev.map((req: any) =>
          req.id === Number(approveForm.id)
            ? { ...req, status: "Approved", is_email_sent: true }
            : req
        )
      );

      // 서버 데이터 동기화
      adminUserSignUpRequestGetChange();

      alert("Sign-up approval email has been sent.");
      setUserNm("");
      setSelectedCompany(undefined);
      setIsDialogOpen((prev) => ({ ...prev, ["su"]: false }));
    });
  };

  const sortedSignUpRequests = useMemo(() => {
    return [...reqForm].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key])
        return sortConfig.direction === "asc" ? -1 : 1;
      if (a[sortConfig.key] > b[sortConfig.key])
        return sortConfig.direction === "asc" ? 1 : -1;
      return 0;
    });
  }, [reqForm, sortConfig]);
  const totalPages = Math.ceil(sortedSignUpRequests.length / ITEMS_PER_PAGE);
  const paginatedSignUpRequests = sortedSignUpRequests.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const handleSort = (key: string) => {
    setSortConfig((currentConfig) => {
      if (currentConfig.key === key) {
        return {
          ...currentConfig,
          direction: currentConfig.direction === "asc" ? "desc" : "asc",
        };
      }
      return { key, direction: "asc" };
    });
  };
  if (APP_STAGE === "DEV") {
    console.log({ paginatedSignUpRequests });
  }

  // TODO: 가입 거절 기능 추가
  // TODO: 가입 승인 후 이메일 발송 기능 추가
  // TODO: IMO ID 중복 체크 기능 추가
  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden p-4">
      <div className="border-b flex justify-between items-center">
        <h2 className="text-2xl font-semibold mb-4">Sign-up Request List</h2>
      </div>

      {/* Sign-Up Request Dialog */}
      <Dialog
        open={isDialogOpen.su}
        onOpenChange={() => handleDialogClose("su")}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Sign-up Approval & User Creation</DialogTitle>
          </DialogHeader>
          <div className="flex flex-col gap-1">
            <Input id="imo"
              value={approveForm.imo}
              isNoSpinner={true}
              label="IMO"
              onChange={(e) => setApproveForm({ ...approveForm, imo: e.target.value })}
              comment={users?.users.some((user: any) => user.login_id.toLowerCase() === approveForm.imo.toLowerCase())
                ? "IMO is used as the login ID. The ID  already exists."
                : "IMO is used as the login ID. The ID is available."}
              commentColor={users?.users.some((user: any) => user.login_id.toLowerCase() === approveForm.imo.toLowerCase()) ? "text-red-500" : "text-green-500"}
            />
            <Input id="name"
              value={approveForm.name}
              isNoSpinner={true}
              label="NAME"
              onChange={(e) => setApproveForm({ ...approveForm, name: e.target.value })}
            />
            <Input id="email"
              value={approveForm.email}
              isNoSpinner={true}
              label="EMAIL"
              // onChange={(e) => setApproveForm({ ...approveForm, email: e.target.value })}
              onChange={() => { }}
            />
            <Input id="company"
              value={approveForm.company}
              isNoSpinner={true}
              label="COMPANY"
              onChange={(e) => setApproveForm({ ...approveForm, company: e.target.value })}
              comment={companies.some((company: any) => company.company_name.toLowerCase() === approveForm.company.toLowerCase())
                ? "The company name already exists. It will be registered as \"" + companies.find(
                  (company: any) => company.company_name.toLowerCase() === approveForm.company.toLowerCase()
                ).company_name + "\""
                : "The company name does not exist. It will be registered as \"" + approveForm.company + "\""}
              commentColor={companies.some((company: any) => company.company_name.toLowerCase() === approveForm.company.toLowerCase()) ? "text-green-500" : "text-red-500"}
            />
            <Input id="contactNumber"
              value={approveForm.contactNumber}
              isNoSpinner={true}
              label="CONTACT NUMBER"
              onChange={(e) => setApproveForm({ ...approveForm, contactNumber: e.target.value })}
            />
            <Input id="vesselName"
              value={approveForm.vesselName}
              isNoSpinner={true}
              label="VESSEL NAME"
              onChange={(e) => setApproveForm({ ...approveForm, vesselName: e.target.value })}
            />
            <Textarea id="Additional Detail"
              value={approveForm.reason}
              isNoSpinner={true}
              label="REASON"
              onChange={() => { }}
            />
          </div>
          {/* <div className="space-y-4">
              <label>
              회사 선택
              <select
                value={selectedCompany}
                onChange={(e) => {
                  setSelectedCompany(Number(e.target.value));
                }}
                className="w-full mt-1 border rounded p-2"
              >
                <option value="">회사 선택</option>
                {companys &&
                  companys.map((ca: any) => (
                    <option key={ca.company_id} value={ca.company_id}>
                      {ca.company_name}
                    </option>
                  ))}
              </select>
            </label>

            <label>
              사용자 이름
              <input
                value={userNm}
                onChange={(e) => setUserNm(e.target.value)}
                placeholder="이름 입력"
              />
            </label>
          </div> */}
          <DialogFooter>
            <button
              type="submit"
              className="flex justify-center items-center w-full text-center px-4 py-3 mb-2 bg-blue-500 text-md text-white rounded-lg bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors gap-4"
              onClick={handleSubmit}
            >
              Approve & Send email
              {/* <ArrowRightIcon className="w-4 h-4" /> */}
            </button>
            {/* <Button onClick={handleSubmit}>Approve & Sned email</Button> */}
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <Table>
        <TableHeader>
          <TableRow>
            {[
              "id",
              "imo",
              "email",
              "company",
              "vesselName",
              "reqDate",
              "processedDate",
              "status",
            ].map((key) => (
              <TableHead
                key={key}
                className="px-6 py-2 text-left text-xs text-nowrap font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                onClick={() => handleSort(key)}
              >
                {key === "id"
                  ? "No."
                  : key === "imo"
                    ? "IMO"
                    : key === "email"
                      ? "EMAIL"
                      : key === "company"
                        ? "COMPANY"
                        : key === "vesselName"
                          ? "VESSEL NAME"
                          : key === "reqDate"
                            ? "REQUEST DATE"
                            : key === "processedDate"
                              ? "PROCESSED DATE"
                              : "STATUS"}
                {sortConfig.key === key && (
                  <span className="ml-1">
                    {sortConfig.direction === "asc" ? "▲" : "▼"}
                  </span>
                )}
              </TableHead>
            ))}
            <TableHead>ACTION</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {paginatedSignUpRequests &&
            paginatedSignUpRequests.map((signUpRequest) => (
              <TableRow key={signUpRequest.id}>
                <TableCell>{signUpRequest.id}</TableCell>
                <TableCell>{signUpRequest.imo}</TableCell>
                <TableCell>{signUpRequest.email}</TableCell>
                <TableCell>{signUpRequest.company}</TableCell>
                {/* <TableCell>{signUpRequest.shipOwner}</TableCell> */}
                <TableCell>{signUpRequest.vesselName}</TableCell>
                <TableCell>{signUpRequest.reqDate}</TableCell>
                <TableCell>{signUpRequest.processedDate}</TableCell>
                <TableCell>
                  {
                    signUpRequest.status == "Pending"
                      ? <span className="border border-blue-500 text-xs text-blue-500 rounded-full px-2 py-1">Pending</span>
                      : signUpRequest.status == "Approved"
                        ? <span className="border border-green-500 text-xs text-green-500 rounded-full px-2 py-1">Approved</span>
                        : signUpRequest.status == "In Progress"
                          ? <span className="border border-yellow-500 text-xs text-yellow-500 rounded-full px-2 py-1">In Progress</span>
                          : signUpRequest.status == "Rejected"
                            ? <span className="border border-red-500 text-xs text-red-500 rounded-full px-2 py-1">Rejected</span>
                            : signUpRequest.status == "On Hold"
                              ? <span className="border border-orange-500 text-xs text-orange-500 rounded-full px-2 py-1">On Hold</span>
                              : signUpRequest.status == "Registered"
                                ? <span className="border border-green-500 text-xs text-green-500 rounded-full px-2 py-1">Success</span>
                                : <span className="border border-gray-500 text-xs text-gray-500 rounded-full px-2 py-1">Unknown</span>
                  }
                </TableCell>
                <TableCell>
                  <DropdownMenu>
                    <DropdownMenuTrigger>
                      <EllipsisVertical className="h-4 w-4" />
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                      {/* <DropdownMenuItem>정보보기</DropdownMenuItem> */}
                      <DropdownMenuItem
                        onClick={() =>
                          handleDialogOpen(
                            "su",
                            signUpRequest
                          )
                        }
                      >
                        Approval
                      </DropdownMenuItem>
                      {/* <DropdownMenuItem>대화내역</DropdownMenuItem>
                                        <DropdownMenuItem>권한변경</DropdownMenuItem>
                                        <DropdownMenuItem>회사변경</DropdownMenuItem> */}
                      <DropdownMenuSeparator />
                      <DropdownMenuItem>Rejection</DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={signUpRequests ? Math.ceil(signUpRequests?.length / 20) : 1}
        totalNumber={signUpRequests?.length}
        listName="Sign-up Request"
      />
    </div>
  );
};

export default SignUpRequestTable;
