import React, { useEffect, useRef, useState } from "react";
import { authLogout, selectUser, selectToken, userLangChange } from "../../lib/features/auth/authSlice";
import { 
  selectChatSide, 
  setChatRoomId, 
  sidebarSelRoom, 
  chatMessageGet,
  chatRoomGet, 
  chatRoomDelete,
  selectChatHistory,
} from "../../lib/features/chatMain/chatMainSlice";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import { useNavigate } from "react-router-dom";
import avatar from "../../images/logo.jpg";
import ChatHeader from "./ChatHeader";

// type ChatType = "user" | "assistant" | "system"

// interface ChatItem {
//   id: string
//   type: ChatType
//   content: string
//   createdAt: Date
//   avatar: string
// }

// const getChatTypeLabel = (type: ChatType) => {
//   switch (type) {
//     case "user":
//       return "사용자"
//     case "assistant":
//       return "AI 어시스턴트"
//     case "system":
//       return "시스템"
//     default:
//       return "알 수 없음"
//   }
// }

// 아바타 컴포넌트
const Avatar = ({ src, alt }) => (
  <div
    className="w-10 h-10 rounded-full overflow-hidden bg-blue-100 flex justify-center items-center"
    style={{
      backgroundImage: `url(${avatar})`,
      backgroundSize: '80%',  // 이미지 확대
      backgroundPosition: 'center',  // 이미지 중앙 정렬
      backgroundRepeat: 'no-repeat',  // 이미지 반복 방지
    }}
    aria-label={alt}
  >
  </div>
);


// 삭제 아이콘 컴포넌트
const DeleteIcon = () => (
  <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 6H5H21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6H19Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

const APP_STAGE = process.env.REACT_APP_STAGE;
function ChatHistory() {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const token = useAppSelector(selectToken);
  const chatHistoryItem = useAppSelector(selectChatHistory);

  const [currentLanguage, setCurrentLanguage] = useState(user?.user_lang);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/");
    }
  }, [user]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    // Add event listener when dropdown is open
    if (dropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownOpen]);

  const handleSignOut = () => {
    dispatch(authLogout(token));
  };

  const handleLanguageChange = (language) => {
    setCurrentLanguage(language);
    changeLanguage(language);
    dispatch(userLangChange({ token, lang: language }));
  };

  const changeLanguage = (language) => {
    if (APP_STAGE === "DEV") {  
      console.log("Selected language:", language);
    }
  };

  const handleMenuClick = (list_idx, room_idx) => {
    dispatch(setChatRoomId(room_idx));
    const idx = chatHistoryItem.findIndex(item=>item.room_id==room_idx)
    dispatch(sidebarSelRoom([0, idx]));
    dispatch(chatMessageGet({ room_id: room_idx, token: token }));

    setTimeout(()=>navigate("/chat"),200);
  }
  const handleDeleteChat = (room_idx)=>{
    dispatch(chatRoomDelete({ room_id: room_idx, token: token })).then(
      ()=>{
        dispatch(chatRoomGet({token: token }))
      }
    )
  }
  if (APP_STAGE === "DEV") {  
    console.log({chatHistoryItem})
  }
  return (
    <main id="chatMain" 
      className='relative h-full flex-1 py-2'>
      <ChatHeader />
      <div className="max-w-4xl h-full mx-auto bg-white">
        <h1 className="text-2xl font-bold mb-1 text-center sm:text-left">Chat History</h1>
        <div className="space-y-4 h-full  overflow-y-scroll-auto p-10">
        {chatHistoryItem &&
            chatHistoryItem.slice(0, 50).map((item, idx) =>(
            <div 
              key={item.room_id} 
              onClick={() => handleMenuClick(idx, item.room_id)}
              className="border-gray-300 border-l-4 border-l-[#1565c0] border-b border-t border-r  p-4 py-2 bg-gray-50 rounded-r-lg rounded flex items-start space-x-4 hover:cursor-pointer hover:bg-[#f5f5f5]">
              <Avatar src={""} alt={""} />
              <div className="flex-1 min-w-0 ">
                <div className="flex items-center justify-start gap-4 mb-1">
                  <span className="text-xs font-bold text-[#1565c0]">{item.cgroup_type=="BWMS-TS"?"BWMS Troubleshooting":item.cgroup_type=="BWMS-MT"?"BWMS Maintenance":item.cgroup_type=="BWMS-GI"?"BWMS General Inquiry":"BWMS Unknown"}</span>
                  <span className="text-xs font-medium text-gray-500">
                    {/* {item.created_at.toLocaleString("ko-KR", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                    })} */}
                    {item.created_at}
                  </span>
                </div>
                <p className="text-sm text-gray-700 break-words mb-0">{item.room_name}</p>
              </div>
              <button 
                className="text-gray-400 hover:text-[#1565c0] transition-colors duration-200"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent triggering the chat click event
                  handleDeleteChat(item.room_id); // Define a delete handler function
                }}
                aria-label="Delete"
              >
                <DeleteIcon />
              </button>
            </div>
          ))}
        </div>
      </div>

    </main>
  )
}

export default ChatHistory
