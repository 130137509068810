import React, { useEffect, useState, useCallback, useMemo } from "react"
import { useAppDispatch, useAppSelector } from "../../../lib/hooks"
import {
  adminUserAllGet,
  adminGroupListGet,
  adminModelListGet,
  adminFileListGet,
  adminFGroupListGet,
  adminFGroupAllListGet,
  adminFileListGetOnfid,
  adminPGroupListGet,
  adminPGroupFileListGet,
  adminChatbotListGet,
  adminPGroupAdd,
  adminPGroupDelete,
  adminFileAdd,
  adminFileDelete,
  selectDashboardUser,
  selectDashboardGroup,
  selectDashboardModel,
  selectDashboardFile,
  selectDashboardFGroup,
  selectDashboardFGroupAll,
  selectDashboardPGroup,
  selectDashboardPGroupFile,
  selectDashboardChatbot,
} from "../../../lib/features/admin/adminSlice"
import { selectToken, selectStatus } from "../../../lib/features/auth/authSlice"
import { FaTrashAlt } from "react-icons/fa"
import TreeView from "../FilePage/FileTree"

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../ui/table"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
    DropdownMenuSub,
    DropdownMenuSubTrigger,
    DropdownMenuSubContent,
} from "../../ui/dropdown-menu"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../../ui/dialog"

import { Plus, Search, Filter, EllipsisVertical, MoreVertical, Check, X, Trash2, UserPlus, Users } from "lucide-react"
import { Button } from "../../ui/button"
import { Input } from "../../ui/input"
import Pagination from "../Common/Pagination";
import FileExplorer from "./FileExplorer";
import FileList from "./FileList";

type DirFile = {
    fgroup_id?: string
    fgroup_name?: string
    pdf_id?: string
    pdf_name?: string
    isFolder: boolean
    checkStatus?: "none"|"half"|"all"
    child?: DirFile[]
    files?: DirFile[]
  }
  
  type FileItem = {
    pdf_id: number
    pdf_display_nm: string
    pdf_mime_type: string
    fgroup_id: number
    pdf_size: number
    is_deleted: boolean
    is_duplicate: boolean
    created_at: string
    updated_at: string
  
  }

const ITEMS_PER_PAGE = 20;
const APP_STAGE = process.env.REACT_APP_STAGE;

const FileGroupTable = ({  }) => {
    const dispatch = useAppDispatch()
    const token = useAppSelector(selectToken)
    const status = useAppSelector(selectStatus)
    const files = useAppSelector(selectDashboardFile)
    const fgroups = useAppSelector(selectDashboardFGroup)
    const fgroupsAll = useAppSelector(selectDashboardFGroupAll)
    const pgroups = useAppSelector(selectDashboardPGroup)
    const pgroupFiles = useAppSelector(selectDashboardPGroupFile)
    const chatbots = useAppSelector(selectDashboardChatbot)
  
    const [selectFiles, setSelectFiles] = useState([])
    const [uploadFiles, setUploadFiles] = useState([])
    const [selectDirName, setSelectDirName] = useState("")
    const [newPGroupName, setNewPGroupName] = useState("")
    const [activeTab, setActiveTab] = useState("file-management")
  
    const [directories, setDirectories] = useState<DirFile[]>([]);
    const [currentDirFiles, setCurrentDirFiles] = useState<FileItem[]>([]);
    const [selectedFolderId, setSelectedFolderId] = useState<string | null>(null);
    const [viewMode, setViewMode] = useState(true);

    const [fileStructure, setFileStructure] = useState<DirFile[] | null>(null)
    
    const updateNodeIsOpen = useCallback((tree: any, id: string, isOpen: boolean) => {
    
    if (tree.fgroup_id == id) {
      return { ...tree, isOpen:!tree.isOpen };
    }
    if (tree.child) {
      return {
        ...tree,
        child: tree.child.map((childNode: any) => updateNodeIsOpen(childNode, id, isOpen))
      };
    }
    return tree;
  }, []);
  
    
      const addIsOpenToTree = useCallback((tree: any) => {
        if (!tree) return null;
        return {
          ...tree,            
          isOpen: false,  
          isFolder: true,    
          checkStatus: "none",
          child: tree.child ? tree.child.map((item: any) => addIsOpenToTree(item)) : []
        };
      }, []);
    
      useEffect(() => {
        if (token) {
          dispatch(adminUserAllGet({ token }))
          dispatch(adminGroupListGet({ token }))
          dispatch(adminModelListGet({ token }))
          dispatch(adminFileListGet({ token }))
          dispatch(adminFGroupListGet({ token }))
          dispatch(adminFGroupAllListGet({ token }))
          dispatch(adminPGroupListGet({ token }))
          dispatch(adminChatbotListGet({ token }))

          
        }
      }, [dispatch, token])

        function convertToTreeNode(inputNode: any, pgroupFiles: any): DirFile {
            // Folder Node Conversion
            const folderNode: DirFile = {
                fgroup_id: inputNode.fgroup_id?.toString(),
                fgroup_name: inputNode.fgroup_name,
                isFolder: true,
                checkStatus: "none",  // 초기값 설정
                child: [],
                files: []
            };
        
            // File Conversion within folderNode
            inputNode.files?.forEach((file: any) => {
                const fileNode: DirFile = {
                    pdf_id: file.pdf_id.toString(),
                    pdf_name: file.pdf_nm,
                    isFolder: false,
                    checkStatus: "none"  // 파일의 기본 checkStatus는 "none"
                };
                folderNode.files?.push(fileNode);
            });
        
            // Recursively convert children folders and determine checkStatus for each child folder
            inputNode.child?.forEach((childNode: any) => {
                const childFolderNode = convertToTreeNode(childNode, pgroupFiles);
                folderNode.child?.push(childFolderNode);
            });
        
            // Calculate checkStatus for files in the current folder based on pgroupFiles
            const fileCount = folderNode.files?.length || 0;
            const includedFileCount = folderNode.files?.filter(
                file => pgroupFiles.some((pfile: any) => pfile.pdf_id.toString() === file.pdf_id?.toString())
            ).length || 0;
        
            // Set current folder's checkStatus based on its files
            let currentFolderStatus: "none" | "half" | "all" = "none";
            if (fileCount > 0) {
                if (includedFileCount === fileCount) {
                    currentFolderStatus = "all";
                } else if (includedFileCount > 0) {
                    currentFolderStatus = "half";
                }
            }
        
            // Determine checkStatus for current folder based on both child folders' statuses and files' status
            const childStatuses = folderNode.child?.map(child => child.checkStatus) || [];
            
            if (childStatuses.length > 0) {
                if (childStatuses.every(status => status === "all") && currentFolderStatus === "all") {
                    folderNode.checkStatus = "all";
                } else if (childStatuses.some(status => status === "half" || status === "all") || currentFolderStatus === "half") {
                    folderNode.checkStatus = "half";
                } else {
                    folderNode.checkStatus = "none";
                }
            } else {
                // If there are no child folders, use the current folder's status based on its own files
                folderNode.checkStatus = currentFolderStatus;
            }
        
            return folderNode;
        }
    
    

      useEffect(() => {
        if (fgroupsAll?.tree) {
          const treeNode = convertToTreeNode(fgroupsAll.tree, pgroupFiles.pdf);
          if (APP_STAGE === "DEV") {  
            console.log({treeNode})
          }
          setFileStructure([treeNode])
        }
      }, [fgroupsAll?.file_tree])
      useEffect(() => {
        if (fgroupsAll?.tree) {
          const processedTree = addIsOpenToTree(fgroupsAll.tree);
          setDirectories(processedTree ? [processedTree] : []);
        }
      }, [fgroupsAll?.tree, addIsOpenToTree]);
      
      useEffect(() => {
        if (fgroupsAll?.dirFile) {
          setCurrentDirFiles(fgroupsAll.files || []);
        }
      }, [fgroupsAll]);

    const handleGetDirFile = useCallback((fgroupId: number) => {
        dispatch(adminFileListGetOnfid({ token: token as string, fgroupId: fgroupId }));
    }, [dispatch, token, fgroups]);

    const handleClickPGroup = (pgroupId: number) => {
        dispatch(adminPGroupFileListGet({ token: token as string, pgroup_id: pgroupId }))
      }
      const handleAddPGroup = (pgroupName: string) => {
        dispatch(adminPGroupAdd({ token: token as string, pgroupName })).then(() => {
          dispatch(adminPGroupListGet({ token: token as string }))
        })
        setNewPGroupName("")
      }
    
      const handleDeletePGroup = (pgroupId: number) => {
        dispatch(adminPGroupDelete({ token: token as string, pgroupId })).then(() => {
          dispatch(adminPGroupListGet({ token: token as string }))
        })
      }

    const [currentPage, setCurrentPage] = useState(1);
    const [newPGroupForm, setNewPGroupForm] = useState({
        name: "",
        status: ""
    });
    const [pgroupForm, setPGroupForm] = useState(
        pgroups.map((pgroup: any) => ({
            id: pgroup.pgroup_id,
            name: pgroup.pgroup_name,
            status: getStatus(pgroup),
      })) || []);

    const [sortConfig, setSortConfig] = useState({ key: "id", direction: "asc" });

    function formatDateTime(dateTimeString: string) {
        if (dateTimeString.length !== 14) {
          return "잘못된 입력 형식입니다.";
        }
    
        const year = dateTimeString.slice(0, 4);
        const month = dateTimeString.slice(4, 6);
        const day = dateTimeString.slice(6, 8);
        const hour = dateTimeString.slice(8, 10);
        const minute = dateTimeString.slice(10, 12);
        const second = dateTimeString.slice(12, 14);
    
        return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
      }
    // 전체 상태 정리
    // 활성, 잠김(이메일, 승인안됨, 비번오류, 이상감지)
    function getStatus(user: any) {
        if (user.is_deleted) {
          return "탈퇴";
        } else if (!user.is_verified || !user.has_email_check || user.is_locked) {
          return "잠김";
        } else {
          return "활성";
        }
    }
    useEffect(() => {
        setPGroupForm(
            pgroups.map((pgroup: any) => ({
            id: pgroup.pgroup_id,
            name: pgroup.pgroup_name,
            status: getStatus(pgroup),
          })) || []
        );
      }, [pgroups]);

      useEffect(() => {
        // console.log("pgroupFiles", pgroupFiles)
      }, [pgroupFiles])


    const sortedPGroups = useMemo(() => {
        return [...pgroupForm].sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key])
                return sortConfig.direction === "asc" ? -1 : 1;
            if (a[sortConfig.key] > b[sortConfig.key])
                return sortConfig.direction === "asc" ? 1 : -1;
            return 0;
        });
    }, [pgroupForm, sortConfig]);
    const totalPages = Math.ceil(sortedPGroups.length / ITEMS_PER_PAGE);
    const paginatedPGroups = sortedPGroups.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        currentPage * ITEMS_PER_PAGE
    );

    const handleSort = (key: string) => {
        setSortConfig((currentConfig) => {
            if (currentConfig.key === key) {
                return {
                    ...currentConfig,
                    direction: currentConfig.direction === "asc" ? "desc" : "asc",
                };
            }
            return { key, direction: "asc" };
        });
    };
    console.log({fileStructure})
    console.log({currentDirFiles})
    return (
        <div className="bg-white shadow-md rounded-lg overflow-hidden p-4">
            <div className="border-b flex justify-between items-center">
                <h2 className="text-2xl font-semibold">PDF Groups</h2>

                <Dialog>
                    <DialogTrigger asChild>
                        <button
                            onClick={() => { }}
                            className="flex items-center gap-2 px-4 py-2 mb-2 bg-blue-500 text-sm text-white rounded-full bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
                        ><Plus className="h-4 w-4" /> 파일그룹 생성
                        </button>
                    </DialogTrigger>
                    <DialogContent>
                        <DialogHeader>
                            <DialogTitle>새 파일그룹 추가</DialogTitle>
                            <DialogDescription>새로운 파일그룹의 정보를 입력하세요.</DialogDescription>
                        </DialogHeader>
                        <form onSubmit={(e) => {
                            e.preventDefault()
                            const formData = new FormData(e.currentTarget)
                            // addGroup(formData.get('name'), formData.get('description'))
                        }}>
                            <div className="grid gap-2 py-2">
                                <div className="flex items-center gap-4">
                                    <Input
                                        label="파일그룹"
                                        type="text"
                                        id="name"
                                        placeholder="파일그룹"
                                        value={newPGroupForm.name}
                                        onChange={(e) => setNewPGroupForm({ ...newPGroupForm, name: e.target.value })}
                                        isNoSpinner={true}
                                    />
                                </div>
                                <div className="flex items-center gap-4">
                                    <Input
                                        label="상태"
                                        type="text"
                                        id="status"
                                        placeholder="상태"
                                        value={newPGroupForm.status}
                                        onChange={(e) => setNewPGroupForm({ ...newPGroupForm, status: e.target.value })}
                                    />
                                </div>

                            </div>
                            <DialogFooter>
                                <button
                                    type="submit"
                                    onClick={() => { }}
                                    className="flex items-center gap-2 px-4 py-2 mb-0 bg-blue-500 text-sm text-white rounded-md bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
                                >파일그룹 추가</button>
                            </DialogFooter>
                        </form>
                    </DialogContent>
                </Dialog>

            </div>
            <Table>
                <TableHeader>
                    <TableRow>
                        {["id", "name", "status"].map((key) => (
                            <TableHead
                                key={key}
                                className="px-6 py-2 text-left text-xs text-nowrap font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                onClick={() => handleSort(key)}
                            >
                                {key === "id"
                                    ? "No."
                                    : key === "name"
                                        ? "파일그룹"
                                        : "상태"}
                                {sortConfig.key === key && (
                                    <span className="ml-1">
                                        {sortConfig.direction === "asc" ? "▲" : "▼"}
                                    </span>
                                )}
                            </TableHead>
                        ))}
                        <TableHead>작업</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {paginatedPGroups && paginatedPGroups.map((pgroup) => (
                        <TableRow key={pgroup.id}>
                            <TableCell>{pgroup.id}</TableCell>
                            <TableCell>
                                <span
                                    className="cursor-pointer text-blue-600 hover:text-blue-800"
                                    onClick={() => handleClickPGroup(pgroup.id)}
                                >{pgroup.name}</span>
                            </TableCell>
                            <TableCell>{pgroup.status}</TableCell>
                            <TableCell>
                                <DropdownMenu>
                                    <DropdownMenuTrigger>
                                        <EllipsisVertical className="h-4 w-4" />
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent>
                                        <DropdownMenuItem>프로필</DropdownMenuItem>
                                        <DropdownMenuItem>대화내역</DropdownMenuItem>
                                        <DropdownMenuItem>권한변경</DropdownMenuItem>
                                        <DropdownMenuItem>회사변경</DropdownMenuItem>
                                        {/* <DropdownMenuSub>
                                            <DropdownMenuSubTrigger>권한변경</DropdownMenuSubTrigger>
                                            <DropdownMenuSubContent>
                                                <DropdownMenuItem>일반 설정</DropdownMenuItem>
                                                <DropdownMenuItem>고급 설정</DropdownMenuItem>
                                            </DropdownMenuSubContent>
                                        </DropdownMenuSub> */}
                                        <DropdownMenuSeparator />
                                        <DropdownMenuItem>계정잠금</DropdownMenuItem>
                                    </DropdownMenuContent>
                                </DropdownMenu>

                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={pgroups ? Math.ceil(pgroups?.length / 20) : 1} totalNumber={pgroups?.length} listName="파일그룹" />

            {pgroupFiles && (
            <div className="mt-4">
                <div className="flex justify-between items-center">
                <h3 className="text-lg font-semibold mb-2">
                    Selected Group: {pgroupFiles.pgroup.pgroup_name}
                </h3>
                <button
                        onClick={() => { setViewMode(!viewMode) }}
                        className={`flex items-center gap-2 px-4 py-2 mb-2 bg-blue-500 text-sm text-white rounded-full bg-gradient-to-br ${viewMode ? "from-cyan-600 to-blue-600 hover:bg-blue-600" : "from-indigo-600 to-blue-600 hover:bg-blue-600"} transition-colors`}
                    >{viewMode ? "파일그룹 수정" : "파일그룹 적용"}
                    </button>
                </div>
                <div className="w-full p-1 overflow-auto">
                    {/* <FileList
                        currentDirFiles={[]}
                        setCurrentDirFiles={()=>{}}
                        currentFiles={pgroupFiles.pdf}
                        setCurrentFiles={()=>{}}
                        onNewFolder={()=>{}}
                        onRename={()=>{}}
                        onDelete={()=>{}}
                        onUpload={()=>{}}
                        selection={true}
                    /> */}
                    <FileExplorer 
                        dir={fileStructure || []} 
                        setDirFiles={setDirectories} 
                        dirFiles={currentDirFiles}
                        setFiles={setCurrentDirFiles}
                        handleGetDirFile={handleGetDirFile}
                        token={token as string}
                        selection={true}
                        viewer={viewMode}
                        pgroupFiles={pgroupFiles.pdf}
                    />
                </div>
            </div>
          )}
        </div>
    )
};

export default FileGroupTable;