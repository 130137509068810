import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

function FeedbackModal({ isOpen, onClose, onSubmit }) {
  const { t } = useTranslation('translation');
  const [feedback, setFeedback] = useState('');
  const [showThankYou, setShowThankYou] = useState(false);

  const handleSubmit = () => {
    onSubmit(feedback);
    setShowThankYou(true);
    setTimeout(() => {
      setShowThankYou(false);
      setFeedback('');
      onClose();
    }, 2000); // 2초 후에 모달을 닫습니다.
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg p-8 max-w-md w-full">
        
        <div className={`absolute inset-0 bg-white flex justify-center items-center rounded-lg transition-all duration-300 ${showThankYou ? "bg-opacity-90 z-10" : "opacity-0 z-[-1]"}`}>
            <p className="text-2xl font-bold text-blue-600">{t("FeedbackModal.FeedbackModal.message.send")}</p>
        </div>
        <h2 className="text-2xl font-bold mb-4 text-left text-blue-900">{t("FeedbackModal.FeedbackModal.tit_group.tit")}</h2>
        <p className="text-gray-600 mb-4 text-left  text-balance">
          {t("FeedbackModal.FeedbackModal.tit_group.decs")}
        </p>
        <textarea
          className="w-full h-32 p-2 border border-gray-300 rounded mb-4 resize-none"
          placeholder={t("FeedbackModal.FeedbackModal.contents.textarea.placeholder")}
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
        ></textarea>
        <div className="flex justify-end gap-4">
          <button
            className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
            onClick={onClose}
          >
            {t("FeedbackModal.FeedbackModal.footer.button.cancel")}
          </button>
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            onClick={handleSubmit}
          >
            {t("FeedbackModal.FeedbackModal.footer.button.submit")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default FeedbackModal;