import React, { useEffect, useState } from "react";
// import "./AdminModelChangePage.css";
const modelOptions = [
  { value: "gpt-3", label: "GPT-3" },
  { value: "gpt-4", label: "GPT-4" },
  { value: "davinci", label: "Davinci" },
];
const APP_STAGE = process.env.REACT_APP_STAGE;

export default function AdminModelChangePage() {
  const [selectedModel, setSelectedModel] = useState("");
  const [topK, setTopK] = useState(50);
  const [topP, setTopP] = useState(0.9);
  const [temperature, setTemperature] = useState(0.7);

  return (
    <div className="gpt-ui-container">
      <h1 className="gpt-ui-title">GPT Model & Hyperparameters</h1>

      {/* 모델 선택 */}
      <div className="gpt-ui-section">
        <h2>Select a Model</h2>
        <div className="gpt-ui-model-options">
          {modelOptions.map((option) => (
            <label key={option.value} className="gpt-ui-radio-label">
              <input
                type="radio"
                name="model"
                value={option.value}
                checked={selectedModel === option.value}
                onChange={(e) => setSelectedModel(e.target.value)}
              />
              <span className="gpt-ui-custom-radio"></span>
              {option.label}
            </label>
          ))}
        </div>
      </div>

      {/* 하이퍼파라미터 조절 */}
      <div className="gpt-ui-section">
        <h2>Top-K: {topK}</h2>
        <input
          type="range"
          min="0"
          max="100"
          value={topK}
          onChange={(e) => setTopK(e.target.value)}
          className="gpt-ui-slider"
        />
      </div>
      <div className="gpt-ui-section">
        <h2>Top-P: {topP}</h2>
        <input
          type="range"
          min="0"
          max="1"
          step="0.01"
          value={topP}
          onChange={(e) => setTopP(e.target.value)}
          className="gpt-ui-slider"
        />
      </div>
      <div className="gpt-ui-section">
        <h2>Temperature: {temperature}</h2>
        <input
          type="range"
          min="0"
          max="1"
          step="0.01"
          value={temperature}
          onChange={(e) => setTemperature(e.target.value)}
          className="gpt-ui-slider"
        />
      </div>

      {/* 확인 버튼 */}
      <button
        className="gpt-ui-submit-btn"
        onClick={() => {
          if (APP_STAGE === "DEV") {  
            console.log("Settings Applied")
          }
        }}
      >
        Apply Settings
      </button>
    </div>
  );
}
