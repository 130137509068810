import React, { useEffect, useMemo, useState } from "react";

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../ui/table"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
    DropdownMenuSub,
    DropdownMenuSubTrigger,
    DropdownMenuSubContent,
} from "../../ui/dropdown-menu"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../../ui/dialog"

import { Plus, Search, Filter, EllipsisVertical, MoreVertical, Check, X, Trash2, UserPlus, Users } from "lucide-react"
import { Button } from "../../ui/button"
import { Input } from "../../ui/input"
import Pagination from "../Common/Pagination";
import { Checkbox } from "../../ui/checkbox";
import { adminGroupAdd, adminGroupUpdate } from "../../../lib/features/admin/adminSlice";
import { useAppDispatch, useAppSelector } from "../../../lib/hooks";

const ITEMS_PER_PAGE = 20;
const APP_STAGE = process.env.REACT_APP_STAGE;

const ChatbotGroupTable = ({ chatbotGroups, userGroups }: { chatbotGroups: any, userGroups: any }) => {
    const dispatch = useAppDispatch();
    const token = useAppSelector((state) => state.authentication.token);

    const [currentPage, setCurrentPage] = useState(1);
    const [newChatbotGroupForm, setNewChatbotGroupForm] = useState({
        name: "",
        // grade: "",
        // description: ""
    });
    const [chatbotForm, setChatbotForm] = useState(
        chatbotGroups.reduce((acc: any, item: any) => {
            // 그룹 ID를 기준으로 기존 그룹을 찾거나 새 그룹을 추가
            let group = acc.find((g: any) => g.groupId === item.group_id);
            if (!group) {
              group = { groupId: item.group_id, groupName: userGroups.find((ug: any) => ug.group_id === item.group_id)?.group_name, chatbots: {} };
              acc.push(group);
            }
          
            // chatbots 객체에 cgroup_type을 키로 하여 정보 추가
            group.chatbots[item.cgroup_type] = {
              id: item.chatbot_id,
              name: item.cgroup_name
            };
          
            return acc;
          }, []) || []);
      
    const [isDialogOpen, setIsDialogOpen] = useState({
        mod: false,
        create: false,
    });
    const [sortConfig, setSortConfig] = useState({ key: "id", direction: "asc" });

    function formatDateTime(dateTimeString: string) {
        if (dateTimeString.length !== 14) {
          return "잘못된 입력 형식입니다.";
        }
    
        const year = dateTimeString.slice(0, 4);
        const month = dateTimeString.slice(4, 6);
        const day = dateTimeString.slice(6, 8);
        const hour = dateTimeString.slice(8, 10);
        const minute = dateTimeString.slice(10, 12);
        const second = dateTimeString.slice(12, 14);
    
        return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
      }
    // 전체 상태 정리
    // 활성, 잠김(이메일, 승인안됨, 비번오류, 이상감지)
    // function getStatus(group: any) {
    //     if (group.is_deleted) {
    //       return "탈퇴";
    //     } else if (!user.is_verified || !user.has_email_check || user.is_locked) {
    //       return "잠김";
    //     } else {
    //       return "활성";
    //     }
    // }
    useEffect(() => {
        setChatbotForm(
            chatbotGroups.reduce((acc: any, item: any) => {
                // 그룹 ID를 기준으로 기존 그룹을 찾거나 새 그룹을 추가
                let group = acc.find((g: any) => g.groupId === item.group_id);
                if (!group) {
                    group = { groupId: item.group_id, groupName: userGroups.find((ug: any) => ug.group_id === item.group_id)?.group_name, chatbots: {} };
                  acc.push(group);
                }
              
                // chatbots 객체에 cgroup_type을 키로 하여 정보 추가
                group.chatbots[item.cgroup_type] = {
                  id: item.chatbot_id,
                  name: item.cgroup_name
                };
              
                return acc;
            }, []) || []
        );
      }, [chatbotGroups]);


    const sortedChatbots = useMemo(() => {
        return [...chatbotForm].sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key])
                return sortConfig.direction === "asc" ? -1 : 1;
            if (a[sortConfig.key] > b[sortConfig.key])
                return sortConfig.direction === "asc" ? 1 : -1;
            return 0;
        });
    }, [chatbotForm, sortConfig]);
    // const totalPages = Math.ceil(sortedGroups.length / ITEMS_PER_PAGE);
    const paginatedChatbots = sortedChatbots.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        currentPage * ITEMS_PER_PAGE
    );

    const handleSort: any = (key: string) => {
        setSortConfig((currentConfig) => {
            if (currentConfig.key === key) {
                return {
                    ...currentConfig,
                    direction: currentConfig.direction === "asc" ? "desc" : "asc",
                };
            }
            return { key, direction: "asc" };
        });
    };

    
  const handleSubmit = () => {
    // dispatch(
    //     adminGroupUpdate({
    //     token: token as string,
    //     groupId: chatbotForm.id as number,
    //     groupName: chatbotForm.name as string,
    //     // groupDesc: chatbotForm.description as string,
    //     // is_admin: chatbotForm?.is_admin,
    //     is_chatbot_ts: chatbotForm?.is_chatbot_ts,
    //     is_chatbot_mt: chatbotForm?.is_chatbot_mt,
    //     is_chatbot_gi: chatbotForm?.is_chatbot_gi,
    //     is_main_manual: permissionForm?.is_main_manual,
    //     is_main_video: permissionForm?.is_main_video,
    //   })
    // ).then(() => {
    //   // userAllChange();
    //   // adminUserSignUpRequestGetChange();
    //   alert("Group information has been updated.");
    //   // setUserNm("");
    //   // setSelectedCompany(undefined);
    //   setIsDialogOpen((prev) => ({ ...prev, ["mod"]: false }));
    // });

    // setIsDialogOpen((prev) => ({ ...prev, ["su"]: false })); // Close the dialog after submitting
  };

  const handleSubmitCreate = () => {
    // dispatch(
    //     adminGroupAdd({
    //     token: token as string,
    //     groupName: permissionForm.name as string,
    //     groupDesc: permissionForm.description as string,
    //     is_admin: permissionForm?.is_admin,
    //     is_chatbot_ts: permissionForm?.is_chatbot_ts,
    //     is_chatbot_mt: permissionForm?.is_chatbot_mt,
    //     is_chatbot_gi: permissionForm?.is_chatbot_gi,
    //     is_main_manual: permissionForm?.is_main_manual,
    //     is_main_video: permissionForm?.is_main_video,
    //   })
    // ).then(() => {
    //   // userAllChange();
    //   // adminUserSignUpRequestGetChange();
    //   alert("Group information has been created.");
    //   setIsDialogOpen((prev) => ({ ...prev, ["create"]: false }));
    // });
  };
  if (APP_STAGE === "DEV") {  
  console.log(chatbotForm);
  }
  
    return (
        <div className="bg-white shadow-md rounded-lg overflow-hidden p-4">
            <div className="border-b flex justify-between items-center">
                <h2 className="text-2xl font-semibold mb-4">CHATBOT GROUPS</h2>
                <button
                    onClick={() => { 
                        setIsDialogOpen({ ...isDialogOpen, create: true })
                    }}
                    className="flex items-center gap-2 px-4 py-2 mb-2 bg-blue-500 text-sm text-white rounded-full bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
                ><Plus className="h-4 w-4" /> Create New
                </button>
                <Dialog
                    open={isDialogOpen.create}
                    onOpenChange={() => {setIsDialogOpen({ ...isDialogOpen, create: false })
                    if (APP_STAGE === "DEV") {  
                      console.log({isDialogOpen})
                    }
                }
                
                }
                >
                    {/* <DialogTrigger>
                        
                    </DialogTrigger> */}
                    <DialogContent>
                        <DialogHeader>
                            <DialogTitle>Create New Group</DialogTitle>
                            <DialogDescription>Please enter the information for the new group.</DialogDescription>
                        </DialogHeader>
                        <form onSubmit={(e) => {
                            e.preventDefault()
                            const formData = new FormData(e.currentTarget)
                            // addGroup(formData.get('name'), formData.get('description'))
                        }}>
                            <div className="flex flex-col gap-2">
                                {/* <Input
                                    label="Group Name"
                                    type="text"
                                    id="name"
                                    placeholder="Group Name"
                                    value={permissionForm.name as unknown as string}
                                    onChange={(e) => setPermissionForm({ ...permissionForm, name: e.target.value as string})}
                                />
                                <Input
                                    label="Group Description"
                                    type="text"
                                    id="description"
                                    placeholder="Group Description"
                                    className="mb-4"
                                    value={permissionForm.description as unknown as string}
                                    onChange={(e) => setPermissionForm({ ...permissionForm, description: e.target.value as string})}
                                /> */}
                            </div>
                            <DialogFooter>
                                <button
                                    type="submit"
                                    onClick={handleSubmitCreate}
                                    className="flex items-center gap-2 px-4 py-2 mb-0 bg-blue-500 text-sm text-white rounded-md bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
                                >Create New Group</button>
                            </DialogFooter>
                        </form>
                    </DialogContent>
                </Dialog>

                </div>
                <Table>
                <TableHeader>
                    <TableRow>
                        {["id", "groupName", "TroubleShooting", "Maintenance"].map((key) => (
                            <TableHead
                                key={key}
                                className="px-6 py-2 text-left text-xs text-nowrap font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                onClick={() => handleSort(key)}
                            >
                                {key === "id"
                                    ? "No."
                                    : key === "groupName"
                                        ? "GroupName"
                                        : key === "TroubleShooting"
                                        ? "TroubleShooting"
                                        : key === "Maintenance"
                                        ? "Maintenance"
                                        : ""}
                                {sortConfig.key === key && (
                                    <span className="ml-1">
                                        {sortConfig.direction === "asc" ? "▲" : "▼"}
                                    </span>
                                )}
                            </TableHead>
                        ))}
                        <TableHead>action</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {paginatedChatbots && paginatedChatbots.map((chatbot, idx) => (
                        <TableRow key={chatbot.id}>
                            <TableCell>{chatbot.groupId}</TableCell>
                            <TableCell>{chatbot.groupName}</TableCell>
                            <TableCell>{chatbot.chatbots["BWMS-TS"] ? chatbot.chatbots["BWMS-TS"].id : ""}</TableCell>
                            <TableCell>{chatbot.chatbots["BWMS-MT"] ? chatbot.chatbots["BWMS-MT"].id : ""}</TableCell>
                            <TableCell>
                                <DropdownMenu>
                                    <DropdownMenuTrigger>
                                        <EllipsisVertical className="h-4 w-4" />
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent>
                                        <DropdownMenuItem
                                            onClick={() =>{
                                                // setPermissionForm(group)
                                                setIsDialogOpen({ ...isDialogOpen, mod: true }) 
                                            }}
                                        >Modify</DropdownMenuItem>
                                        <DropdownMenuSeparator />
                                        <DropdownMenuItem>Delete</DropdownMenuItem>
                                    </DropdownMenuContent>
                                </DropdownMenu>

                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={chatbotForm ? Math.ceil(chatbotForm?.length / 20) : 1} totalNumber={chatbotForm?.length} listName="Chatbot Group"/>
         
        </div>
    );
};

export default ChatbotGroupTable;
