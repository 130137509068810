import React from 'react'
import ChatSideBar from '../components/main/ChatSideBar'
import VideoViewer from '../components/main/VideoViwer'
import PersonInCharge from '../components/main/PersonInCharge'

function PersonInChargePage() {
  return (
    <div className="min-h-screen-patched flex w-full flex-col bg-white sm:flex-row">
       <ChatSideBar isOpen={true} setIsOpen={()=>{}}/>
       <PersonInCharge />
    </div>
  )
}

export default PersonInChargePage
