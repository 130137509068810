import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { Folder, File, ChevronRight, ChevronDown } from 'lucide-react';
import ChatHeader from './ChatHeader';

const BE_HOST = process.env.REACT_APP_BE_HOST;
const BASE_URL: string = BE_HOST ? BE_HOST : "https://snsys-qa.neoali.com";

// 가상의 파일 구조 타입 정의
interface FileStructure {
  name: string;
  url?: string;
  type: 'folder' | 'file';
  children?: FileStructure[];
}

// 가상의 파일 구조 데이터
const fileStructure: FileStructure[] = [
  {
    name: '01. OPERATION',
    type: 'folder',
    children: [
      { name: 'SAMSUNG_BALLAST_ENG_REV1.mp4', url:BASE_URL+'/api/file/video/1', type: 'file' },
      { name: 'SAMSUNG_DEBALLAST_ENG_REV1.mp4', url:BASE_URL+'/api/file/video/2', type: 'file' },
    ],
  },
  {
    name: '02. ELE',
    type: 'folder',
    children: [
      { name: '[BAM-EG-02] ELE Acid Cleaning.avi', url:BASE_URL+'/api/file/video/3', type: 'file' },
      { name: '[BAM-ES-08] EPT sensor replacement.avi', url:BASE_URL+'/api/file/video/4', type: 'file' }, 
      { name: 'SAMSUNG_GAS CALIBRATION_ENG_REV1.mp4', url:BASE_URL+'/api/file/video/5', type: 'file' }, 
    ],
  },
  {
    name: '03. FILTER',
    type: 'folder',
    children: [
      { name: '[BAM-FR-01] PT Replacement (Danfoss → Emerson).mp4', url:BASE_URL+'/api/file/video/6', type: 'file' },
      { name: '[BAM-FR-02].mp4', url:BASE_URL+'/api/file/video/7', type: 'file' }, 
      { name: 'COSCO_FILTER 동영상 자료.mp4', url:BASE_URL+'/api/file/video/8', type: 'file' },
      { name: 'SAMSUNG_FILTER_ENG_REV1.mp4', url:BASE_URL+'/api/file/video/9', type: 'file' },
    ],
  },
  {
    name: '04. TRO',
    type: 'folder',
    children: [
      { name: '[BAM-TC-05] Cooling chamber replacement.avi', url:BASE_URL+'/api/file/video/10', type: 'file' },
      { name: '[BAM-TC-06] power supply of cooling chamber replacement.avi', url:BASE_URL+'/api/file/video/11', type: 'file' },
      { name: '[BAM-TG-03] Commnunication check.avi', url:BASE_URL+'/api/file/video/12', type: 'file' },
      { name: '[BAM-TG-08] PCB replacement.avi', url:BASE_URL+'/api/file/video/13', type: 'file' },
      { name: '[BAM-TO-05] Intake sol. valve replacement.avi', url:BASE_URL+'/api/file/video/14', type: 'file' },
      { name: '[BAM-TO-06] Drain sol. valve replacement.avi', url:BASE_URL+'/api/file/video/15', type: 'file' },
      { name: '[BAM-TS-04] cuvette maintanance.avi', url:BASE_URL+'/api/file/video/16', type: 'file' },
      { name: '[BAM-TS-06] TRO Reagent Pump Check (replace & cleanning).avi', url:BASE_URL+'/api/file/video/17', type: 'file' },
      { name: '[BAM-TS-08] Optic sensor replacement.avi', url:BASE_URL+'/api/file/video/18', type: 'file' },
      { name: 'SAMSUNG_TRO BYPASS MODE_ENG_REV1.mp4', url:BASE_URL+'/api/file/video/19', type: 'file' },
      { name: 'SAMSUNG_TRO FLOW SETTING_ENG_REV1.mp4', url:BASE_URL+'/api/file/video/20', type: 'file' },
      { name: 'SAMSUNG_TRO FLUSHING_KOR_REV1.mp4', url:BASE_URL+'/api/file/video/21', type: 'file' },
      { name: 'SAMSUNG_TRO REAGENT KIT_ENG_REV1.mp4', url:BASE_URL+'/api/file/video/22', type: 'file' },
      { name: 'TRO_1min_SENSING_1MB - Compressed.mp4', url:BASE_URL+'/api/file/video/23', type: 'file' },
    ],
  },
  {
    name: '05. MCP_PDP',
    type: 'folder',
    children: [
      { name: 'SAMSUNG_LOG CONVERTER_ENG_REV1.mp4', url:BASE_URL+'/api/file/video/24', type: 'file' },
    ],
  },
  {
    name: '06. RECTIFIER',
    type: 'folder',
    children: [
      { name: '[BAM-RR-01] SMPS Replacement.mp4', url:BASE_URL+'/api/file/video/25', type: 'file' },
      { name: '[BAM-RR-02] Control Board Replacement.mp4', url:BASE_URL+'/api/file/video/26', type: 'file' },
      { name: '[BAM-RR-03] Interface Board Replacement.mp4', url:BASE_URL+'/api/file/video/27', type: 'file' },
      { name: '[BAM-RR-07] DC LINK Board Replacement.mp4', url:BASE_URL+'/api/file/video/28', type: 'file' },
    ],
  },
];

// 파일 트리 컴포넌트
const FileTree = ({ structure, onSelect }: { structure: FileStructure[], onSelect: (file: FileStructure) => void }) => {
  const [expanded, setExpanded] = useState<{ [key: string]: boolean }>({});
  
  const toggleFolder = (name: string) => {
    setExpanded(prev => ({ ...prev, [name]: !prev[name] }));
  };

  return (
    <ul className="pl-3">
      {structure.map((item) => (
        <li key={item.name} className="py-1">
          {item.type === 'folder' ? (
            <div>
              <button
                onClick={() => toggleFolder(item.name)}
                className="flex items-center text-sm hover:text-blue-500"
              >
                {expanded[item.name] ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
                <Folder size={16} className="mr-1" />
                {item.name}
              </button>
              {expanded[item.name] && item.children && (
                <FileTree structure={item.children} onSelect={onSelect} />
              )}
            </div>
          ) : (
            <button
              onClick={() => onSelect(item)}
              className="flex items-center text-sm hover:text-blue-500 truncate"
              title={item.name}
            >
              <File size={16} className="mr-1" />
              {item.name}
            </button>
          )}
        </li>
      ))}
    </ul>
  );
};

// 비디오 뷰어 컴포넌트
const VideoViewer: React.FC = () => {
  const [selectedFile, setSelectedFile] = useState<FileStructure | null>(null);
  const [isOpen, setIsOpen] = useState(true)
  const handleSelect = (file: FileStructure) => {
    setSelectedFile(file);
  };

  return (
    <main id="chatMain"  className='relative min-h-full flex-1 p-2 sm:p-6'>
      <ChatHeader />
      <div className="flex w-full h-screen">
        <div className="w-1/4 p-4 border-r hidden md:block">
          <h2 className="text-xl font-bold mb-6 text-blue-800">VIDEO GUIDE</h2>
          <div className="w-full p-0 overflow-y-auto">
            <FileTree structure={fileStructure} onSelect={handleSelect} />
          </div>
        </div>
        <div className="flex-1 p-4">
          
          {selectedFile ? (
            <div>
              <h2 className="text-lg font-semibold mb-4">{`${selectedFile.name}`}</h2>
              <ReactPlayer url={`${selectedFile.url}`} controls width="100%" height="100%" />
            </div>
          ) : (
            <p className="text-center text-gray-500 mt-10">Select a video file to view</p>
          )}
        </div>
      </div>
      <div className={`absolute bottom-0 w-full bg-white p-2 border-r border-gray-200 shadow-md pb-4 sm:hidden ${isOpen ? 'h-1/2' : 'h-0'} transition-all duration-300`}>
        {/* <h1 className="text-xl font-bold mb-6 text-blue-800">MANUALS</h1> */}
        <div 
          onClick={()=>setIsOpen(!isOpen)}
          className={`flex justify-center items-center border-gray-200 pb-2 mb-2 cursor-pointer ${isOpen ? 'border-b border-t' : 'border-t'}`}>
          <ChevronDown className={`w-6 h-6 transition-transform duration-300 ${!isOpen ? 'rotate-180' : ''}`} />
        </div>
        <div className="w-full h-full p-4 overflow-y-auto">
          <div className="w-full p-0">
            <FileTree structure={fileStructure} onSelect={handleSelect} />
          </div>
        </div>
      </div>
    </main>
  );
};

export default VideoViewer;
