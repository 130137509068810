import React, { useState, useRef, useEffect } from 'react';

interface PopoverProps {
  children: React.ReactNode;
  open: boolean;
  className?: string;
  onOpenChange: (open: boolean) => void;
}

export function Popover({ children, open, className, onOpenChange }: PopoverProps) {
  const popoverRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
        onOpenChange(false);
      }
    }

    function handleEscapeKey(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        onOpenChange(false);
      }
    }

    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleEscapeKey);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [open, onOpenChange]);

  return (
    <div className={`relative inline-block ${className}`} ref={popoverRef}>
      {children}
    </div>
  );
}

export const PopoverTrigger = React.forwardRef<HTMLDivElement, { 
  children: React.ReactNode, 
  className?: string,
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  }>(
  ({ children, className, onMouseEnter, onMouseLeave }, ref) => (
    <div ref={ref} className={className} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {children}
    </div>
  )
);

PopoverTrigger.displayName = 'PopoverTrigger';

export const PopoverContent = React.forwardRef<HTMLDivElement, { children: React.ReactNode; open: boolean; className?: string }>(
  ({ children, open, className }, ref) => (
    <div 
      ref={ref} 
      className={`absolute z-10 w-full mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none transition-all duration-200 ease-in-out ${
        open ? 'opacity-100 scale-100' : 'opacity-0 scale-95 pointer-events-none'
      } ${className}`}
    >
      {children}
    </div>
  )
);

PopoverContent.displayName = 'PopoverContent';

