import React, { useEffect, useMemo, useState } from "react";

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../ui/table"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
    DropdownMenuSub,
    DropdownMenuSubTrigger,
    DropdownMenuSubContent,
} from "../../ui/dropdown-menu"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../../ui/dialog"

import { Plus, Search, Filter, EllipsisVertical, MoreVertical, Check, X, Trash2, UserPlus, Users, ThumbsUp, ThumbsDown, Info } from "lucide-react"
import { Button } from "../../ui/button"
import { Input } from "../../ui/input"
import Pagination from "../Common/Pagination";
import { Checkbox } from "../../ui/checkbox";
import { adminChatbotAdd, adminFeedbackMessage, adminFeedbackMessageFinetune, adminFeedbackMessageUpdate, adminGroupAdd, adminGroupUpdate, adminMessageGetbyMessageId } from "../../../lib/features/admin/adminSlice";
import { useAppDispatch, useAppSelector } from "../../../lib/hooks";
import { Tooltip } from "react-bootstrap";
import { Popover, PopoverContent, PopoverTrigger } from "../../ui/popover";
import ChatContents from "../Common/ChatContents";
import ChatMain from "../Common/ChatMain";
import { setDocViewer } from "../../../lib/features/chatMain/chatMainSlice";

const ITEMS_PER_PAGE = 20;
const MessageFeedbackTable = ({ feedbacksMessage }: { feedbacksMessage: any }) => {
    const dispatch = useAppDispatch();
    const token = useAppSelector((state) => state.authentication.token);

    const [currentPage, setCurrentPage] = useState(1);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [selectedFeedbackId, setSelectedFeedbackId] = useState<number | null>(null);
    const [detailFeedbackForm, setDetailFeedbackForm] = useState<any>({
        id: "",
        content: "",
        message_id: "",
        pdf_id: "",
        pdf_page: "",
        rating: "",
        reflection: ""
      });
    const [newFeedbackForm, setNewFeedbackForm] = useState({
        content: "",
        message_id: "",
        pdf_id: "",
        pdf_page: "",
        rating: "",
        reflection: ""
    });
    const [feedbackForm, setFeedbackForm] = useState(
        feedbacksMessage.map((feedback: any) => ({
            id: feedback.feedback_id,
            content: feedback.feedback_content,
            message_id: feedback.message_id,
            pdf_id: feedback.pdf_id,
            pdf_page: feedback.pdf_page,
            rating: feedback.feedback_rating,
            reflection: feedback.is_reflected,
            checked: feedback.is_checked,
      })) || []);
      
    const [isDialogOpen, setIsDialogOpen] = useState({
        modify: false,
        create: false,
        delete: false,
        detail: false,
        message: false,
    });
    const [sortConfig, setSortConfig] = useState({ key: "id", direction: "asc" });

    function formatDateTime(dateTimeString: string) {
        if (dateTimeString.length !== 14) {
          return "잘못된 입력 형식입니다.";
        }
    
        const year = dateTimeString.slice(0, 4);
        const month = dateTimeString.slice(4, 6);
        const day = dateTimeString.slice(6, 8);
        const hour = dateTimeString.slice(8, 10);
        const minute = dateTimeString.slice(10, 12);
        const second = dateTimeString.slice(12, 14);
    
        return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
      }
    // 전체 상태 정리
    // 활성, 잠김(이메일, 승인안됨, 비번오류, 이상감지)
    // function getStatus(group: any) {
    //     if (group.is_deleted) {
    //       return "탈퇴";
    //     } else if (!user.is_verified || !user.has_email_check || user.is_locked) {
    //       return "잠김";
    //     } else {
    //       return "활성";
    //     }
    // }
    useEffect(() => {
        setFeedbackForm(
            feedbacksMessage.map((feedback: any) => ({
                id: feedback.feedback_id,
                content: feedback.feedback_content,
                message_id: feedback.message_id,
                pdf_id: feedback.pdf_id,
                pdf_page: feedback.pdf_page,
                rating: feedback.feedback_rating,
                reflection: feedback.is_reflected,
                checked: feedback.is_checked,
            })) || []
        );
    }, [feedbacksMessage]);

    const sortedFeedbacks = useMemo(() => {
        return [...feedbackForm].sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key])
                return sortConfig.direction === "asc" ? -1 : 1;
            if (a[sortConfig.key] > b[sortConfig.key])
                return sortConfig.direction === "asc" ? 1 : -1;
            return 0;
        });
    }, [feedbackForm, sortConfig]);
    // const totalPages = Math.ceil(sortedGroups.length / ITEMS_PER_PAGE);
    const paginatedFeedbacks = sortedFeedbacks.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        currentPage * ITEMS_PER_PAGE
    );

    const handleSort: any = (key: string) => {
        setSortConfig((currentConfig) => {
            if (currentConfig.key === key) {
                return {
                    ...currentConfig,
                    direction: currentConfig.direction === "asc" ? "desc" : "asc",
                };
            }
            return { key, direction: "asc" };
        });
    };

    
  const handleSubmit = () => {
    // dispatch(
    //     adminGroupUpdate({
    //     token: token as string,
    //     groupId: chatbotForm.id as number,
    //     groupName: chatbotForm.name as string,
    //     // groupDesc: chatbotForm.description as string,
    //     // is_admin: chatbotForm?.is_admin,
    //     is_chatbot_ts: chatbotForm?.is_chatbot_ts,
    //     is_chatbot_mt: chatbotForm?.is_chatbot_mt,
    //     is_chatbot_gi: chatbotForm?.is_chatbot_gi,
    //     is_main_manual: permissionForm?.is_main_manual,
    //     is_main_video: permissionForm?.is_main_video,
    //   })
    // ).then(() => {
    //   // userAllChange();
    //   // adminUserSignUpRequestGetChange();
    //   alert("Group information has been updated.");
    //   // setUserNm("");
    //   // setSelectedCompany(undefined);
    //   setIsDialogOpen((prev) => ({ ...prev, ["mod"]: false }));
    // });

    // setIsDialogOpen((prev) => ({ ...prev, ["su"]: false })); // Close the dialog after submitting
  };

//   const handleSubmitCreate = () => {
//     const pgroupId = pgroups.find((pgroups: any) => pgroups.pgroup_name === newChatbotForm.pgroup_name)?.pgroup_id;
//     const chatbotType = newChatbotForm.chatbot_type==="Troubleshooting"?"BWMS-TS":"BWMS-MT";
//     dispatch(
//         adminChatbotAdd({
//         token: token as string,
//         pgroupId: pgroupId as number,
//         chatbotType: chatbotType as string,
//         modelId: 1
//       })
//     ).then(() => {
//       alert("Chatbot information has been created.");
//       setIsDialogOpen((prev) => ({ ...prev, ["create"]: false }));
//       setNewChatbotForm({
//         pgroup_name: "",
//         pgroup_id: "",
//         chatbot_status: "",
//         chatbot_type: "Troubleshooting"
//     });
//     });
//   };
    const [isOpenTooltip, setIsOpenTooltip] = useState(false);
    console.log({feedbackForm, paginatedFeedbacks});
    return (
        <div className="bg-white shadow-md rounded-lg overflow-hidden p-4">
            <div className="border-b flex justify-between items-center">
                <h2 className="text-2xl font-semibold mb-4">MESSAGE FEEDBACKS</h2>
                <button
                    onClick={()=>{
                        dispatch(
                            adminFeedbackMessageFinetune({token: token as string})
                        ).then(() => {
                            dispatch(adminFeedbackMessage({token: token as string}))
                        })
                    }}
                    className="flex items-center gap-2 px-4 py-2 mb-2 bg-blue-500 text-sm text-white rounded-full bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
                >
                    Reflect
                </button>
            </div>
            
            <Dialog open={isDialogOpen.detail} onOpenChange={() => {setIsDialogOpen({ ...isDialogOpen, detail: false })}}>
                <DialogContent className="max-w-2xl">
                    <DialogHeader>
                        <DialogTitle className="text-xl font-bold text-gray-600 flex items-center gap-2">
                            Feedback Details
                        </DialogTitle>
                        <DialogDescription className="text-gray-600">
                            To apply this feedback, implementation is required. (Coming soon)
                        </DialogDescription>
                    </DialogHeader>
                    <div className="grid grid-cols-4 grid-rows-auto gap-3 text-base bg-gray-50 p-4 rounded-lg border border-gray-200 my-2">
                        {[
                            { 
                                label: "Content", 
                                value: detailFeedbackForm.content,
                            },
                            { 
                                label: "Rating", 
                                value: detailFeedbackForm.rating==1 ? 
                                    <div className="flex items-center text-green-500"><ThumbsUp className="h-4 w-4 mr-1" /> Positive</div> : 
                                    <div className="flex items-center text-red-500"><ThumbsDown className="h-4 w-4 mr-1" /> Negative</div>,
                            },
                            { 
                                label: "Reflected", 
                                value: detailFeedbackForm.reflection ? 
                                    <span className="px-2 py-1 bg-white-100 text-green-500 rounded-full text-sm font-medium">Yes</span> : 
                                    <span className="px-2 py-1 bg-white-100 text-red-700 rounded-full text-sm font-medium">No</span>,
                            },
                            { 
                                label: "Checked", 
                                value: (
                                    <div className="flex items-center">
                                        <button
                                            className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none ${detailFeedbackForm.checked ? 'bg-green-500' : 'bg-gray-300'}`}
                                            onClick={() => {
                                                setDetailFeedbackForm({
                                                    ...detailFeedbackForm,
                                                    checked: !detailFeedbackForm.checked
                                                });
                                            }}
                                        >
                                            <span
                                                className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${detailFeedbackForm.checked ? 'translate-x-6' : 'translate-x-1'}`}
                                            />
                                        </button>
                                        <span className="ml-2 text-sm">{detailFeedbackForm.checked ? 'Yes' : 'No'}</span>
                                    </div>
                                ),
                            },
                        ].map((item, index) => (
                            <React.Fragment key={index}>
                                <span className="font-semibold col-span-1 flex items-center gap-1 text-gray-700">
                                    {item.label}
                                </span>
                                <span className="col-span-3">{item.value}</span>
                            </React.Fragment>
                        ))}
                    </div>
                    <DialogFooter className="gap-2 mt-4">
                        <Button 
                            variant="default" 
                            className="bg-blue-600 hover:bg-blue-700 text-white flex items-center gap-2"
                            onClick={() => {
                                setIsSidebarOpen(false);
                                setIsDialogOpen({ ...isDialogOpen, message: true })
                                dispatch(adminMessageGetbyMessageId({token: token as string, message_id: detailFeedbackForm.message_id}))
                            }}
                        >
                            <Search className="h-4 w-4" /> View Chat
                        </Button>
                        <Button 
                            variant="default" 
                            className="bg-blue-600 hover:bg-blue-700 text-white flex items-center gap-2"
                            onClick={() => {
                                dispatch(
                                    adminFeedbackMessageUpdate(
                                        {token: token as string, feedback_id: detailFeedbackForm.id, is_checked: detailFeedbackForm.checked}
                                    )
                                ).then(() => {
                                    dispatch(adminFeedbackMessage({token: token as string}))
                                    setIsDialogOpen({ ...isDialogOpen, detail: false })
                                })
                            }}
                        > OK </Button>
                        <Button 
                            variant="outline" 
                            className="border-gray-300 hover:bg-gray-100"
                            onClick={() => {setIsDialogOpen({ ...isDialogOpen, detail: false })}}
                        >
                            Cancel
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
            <Dialog 
                open={isDialogOpen.message} 
                onOpenChange={() => {
                    setIsDialogOpen({ ...isDialogOpen, message: false })
                    dispatch(setDocViewer({
                        isOn: false,
                        docUrl: "",
                        docPage: 0,
                        docTitle: ""
                    }));
                }}
            >
                <DialogContent className="flex-1 !max-w-full !max-h-full !w-[calc(100vw-100px)] !h-[calc(100vh-100px)]">
                    <DialogHeader>
                        <DialogTitle>Chat History</DialogTitle>
                        <DialogDescription></DialogDescription>
                    </DialogHeader>
                    <ChatMain isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen} mode="message" ids={{feedbackId:detailFeedbackForm.id, messageId:detailFeedbackForm.message_id}}/>
                </DialogContent>
            </Dialog>
            <Table>
                <TableHeader>
                    <TableRow>
                        {["content", "rating", "reflection", "checked"].map((key) => (
                            <TableHead
                                key={key}
                                className="px-6 py-2 text-left text-xs text-nowrap font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                onClick={() => handleSort(key)}
                            >
                                {key === "id"
                                    ? "No."
                                    : key === "content"
                                        ? "Content"
                                        : key === "message_id"
                                        ? "Message ID"
                                        : key === "pdf_id"
                                        ? "PDF ID"
                                        : key === "pdf_page"
                                        ? "PDF Page"
                                        : key === "rating"
                                        ? "Rating"
                                        : key === "reflection"
                                        ? "Reflection"
                                        : key === "checked"
                                        ? "Checked"
                                        : ""}
                                {sortConfig.key === key && (
                                    <span className="ml-1">
                                        {sortConfig.direction === "asc" ? "▲" : "▼"}
                                    </span>
                                )}
                            </TableHead>
                        ))}
                        {/* <TableHead>action</TableHead> */}
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {paginatedFeedbacks && paginatedFeedbacks.map((feedback) => (
                        <TableRow key={feedback.id}>
                            {/* <TableCell>{feedback.id}</TableCell> */}
                            <TableCell  
                                onClick={() =>{
                                    setDetailFeedbackForm(feedback);
                                    setSelectedFeedbackId(feedback.id);
                                    setIsDialogOpen({ ...isDialogOpen, detail: true }) 
                                }}
                                className="truncate max-w-xs"
                            >{feedback.content}</TableCell>
                            {/* <TableCell>{feedback.message_id}</TableCell>
                            <TableCell>{feedback.pdf_id}</TableCell>
                            <TableCell>{feedback.pdf_page}</TableCell> */}
                            <TableCell className="w-10">{feedback.rating==1?<ThumbsUp className="h-4 w-4 text-green-500"/>:<ThumbsDown className="h-4 w-4 text-red-500"/>}</TableCell>
                            <TableCell className="w-10">
                                <div
                                    className={`text-xs font-medium px-2 py-1 rounded-full ${feedback.reflection
                                        ? "text-green-400"
                                        : "text-red-500"
                                    }`}
                                >
                                    {feedback.reflection
                                    ? "OK"
                                    : "NO"}
                                </div>
                            </TableCell>
                            <TableCell className="w-10">
                                <div
                                    className={`text-xs font-medium px-2 py-1 rounded-full ${feedback.checked
                                        ? "text-green-500"
                                        : "text-red-500"
                                    }`}
                                >
                                    {feedback.checked
                                    ? "OK"
                                    : "NO"}
                                </div>
                            </TableCell>
            
                            
                            {/* <TableCell className="w-10">
                                <DropdownMenu>
                                    <DropdownMenuTrigger>
                                        <EllipsisVertical className="h-4 w-4" />
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent>
                                        <DropdownMenuItem
                                            onClick={() =>{
                                                setDetailFeedbackForm(feedback);
                                                setSelectedFeedbackId(feedback.id);
                                                setIsDialogOpen({ ...isDialogOpen, detail: true }) 
                                            }}
                                        >Detail</DropdownMenuItem>
                                    </DropdownMenuContent>
                                </DropdownMenu>

                            </TableCell> */}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={feedbacksMessage ? Math.ceil(feedbacksMessage?.length / 20) : 1} totalNumber={feedbacksMessage?.length} listName="Chatbot Feedback"/>
         
        </div>
    );
};

export default MessageFeedbackTable;
