import React, { useEffect, useMemo, useState } from "react";

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../ui/table"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
    DropdownMenuSub,
    DropdownMenuSubTrigger,
    DropdownMenuSubContent,
} from "../../ui/dropdown-menu"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../../ui/dialog"

import { Plus, Search, Filter, EllipsisVertical, MoreVertical, Check, X, Trash2, UserPlus, Users } from "lucide-react"
import { Button } from "../../ui/button"
import { Input } from "../../ui/input"
import Pagination from "../Common/Pagination";
import { Checkbox } from "../../ui/checkbox";
import { adminGroupAdd, adminGroupDelete, adminGroupUpdate } from "../../../lib/features/admin/adminSlice";
import { useAppDispatch, useAppSelector } from "../../../lib/hooks";

const ITEMS_PER_PAGE = 20;
const GroupTable = ({ groups }: { groups: any }) => {
    const dispatch = useAppDispatch();
    const token = useAppSelector((state) => state.authentication.token);

    const [currentPage, setCurrentPage] = useState(1);
    const [newGroupForm, setNewGroupForm] = useState({
        name: "",
        grade: "",
        description: ""
    });
    const [groupForm, setGroupForm] = useState(
        groups.map((group: any) => ({
            id: group.group_id,
            name: group.group_name ? group.group_name : "",
            is_admin: group.permission.is_admin,
            is_chatbot_ts: group.permission.is_chatbot_ts,
            is_chatbot_mt: group.permission.is_chatbot_mt,
            is_chatbot_gi: group.permission.is_chatbot_gi,
            is_main_manual: group.permission.is_main_manual,
            is_main_video: group.permission.is_main_video,
            description: group.group_desc ? group.group_desc : ""
      })) || []);
      const [permissionForm, setPermissionForm] = useState({
        id: 0,
        name: "",
        is_admin: false,
        is_chatbot_ts: false,
        is_chatbot_mt: false,
        is_chatbot_gi: false,
        is_main_manual: false,
        is_main_video: false,
        description: ""
      });

    const [isDialogOpen, setIsDialogOpen] = useState({
        edit: false,
        create: false,
        delete: false
    });
    const [sortConfig, setSortConfig] = useState({ key: "id", direction: "asc" });

    function formatDateTime(dateTimeString: string) {
        if (dateTimeString.length !== 14) {
          return "잘못된 입력 형식입니다.";
        }
    
        const year = dateTimeString.slice(0, 4);
        const month = dateTimeString.slice(4, 6);
        const day = dateTimeString.slice(6, 8);
        const hour = dateTimeString.slice(8, 10);
        const minute = dateTimeString.slice(10, 12);
        const second = dateTimeString.slice(12, 14);
    
        return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
      }
    // 전체 상태 정리
    // 활성, 잠김(이메일, 승인안됨, 비번오류, 이상감지)
    // function getStatus(group: any) {
    //     if (group.is_deleted) {
    //       return "탈퇴";
    //     } else if (!user.is_verified || !user.has_email_check || user.is_locked) {
    //       return "잠김";
    //     } else {
    //       return "활성";
    //     }
    // }
    useEffect(() => {
        setGroupForm(
            groups.map((group: any) => ({
                id: group.group_id,
                name: group.group_name ? group.group_name : "",
                is_admin: group.permission.is_admin,
                is_chatbot_ts: group.permission.is_chatbot_ts,
                is_chatbot_mt: group.permission.is_chatbot_mt,
                is_chatbot_gi: group.permission.is_chatbot_gi,
                is_main_manual: group.permission.is_main_manual,
                is_main_video: group.permission.is_main_video,
                description: group.group_desc ? group.group_desc : ""
          })) || []
        );
      }, [groups]);


    const sortedGroups = useMemo(() => {
        return [...groupForm].sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key])
                return sortConfig.direction === "asc" ? -1 : 1;
            if (a[sortConfig.key] > b[sortConfig.key])
                return sortConfig.direction === "asc" ? 1 : -1;
            return 0;
        });
    }, [groups, sortConfig]);
    // const totalPages = Math.ceil(sortedGroups.length / ITEMS_PER_PAGE);
    const paginatedGroups = sortedGroups.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        currentPage * ITEMS_PER_PAGE
    );

    const handleSort: any = (key: string) => {
        setSortConfig((currentConfig) => {
            if (currentConfig.key === key) {
                return {
                    ...currentConfig,
                    direction: currentConfig.direction === "asc" ? "desc" : "asc",
                };
            }
            return { key, direction: "asc" };
        });
    };

    
  const handleSubmit = () => {
    dispatch(
        adminGroupUpdate({
        token: token as string,
        groupId: permissionForm.id as number,
        groupName: permissionForm.name as string,
        groupDesc: permissionForm.description as string,
        is_admin: permissionForm?.is_admin,
        is_chatbot_ts: permissionForm?.is_chatbot_ts,
        is_chatbot_mt: permissionForm?.is_chatbot_mt,
        is_chatbot_gi: permissionForm?.is_chatbot_gi,
        is_main_manual: permissionForm?.is_main_manual,
        is_main_video: permissionForm?.is_main_video,
      })
    ).then(() => {
      // userAllChange();
      // adminUserSignUpRequestGetChange();
      alert("Group information has been updated.");
      // setUserNm("");
      // setSelectedCompany(undefined);
      setIsDialogOpen((prev) => ({ ...prev, ["mod"]: false }));
    });

    // setIsDialogOpen((prev) => ({ ...prev, ["su"]: false })); // Close the dialog after submitting
  };

  const handleSubmitCreate = () => {
    dispatch(
        adminGroupAdd({
        token: token as string,
        groupName: permissionForm.name as string,
        groupDesc: permissionForm.description as string,
        is_admin: permissionForm?.is_admin,
        is_chatbot_ts: permissionForm?.is_chatbot_ts,
        is_chatbot_mt: permissionForm?.is_chatbot_mt,
        is_chatbot_gi: permissionForm?.is_chatbot_gi,
        is_main_manual: permissionForm?.is_main_manual,
        is_main_video: permissionForm?.is_main_video,
      })
    ).then(() => {
      // userAllChange();
      // adminUserSignUpRequestGetChange();
      alert("Group information has been created.");
      setIsDialogOpen((prev) => ({ ...prev, ["create"]: false }));
    });
  };

  const handleSubmitDelete = () => {
    dispatch(
        adminGroupDelete({
            token: token as string,
            groupId: permissionForm.id as number,
        })
    ).then((res) => {
        const isSuccess = res.payload.status === 200;
        const message = isSuccess
            ? `Group information has been deleted successfully. ${res.payload.message}`
            : `Failed to delete group. ${res.payload.error.message}`;
            
        alert(message);
        setIsDialogOpen((prev) => ({ ...prev, delete: false }));
    }).catch((error) => {
        alert('An unexpected error occurred while deleting the group.');
        console.error('Delete group error:', error);
    });
  };

  
    return (
        <div className="bg-white shadow-md rounded-lg overflow-hidden p-4">
            <div className="border-b flex justify-between items-center">
                <h2 className="text-2xl font-semibold mb-4">USER GROUP</h2>
                <button
                    onClick={() => { 
                        setPermissionForm({ ...permissionForm, id: 0, name: "", is_admin: false, is_chatbot_ts: false, is_chatbot_mt: true, is_chatbot_gi: false, is_main_manual: false, is_main_video: false, description: "" }) 
                        setIsDialogOpen({ ...isDialogOpen, create: true })
                    }}
                    className="flex items-center gap-2 px-4 py-2 mb-2 bg-blue-500 text-sm text-white rounded-full bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
                ><Plus className="h-4 w-4" /> Create New
                </button>
                <Dialog
                    open={isDialogOpen.create}
                    onOpenChange={() => {setIsDialogOpen({ ...isDialogOpen, create: false })
                }}
                >
                    <DialogContent>
                        <DialogHeader>
                            <DialogTitle>Create New Group</DialogTitle>
                            <DialogDescription>Please enter the information for the new group.</DialogDescription>
                        </DialogHeader>
                        <form onSubmit={(e) => {
                            e.preventDefault()
                            const formData = new FormData(e.currentTarget)
                            // addGroup(formData.get('name'), formData.get('description'))
                        }}>
                            <div className="flex flex-col gap-2">
                                <Input
                                    label="Group Name"
                                    type="text"
                                    id="name"
                                    placeholder="Group Name"
                                    value={permissionForm.name as unknown as string}
                                    onChange={(e) => setPermissionForm({ ...permissionForm, name: e.target.value as string})}
                                />
                                <Input
                                    label="Group Description"
                                    type="text"
                                    id="description"
                                    placeholder="Group Description"
                                    className="mb-4"
                                    value={permissionForm.description as unknown as string}
                                    onChange={(e) => setPermissionForm({ ...permissionForm, description: e.target.value as string})}
                                />
                                {/* <Checkbox
                                    label="Admin"
                                    id="is_admin"
                                    checked={permissionForm.is_admin}
                                    // onChange={(e) => setPermissionForm({ ...permissionForm, is_admin: e.target.checked })}
                                    onChange={(e) => {}}
                                    comment="Admin privileges are protected by the system."
                                    commentColor="text-red-500 ml-4 self-center"
                                /> */}
                                <Checkbox
                                    label="TS chatbot"
                                    id="is_chatbot_ts"
                                    checked={permissionForm.is_chatbot_ts}
                                    onChange={(e) => setPermissionForm({ ...permissionForm, is_chatbot_ts: e.target.checked })}
                                    comment="TroubleShooting chatbot privileges."
                                />
                                <Checkbox
                                    label="MT chatbot"
                                    id="is_chatbot_mt"
                                    checked={permissionForm.is_chatbot_mt}
                                    onChange={(e) => setPermissionForm({ ...permissionForm, is_chatbot_mt: e.target.checked })}
                                    comment="Maintenance chatbot privileges."
                                />
                                <Checkbox
                                    label="GI chatbot"
                                    id="is_chatbot_gi"
                                    checked={permissionForm.is_chatbot_gi}
                                    onChange={(e) => setPermissionForm({ ...permissionForm, is_chatbot_gi: e.target.checked })}
                                    comment="General Inquiry chatbot privileges."
                                />
                                <Checkbox
                                    label="Manual"
                                    id="is_main_manual"
                                    checked={permissionForm.is_main_manual}
                                    onChange={(e) => setPermissionForm({ ...permissionForm, is_main_manual: e.target.checked })}
                                    comment="Manual Guide page privileges."
                                />
                                <Checkbox
                                    label="Video"
                                    id="is_main_video"
                                    checked={permissionForm.is_main_video}
                                    onChange={(e) => setPermissionForm({ ...permissionForm, is_main_video: e.target.checked })}
                                    comment="Video Guide page privileges."
                                />
                            </div>
                            <DialogFooter>
                                <button
                                    type="submit"
                                    onClick={handleSubmitCreate}
                                    className="flex items-center gap-2 px-4 py-2 mb-0 bg-blue-500 text-sm text-white rounded-md bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
                                >Create New Group</button>
                            </DialogFooter>
                        </form>
                    </DialogContent>
                </Dialog>

                <Dialog
                    open={isDialogOpen.edit}
                    onOpenChange={() => setIsDialogOpen({ ...isDialogOpen, edit: false })}
                >
                    <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Change of group permissions</DialogTitle>
                    </DialogHeader>
                    <div className="flex flex-col gap-2">
                        {permissionForm.name && <Input
                            label="Group Name"
                            type="text"
                            id="name"
                            placeholder="Group Name"
                            value={permissionForm.name as unknown as string}
                            onChange={(e) => setPermissionForm({ ...permissionForm, name: e.target.value as string})}
                        />}
                        <Checkbox
                            label="Admin"
                            id="is_admin"
                            checked={permissionForm.is_admin}
                            // onChange={(e) => setPermissionForm({ ...permissionForm, is_admin: e.target.checked })}
                            onChange={(e) => {}}
                            comment="Admin privileges are protected by the system."
                            commentColor="text-red-500 ml-4 self-center"
                        />
                        <Checkbox
                            label="TS chatbot"
                            id="is_chatbot_ts"
                            checked={permissionForm.is_chatbot_ts}
                            onChange={(e) => setPermissionForm({ ...permissionForm, is_chatbot_ts: e.target.checked })}
                        />
                        <Checkbox
                            label="MT chatbot"
                            id="is_chatbot_mt"
                            checked={permissionForm.is_chatbot_mt}
                            onChange={(e) => setPermissionForm({ ...permissionForm, is_chatbot_mt: e.target.checked })}
                        />
                        <Checkbox
                            label="GI chatbot"
                            id="is_chatbot_gi"
                            checked={permissionForm.is_chatbot_gi}
                            onChange={(e) => setPermissionForm({ ...permissionForm, is_chatbot_gi: e.target.checked })}
                        />
                        <Checkbox
                            label="Manual"
                            id="is_main_manual"
                            checked={permissionForm.is_main_manual}
                            onChange={(e) => setPermissionForm({ ...permissionForm, is_main_manual: e.target.checked })}
                        />
                        <Checkbox
                            label="Video"
                            id="is_main_video"
                            checked={permissionForm.is_main_video}
                            onChange={(e) => setPermissionForm({ ...permissionForm, is_main_video: e.target.checked })}
                        />
                    </div>

                    <DialogFooter>
                    <button
                        type="submit"
                        className="flex justify-center items-center w-full text-center px-4 py-3 mb-2 bg-blue-500 text-md text-white rounded-lg bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors gap-4"
                        onClick={handleSubmit}
                        >
                        Apply changes
                        {/* <ArrowRightIcon className="w-4 h-4" /> */}
                        </button>
                        {/* <Button onClick={handleSubmit}>Approve & Sned email</Button> */}
                    </DialogFooter>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={isDialogOpen.delete}
                    onOpenChange={() => setIsDialogOpen({ ...isDialogOpen, delete: false })}
                >
                    <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Delete Group</DialogTitle>
                    </DialogHeader>
                    <DialogDescription>Are you sure you want to delete this group?</DialogDescription>
                    <DialogFooter>
                        <button
                            type="submit"
                            className="flex justify-center items-center w-full text-center px-4 py-3 mb-2 bg-blue-500 text-md text-white rounded-lg bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors gap-4"
                            onClick={handleSubmitDelete}
                        >
                        Delete
                        </button>
                    </DialogFooter>
                    </DialogContent>
                </Dialog>

                </div>
                <Table>
                <TableHeader>
                    <TableRow>
                        {["id", "name", "is_admin", "is_chatbot_ts", "is_chatbot_mt", "is_chatbot_gi", "is_main_manual", "is_main_video", "description"].map((key) => (
                            <TableHead
                                key={key}
                                className="px-6 py-2 text-left text-xs text-nowrap font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                onClick={() => handleSort(key)}
                            >
                                {key === "id"
                                    ? "No."
                                    : key === "name"
                                        ? "Name"
                                        : key === "is_admin"
                                            ? "Admin"
                                            : key === "is_chatbot_ts"
                                                ? "TS chatbot"
                                                : key === "is_chatbot_mt"
                                                    ? "MT chatbot"
                                                    : key === "is_chatbot_gi"
                                                        ? "GI chatbot"
                                                        : key === "is_main_manual"
                                                            ? "Manual"
                                                            : key === "is_main_video"
                                                                ? "Video"
                                                                : key === "description"
                                                                    ? "Description"
                                                : ""}
                                {sortConfig.key === key && (
                                    <span className="ml-1">
                                        {sortConfig.direction === "asc" ? "▲" : "▼"}
                                    </span>
                                )}
                            </TableHead>
                        ))}
                        <TableHead>action</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {paginatedGroups && paginatedGroups.map((group) => (
                        <TableRow key={group.id}>
                            <TableCell>{group.id}</TableCell>
                            <TableCell>{group.name}</TableCell>
                            <TableCell>{group.is_admin ? "O" : "X" }</TableCell>
                            <TableCell>{group.is_chatbot_ts ? "O" : "X"}</TableCell>
                            <TableCell>{group.is_chatbot_mt ? "O" : "X"}</TableCell>
                            <TableCell>{group.is_chatbot_gi ? "O" : "X"}</TableCell>
                            <TableCell>{group.is_main_manual ? "O" : "X"}</TableCell>
                            <TableCell>{group.is_main_video ? "O" : "X"}</TableCell>
                            <TableCell>{group.description}</TableCell>
                            <TableCell>
                                <DropdownMenu>
                                    <DropdownMenuTrigger>
                                        <EllipsisVertical className="h-4 w-4" />
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent>
                                        <DropdownMenuItem
                                            onClick={() =>{
                                                setPermissionForm(group)
                                                setIsDialogOpen({ ...isDialogOpen, edit: true }) 
                                            }}
                                        >Edit</DropdownMenuItem>
                                        <DropdownMenuSeparator />
                                        <DropdownMenuItem
                                            onClick={() =>{
                                                setPermissionForm(group)
                                                setIsDialogOpen({ ...isDialogOpen, delete: true }) 
                                            }}
                                        >Delete</DropdownMenuItem>
                                    </DropdownMenuContent>
                                </DropdownMenu>

                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={groups ? Math.ceil(groups?.length / 20) : 1} totalNumber={groups?.length} listName="권한그룹"/>
         
        </div>
    );
};

export default GroupTable;