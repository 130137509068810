export default {
    solution: {
      tit_group: {
        tit: "S&SYS AI CUSTOMER SUPPORT",
        decs: "We Provide Real-Time AI Services for Our Valued Customers"
      }
    },
    info_list:{
        eco:{
            tit:"BWMS\nSolution",
            desc:"We propose actual fuel reduction methods\nand eco-friendly systems to ensure\noptimum navigational conditions."
        },
        automation:{
            tit:"Automation\nSolution",
            desc:"We fulfill clients’ needs through reliable and\nproven system performance, efficient\ncontrols and easy maintenance.",
            update: "Coming Soon"
        },
        power:{
            tit:"Switchboard\nSolution",
            desc:"Our designs meet environmental and safety\nrequirements based on modern technology\nand experience and we supply various\nsolutions for shipboard electrical systems.",
            update: "Coming Soon"
        },
    }
  };