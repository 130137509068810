import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Document, Page, Outline } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  // 'pdfjs-dist/build/pdf.worker.min.mjs',src/components/pdfViewer/PdfViewer.jsx
  // '../../../node_modules/pdfjs-dist/legacy/build/pdf.worker.min.mjs',
  '../../../../node_modules/pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFViewer = ({ url, page, title, isOpen }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(page );
  const [scale, setScale] = useState(1);
  const viewerRef = useRef(null); // PDF 뷰어 컨테이너 참조

  useEffect(() => {
    setPageNumber(page + 1);
  }, [page]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const goToPrevPage = () => {
    if (pageNumber > 1) setPageNumber(pageNumber - 1);
  };

  const goToNextPage = () => {
    if (pageNumber < numPages) setPageNumber(pageNumber + 1);
  };

  // PDF 페이지 너비에 따라 스케일을 동적으로 조정하는 함수
  useEffect(() => {
    const handleResize = () => {
      if (viewerRef.current) {
        const containerWidth = viewerRef.current.offsetWidth;
        const containerHeight = viewerRef.current.offsetHeight;
        const defaultPageWidth = 700; // PDF의 기본 너비
        const defaultPageHeight = 900;
        const newScale = containerWidth / defaultPageWidth;
        // const newScale = containerHeight / defaultPageHeight; 
        setScale(newScale > 1.5 ? 1.5 : newScale); // 기본 스케일보다 크게 하지 않음
        // console.log({containerWidth, defaultPageWidth, newScale})
      }
    };
    
    window.addEventListener('resize', handleResize);
    handleResize(); // 컴포넌트 초기 로드 시에도 실행
    
    return () => window.removeEventListener('resize', handleResize);
  }, [viewerRef,isOpen]);

  // const loadingTask = pdfjs.getDocument({
  //   url: url,
  //   cMapPacked: true,
  //   enableXfa: true,
  // });

  // console.log({url, page})


  return (
    <div className="pdf-viewer-container flex flex-col w-full h-full" ref={viewerRef}>
      <div className="self-center max-h-[90%] overflow-auto text-center" style={{scrollbarWidth: "thin"}}>
        <Document
          file={url}
          onLoadSuccess={onDocumentLoadSuccess}
          loading="Loading PDF..."
        >
          {/* <Outline /> */}
          <Page pageNumber={pageNumber} 
              scale={scale}  
              loading="Loading..." />
        </Document>
      </div>

      <div class="sticky inset-x-0 bottom-0 flex items-center z-20">
        <div class="relative flex w-full flex-col bg-background mx-auto max-w-3xl px-2 sm:px-3 md:px-4 pb-0 sm:pb-0 md:pb-0">
          <div className="relative pagination-container  bg-gray-100 p-2 flex justify-center items-center">
            <button
              onClick={goToPrevPage}
              disabled={pageNumber <= 1}
              className="pagination-button bg-gray-300 text-gray-700 rounded px-4 py-2 mx-2"
            >
              &lt; Prev
            </button>
            <span className="pagination-info">
              Page {pageNumber} of {numPages}
            </span>
            <button
              onClick={goToNextPage}
              disabled={pageNumber >= numPages}
              className="pagination-button bg-gray-300 text-gray-700 rounded px-4 py-2 mx-2"
            >
              Next &gt;
            </button>
          </div>
      </div>
      </div>
    </div>
  );
};

export default PDFViewer;
// import React, { useEffect, useRef, useState } from "react";
// import { getDocument, GlobalWorkerOptions } from "pdfjs-dist";
// import { ChevronLeft, ChevronRight, ZoomIn, ZoomOut } from "lucide-react";

// // PDF.js 워커 설정
// GlobalWorkerOptions.workerSrc = "/pdf.worker.min.mjs";

// const PDFViewer = ({ url, page }) => {
//   const canvasRef = useRef(null); // PDF 캔버스 참조
//   const containerRef = useRef(null); // 캔버스 컨테이너 참조
//   const renderTaskRef = useRef(null); // 현재 렌더링 작업 참조
//   const [numPages, setNumPages] = useState(0); // 총 페이지 수
//   const [pageNumber, setPageNumber] = useState(page); // 현재 페이지 번호
//   const [scale, setScale] = useState(1.0); // 줌 스케일
//   const [error, setError] = useState(null); // 에러 상태
//   const [dragging, setDragging] = useState(false); // 드래그 상태
//   const [position, setPosition] = useState({ x: 0, y: 0 }); // 캔버스 이동 상태

//   // PDF 로드 및 렌더링
//   useEffect(() => {
//     let pdfInstance = null;

//     const loadPDF = async () => {
//       try {
//         const loadingTask = getDocument(url); // PDF 파일 로드
//         pdfInstance = await loadingTask.promise;
//         setNumPages(pdfInstance.numPages); // 총 페이지 수 설정
//         await renderPage(pdfInstance, pageNumber); // 첫 페이지 렌더링
//       } catch (err) {
//         console.error("Error loading PDF:", err);
//         setError("Failed to load PDF. Please check the file URL.");
//       }
//     };

//     const renderPage = async (pdf, pageNum) => {
//       try {
//         // 이전 작업 취소 및 초기화
//         if (renderTaskRef.current) {
//           await renderTaskRef.current.cancel();
//           renderTaskRef.current = null;
//         }
    
//         const page = await pdf.getPage(pageNum); // 특정 페이지 로드
//         const viewport = page.getViewport({ scale }); // 스케일 적용 뷰포트 생성
    
//         const canvas = canvasRef.current; // 캔버스 참조
//         if (!canvas) {
//           console.error("Canvas is not initialized");
//           return;
//         }
//         const context = canvas.getContext("2d");
//         canvas.width = viewport.width;
//         canvas.height = viewport.height;
    
//         const renderContext = {
//           canvasContext: context,
//           viewport,
//         };
    
//         // 렌더링 작업 시작
//         renderTaskRef.current = page.render(renderContext);
    
//         // 렌더링 완료 대기
//         await renderTaskRef.current.promise;
//         renderTaskRef.current = null; // 작업 완료 후 초기화
//       } catch (err) {
//         if (err.name === "RenderingCancelledException") {
//           console.log("Rendering cancelled");
//         } else {
//           console.error("Error rendering page:", err);
//           setError("Error rendering the PDF page.");
//         }
//       }
//     };

//     loadPDF();

//     // 페이지 넘김 및 스케일 변경 시 렌더링 다시 수행
//     if (pdfInstance) {
//       renderPage(pdfInstance, pageNumber);
//     }

//     // 컴포넌트 언마운트 시 정리
//     return () => {
//       if (pdfInstance) pdfInstance.destroy();
//       if (renderTaskRef.current) renderTaskRef.current.cancel();
//     };
//   }, [url, pageNumber, scale]);

//   const handlePrevPage = () => setPageNumber((prev) => Math.max(prev - 1, 1));
//   const handleNextPage = () => setPageNumber((prev) => Math.min(prev + 1, numPages));
//   const handleZoomIn = () => setScale((prev) => prev + 0.1);
//   const handleZoomOut = () => setScale((prev) => Math.max(prev - 0.1, 0.5));

//   // 드래그 이벤트 처리
//   const handleMouseDown = (e) => {
//     setDragging(true);
//     setPosition({ x: e.clientX, y: e.clientY });
//   };

//   const handleMouseMove = (e) => {
//     if (!dragging) return;

//     const dx = e.clientX - position.x;
//     const dy = e.clientY - position.y;

//     containerRef.current.scrollLeft -= dx;
//     containerRef.current.scrollTop -= dy;

//     setPosition({ x: e.clientX, y: e.clientY });
//   };

//   const handleMouseUp = () => setDragging(false);

//   const handleTouchStart = (e) => {
//     const touch = e.touches[0];
//     setDragging(true);
//     setPosition({ x: touch.clientX, y: touch.clientY });
//   };

//   const handleTouchMove = (e) => {
//     if (!dragging) return;

//     const touch = e.touches[0];
//     const dx = touch.clientX - position.x;
//     const dy = touch.clientY - position.y;

//     containerRef.current.scrollLeft -= dx;
//     containerRef.current.scrollTop -= dy;

//     setPosition({ x: touch.clientX, y: touch.clientY });
//   };

//   const handleTouchEnd = () => setDragging(false);

//   // 에러 상태 처리
//   if (error) {
//     return (
//       <div className="text-center text-red-500">
//         <p>{error}</p>
//         <p>Please try a different file or browser.</p>
//       </div>
//     );
//   }

//   return (
//     <div className="w-full">
//       {/* PDF 캔버스 */}
//       <div
//         ref={containerRef}
//         className="relative text-center h-[calc(100vh-160px)] sm:h-[calc(100vh-180px)] overflow-auto"
//         style={{ scrollbarWidth: "thin" }}
//         onMouseDown={handleMouseDown}
//         onMouseMove={handleMouseMove}
//         onMouseUp={handleMouseUp}
//         onMouseLeave={handleMouseUp}
//         onTouchStart={handleTouchStart}
//         onTouchMove={handleTouchMove}
//         onTouchEnd={handleTouchEnd}
//       >
//         <canvas 
//         ref={canvasRef} 
//         style={{ border: "1px solid black", position: "absolute", left: "0%", transform: "translate(0%, 0%)"}} 
//         />
//       </div>

//       {/* 페이지 및 줌 컨트롤 */}
//       <div className="sticky inset-x-0 bottom-0 z-10 w-full">
//         <div className="relative flex w-full h-[50px] sm:h-[64px] flex-col bg-gray-100 mx-auto max-w-3xl py-0 px-2 sm:px-3 md:px-4">
//           <div className="pagination-container bg-gray-100 p-2 flex justify-center items-center">
//             <button
//               onClick={handlePrevPage}
//               disabled={pageNumber <= 1}
//               className="pagination-button bg-gray-300 text-gray-700 rounded px-4 py-2 mx-2"
//             >
//               <ChevronLeft className="h-5 w-5" />
//               <span className="hidden md:block">Prev</span>
//             </button>
//             <span className="pagination-info text-sm md:text-base text-nowrap">
//               {pageNumber} / {numPages}
//             </span>
//             <button
//               onClick={handleNextPage}
//               disabled={pageNumber >= numPages}
//               className="pagination-button bg-gray-300 text-gray-700 rounded px-4 py-2 mx-2"
//             >
//               <ChevronRight className="h-5 w-5" />
//               <span className="hidden md:block">Next</span>
//             </button>
//             <button
//               onClick={handleZoomOut}
//               className="pagination-button bg-gray-300 text-gray-700 rounded px-4 py-2 mx-2"
//             >
//               <ZoomOut className="h-5 w-5" />
//               <span className="hidden md:block">Zoom Out</span>
//             </button>
//             <button
//               onClick={handleZoomIn}
//               className="pagination-button bg-gray-300 text-gray-700 rounded px-4 py-2 mx-2"
//             >
//               <ZoomIn className="h-5 w-5" />
//               <span className="hidden md:block">Zoom In</span>
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PDFViewer;

