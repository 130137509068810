import React from 'react';
// import './sideBar.css';
// import navModel from '../../data/navItem'
import NavItem from './NavItem';
import {
  selectSideMenu,
  setChatRoomId,
} from "../../lib/features/admin/adminSlice";
import {
  setDocViewer
} from "../../lib/features/chatMain/chatMainSlice";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import logo from '../../images/snsys_logo.png';
import NavAvatar from './NavAvatar';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function SideBar({ isOpen, setIsOpen }) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const sideBar = useAppSelector(selectSideMenu);
  const {t} = useTranslation('translation');

  const handleToggleSideBar = () => {
    setIsOpen(!isOpen)
  };
  const handleMoveMenu = () =>{
    navigate('/menu')
    // dispatch(setChatRoomId(null))
    dispatch(setDocViewer({
      docUrl: "",
      docPage: 0,
      docTitle: "",
      isOn: false,
      }))
  }
  // AI Manager Information
  return (
    <aside className="relative hidden sm:flex sm:flex-col h-screen bg-white text-gray-800 border-r border-gray-200 transition-all duration-300 w-64">
  <div className="flex w-full flex-col items-center gap-1">
    <div className="flex w-full aspect-square h-14 flex-col items-center justify-center">
      <div className="flex items-center h-12 justify-between px-4 py-3">
        <div
          onClick={handleMoveMenu}
          className="w-24 inline-flex shrink-0 cursor-pointer items-center justify-center gap-1.5 whitespace-nowrap text-nowrap transition-all rounded-lg size-8"
          href=""
        >
          <img
            src={logo}
            className="inline-flex shrink-0 cursor-pointer items-center justify-center gap-1.5"
          />
          
        </div>
        
      </div>
    </div>
    <hr className="mx-4 my-2 border-t-gray-400" />
    <nav className="flex h-full w-full flex-col px-3">
      <div className="flex-col h-full flex-1 transition-opacity duration-500 relative -mr-2 pr-2 overflow-y-auto overflow-x-hidden">
        <div className="flex flex-col gap-2 pb-2 text-token-text-primary text-sm mt-0">
          <div className="chatSidebar-nav h-full w-[260px] text-sm" id="side">
            {sideBar.map((nav, idx) => (
              <NavItem key={nav.label} item={nav} index={idx} />
            ))}
          </div>
        </div>
      </div>
      <div className="flex flex-col py-2 empty:hidden dark:border-white/20"></div>
    </nav>
  </div>

  {/* 아바타를 맨 밑에 고정 */}
  <div className="absolute inset-x-0 bottom-0 px-4 py-2 border-t border-gray-200">
    <NavAvatar sidebar={true} toggle={true} />
  </div>
</aside>

  )
}

export default SideBar
