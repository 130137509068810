export default {
    chatMenu: {
      title: {
        tit: "Support & Services",
        list: ["Quickly diagnose and expertly resolve technical issues.",
            "Provides guidance on how to properly maintain equipment.",
            "Ask about S&SYS products, services, or any other inquiries.",
            "Conveniently request services or spare parts."
        ]
      }
    },
    info_list:{
        title: "Please select the menu you want",
        troubleshooting:{
            name:"TROUBLESHOOTING",
        },
        maintenance:{
            name:"MAINTENANCE & OPERATION GUIDE",
        },
        generalinquiry:{
            name:"GENERAL INQUIRY",
            update: "Coming Soon"
        },
        servicerequest:{
            name:"SERVICE REQUEST",
        },
    }
  };