import React, { useEffect, useRef, useState } from "react";
import "./chatContents.css";
import Message from "./Message";

import {
  chatMessageGet,
  chatMessageAdd,
  selectChatMain,
  selectChatMode,
  selectStreamStatus
} from "../../lib/features/chatMain/chatMainSlice";
import {
    selectUser,
  } from "../../lib/features/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import { useNavigate } from "react-router-dom";
import {default as greet_messages} from '../../data/greet_message.json'



function ChatContents({mode}) {
  const messageEndRef = useRef(null);

  const chatMain = useAppSelector(selectChatMain);
  const chatMode = useAppSelector(selectChatMode);
  const user = useAppSelector(selectUser);
  const streamStatus = useAppSelector(selectStreamStatus);
  const navigate = useNavigate();

  const greet_message = [
    user?.user_lang == "EN"
      ? chatMode.mode_2 == "TS"
        ? {...greet_messages.EN.BWMS_troubleshooting, text: JSON.stringify([{text:greet_messages.EN.BWMS_troubleshooting.text}])}
        : chatMode.mode_2 == "MT"
        ? {...greet_messages.EN.BWMS_maintenance, text: JSON.stringify([{text:greet_messages.EN.BWMS_maintenance.text}])}
        : chatMode.mode_2 == "GI"
        ? {...greet_messages.EN.BWMS_generalinquery, text: JSON.stringify([{text:greet_messages.EN.BWMS_generalinquery.text}])}
        : {...greet_messages.EN.BWMS_except, text: JSON.stringify([{text:greet_messages.EN.BWMS_except.text}])}
      : chatMode.mode_2 == "TS"
      ? {...greet_messages.KO.BWMS_troubleshooting, text: JSON.stringify([{text:greet_messages.KO.BWMS_troubleshooting.text}])}
      : chatMode.mode_2 == "MT"
      ? {...greet_messages.KO.BWMS_maintenance, text: JSON.stringify([{text:greet_messages.KO.BWMS_maintenance.text}])}
      : chatMode.mode_2 == "GI"
      ? {...greet_messages.KO.BWMS_generalinquery, text: JSON.stringify([{text:greet_messages.KO.BWMS_generalinquery.text}])}
      : {...greet_messages.KO.BWMS_except, text: JSON.stringify([{text:greet_messages.KO.BWMS_except.text}])}
  ];

  // const greet_message =[user
  //                     ?.user_lang=='EN'?
  //                       {...greet_messages.en.BWMS_troubleshooting, text: JSON.stringify([{text:greet_messages.en.BWMS_troubleshooting.text}])}
  //                     :{...greet_messages.kr.BWMS_troubleshooting, text: JSON.stringify([{text:greet_messages.kr.BWMS_troubleshooting.text}])}];

  

  useEffect(()=>{},[chatMain.messages])
  useEffect(() => {
    scrollToBottom();
    // console.log("call")
  }, [chatMain.messages]);

  const scrollToBottom = () => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollTop = messageEndRef.current.scrollHeight;
    }
  };

  const moveServicePage = () => {
    const roomId = chatMain.roomId;
    navigate("/service/" + roomId);
  };

  return (
    <div className=" flex h-full ">
      <div
        className=" text-base py-[0px] px-0 m-auto w-full h-full md:px-5 lg:px-1 xl:px-5 "
        ref={messageEndRef}
      >
        <div className="group/conversation-turn relative flex w-full h-full min-w-0 flex-col agent-turn justify-between">
          <div className="group/conversation-turn relative flex w-full min-w-0 flex-col agent-turn">
            <div className="chatMargin"></div>
            {!mode && chatMain.messages &&
              chatMain.messages.map((item, index) => (
                <Message key={index} model={item} roomId={chatMain.roomId} messages={chatMain.messages}/>
              ))}

            {mode=="greet" && greet_message &&
                greet_message.map((item, index) => (
                <Message key={index} model={item} />
              ))}
            {streamStatus!=="idle" && <Message key={'waiting'} model={{type:"waiting", isBot:true}} />}
              

            <div className="chatFootMargin" />
          </div>
          {/* <a
            onClick={() => moveServicePage()}
            className="relative w-full px-2 my-3 text-right text-xs text-gray-600 text-token-text-secondary empty:hidden md:px-[60px]"
          >
            {user.user_lang=="KO"? "그래도 문제가 해결되지 않으신가요? 서비스를 신청하세요. 바로가기 >>"
            :"Still not solving the problem? Apply for service. Go to >>"}
          </a> */}
        </div>
      </div>
    </div>
  );
}

export default ChatContents;
