import React, { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../lib/hooks";
import {
  selectDashboardUser,
  selectDashboardGroup,
  selectDashboardCompany,
  selectDashboardSignUpRequest,
} from "../../../lib/features/admin/adminSlice";
import { selectToken } from "../../../lib/features/auth/authSlice";
import {
  adminUserAllGet,
  adminGroupListGet,
  adminUserSignUpRequestGet,
  adminCompanyGet,
} from "../../../lib/features/admin/adminSlice";

import DashboardTabs from "../Common/DashboardTabs";
import UserTable from "../UserPage/UserTable";
import GroupTable from "../UserPage/GroupTable";
import CompanyTable from "../UserPage/CompanyTable";
import Pagination from "../Common/Pagination";
import { Tabs, TabsContent, TabsTrigger } from "../../ui/tabs";
import SignUpRequestTable from "../UserPage/SignUpRequestTable";
import PersonInCharge from "../UserPage/PersonInCharge";
const APP_STAGE = process.env.REACT_APP_STAGE;

const AdminUserPage = () => {
  const dispatch = useAppDispatch();
  const token = useAppSelector(selectToken);
  const allUsers = useAppSelector(selectDashboardUser);
  const groups = useAppSelector(selectDashboardGroup);
  const companies = useAppSelector(selectDashboardCompany);
  const signUpRequests = useAppSelector(selectDashboardSignUpRequest);
  const [currentPage, setCurrentPage] = useState(1);
  const [tabValue, setTabValue] = useState("users");

  // useEffect(() => {
  //   dispatch(adminUserAllGet({ token }));
  //   dispatch(adminGroupListGet({ token }));
  //   dispatch(adminCompanyGet({ token }));
  //   dispatch(adminUserSignUpRequestGet({ token }));
  // }, []);

  useEffect(() => {
    dispatch(adminUserAllGet({ token }));
    dispatch(adminGroupListGet({ token }));
    dispatch(adminCompanyGet({ token }));
    dispatch(adminUserSignUpRequestGet({ token }));
  }, [dispatch, token]);
  useEffect(() => {
      dispatch(adminUserAllGet({ token }));
      dispatch(adminGroupListGet({ token }));
    dispatch(adminCompanyGet({ token }));
    dispatch(adminUserSignUpRequestGet({ token }));
  }, [tabValue]);
  const userAllChange = () => {
    dispatch(adminUserAllGet({ token }));
  };

  const companyAllChange = () => {
    dispatch(adminCompanyGet({ token }));
  };

  const adminUserSignUpRequestGetChange = () => {
    dispatch(adminUserSignUpRequestGet({ token }));
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [tabValue]);
  useEffect(() => {}, [allUsers]);

  const tabData = [
    { tabName: "users", name: "USERS", notifications: 3 },
    { tabName: "groups", name: "GROUPS", notifications: 0 },
    { tabName: "company", name: "COMPANIES", notifications: 0 },
    { tabName: "request", name: "SIGN-UP REQUESTS", notifications: 1 },
    { tabName: "pic", name: "PIC", notifications: 0 },
  ];
  if (APP_STAGE === "DEV") {  
    console.log(signUpRequests);
  }
  return (
    <div className="w-full p-0 mx-0">
      <h1 className="text-2xl font-bold mb-6">USER MANAGEMENT DASHBOARD</h1>
      <Tabs defaultValue="users">
        <DashboardTabs
          data={tabData}
          tabValue={tabValue}
          setTabValue={setTabValue}
        />
        <TabsContent value="users">
          <UserTable
            users={allUsers?.users || []}
            token={token}
            groups={groups || []}
            companies={companies || []}
            userAllChange={userAllChange}
            companyAllChange={companyAllChange}
          />
        </TabsContent>
        <TabsContent value="groups">
          <GroupTable groups={groups || []} />
        </TabsContent>
        <TabsContent value="company">
          <CompanyTable
            token={token}
            companies={companies || []}
            userAllChange={companyAllChange}
          />
        </TabsContent>
        <TabsContent value="request">
          <SignUpRequestTable
            signUpRequests={signUpRequests || []}
            token={token}
            companies={companies || []}
            adminUserSignUpRequestGetChange={adminUserSignUpRequestGetChange}
          />
        </TabsContent>
        <TabsContent value="pic">
          <PersonInCharge
            token={token}
          />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default AdminUserPage;
