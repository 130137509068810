import "bootstrap-icons/font/bootstrap-icons.css";
import "remixicon/fonts/remixicon.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/js/dist/util";
import "bootstrap";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import ChatPage from "./pages/ChatPage";
import Dashboard from "./pages/Dashboard";
import EmailSendPage from "./pages/EmailSendPage";
import ServicePage from "./pages/ServicePage";
import {
  userMe,
  selectToken,
  selectUser,
  selectIsAuthenticated,
  setToken,
} from "./lib/features/auth/authSlice";
import MenuPage from "./pages/MenuPage";
import ChatHistory from "./pages/ChatHistoryPage";
import ChatHistoryPage from "./pages/ChatHistoryPage";
import PDFPage from "./pages/PDFPage";
import VideoPage from "./pages/VideoPage";
import i18n from "./i18n";
import "@fortawesome/fontawesome-free/css/all.min.css";
import UserServiceRequestPage from "./pages/UserServiceRequestPage";
import PasswordChange from "./components/login/PasswordChange";
import ResetPassword from "./components/login/ResetPassword";
import PersonInChargePage from "./pages/PersonInCharge";

function App() {
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const user = useSelector(selectUser);

  const isAuthenticated = useSelector(selectIsAuthenticated);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken && !token && !user) {
      dispatch(setToken(storedToken));
      dispatch(userMe(storedToken));
    }
  }, [dispatch, token]);

  useEffect(() => {
    changeLanguage(user?.user_lang.toLowerCase());
  }, [user?.user_lang]);

  useEffect(() => {
    if (token && !isAuthenticated && !user) {
      dispatch(userMe(token));
    }

    // const forcePasswordChangeAlertShown = sessionStorage.getItem(
    //   "forcePasswordChangeAlertShown"
    // );

    // if (
    //   user &&
    //   user.force_password_change === 1
    // ) {
    //   alert("You must change your password before continuing.");
    //   sessionStorage.setItem("forcePasswordChangeAlertShown", true);
    //   window.location.href = "/password-change"; // Redirect to password change page
    // }
  }, [dispatch, token, isAuthenticated, user]);

  return (
    <div className="App relative flex h-full w-full overflow-hidden transition-colors">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/password-change" element={<PasswordChange />} />

          <Route
            path="dashboard"
            element={
              isAuthenticated && user && !user.is_pw_change_forced  ? (
                <Dashboard />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="chat"
            element={
              isAuthenticated && user && !user.is_pw_change_forced  ? (
                <ChatPage />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="history"
            element={
              isAuthenticated && user && !user.is_pw_change_forced  ? (
                <ChatHistoryPage />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="menu"
            element={
              isAuthenticated && user && !user.is_pw_change_forced  ? (
                <MenuPage />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          {/* <Route
            path="service"
            element={isAuthenticated ? <ServicePage /> : <Navigate to="/" />}
          />*/}
          <Route
            path="service/:roomId"
            element={
              isAuthenticated && user && !user.is_pw_change_forced  ? (
                <ServicePage />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="email-send"
            element={
              isAuthenticated && user && !user.is_pw_change_forced  ? (
                <EmailSendPage />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="email-send/:roomId"
            element={
              isAuthenticated && user && !user.is_pw_change_forced  ? (
                <EmailSendPage />
              ) : (
                <Navigate to="/" />
              )
            }
          />

          <Route
            path="pdf"
            element={
              isAuthenticated && user && !user.is_pw_change_forced  ? (
                <PDFPage />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="video"
            element={
              isAuthenticated && user && !user.is_pw_change_forced  ? (
                <VideoPage />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="pic"
            element={
              isAuthenticated && user && !user.is_pw_change_forced  ? (
                <PersonInChargePage />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route path="reset-password/:token" element={<ResetPassword />} />

          {/* <Route path="/" element={<Home />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
