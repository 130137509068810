import React, { useEffect, useState } from 'react';
import { useAppSelector } from "../../../lib/hooks";
import PDFViewer from './PdfViewer';
import { X } from 'lucide-react';

const SideBarDoc = () => {
  const [isOpen, setIsOpen] = useState(false);
  const docViewer = useAppSelector(state => state.chatMain.docViewer);
  const token = useAppSelector(state => state.authentication?.token);

  useEffect(() => {
    setIsOpen(docViewer.isOn);
    // console.log("DocViewer state:", docViewer);
  }, [docViewer]);

  if (!isOpen) return null;

  return (
    <div className="lg:flex-1 lg:static fixed top-0 left-0 bottom-0 z-50 w-full h-full lg:h-auto bg-white lg:bg-transparent flex flex-col">
      {/* {console.log("Rendering PDF with URL:", docViewer?.docUrl)} */}
      <div className="flex flex-col h-full overflow-auto">
        <div className="flex justify-between items-center p-2 border-b sticky top-0 bg-white z-10">
          <h2 className="text-lg font-medium truncate">{docViewer?.docTitle}</h2>
          <button
            className="rounded-full p-1 text-gray-500 hover:bg-gray-200 outline-none focus:ring-2"
            aria-label="Close"
            onClick={() => setIsOpen(false)}
          >
            <X className="h-5 w-5" />
          </button>
        </div>
        <div className="flex-1 p-2 overflow-auto">
          {docViewer?.docUrl && (
            <PDFViewer 
              url={docViewer?.docUrl + '?token=' + token?.split(' ')[1]} 
              page={docViewer?.docPage} 
              title={docViewer?.docTitle} 
              isOpen={isOpen} 
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SideBarDoc;
