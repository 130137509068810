import React, { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../lib/hooks";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../ui/table";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DropdownMenuSub,
  DropdownMenuSubTrigger,
  DropdownMenuSubContent,
} from "../../ui/dropdown-menu";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../ui/dialog";
import { adminCompanyAdd } from "../../../lib/features/admin/adminSlice";

import {
  Plus,
  Search,
  Filter,
  EllipsisVertical,
  MoreVertical,
  Check,
  X,
  Trash2,
  UserPlus,
  Users,
} from "lucide-react";
import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import Pagination from "../Common/Pagination";

const ITEMS_PER_PAGE = 20;
const CompanyTable = ({
  companies,
  token,
  userAllChange,
}: {
  companies: any;
  token: any;
  userAllChange: any;
}) => {
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [newCompanyForm, setNewCompanyForm] = useState({
    name: "",
  });
  const [companyForm, setCompanyForm] = useState(
    companies.map((conpany: any) => ({
      id: conpany.company_id,
      name: conpany.company_name,
    })) || []
  );

  const [sortConfig, setSortConfig] = useState({ key: "id", direction: "asc" });
  const companyAdd = () => {
    if (!newCompanyForm.name.trim()) {
      alert("회사 이름을 입력해 주세요.");
      return;
    }
    dispatch(adminCompanyAdd({ token, company: newCompanyForm.name })).then(
      () => {
        userAllChange();
        alert("변경되었습니다.");
        setNewCompanyForm({ name: "" }); // 입력 필드 초기화
      }
    );
  };
  function formatDateTime(dateTimeString: string) {
    if (dateTimeString.length !== 14) {
      return "잘못된 입력 형식입니다.";
    }

    const year = dateTimeString.slice(0, 4);
    const month = dateTimeString.slice(4, 6);
    const day = dateTimeString.slice(6, 8);
    const hour = dateTimeString.slice(8, 10);
    const minute = dateTimeString.slice(10, 12);
    const second = dateTimeString.slice(12, 14);

    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  }
  // 전체 상태 정리
  // 활성, 잠김(이메일, 승인안됨, 비번오류, 이상감지)
  // function getStatus(group: any) {
  //     if (group.is_deleted) {
  //       return "탈퇴";
  //     } else if (!user.is_verified || !user.has_email_check || user.is_locked) {
  //       return "잠김";
  //     } else {
  //       return "활성";
  //     }
  // }
  useEffect(() => {
    setCompanyForm(
      companies.map((company: any) => ({
        id: company.company_id,
        name: company.company_name,
      })) || []
    );
  }, [companies]);

  const sortedCompanies = useMemo(() => {
    return [...companyForm].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key])
        return sortConfig.direction === "asc" ? -1 : 1;
      if (a[sortConfig.key] > b[sortConfig.key])
        return sortConfig.direction === "asc" ? 1 : -1;
      return 0;
    });
  }, [companyForm, sortConfig]);
  const totalPages = Math.ceil(sortedCompanies.length / ITEMS_PER_PAGE);
  const paginatedCompanies = sortedCompanies.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const handleSort = (key: string) => {
    setSortConfig((currentConfig) => {
      if (currentConfig.key === key) {
        return {
          ...currentConfig,
          direction: currentConfig.direction === "asc" ? "desc" : "asc",
        };
      }
      return { key, direction: "asc" };
    });
  };

  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden p-4">
      <div className="border-b flex justify-between items-center">
        <h2 className="text-2xl font-semibold mb-4">회사그룹 목록</h2>
        <Dialog>
          <DialogTrigger asChild>
            <button className="flex items-center gap-2 px-4 py-2 mb-2 bg-blue-500 text-sm text-white rounded-full bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors">
              <Plus className="h-4 w-4" /> 새 회사그룹 생성
            </button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>새 회사 추가</DialogTitle>
              <DialogDescription>
                새로운 회사의 정보를 입력하세요.
              </DialogDescription>
            </DialogHeader>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                companyAdd();
              }}
            >
              <div className="grid gap-2 py-2">
                <div className="flex items-center gap-4">
                  <Input
                    label="회사이름"
                    type="text"
                    id="name"
                    placeholder="회사이름"
                    value={newCompanyForm.name}
                    onChange={(e) =>
                      setNewCompanyForm({
                        ...newCompanyForm,
                        name: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <DialogFooter>
                <button
                  type="submit"
                  className="flex items-center gap-2 px-4 py-2 mb-0 bg-blue-500 text-sm text-white rounded-md bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
                >
                  회사 추가
                </button>
              </DialogFooter>
            </form>
          </DialogContent>
        </Dialog>
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            {["id", "name"].map((key) => (
              <TableHead
                key={key}
                className="px-6 py-2 text-left text-xs text-nowrap font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                onClick={() => handleSort(key)}
              >
                {key === "id" ? "No." : "회사이름"}
                {sortConfig.key === key && (
                  <span className="ml-1">
                    {sortConfig.direction === "asc" ? "▲" : "▼"}
                  </span>
                )}
              </TableHead>
            ))}
            <TableHead>작업</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {paginatedCompanies &&
            paginatedCompanies.map((company) => (
              <TableRow key={company.id}>
                <TableCell>{company.id}</TableCell>
                <TableCell>{company.name}</TableCell>

                <TableCell>
                  <DropdownMenu>
                    <DropdownMenuTrigger>
                      <EllipsisVertical className="h-4 w-4" />
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                      <DropdownMenuItem>프로필</DropdownMenuItem>
                      <DropdownMenuItem>대화내역</DropdownMenuItem>
                      <DropdownMenuItem>권한변경</DropdownMenuItem>
                      <DropdownMenuItem>회사변경</DropdownMenuItem>
                      <DropdownMenuSeparator />
                      <DropdownMenuItem>계정잠금</DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={companies ? Math.ceil(companies?.length / 20) : 1}
        totalNumber={companies?.length}
        listName="회사그룹"
      />
    </div>
  );
};

export default CompanyTable;
