import React from 'react'
import { X } from 'lucide-react'

type UploadModalProps = {
  isOpen: boolean
  onClose: () => void
  files: File[]
  onConfirm: () => void
}

export default function UploadModal ({ isOpen, onClose, files, onConfirm }: UploadModalProps) {
  if (!isOpen) return null

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-4xl" role="dialog" aria-modal="true" aria-labelledby="modal-title">
        <div className="flex justify-between items-center mb-2">
          <h2 id="modal-title" className="text-xl font-bold">업로드 파일 정보 ({files.length} 건)</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700" aria-label="닫기">
            <X size={24} />
          </button>
        </div>
        <h6 className="text-sm text-blue-600">업로드 전 파일을 확인 후 버튼을 눌러 업로드 해 주세요.</h6>
        <div className="mb-1 max-h-80 overflow-y-auto gap-1 mb-4">
          {files.map((file, index) => (
            <div key={index} className="flex flex-col justify-between px-4 py-1 mb-1 bg-gray-100 rounded">
              <div className="flex justify-between mb-1">
                <p className="font-medium p-0 m-0">{file.name}</p>
                
              </div>
              <div className="flex justify-between gap-4 text-xs">
                <p className="text-xs text-gray-600 p-0 m-0">{'./'+file.webkitRelativePath.substring(0, file.webkitRelativePath.lastIndexOf('/'))+'/'}</p>
                <p className="text-sm text-gray-600 p-0 m-0">
                    {file.size >= 1024 * 1024
                        ? `${(file.size / (1024 * 1024)).toFixed(2)} MB`
                        : `${(file.size / 1024).toFixed(2)} KB`}
                </p>
                <p className="text-sm text-gray-600 p-0 m-0">{file.type || '알 수 없음'}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 mr-2"
          >
            취소
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            확인
          </button>
        </div>
      </div>
    </div>
  )
}