import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logoImgage from "../images/snsys_logo.png";
import { selectUser } from "../lib/features/auth/authSlice";
import { useAppSelector } from "../lib/hooks";
import Login from "../components/login/Login";
import Register from "../components/login/Register";
import FindPW from "../components/login/FindPW";
import backgroundImage from "../images/login_bg.jpg";
import UserServiceRequestPage from "./UserServiceRequestPage";

function Home() {
  const user = useAppSelector(selectUser);
  const [page, setPage] = useState("signin"); //'signin', 'signup', 'forgetpw'

  const navigate = useNavigate();

  useEffect(() => {
    if (user && user.is_pw_change_forced === true) {
      navigate("/password-change");
      return;
    }
    if (user) {
      navigate("/menu");
      return;
    }
  }, [user, navigate]);

  return (
    <div className="flex flex-col md:flex-row items-center justify-center min-h-screen w-full">
      <div
        className="hidden md:flex flex-col w-full md:w-3/5 h-screen bg-cover bg-center relative"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <img
          className="absolute top-6 left-6 w-24 md:w-36 aspect-[3.57] object-contain"
          src={logoImgage}
          alt="logo"
        />
        <div className="flex flex-col flex-grow items-center text-center text-white font-bold p-10 bg-black/30">
          <div className="text-4xl md:text-6xl font-semibold leading-snug tracking-wider mb-8  mt-40">
            Technology
            <br />
            Connect with You
          </div>
          <div className="text-2xl mt-4">
            에스엔시스는 오늘의 기술을 더 나은 당신의 내일로 이어갑니다.
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center w-full md:w-2/5 h-screen p-4 md:p-6">
        <img
          className="block md:hidden w-24 mt-6 mb-4"
          src={logoImgage}
          alt="logo"
        />
        {/* {user ? (
          handleMovePage()
        ) : page === "signin" ? (
          <Login mvPage={setPage} />
        ) : page === "signup" ? (
          <Register mvPage={setPage} />
        ) : page === "user-service" ? (
          <UserServiceRequestPage mvPage={setPage} />
        ) : (
          <FindPW mvPage={setPage} />
        )} */}
        {page === "signin" ? (
          <Login mvPage={setPage} />
        ) : page === "signup" ? (
          <Register mvPage={setPage} />
        ) : page === "user-service" ? (
          <UserServiceRequestPage mvPage={setPage} />
        ) : (
          <FindPW mvPage={setPage} />
        )}
      </div>
    </div>
  );
}

export default Home;
