import {
  authLogin,
  User,
  authRegister,
  UserLangChangeData,
  requestUserData,
  UserLangChangePassword,
  serviceEmailSendData,
} from "./authSlice";

// const BASE_URL:string = 'http://155.230.135.140:50005';
const BE_HOST = process.env.REACT_APP_BE_HOST;
// const BASE_URL:string = 'http://155.230.135.140:50005';
const BASE_URL: string = BE_HOST ? BE_HOST : "https://snsys-dev.neoali.com";
// const BASE_URL: string = "http://localhost:12345";
// A mock function to mimic making an async request for data

const APP_STAGE = process.env.REACT_APP_STAGE;

// export const fetchServiceEmailSend = async (data: serviceEmailSendData) => {
//   try {
//     if (APP_STAGE === "DEV") {  
//       console.log(data);
//     }
//     const response = await fetch(BASE_URL + "/api/auth/email/service-email/send", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json", // Add Content-Type header
//         Authorization: data.token,
//       },
//       body: JSON.stringify({
//         service_emails: data.data.shipEmail,
//         issue_detail: data.data.issueDetail,
//         imo_no: data.data.imono,
//         phone_number: data.data.phoneNumber,
//         ship_owner: data.data.shipowner,
//         your_name: data.data.yourName,
//         vessel_name: data.data.vesselName,
//         roomId: data.roomId,
//       }),
//     });

//     const result: any = await response.json();

//     return result;
//   } catch (error) {
//     if (APP_STAGE === "DEV") {  
//       console.error("Error in fetchUser:", error);
//     }
//     throw error;
//   }
// };

export const fetchServiceEmailSend = async (data: serviceEmailSendData) => {
  try {
    if (APP_STAGE === "DEV") {
      console.log(data);
    }

    const formData = new FormData();
    formData.append("service_emails", JSON.stringify(data.data.shipEmail));
    formData.append("issue_detail", data.data.issueDetail);
    formData.append("imo_no", data.data.imono);
    formData.append("phone_number", data.data.phoneNumber);
    formData.append("ship_owner", data.data.shipowner);
    formData.append("your_name", data.data.yourName);
    formData.append("vessel_name", data.data.vesselName);
    formData.append("roomId", data.roomId);

    if (data.files && data.files.length > 0) {
      data.files.forEach((file: any) => {
        formData.append("files", file); // 여러 파일 추가
      });
    }

    const response = await fetch(BASE_URL + "/api/auth/email/service-email/send", {
      method: "POST",
      headers: {
        Authorization: data.token, // Content-Type은 FormData가 자동으로 설정
      },
      body: formData,
    });

    if (!response.ok) {
      // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
      const error = await response.json();
      throw { status: response.status, message: error.message  || 'Unknown error' };
    }
  
    return response.json();
  } catch (error) {
    if (APP_STAGE === "DEV") {
      console.error("Error in fetchServiceEmailSend:", error);
    }
    throw error;
  }
};

export const fetchUserPasswordChange = async (data: UserLangChangePassword) => {
  try {
    const response = await fetch(BASE_URL + "/api/user/change-password", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: data.token,
      },
      body: JSON.stringify({
        password: data.password,
      }),
    });

    if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
  } catch (error) {
    if (APP_STAGE === "DEV") {  
      console.error("Error in fetchUser:", error);
    }
    throw error;
  }
};

export const fetchUserPasswordReminder = async (token: string) => {
  try {
    const response = await fetch(BASE_URL + "/api/auth/reminder", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
  } catch (error) {
    if (APP_STAGE === "DEV") {  
      console.error("Error in fetchUser:", error);
    }
    throw error;
  }
};
export const requestUserAPI = async (requestUserData: any) => {
  try {
    if (APP_STAGE === "DEV") {  
      console.log(requestUserData);
    }
    const response = await fetch(BASE_URL + "/api/auth/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Add Content-Type header
      },
      body: JSON.stringify({
        user_email: requestUserData.shipEmail,
        imo_no: requestUserData.imono,
        vessel_name: requestUserData.vesselName,
        contact_num: requestUserData.phoneNumber,
        reason: requestUserData.reason,
        your_name: requestUserData.yourName,
        company: requestUserData.company,
      }),
    });

    if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
  } catch (error) {
    if (APP_STAGE === "DEV") {  
      console.error("Error in fetchUser:", error);
    }
    throw error;
  }
};

export const fetchUser = async (token: string) => {
  try {
    const response = await fetch(BASE_URL + "/api/user/me", {
      method: "GET",
      headers: { "Content-Type": "application/json", Authorization: token },
    });

    if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
  } catch (error) {
    if (APP_STAGE === "DEV") {  
      console.error("Error in fetchUser:", error);
    }
    throw error;
  }
};

export const fetchUserLangChange = async (
  userLangChangeData: UserLangChangeData
) => {
  try {
    const response = await fetch(
      BASE_URL + "/api/user/lang?lang=" + userLangChangeData.lang,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: userLangChangeData.token,
        },
      }
    );

    if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
  } catch (error) {
    if (APP_STAGE === "DEV") {  
      console.error("Error in fetchUser:", error);
    }
    throw error;
  }
};

export const fetchLogin = async (authData: authLogin) => {
  const response = await fetch(BASE_URL + "/api/auth/signin", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      id: authData.id,
      password: authData.password,
    }),
  });
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};

export const fetchLogout = async (token: string) => {
  const response = await fetch(BASE_URL + "/api/auth/signout", {
    method: "GET",
    headers: { "Content-Type": "application/json", Authorization: token },
  });
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};

export const fetchRegister = async (authData: authRegister) => {
  const response = await fetch(BASE_URL + "/api/auth/signup", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      email: authData.useremail,
      user_nm: authData.username,
      password: authData.password,
      lang: authData.lang,
    }),
  });
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};
