'use client'

import React, { useState, useEffect, useRef } from 'react'
import { ChevronsUpDown, Check } from 'lucide-react'

interface Items {
    id: string
    name: string
    selected: boolean
    category?: string
    color?: string
}

interface SelectionFilterProps {
    selectionItems: Items[];
    setSelectionItems: (tags: Items[]) => void;
    className?: string;
}

// export function SelectionFilter({selectionItems, setSelectionItems}: {selectionItems: Items[], setSelectionItems: (tags: Items[]) => void}) {
export const SelectionFilter = React.forwardRef<HTMLDivElement, SelectionFilterProps>(
    (
        {
            selectionItems,
            setSelectionItems,
            className,
            ...props
        }, ref
    ) => {
        const [open, setOpen] = useState(false)
        const [selectedItems, setSelectedItems] = useState<string[]>(selectionItems.map(item => item.id))
        const [searchValue, setSearchValue] = useState('')
        const [filteredItems, setFilteredItems] = useState(selectionItems)

        const dropdownRef = useRef<HTMLDivElement | null>(null)

        useEffect(() => {
            const initiallySelected = selectionItems.filter((item) => item.selected).map((item) => item.id)
            setSelectedItems(initiallySelected)
            setFilteredItems(selectionItems)
        }, [selectionItems])

        useEffect(() => {
            const handleOutsideClick = (event: MouseEvent) => {
                if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                    setOpen(false)
                }
            }

            if (open) {
                document.addEventListener('mousedown', handleOutsideClick)
            }

            return () => {
                document.removeEventListener('mousedown', handleOutsideClick)
            }
        }, [open])

        useEffect(() => {
            const lowercasedSearch = searchValue.toLowerCase()
            const filtered = selectionItems.filter((item) =>
                item.name.toLowerCase().includes(lowercasedSearch)
            )
            setFilteredItems(filtered)
        }, [searchValue, selectionItems])

        const toggleItem = (itemId: string) => {
            let updatedSelectedItems: string[]
            if (itemId === 'all') {
                updatedSelectedItems =
                    selectionItems.length === selectedItems.length ? [] : selectionItems.map((item) => item.id)
            } else {
                updatedSelectedItems = selectedItems.includes(itemId)
                    ? selectedItems.filter((id) => id !== itemId)
                    : [...selectedItems, itemId]
            }
            setSelectedItems(updatedSelectedItems)

            // 업데이트된 상태를 부모로 전달
            const updatedSelectionItems = selectionItems.map((item) => ({
                ...item,
                selected: updatedSelectedItems.includes(item.id),
            }))
            setSelectionItems(updatedSelectionItems)
        }


        return (
            <div
                ref={(node) => {
                    dropdownRef.current = node
                    if (typeof ref === "function") {
                        ref(node)
                    } else if (ref) {
                        (ref as React.MutableRefObject<HTMLDivElement | null>).current = node
                    }
                }}
                // onBlur={() => setOpen(false)}
                className={`relative w-[300px] ${className}`}
            >
                <button
                    onClick={() => setOpen(!open)}
                    className="w-full flex items-center justify-between p-2 border rounded-md"
                >
                    <span className="truncate">
                        {selectedItems.length > 0
                            ? `${selectedItems.length} item${selectedItems.length > 1 ? 's' : ''} selected`
                            : "Select items..."}
                    </span>
                    <ChevronsUpDown className="h-4 w-4 opacity-50" />
                </button>
                {open && (
                    <div className="absolute z-10 w-full mt-1 bg-white border rounded-md shadow-lg">
                        <input
                            type="text"
                            placeholder="Find items..."
                            className="w-full p-2 border-b"
                            onChange={(e) => setSearchValue(e.target.value)}
                        />
                        <div
                            key={"0"}
                            className="flex items-center px-2 py-1 cursor-pointer bg-gray-200 hover:bg-gray-300"
                            onClick={() => toggleItem("all")}
                        >
                            <span className="flex-1 select-none">Select All Items</span>
                            {selectionItems.length === selectedItems.length ?
                                <Check className="h-4 w-4" />
                                : <Check className="h-4 w-4 text-gray-100" />}
                        </div>
                        <div className="max-h-60 overflow-y-auto">
                            {filteredItems.length === 0 ? (
                                <div className="p-2 text-sm text-gray-500">No items found.</div>
                            ) : (
                                filteredItems.map((item) => (
                                    <div
                                        key={item.id}
                                        className="flex items-center px-2 py-1 cursor-pointer hover:bg-gray-100"
                                        onClick={() => toggleItem(item.id)}
                                    >
                                        {item.category && (
                                            <div
                                                className="w-1 h-4 rounded-full mr-2"
                                                style={{ backgroundColor: item.color }}
                                            />
                                        )}
                                        <span className="flex-1">{item.name}</span>
                                        {selectedItems.includes(item.id) ?
                                            <Check className="h-4 w-4" />
                                            : <Check className="h-4 w-4 text-gray-300" />}
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                )}
            </div>
        )
    }
)

