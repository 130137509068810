export default {
    FeedbackModal: {
      tit_group: {
        tit: "피드백",
        decs: "플랫폼 이용에 관해 어떤 의견이든 자유롭게 남겨주세요."
      }
    },
    contents:{
        textarea:{
            placeholder:"여기에 피드백을 입력해주세요...",
        },
    },
    footer:{
      button:{
          submit:"제출",
          cancel:"취소",
      },
    },
    message:{
      send:"소중한 의견 감사합니다!",
    }
  };

