import React from "react";
import {
  setDocViewer,
  selectDocViewer,
} from "../../lib/features/chatMain/chatMainSlice";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";

function MessageImage(props) {
  const { type, isBot, imageUrl, text, sendTime } = props.model;
  const dispatch = useAppDispatch();
  const handleClickDocView = (url, page) => {
    dispatch(
      setDocViewer({
        docUrl: url,
        docPage: page,
      })
    );
  };

  return (
    <div
      className={
        isBot
          ? "messageBox-bot flex max-w-full flex-col flex-grow mb-10"
          : "messageBox-me flex max-w-full flex-col flex-grow mb-10"
      }
    >
      <div className={isBot ? "messageBody-bot" : "messageBody-me"}>
        <div>
          <img className="messageImage" src={imageUrl} />
        </div>
        
      </div>
      <div className="sendTime">{sendTime}</div>
    </div>
  );
}

export default MessageImage;
