import React, { useEffect, useMemo, useState } from "react";


import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../ui/table"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
    DropdownMenuSub,
    DropdownMenuSubTrigger,
    DropdownMenuSubContent,
} from "../../ui/dropdown-menu"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../../ui/dialog"

import { Plus, Search, Filter, EllipsisVertical, MoreVertical, Check, X, Trash2, UserPlus, Users } from "lucide-react"
import { Button } from "../../ui/button"
import { Input } from "../../ui/input"
import Pagination from "../Common/Pagination";

const ITEMS_PER_PAGE = 20;
const APP_STAGE = process.env.REACT_APP_STAGE;

const APILogTable = ({ apiLogs }: { apiLogs: any }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [apilogsForm, setApilogsForm] = useState(
        apiLogs.map((logs: any) => ({
            id: logs.api_log_id,
            method: logs.api_method,
            path: logs.api_path,
            message: logs.api_resp_msg,
            status: logs.api_status_code,
            created_at: formatDateTime(logs.created_at),
      })) || []);

    const [sortConfig, setSortConfig] = useState({ key: "id", direction: "desc" });

    function formatDateTime(dateTimeString: string) {
        if (dateTimeString.length !== 14 && dateTimeString.length !== 19) {
          return "잘못된 입력 형식입니다.";
        }
        if (dateTimeString.length === 14) {     
          const year = dateTimeString.slice(0, 4);
          const month = dateTimeString.slice(4, 6);
          const day = dateTimeString.slice(6, 8);
          const hour = dateTimeString.slice(8, 10);
          const minute = dateTimeString.slice(10, 12);
          const second = dateTimeString.slice(12, 14);
          return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
        }
        if (dateTimeString.length === 19) {
            const year = dateTimeString.slice(0, 4);
            const month = dateTimeString.slice(5, 7);
            const day = dateTimeString.slice(8, 10);
            const hour = dateTimeString.slice(11, 13);
            const minute = dateTimeString.slice(14, 16);
            const second = dateTimeString.slice(17, 19);
            return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
        }
        
      }

    useEffect(() => {
        setApilogsForm(
            apiLogs.map((logs: any) => ({
                id: logs.api_log_id,
                method: logs.api_method,
                path: logs.api_path,
                message: logs.api_resp_msg,
                status: logs.api_status_code,
                created_at: formatDateTime(logs.created_at),
          })) || []
        );
      }, [apiLogs]);

    const sortedApilogs = useMemo(() => {
        return [...apilogsForm].sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key])
                return sortConfig.direction === "asc" ? -1 : 1;
            if (a[sortConfig.key] > b[sortConfig.key])
                return sortConfig.direction === "asc" ? 1 : -1;
            return 0;
        });
    }, [apiLogs, sortConfig]);
    const totalPages = Math.ceil(sortedApilogs.length / ITEMS_PER_PAGE);
    const paginatedApilogs = sortedApilogs.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        currentPage * ITEMS_PER_PAGE
    );

    const handleSort = (key: string) => {
        setSortConfig((currentConfig) => {
            if (currentConfig.key === key) {
                return {
                    ...currentConfig,
                    direction: currentConfig.direction === "asc" ? "desc" : "asc",
                };
            }
            return { key, direction: "asc" };
        });
    };
    if (APP_STAGE === "DEV") {  
      console.log({apiLogs})
      console.log({sortedApilogs})
      console.log({paginatedApilogs})
    }

    return (
        <div className="bg-white shadow-md rounded-lg overflow-hidden p-4">
            <div className="border-b flex justify-between items-center">
                <h2 className="text-2xl font-semibold">API LOG</h2>
            </div>
            <Table>
                <TableHeader>
                    <TableRow>
                        {["id", "method", "path", "message", "status", "created_at"].map((key) => (
                            <TableHead
                                key={key}
                                className="px-6 py-2 text-left text-xs text-nowrap font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                onClick={() => handleSort(key)}
                            >
                                {key === "id"
                                    ? "NO."
                                    : key === "method"
                                        ? "METHOD"
                                        : key === "path"
                                            ? "PATH"
                                            : key === "message"
                                                ? "MESSAGE"
                                                : key === "status"
                                                    ? "STATUS"
                                                    : key === "created_at"
                                                        ? "CREATED AT"
                                                            : ""}
                                {sortConfig.key === key && (
                                    <span className="ml-1">
                                        {sortConfig.direction === "asc" ? "▲" : "▼"}
                                    </span>
                                )}
                            </TableHead>
                        ))}
                        <TableHead>작업</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {paginatedApilogs && paginatedApilogs.map((logs) => (
                        <TableRow key={logs.id}>
                            <TableCell>{logs.id}</TableCell>
                            <TableCell>{logs.method}</TableCell>
                            <TableCell>{logs.path}</TableCell>
                            <TableCell className="truncate max-w-xs">{logs.message}</TableCell>
                            <TableCell>{logs.status}</TableCell>
                            <TableCell>{logs.created_at}</TableCell>
                            <TableCell>
                                <DropdownMenu>
                                    <DropdownMenuTrigger>
                                        <EllipsisVertical className="h-4 w-4" />
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent>
                                        <DropdownMenuItem
                                            onClick={() => {
                                                if (APP_STAGE === "DEV") {  
                                                  console.log("Detail Click")
                                                }
                                            }}
                                            >Detail</DropdownMenuItem>
                                        {/* <DropdownMenuItem>대화내역</DropdownMenuItem>
                                        <DropdownMenuItem>권한변경</DropdownMenuItem>
                                        <DropdownMenuItem>회사변경</DropdownMenuItem> */}
                                        {/* <DropdownMenuSub>
                                            <DropdownMenuSubTrigger>권한변경</DropdownMenuSubTrigger>
                                            <DropdownMenuSubContent>
                                                <DropdownMenuItem>일반 설정</DropdownMenuItem>
                                                <DropdownMenuItem>고급 설정</DropdownMenuItem>
                                            </DropdownMenuSubContent>
                                        </DropdownMenuSub> */}
                                        <DropdownMenuSeparator />
                                        {/* <DropdownMenuItem>계정잠금</DropdownMenuItem> */}
                                    </DropdownMenuContent>
                                </DropdownMenu>

                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={apiLogs ? Math.ceil(apiLogs?.length / ITEMS_PER_PAGE) : 1} totalNumber={apiLogs?.length} listName="API 로그" />
        </div>
    )
};

export default APILogTable;