'use client'

import { useState, useEffect } from 'react'
// import { CpuChipIcon } from '@heroicons/react/24/outline'
import {
    ChartScatter,
    FileChartColumn,
    ChartPie, 
    Bot,
    NotebookPen,
    BrainCircuit
  } from "lucide-react";

const loadingPhases = {
  "thinking": "AI thinking",
  "searching": "Analyzing Dialog",
  "generating": "Generating response",
  "optimizing": "Optimizing answer",
}

export default function StreamLoading(props: {streamStatus: string}) {
  const [currentPhase, setCurrentPhase] = useState(props.streamStatus)
  const [dots, setDots] = useState('')

//   useEffect(() => {
//     setCurrentPhase(props.streamStatus)
//   }, [])

  useEffect(() => {
    const phaseInterval = setInterval(() => {
    //   setCurrentPhase((prevPhase) => (prevPhase<3?prevPhase + 1:3))
        setCurrentPhase(props.streamStatus)
    }, 3000)

    const dotInterval = setInterval(() => {
      setDots(prev => {
        if (prev === '......') return ''
        return prev + '.'
      })
    }, 500)

    return () => {
      clearInterval(phaseInterval)
      clearInterval(dotInterval)
    }
  }, [props.streamStatus])

//   console.log("StreamLoading", props.streamStatus)
//   console.log("StreamLoading", currentPhase)
  return (

    <div className="w-full max-w-md p-4 bg-white rounded-xl">
    <div className="flex text-base items-center space-x-4 bg-white p-4 rounded-lg">
        {currentPhase=="thinking" && <Bot className="w-8 h-8 text-blue-800 animate-pulse" />}
        {currentPhase=="searching" && <FileChartColumn className="w-8 h-8 text-blue-800 animate-pulse" />}
        {currentPhase=="generating" && <BrainCircuit className="w-8 h-8 text-blue-800 animate-pulse" />}
        {currentPhase=="optimizing" && <NotebookPen className="w-8 h-8 text-blue-800 animate-pulse" />}
        <div className="flex-1 overflow-hidden">
        <div className="text-sm font-medium text-gray-800 animate-blink">
            {loadingPhases[currentPhase as keyof typeof loadingPhases]}
            <span className="inline-block w-8">{dots}</span>
        </div>
        </div>
        <div className="w-2 h-2 bg-blue-800 rounded-full animate-ping"></div>
    </div>
    </div>
  )
}

// [Analyzing Dialog]
// [Searching]
// [Analyzing Dialog]