import React, { useRef, useEffect } from "react";
// import './chatContainer.css'
import ChatInput from "./ChatInput";
import ChatHeader from "./ChatHeader";
import ChatContents from "./ChatContents";

import {
  setChatMode,
  selectChatMain,
  selectChatMode,
} from "../../lib/features/chatMain/chatMainSlice";
import { selectUser } from "../../lib/features/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
// import SideBarDoc from "./SideBarDoc";
// import Header from "../dashboard/Header";
// import Nav from "../dashboard/Nav";
// import NavAvatar from "../dashboard/NavAvatar";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';


function ChatContainer({ isOpen, setIsOpen }) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const chatMain = useAppSelector(selectChatMain);
  const chatMode = useAppSelector(selectChatMode);
  const user = useAppSelector(selectUser);
  const messageEndRef = useRef(null);

  const { t  } = useTranslation('translation'); 

  useEffect(() => {
    scrollToBottom();
  }, [chatMain.messages]);

  const scrollToBottom = () => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollTop = messageEndRef.current.scrollHeight;
    }
  };

  const handleMenu1Click = (menu) => {
    dispatch(
      setChatMode({
        mode_1: chatMode.mode_1,
        mode_2: menu,
        isNewChat: true,
      })
    );
    if (menu == "SR") {
      navigate("/email-send");
    } else {
      navigate("/chat");
    }
  };

  return (
    <aside
      // id="chatContainer"
      className="flex min-w-0 max-w-4lg h-full flex-1 flex-col md:min-w-[28rem] lg:min-w-[32rem] p-2 lg:items-center"
    >
      {/* 챗룸 열려있을떄, */}
      {chatMain.roomId && <ChatHeader />}
      {chatMain.roomId && (
        <div className="relative size-full overflow-auto" ref={messageEndRef}>
          <div className="relative min-h-full w-full transition-opacity duration-150 ease-in">
            <ChatContents />
          </div>
        </div>
      )}
      {chatMain.roomId && <ChatInput />}
      {/* 챗룸 없을떄*/}
      {!chatMain.roomId && chatMode.isNewChat &&
            <div className="relative size-full px-0 lg:px-10"> 
              <ChatHeader />
              <div
                  className="relative size-full overflow-auto"
                  ref={messageEndRef}
                >
                <div className="relative min-h-full w-full transition-opacity duration-150 ease-in">
                  <ChatContents  mode="greet" />
                </div>
              </div>
              <ChatInput />
            </div>
        } 
      {!chatMain.roomId && !chatMode.isNewChat && <ChatHeader newChat={true} />}
      {/* {!chatMain.roomId && !chatMode.isNewChat &&
        <div className="flex flex-col h-full max-w-4xl mx-auto p-4 bg-white rounded-lg shadow-xl lg:p-8">
        <h1 className="text-3xl lg:text-4xl font-black font-sans text-blue-900 text-center mb-6 lg:mb-10" style={{ fontFamily: 'Poppins, sans-serif', textShadow: '1px 1px 2px rgba(0,0,0,0.1)', letterSpacing: '-0.5px' }}>
          Support & Services
        </h1>
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8">
          {[
            { icon: 'fas fa-tools', title: t('chatMenu.info_list.troubleshooting.name'), menu:"TS", disable: false, description: t('chatMenu.chatMenu.title.list.0'), buttonText: 'Chat Now' },
            { icon: 'fas fa-cogs', title: t('chatMenu.info_list.maintenance.name'), menu:"MT", disable: false, description: t('chatMenu.chatMenu.title.list.1'), buttonText: 'Chat Now' },
            { icon: 'fas fa-question-circle', title: t('chatMenu.info_list.generalinquiry.name'), menu:"GI", disable: false, description: t('chatMenu.chatMenu.title.list.2'), buttonText: 'Chat Now' },
            { icon: 'fa-solid fa-clipboard-list', title: t('chatMenu.info_list.servicerequest.name'), menu:"SR", disable: false, description: t('chatMenu.chatMenu.title.list.3'), buttonText: 'Request Service' },
          ].map((item, index) => (
            <div key={index} className="group flex flex-col items-center bg-gradient-to-t from-gray-50 to-white border border-gray-200 rounded-lg p-6 lg:p-8 shadow-md transition-transform duration-300 hover:-translate-y-2 hover:shadow-lg">

              <div className="flex flex-col items-center mb-4">
                <i className={`${item.icon} text-4xl lg:text-5xl text-blue-800 mb-2 bg-gradient-to-br from-cyan-500 to-blue-700 bg-clip-text text-transparent`}></i>
                <h2 className="text-lg lg:text-xl font-semibold text-gray-700">{item.title}</h2>
              </div>
      
              <p className="text-gray-500 text-sm lg:text-base text-center text-balance mb-4">{item.description}</p>
              
              <button
                onClick={() => handleMenu1Click(item.menu)}
                //className="relative w-36 lg:w-40 max-w-xs border border-blue-700 text-blue-700 text-sm font-semibold py-2 rounded-md shadow-md overflow-hidden hover:text-indigo-800 disabled:border-gray-300 disabled:text-gray-400"
                className="relative w-36 lg:w-48 max-w-xs text-sm bg-blue-100 hover:bg-blue-500 text-gray-800 hover:text-white font-bold py-2 px-4 border-b-4 border-blue-500 rounded transform transition duration-200 ease-in-out hover:-translate-y-1 hover:scale-110"
                disabled={item.disable}
              >
                <span className="relative z-10">{item.buttonText}</span>
                <i className="fa-solid fa-arrow-right ml-2 relative z-10"></i>
                <span className="absolute inset-0 border border-transparent rounded-md before:absolute before:inset-0 before:border-2 before:border-transparent before:rounded-md before:bg-gradient-to-r from-blue-500 to-indigo-600 before:animate-border-flow opacity-0 hover:opacity-100"></span>
              </button>
            </div>
          ))}
        </div>
      </div>
      } */}
      {!chatMain.roomId && !chatMode.isNewChat &&
        <div className="flex flex-col h-full max-w-4xl mx-0 p-2 bg-white rounded-xl overflow-auto lg:mx-auto">
          <h1 className="text-3xl lg:text-4xl font-bold text-blue-900 text-center pt-0 mb-2 lg:pt-12 tracking-wide">Support & Services</h1>
          <div className="grid grid-cols-1 gap-2 py-0 px-0 md:gap-4 lg:grid-cols-2 lg:gap-8 lg:py-12 lg:px-8">
            {[
              { icon: 'fas fa-tools', title: t('chatMenu.info_list.troubleshooting.name'), menu:"TS", disable: !user.Permission.is_chatbot_ts, description: t('chatMenu.chatMenu.title.list.0'), buttonText: 'Chat Now' },
              { icon: 'fas fa-cogs', title: t('chatMenu.info_list.maintenance.name'), menu:"MT", disable: !user.Permission.is_chatbot_mt, description: t('chatMenu.chatMenu.title.list.1'), buttonText: 'Chat Now' },
              { icon: 'fas fa-question-circle', title: t('chatMenu.info_list.generalinquiry.name'), menu:"GI", disable: !user.Permission.is_chatbot_gi, description: t('chatMenu.chatMenu.title.list.2'), buttonText: 'Chat Now' },
              { icon: 'fa-solid fa-clipboard-list', title: t('chatMenu.info_list.servicerequest.name'), menu:"SR", disable: false, description: t('chatMenu.chatMenu.title.list.3'), buttonText: 'Request Service' },
            ].map((item, index) => (
              <div key={index} className="group flex flex-col bg-gradient-to-t from-white to-[#eaf3ff] rounded-xl p-4 lg:p-8 shadow text-center transition-transform duration-300 hover:-translate-y-2">
                <div className="flex flex-row justify-start items-center mb-2 min-h-8 lg:min-h-24">
                  <h2 className="text-xl text-left font-bold mb-0 lg:text-2xl lg:mb-2">{item.title}</h2>
                </div>
                <div className="flex flex-row justify-between items-center">
                  <i className={`${item.icon} text-6xl text-blue-800 mb-2`}></i>
                  <div className="flex flex-col justify-center items-end px-2">
                    <p className="text-gray-600 text-xs sm:text-base text-right sm:text-center text-balance mb-2">{item.description}</p>
                    <button 
                      onClick={()=>handleMenu1Click(item.menu)}
                      className="w-40 lg:w-full max-w-48 text-sm sm:text-base bg-white text-blue-800 border border-blue-500 font-semibold py-2 px-0 lg:px-4 lg:py-3 lg:px-6 rounded-lg hover:bg-blue-900 hover:border-blue-500 hover:text-blue-800 transition-colors duration-300 disabled:bg-gray-100 disabled:text-gray-300 disabled:border-gray-300"
                      disabled={item.disable}
                    >
                      {item.buttonText}
                      <i className="fa-solid fa-arrow-right ml-2"></i>
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      }

    
    </aside>
  );
}

export default ChatContainer;