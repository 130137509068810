import React, { useEffect, useMemo, useState } from "react";


import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../ui/table"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
    DropdownMenuSub,
    DropdownMenuSubTrigger,
    DropdownMenuSubContent,
} from "../../ui/dropdown-menu"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../../ui/dialog"

import { Plus, Search, Filter, EllipsisVertical, MoreVertical, Check, X, Trash2, UserPlus, Users } from "lucide-react"
import { Button } from "../../ui/button"
import { Input } from "../../ui/input"
import Pagination from "../Common/Pagination";

const ITEMS_PER_PAGE = 20;
const APP_STAGE = process.env.REACT_APP_STAGE;

const EmailLogTable = ({ emailLogs }: { emailLogs: any }) => {
    const getStatus = (status: string) => {
        if (status === "PENDING") {
            return "PENDING";
        } else {
            return status;
        }
    }
    const getType = (type: string) => {
        type = type.replace("INFO:", "");
        if (type === "SERVICE_EMAIL_REQUEST") {
            return "service request";
        } else if (type === "INVITATION_EMAIL") {
            return "sending invitation";
        } else if (type === "SEND_INVITATION_EMAIL") {
            return "sending invitation";
        } else if (type === "PASSWORD_RESET_REQUEST") {
            return "password reset request";
        } else if (type === "REGISTRATION_COMPLETE") {
            return "registration complete";
        } else if (type === "PASSWORD_INITIALIZED") {
            return "password initialized and sent";
        } else {
            return "unknown";
        }
    }

    const [currentPage, setCurrentPage] = useState(1);
    const [emailLogsForm, setEmailLogsForm] = useState(
        emailLogs.map((logs: any) => ({
            id: logs.email_sending_logs_id,
            type: getType(logs.email_type),
            to: logs.email_to,
            cc: logs.email_cc,
            status: getStatus(logs.email_status),
            error_msg: logs.email_error_message,
            created_at: formatDateTime(logs.created_at),
      })) || []);

    const [sortConfig, setSortConfig] = useState({ key: "id", direction: "desc" });

    
    function formatDateTime(dateTimeString: string) {
        if (dateTimeString.length !== 14 && dateTimeString.length !== 19) {
          return "잘못된 입력 형식입니다.";
        }
        if (dateTimeString.length === 14) {     
          const year = dateTimeString.slice(0, 4);
          const month = dateTimeString.slice(4, 6);
          const day = dateTimeString.slice(6, 8);
          const hour = dateTimeString.slice(8, 10);
          const minute = dateTimeString.slice(10, 12);
          const second = dateTimeString.slice(12, 14);
          return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
        }
        if (dateTimeString.length === 19) {
            const year = dateTimeString.slice(0, 4);
            const month = dateTimeString.slice(5, 7);
            const day = dateTimeString.slice(8, 10);
            const hour = dateTimeString.slice(11, 13);
            const minute = dateTimeString.slice(14, 16);
            const second = dateTimeString.slice(17, 19);
            return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
        }
        
      }

    useEffect(() => {
        setEmailLogsForm(
            emailLogs.map((logs: any) => ({
                id: logs.email_sending_logs_id,
                type: getType(logs.email_type),
                to: logs.email_to,
                cc: logs.email_cc,
                status: getStatus(logs.email_status),
                error_msg: logs.email_error_message,
                created_at: formatDateTime(logs.created_at),
          })) || []
        );
      }, [emailLogs]);
    
    const sortedEmailLogs = useMemo(() => {
        return [...emailLogsForm].sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key])
                return sortConfig.direction === "asc" ? -1 : 1;
            if (a[sortConfig.key] > b[sortConfig.key])
                return sortConfig.direction === "asc" ? 1 : -1;
            return 0;
        });
    }, [emailLogs, sortConfig]);
    const totalPages = Math.ceil(sortedEmailLogs.length / ITEMS_PER_PAGE);
    const paginatedEmailLogs = sortedEmailLogs.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        currentPage * ITEMS_PER_PAGE
    );

    const handleSort = (key: string) => {
        setSortConfig((currentConfig) => {
            if (currentConfig.key === key) {
                return {
                    ...currentConfig,
                    direction: currentConfig.direction === "asc" ? "desc" : "asc",
                };
            }
            return { key, direction: "asc" };
        });
    };
    if (APP_STAGE === "DEV") {  
      console.log({emailLogs})
      console.log({paginatedEmailLogs})
    }

    return (
        <div className="bg-white shadow-md rounded-lg overflow-hidden p-4">
            <div className="border-b flex justify-between items-center">
                <h2 className="text-2xl font-semibold">EMAIL SENDING LOG</h2>
            </div>
            <Table>
                <TableHeader>
                    <TableRow>
                        {["id", "type", "to", "cc", "status", "error_msg", "created_at"].map((key) => (
                            <TableHead
                                key={key}
                                className="px-6 py-2 text-left text-xs text-nowrap font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                onClick={() => handleSort(key)}
                            >
                                {key === "id"
                                    ? "NO."
                                    : key === "type"
                                        ? "TYPE"
                                        : key === "to"
                                            ? "TO"
                                            : key === "cc"
                                                ? "CC"
                                                : key === "status"
                                                    ? "STATUS"
                                                    : key === "error_msg"
                                                        ? "ERROR MSG"
                                                        : key === "created_at"
                                                            ? "CREATED AT"
                                                            : ""}
                                {sortConfig.key === key && (
                                    <span className="ml-1">
                                        {sortConfig.direction === "asc" ? "▲" : "▼"}
                                    </span>
                                )}
                            </TableHead>
                        ))}
                        <TableHead>작업</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {paginatedEmailLogs && paginatedEmailLogs.map((logs) => (
                        <TableRow key={logs.id}>
                            <TableCell>{logs.id}</TableCell>
                            <TableCell>{logs.type}</TableCell>
                            <TableCell>{logs.to}</TableCell>
                            <TableCell>{logs.cc}</TableCell>
                            <TableCell>
                                <div
                                    className={`text-xs text-center font-medium px-2 py-0.5 rounded-full ${logs.status === "PENDING"
                                        ? "text-white bg-yellow-500"
                                        : logs.status === "SENT"
                                            ? "text-white bg-green-500"
                                            : logs.status === "FAILED"
                                                ? "text-white bg-red-500"
                                                : "text-white bg-gray-500"
                                    }`}
                                >
                                    {logs.status}
                                </div>
                            </TableCell>
                            <TableCell>{logs.error_msg}</TableCell>
                            <TableCell>{logs.created_at}</TableCell>
                            <TableCell>
                                <DropdownMenu>
                                    <DropdownMenuTrigger>
                                        <EllipsisVertical className="h-4 w-4" />
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent>
                                        <DropdownMenuItem
                                            onClick={() => {
                                                if (APP_STAGE === "DEV") {  
                                                  console.log("Detail Click")
                                                }
                                            }}
                                            >Detail</DropdownMenuItem>
                                        {/* <DropdownMenuItem>대화내역</DropdownMenuItem>
                                        <DropdownMenuItem>권한변경</DropdownMenuItem>
                                        <DropdownMenuItem>회사변경</DropdownMenuItem> */}
                                        {/* <DropdownMenuSub>
                                            <DropdownMenuSubTrigger>권한변경</DropdownMenuSubTrigger>
                                            <DropdownMenuSubContent>
                                                <DropdownMenuItem>일반 설정</DropdownMenuItem>
                                                <DropdownMenuItem>고급 설정</DropdownMenuItem>
                                            </DropdownMenuSubContent>
                                        </DropdownMenuSub> */}
                                        <DropdownMenuSeparator />
                                        {/* <DropdownMenuItem>계정잠금</DropdownMenuItem> */}
                                    </DropdownMenuContent>
                                </DropdownMenu>

                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={emailLogs ? Math.ceil(emailLogs?.length / ITEMS_PER_PAGE) : 1} totalNumber={emailLogs?.length} listName="이메일 로그" />
        </div>
    )
};

export default EmailLogTable;