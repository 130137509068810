import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { Folder, File, ChevronRight, ChevronDown } from 'lucide-react';
import ChatHeader from './ChatHeader';
import { Input } from '../ui/input';
import { selectUser } from '../../lib/features/auth/authSlice';
import { useAppSelector } from '../../lib/hooks';

const BE_HOST = process.env.REACT_APP_BE_HOST;
const BASE_URL: string = BE_HOST ? BE_HOST : "https://snsys-qa.neoali.com";


// 비디오 뷰어 컴포넌트
const PersonInCharge: React.FC = () => {
  const user = useAppSelector(selectUser);

  return (
    <main id="chatMain"  className='relative min-h-full flex-1 p-2 sm:p-6'>
      <ChatHeader />
      <div className="flex w-full h-screen">
        <div className="w-full p-4 hidden md:block">
          <h2 className="text-xl font-bold mb-6 text-blue-800">Person In Charge</h2>
          
          <div className="w-full p-0 overflow-y-auto p-4 border-1 border-gray-200 rounded-lg">
            <h3 className="mt-2 text-base font-bold mb-6 text-gray-600">Your Vessel</h3>
            {user?.Vessel ? 
            <div className="w-full p-0 overflow-y-auto">
              {user?.Vessel && <Input label="IMO No." id="vessel" value={user?.Vessel.imo_no} onChange={() => {}} />}
              {user?.Vessel && <Input label="SHIP NAME" id="vessel" value={user?.Vessel.ship_name} onChange={() => {}} />}
            </div>
            :
            <div className="w-full p-0 overflow-y-auto">
              <p className="text-gray-600">No Vessel Information</p>
            </div>
            }
            <h3 className="mt-8 text-base font-bold mb-6 text-gray-600">Person In Charge</h3>
            {user?.Vessel?.PersonInCharge ? (
              <div className="w-full p-0 overflow-y-auto">
                <Input label="PIC Name" id="pic_name" 
                value={user?.user_lang === "KO" ? user?.Vessel.PersonInCharge.pic_name : user?.Vessel.PersonInCharge.pic_name_en} 
                onChange={() => {}} />
                <Input label="PIC Email" id="pic_email" value={user?.Vessel.PersonInCharge.pic_email} onChange={() => {}} />
                <Input label="PIC Phone" id="pic_phone" value={user?.Vessel.PersonInCharge.pic_phone} onChange={() => {}} />
              </div>
            ) : (
              <div className="w-full p-0 overflow-y-auto">
                <p className="text-gray-600">No PIC Information</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default PersonInCharge;
