import React, { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../lib/hooks"
import {
  adminUserAllGet,
  adminApilogs,
  adminFeedbackLikes,
  selectDashboardApiLog,
  selectDashboardFeedbackLike,
  selectDashboardUser,
} from "../../../lib/features/admin/adminSlice"
import { selectToken, selectStatus } from "../../../lib/features/auth/authSlice"
import { Tabs, TabsContent, TabsTrigger } from "../../ui/tabs"
import APILogTable from "../LogPage/APILogTable"
import DashboardTabs from "../Common/DashboardTabs"


const ITEMS_PER_PAGE = 10
const APP_STAGE = process.env.REACT_APP_STAGE;
export default function AdminFeedbackPage() {
  const dispatch = useAppDispatch();
  const token = useAppSelector(selectToken);
  const apilogs = useAppSelector(selectDashboardApiLog);
  const [tabValue, setTabValue] = useState("users");

  useEffect(() => {
    dispatch(adminApilogs({ token }));
  }, [dispatch, token]);

  const tabData = [
    {tabName: "apilogs", name: "API 로그", notifications: 0},
    // {tabName: "groups", name: "권한그룹", notifications: 0},
    // {tabName: "company", name: "회사그룹", notifications: 0},
    // {tabName: "request", name: "가입신청", notifications: 1},
  ];
  if (APP_STAGE === "DEV") {  
    console.log({apilogs})
  }
  return (
    <div className="w-full p-0 mx-0">
      <h1 className="text-2xl font-bold mb-6">사용자 관리 대시보드</h1>
      <Tabs defaultValue="apilogs">
        <DashboardTabs data={tabData} tabValue={tabValue} setTabValue={setTabValue}/>
        <TabsContent value="apilogs">
          <APILogTable apilogs={apilogs || []}  />
        </TabsContent>
        {/* <TabsContent value="groups">
          <GroupTable groups={groups || []}  />
        </TabsContent>
        <TabsContent value="company">
          <CompanyTable companies={companys || []}  />
        </TabsContent>
        <TabsContent value="request">
          <SignUpRequestTable signUpRequests={signUpRequests || []}  />
        </TabsContent> */}
      </Tabs>

    </div>
  )
}
