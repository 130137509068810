import React from "react";
import MessageBasic from "./MessageBasic";
import MessageImage from "./MessageImage";
import MessageWait from "./MessageWait";

function Message(props) {
  const { type, isBot, text, sendTime } = props.model;
  const ids = props.ids;

  switch (type) {
    case "basic":
      return <MessageBasic model={props.model} roomId={props.roomId} ids={ids} />;
    case "image":
      return <MessageImage model={props.model} roomId={props.roomId} />;
    case "waiting":
      return <MessageWait model={props.model} roomId={props.roomId} />;
    default:
      return null;
  }
}

export default Message;
