import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../lib/hooks";
import {
  adminUserAllGet,
  adminFaqList,
  adminKeywordList,
  adminFeedbackLikes,
  selectDashboardFaqs,
  selectDashboardFeedbackLike,
  selectDashboardUser,
  selectDashboardKeywords,
} from "../../../lib/features/admin/adminSlice";
import {
  selectToken,
  selectStatus,
} from "../../../lib/features/auth/authSlice";
import { Tabs, TabsContent, TabsTrigger } from "../../ui/tabs";
import APILogTable from "../LogPage/APILogTable";
import DashboardTabs from "../Common/DashboardTabs";
import FAQDataTable from "../DataPage/FAQTable";
import KeywordTable from "../DataPage/KeywordTable";
import AbbreviationTable from "../DataPage/AbbreviationTable";
import NoteTable from "../DataPage/NoteTable";

const ITEMS_PER_PAGE = 10;
const APP_STAGE = process.env.REACT_APP_STAGE;

export default function AdminDataPage() {
  const dispatch = useAppDispatch();
  const token = useAppSelector(selectToken);
  const faqdatas = useAppSelector(selectDashboardFaqs);
  const keyworddatas = useAppSelector(selectDashboardKeywords);
  const [tabValue, setTabValue] = useState("faqdata");

  useEffect(() => {
    dispatch(adminFaqList({ token }));
    dispatch(adminKeywordList({ token }));
  }, [dispatch, token]);

  const adminFaqListChange = () => {
    dispatch(adminFaqList({ token }));
  };

  const adminKeywordListChange = () => {
    dispatch(adminKeywordList({ token }));
  };

  const tabData = [
    { tabName: "faqdata", name: "FAQ", notifications: 0 },
    { tabName: "keyworddata", name: "Keyword", notifications: 0 },
    { tabName: "abbreviation", name: "Abbreviation", notifications: 0 },
    { tabName: "note", name: "Note", notifications: 0 },
  ];
  if (APP_STAGE === "DEV") {  
    console.log({ faqdatas, keyworddatas });
  }
  return (
    <div className="w-full p-0 mx-0">
      <h1 className="text-2xl font-bold mb-6">DATA MANAGEMENT DASHBOARD</h1>
      <Tabs defaultValue="faqdata">
        <DashboardTabs
          data={tabData}
          tabValue={tabValue}
          setTabValue={setTabValue}
        />
        <TabsContent value="faqdata">
          <FAQDataTable
            faqdatas={faqdatas || []}
            token={token}
            adminFaqListChange={adminFaqListChange}
          />
        </TabsContent>
        <TabsContent value="keyworddata">
          <KeywordTable
            keyworddatas={keyworddatas || []}
            token={token}
            adminKeywordListChange={adminKeywordListChange}
          />
        </TabsContent>
        <TabsContent value="abbreviation">
          <AbbreviationTable
            token={token}
          />
        </TabsContent>
        <TabsContent value="note">
          <NoteTable
            token={token}
          />
        </TabsContent>
      </Tabs>
    </div>
  );
}
