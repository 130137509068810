import React, { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../lib/hooks";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../ui/table";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DropdownMenuSub,
  DropdownMenuSubTrigger,
  DropdownMenuSubContent,
} from "../../ui/dropdown-menu";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../ui/dialog";
import { 
    adminServiceListGet,
    adminServiceUpdate, 
    selectDashboardUserServices 
} from "../../../lib/features/admin/adminSlice";

import {
  Plus,
  Search,
  Filter,
  EllipsisVertical,
  MoreVertical,
  Check,
  X,
  Trash2,
  UserPlus,
  Users,
} from "lucide-react";
import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import Pagination from "../Common/Pagination";

const ITEMS_PER_PAGE = 20;
const ServiceReqTable = ({
  token,
}: {
  token: any;
}) => {
  const dispatch = useAppDispatch();
  const [isOpenDialog, setIsOpenDialog] = useState({
    detail: false,
    userinfo: false,
  });
  const servicesRequest = useAppSelector(selectDashboardUserServices);

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedService, setSelectedService] = useState(0);
  const [detailServiceForm, setDetailServiceForm] = useState({
    id: 0,
    imo_no: "",
    your_name: "",
    vessel_name: "",
    ship_owner: "",
    emails: "",
    phone_number: "",
    issue_detail: "",
    attachment: "",
    status: "",
    created_at: "",
    updated_at: ""
  });
  const [serviceForm, setServiceForm] = useState(
    servicesRequest?.map((service: any) => ({
      id: service.service_req_id,
      imo_no: service.service_req_imo_no,
      your_name: service.service_req_your_name,
      vessel_name: service.service_req_vessel_name,
      ship_owner: service.service_req_ship_owner,
      emails: service.service_req_emails,
      phone_number: service.service_req_phone_num,
      issue_detail: service.service_req_issue_detail,
      attachment: service.service_req_attach_list,
      status: service.service_req_status,
      created_at: formatDateTime(service.created_at),
      updated_at: formatDateTime(service.updated_at)
    })) || []
  );

  const [sortConfig, setSortConfig] = useState({ key: "id", direction: "asc" });
  
  function formatDateTime(dateTimeString: string) {
    if (dateTimeString.length !== 14 && dateTimeString.length !== 19) {
      return "잘못된 입력 형식입니다.";
    }
    if (dateTimeString.length === 14) {     
      const year = dateTimeString.slice(0, 4);
      const month = dateTimeString.slice(4, 6);
      const day = dateTimeString.slice(6, 8);
      const hour = dateTimeString.slice(8, 10);
      const minute = dateTimeString.slice(10, 12);
      const second = dateTimeString.slice(12, 14);
      return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    }
    if (dateTimeString.length === 19) {
        const year = dateTimeString.slice(0, 4);
        const month = dateTimeString.slice(5, 7);
        const day = dateTimeString.slice(8, 10);
        const hour = dateTimeString.slice(11, 13);
        const minute = dateTimeString.slice(14, 16);
        const second = dateTimeString.slice(17, 19);
        return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    }
    
  }
  useEffect(() => {
    setServiceForm(
      servicesRequest?.map((service: any) => ({
        id: service.service_req_id,
        imo_no: service.service_req_imo_no,
        your_name: service.service_req_your_name,
        vessel_name: service.service_req_vessel_name,
        ship_owner: service.service_req_ship_owner,
        emails: service.service_req_emails,
        phone_number: service.service_req_phone_num,
        issue_detail: service.service_req_issue_detail,
        attachment: service.service_req_attach_list,
        status: service.service_req_status,
        created_at: formatDateTime(service.created_at),
        updated_at: formatDateTime(service.updated_at)
      })) || []
    );
    console.log(detailServiceForm);
    console.log(selectedService);
    console.log(detailServiceForm.status);
    setDetailServiceForm(serviceForm.find((service: any) => service.id === selectedService)||{
        id: 0,
        imo_no: "",
        your_name: "",
        vessel_name: "",
        ship_owner: "",
        emails: "",
        phone_number: "",
        issue_detail: "",
        attachment: "",
        status: "",
        created_at: "",
        updated_at: ""
      });
  }, [servicesRequest, selectedService]);

  useEffect(() => {
    console.log(detailServiceForm);
  }, [detailServiceForm.status]);

  useEffect(() => {
    setServiceForm(
        servicesRequest?.map((service: any) => ({
          id: service.service_req_id,
          imo_no: service.service_req_imo_no,
          your_name: service.service_req_your_name,
          vessel_name: service.service_req_vessel_name,
          ship_owner: service.service_req_ship_owner,
          emails: service.service_req_emails,
          phone_number: service.service_req_phone_num,
          issue_detail: service.service_req_issue_detail,
          attachment: service.service_req_attach_list,
          status: service.service_req_status,
          created_at: formatDateTime(service.created_at),
          updated_at: formatDateTime(service.updated_at)
        })) || []
      );
  }, [dispatch, token]);


  const sortedServices = useMemo(() => {
    return [...serviceForm].sort((a, b) => {
      if (a.status=='pending' || b.status=='pending'){
        if (a.status === 'pending')
          return sortConfig.direction === "asc" ? -1 : 1;
        if (b.status === 'pending')
          return sortConfig.direction === "asc" ? 1 : -1;
      }else if (a.status === b.status){
        if (a[sortConfig.key] < b[sortConfig.key])
          return sortConfig.direction === "asc" ? -1 : 1;
        if (a[sortConfig.key] > b[sortConfig.key])
          return sortConfig.direction === "asc" ? 1 : -1;
    }
      return 0;
    });
  }, [serviceForm, sortConfig]);
  const totalPages = Math.ceil(sortedServices.length / ITEMS_PER_PAGE);
  const paginatedServices = sortedServices.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const handleSort = (key: string) => {
    setSortConfig((currentConfig) => {
      if (currentConfig.key === key) {
        return {
          ...currentConfig,
          direction: currentConfig.direction === "asc" ? "desc" : "asc",
        };
      }
      return { key, direction: "asc" };
    });
  };
  const handleUpdateStatus = async (id: number, status: string) => {
    // 1. 로컬 상태를 즉시 업데이트 (테이블 및 다이얼로그 동기화)
    setServiceForm((prev: any) =>
      prev.map((service: any) =>
        service.id === id ? { ...service, status } : service
      )
    );
  
    // 2. 현재 선택된 서비스가 있다면 다이얼로그 상태도 즉시 업데이트
    if (detailServiceForm.id === id) {
      setDetailServiceForm((prev) => ({ ...prev, status }));
    }
  
    // 3. 서버에 상태 업데이트 요청
    await dispatch(adminServiceUpdate({ service_req_id: id, status, token }));
  
    // 4. 서버에서 데이터를 가져와 로컬 상태 동기화
    await dispatch(adminServiceListGet({ token }));
  };

  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden p-4">
      <div className="border-b flex justify-between items-center">
        <h2 className="text-2xl font-semibold mb-4">Service Request</h2>
      </div>
      <Dialog open={isOpenDialog.detail} onOpenChange={() => setIsOpenDialog({ ...isOpenDialog, detail: false })}>
        <DialogContent>
            <DialogHeader>
                <DialogTitle>Service Request Detail</DialogTitle>
            </DialogHeader>
            
            <DialogDescription>
                <div className="grid grid-cols-4 grid-rows-auto gap-2 text-base ">

                        {[  {label: "IMO No.", value: detailServiceForm.imo_no}, 
                            {label: "Your Name", value: detailServiceForm.your_name}, 
                            {label: "Vessel Name", value: detailServiceForm.vessel_name}, 
                            {label: "Ship Owner", value: detailServiceForm.ship_owner}, 
                            {label: "Emails", value: detailServiceForm.emails}, 
                            {label: "Phone Number", value: detailServiceForm.phone_number}, 
                            {label: "Issue Detail", value: detailServiceForm.issue_detail}, 
                            {label: "Attachment", value: detailServiceForm.attachment}, 
                            {label: "Status", value: detailServiceForm.status}, 
                            {label: "Request Date", value: detailServiceForm.created_at}, 
                            {label: "Update Date", value: detailServiceForm.updated_at}].map((item) => (
                            <>
                                <span className="font-semibold col-span-1">{item.label}</span>
                                {
                                    item.label === "Status" ? 
                                    <DropdownMenu>
                                        <DropdownMenuTrigger asChild>
                                        <span 
                                            className={`col-span-3 size-fit ${item.value === "pending" 
                                                ? "bg-yellow-500 text-white rounded-md px-2 py-1 text-sm" 
                                                : item.value === "in progress" 
                                                    ? "bg-blue-500 text-white rounded-md px-2 py-1 text-sm" 
                                                    : item.value === "in trouble" 
                                                        ? "bg-red-500 text-white rounded-md px-2 py-1 text-sm" 
                                                        : "bg-green-500 text-white rounded-md px-2 py-1 text-sm"}`}
                                            onClick={item.label === "Status" ? () => {} : () => {}}
                                        >
                                            {item.value}
                                        </span>
                                        </DropdownMenuTrigger>
                                        <DropdownMenuContent>
                                            <DropdownMenuItem onClick={() => handleUpdateStatus(detailServiceForm.id as number, "pending")}>
                                                <span className="bg-yellow-500 text-white rounded-md px-2 py-1 text-sm">Pending</span>
                                            </DropdownMenuItem>
                                            <DropdownMenuItem onClick={() => handleUpdateStatus(detailServiceForm.id as number, "in progress")}>
                                                <span className="bg-blue-500 text-white rounded-md px-2 py-1 text-sm">In Progress</span>
                                            </DropdownMenuItem>
                                            <DropdownMenuItem onClick={() => handleUpdateStatus(detailServiceForm.id as number, "in trouble")}>
                                                <span className="bg-red-500 text-white rounded-md px-2 py-1 text-sm">In Trouble</span>
                                            </DropdownMenuItem>
                                            <DropdownMenuItem onClick={() => handleUpdateStatus(detailServiceForm.id as number, "completed")}>
                                                <span className="bg-green-500 text-white rounded-md px-2 py-1 text-sm">Completed</span>
                                            </DropdownMenuItem>
                                        </DropdownMenuContent>
                                    </DropdownMenu>
                                 : 
                                <span className="col-span-3">
                                    {item.value}
                                </span>
                                }
                            </>
                        ))}

                    
                </div>
            </DialogDescription>
            <DialogFooter>
                <button
                  onClick={() => {
                    setIsOpenDialog({ ...isOpenDialog, userinfo: true })
                  }}
                  className="flex items-center gap-2 px-4 py-2 mb-0 bg-blue-500 text-sm text-white rounded-md bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
                >
                  Requestor Info
                </button>
                <button
                  onClick={() => {}}
                  className="flex items-center gap-2 px-4 py-2 mb-0 bg-blue-500 text-sm text-white rounded-md bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
                >
                  Show Messages
                </button>
            </DialogFooter>
        </DialogContent>
      </Dialog>
      <Dialog open={isOpenDialog.userinfo} onOpenChange={() => setIsOpenDialog({ ...isOpenDialog, userinfo: false })}>
        <DialogContent>
            <DialogHeader>
                <DialogTitle>Service Request User Info</DialogTitle>
            </DialogHeader>
            
            <DialogDescription>
                <div className="grid grid-cols-4 grid-rows-auto gap-2 text-base ">

                        {[  {label: "User ID", value: detailServiceForm.imo_no}, 
                            {label: "Name", value: detailServiceForm.your_name}, 
                            {label: "Email", value: detailServiceForm.vessel_name}, 
                            {label: "Company", value: detailServiceForm.ship_owner}, 
                            {label: "Group", value: detailServiceForm.emails}, 
                            {label: "Phone Number", value: detailServiceForm.phone_number}].map((item) => (
                            <>
                                <span className="font-semibold col-span-1">{item.label}</span>
                                {
                                    item.label === "Status" ? 
                                    <DropdownMenu>
                                        <DropdownMenuTrigger asChild>
                                        <span 
                                            className={`col-span-3 ${item.value === "pending" ? "bg-red-500 text-white rounded-md px-2 py-1 text-sm" : "bg-green-500 text-white rounded-md px-2 py-1 text-sm"}`}
                                            onClick={item.label === "Status" ? () => {} : () => {}}
                                        >
                                            {item.value}
                                        </span>
                                        </DropdownMenuTrigger>
                                        <DropdownMenuContent>
                                            <DropdownMenuItem>
                                                Pending</DropdownMenuItem>
                                            <DropdownMenuItem>In Progress</DropdownMenuItem>
                                            <DropdownMenuItem>In Trouble</DropdownMenuItem>
                                            <DropdownMenuItem>Completed</DropdownMenuItem>
                                        </DropdownMenuContent>
                                    </DropdownMenu>
                                 : 
                                <span className="col-span-3">
                                    {item.value}
                                </span>
                                }
                            </>
                        ))}

                    
                </div>
            </DialogDescription>
            <DialogFooter>
                <button
                  onClick={() => {
                    setIsOpenDialog({ ...isOpenDialog, userinfo: false })
                  }}
                  className="flex items-center gap-2 px-4 py-2 mb-0 bg-blue-500 text-sm text-white rounded-md bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
                >
                  OK
                </button>
            </DialogFooter>
        </DialogContent>
      </Dialog>
      <Table>
        <TableHeader>
          <TableRow>
            {["id", "your_name", "vessel_name", "status", "created_at", "updated_at"].map((key) => (
              <TableHead
                key={key}
                className="px-6 py-2 text-left text-xs text-nowrap font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                onClick={() => handleSort(key)}
              >
                {key === "id" ? 
                    "No." :
                    key === "imo_no" ? 
                        "IMO No." :
                    key === "your_name" ? 
                        "Your Name" :
                    key === "vessel_name" ? 
                        "Vessel Name" :
                    key === "ship_owner" ? 
                        "Ship Owner" :
                    key === "emails" ? 
                        "Emails" :
                    key === "phone_number" ? 
                        "Phone Number" :
                    key === "issue_detail" ? 
                        "Issue Detail" :
                    key === "attachment" ? 
                        "Attachment" :
                    key === "created_at" ? 
                        "Request Date" : 
                        key === "updated_at" ? 
                            "Update Date" : 
                            key}
                {sortConfig.key === key && (
                  <span className="ml-1">
                    {sortConfig.direction === "asc" ? "▲" : "▼"}
                  </span>
                )}
              </TableHead>
            ))}
            {/* <TableHead>Action</TableHead> */}
          </TableRow>
        </TableHeader>
        <TableBody>
          {paginatedServices &&
            paginatedServices.map((service, index) => (
              <TableRow 
                key={service.id}
                onClick={() => {
                    setDetailServiceForm(service); 
                    setSelectedService(service.id);
                    setIsOpenDialog({ ...isOpenDialog, detail: true })
                }}
              >
                <TableCell>{service.id}</TableCell>
                <TableCell>{service.your_name}</TableCell>
                <TableCell>{service.vessel_name}</TableCell>
                <TableCell>
                    <span className={`rounded-full px-2 py-1 text-xs border-1
                         ${service.status === "pending" ? "border-yellow-500 text-yellow-500" 
                         : service.status === "in progress" ? "border-blue-500 text-blue-500" 
                         : service.status === "in trouble" ? "border-red-500 text-red-500" 
                         : "border-green-500 text-green-500"}`}>{service.status}</span>
                </TableCell>
                <TableCell>{service.created_at.slice(0, 10)}</TableCell>
                <TableCell>{service.updated_at.slice(0, 10)}</TableCell>
                {/* <TableCell>
                  <DropdownMenu>
                    <DropdownMenuTrigger>
                      <EllipsisVertical className="h-4 w-4" />
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                      <DropdownMenuItem
                        onClick={() => {
                            setDetailServiceForm(service); 
                            setSelectedService(service.id);
                            setIsOpenDialog({ ...isOpenDialog, detail: true })
                        }}
                      >Detail</DropdownMenuItem>
                      <DropdownMenuSeparator />
                      <DropdownMenuItem>Case Close</DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </TableCell> */}
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={servicesRequest ? Math.ceil(servicesRequest?.length / 20) : 1}
        totalNumber={servicesRequest?.length}
        listName="Service Request"
      />
    </div>
  );
};

export default ServiceReqTable;
