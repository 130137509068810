import React, { useEffect, useState } from 'react';
import { setChatMode, setChatRoomId } from '../lib/features/chatMain/chatMainSlice';
import { useAppDispatch, useAppSelector } from '../lib/hooks';
import { useNavigate } from 'react-router-dom';
import img_solution01 from '../images/main/solution01.png';
import img_solution02 from '../images/main/solution02.png';
import img_solution03 from '../images/main/solution03.png';

import './menuPage.css';
import { useTranslation } from 'react-i18next';
import { selectUser } from '../lib/features/auth/authSlice';
import i18n from '../i18n';;

const APP_STAGE = process.env.REACT_APP_STAGE;
const MenuPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t  } = useTranslation('translation'); 
  
  const user = useAppSelector(selectUser);
  const chatMode = useAppSelector((state) => state.chatMain.chatMode);

  // 상태 추가
  const [activeIndex, setActiveIndex] = useState(null); // 현재 hover된 메뉴나 이미지의 인덱스


  // console.log({language:i18n.language})
  // console.log({user_lang:user?.user_lang})
  // console.log(i18n.getResource('kr', 'translation'))

  const handleMenu1Click = (menu) => {
    dispatch(setChatMode({
      mode_1: menu,
      mode_2: "",
    }));
    navigate('/chat');
  };

  const handleMenu2Click = (menu) => {
    dispatch(setChatMode({
      mode_1: chatMode.mode_1,
      mode_2: menu,
      isNewChat: false
    }));
    dispatch(setChatRoomId(null));
    if (chatMode.mode_1 === "BWMS" && menu === '3') {
      navigate('/service');
      return;
    }
    navigate('/chat');
  };

  return (
    <div className="menuBG relative flex flex-col w-full min-h-screen bg-white overflow-y-auto justify-center items-center">
      <div className="solution">
        <div className="tit_group">
          <h2 className="tit">{t('menu.solution.tit_group.tit')}</h2>
          <p className="desc">{t('menu.solution.tit_group.decs')}</p>
        </div>

        <ul className="info_list">
          <li
            className={`eco ${activeIndex === 0 ? 'highlight' : ''} ${activeIndex === null ? 'highlight' : ''}`}
            onMouseEnter={() => setActiveIndex(0)}
            onMouseLeave={() => setActiveIndex(null)}
            onClick={() => handleMenu1Click('BWMS')}
          >
            <a href="#">
              <h3 className="tit"  dangerouslySetInnerHTML={{ __html: t('menu.info_list.eco.tit').replace(/\n/g, '<br/>') }}/>
              {/* <p className="desc"  dangerouslySetInnerHTML={{ __html: t('menu.info_list.eco.desc').replace(/\n/g, '<br/>') }}/> */}
            </a>
          </li>
          <li
            className={`automation ${activeIndex === 1 ? 'highlight' : ''}`}
            onMouseEnter={() => setActiveIndex(1)}
            onMouseLeave={() => setActiveIndex(null)}
            onClick={() => {}}
          >
            <a href="#">
              <h3 className="tit"  dangerouslySetInnerHTML={{ __html: t('menu.info_list.automation.tit').replace(/\n/g, '<br/>') }}/>
              {/* <p className="desc"  dangerouslySetInnerHTML={{ __html: t('menu.info_list.automation.desc').replace(/\n/g, '<br/>') }}/> */}
              <div className="overlay">{t('menu.info_list.automation.update')}</div>
            </a>
          </li>
          <li
            className={`power ${activeIndex === 2 ? 'highlight' : ''}`}
            onMouseEnter={() => setActiveIndex(2)}
            onMouseLeave={() => setActiveIndex(null)}
            onClick={() => {}}
          >
            <a href="#">
              <h3 className="tit"  dangerouslySetInnerHTML={{ __html: t('menu.info_list.power.tit').replace(/\n/g, '<br/>') }}/>
              {/* <p className="desc"  dangerouslySetInnerHTML={{ __html: t('menu.info_list.power.desc').replace(/\n/g, '<br/>') }}/> */}
              <div className="overlay">{t('menu.info_list.power.update')}</div>
            </a>
          </li>
        </ul>

        <ul className="img_list">
          <li
            className={activeIndex === 0 ? 'active' : ''}
            onMouseEnter={() => setActiveIndex(0)}
            onMouseLeave={() => setActiveIndex(null)}
          >
            <img src={img_solution01} alt="에코 솔루션" />
          </li>
          <li
            className={activeIndex === 1 ? 'active' : ''}
            onMouseEnter={() => setActiveIndex(1)}
            onMouseLeave={() => setActiveIndex(null)}
          >
            <img src={img_solution02} alt="운항제어 솔루션" />
          </li>
          <li
            className={activeIndex === 2 ? 'active' : ''}
            onMouseEnter={() => setActiveIndex(2)}
            onMouseLeave={() => setActiveIndex(null)}
          >
            <img src={img_solution03} alt="파워 솔루션" />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MenuPage;
