import React from 'react'
import ChatContents from './ChatContents';
import SideBarDoc from './SideBarDoc';

function ChatMain({isOpen, setIsOpen, mode, ids}) {
  return (
    <div className='flex flex-col lg:flex-row w-full h-full overflow-hidden'>
      <div className='flex-1 overflow-y-auto'>
        <ChatContents isOpen={isOpen} setIsOpen={setIsOpen} mode={mode} ids={ids} />
      </div>
      <SideBarDoc />
    </div>
  )
}

export default ChatMain