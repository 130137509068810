import React, { useEffect, useRef, useState } from "react";
import { 
  authLogout, 
  selectUser, 
  selectToken, 
  userLangChange
} from "../../lib/features/auth/authSlice";
import { resetAdmin } from "../../lib/features/admin/adminSlice";
import { resetChatMain } from "../../lib/features/chatMain/chatMainSlice";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import { useNavigate } from "react-router-dom";

const APP_STAGE = process.env.REACT_APP_STAGE;

function NavAvatar({sidebar, toggle}) {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const token = useAppSelector(selectToken);

  const [currentLanguage, setCurrentLanguage] = useState(user?.user_lang);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/");
    }
  }, [user]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    // Add event listener when dropdown is open
    if (dropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownOpen]);

  const handleSignOut = () => {
    dispatch(authLogout(token));
    dispatch(resetAdmin());
    dispatch(resetChatMain());
  };

  const handleLanguageChange = (language) => {
    setCurrentLanguage(language);
    // changeLanguage(language);
    dispatch(userLangChange({ token, lang: language }));
  };

  const changeLanguage = (language) => {
    if (APP_STAGE === "DEV") {  
      console.log("Selected language:", language);
    }
  };

  return (
    <div className={`flex flex-row w-full sm:h-14 flex-col items-center sm:items-end justify-start gap-3 sm:pb-2 list-none  ${toggle?" w-8 sm:w-full":" w-8 sm:w-8 "}`}>
      {/* <div className="bg-blue-300 focus-visible:ring-offset-background inline-flex shrink-0 cursor-pointer items-center justify-center gap-1.5 whitespace-nowrap text-nowrap border font-medium outline-none ring-blue-600 transition-all focus-visible:ring-2 focus-visible:ring-offset-1 disabled:pointer-events-none disabled:cursor-not-allowed disabled:ring-0 has-[:focus-visible]:ring-2 [&amp;>svg]:pointer-events-none [&amp;_svg]:shrink-0 border-transparent text-gray-900 hover:border-transparent focus:border-transparent focus-visible:border-transparent disabled:border-transparent disabled:bg-transparent disabled:text-gray-400 px-1 text-sm has-[>kbd]:gap-3 has-[>kbd]:pr-[6px] rounded-lg size-8 border-none hover:bg-transparent focus:bg-transparent focus-visible:bg-transparent has-[>svg]:p-0 [&amp;>svg]:size-8"> */}
      <div 
        onClick={() => setDropdownOpen(!dropdownOpen)}
        className={`bg-blue-300 sm:w-full ${toggle?" w-8 sm:w-full":" w-8 sm:w-8 "}  inline-flex shrink-0 cursor-pointer items-center justify-center gap-1.5 whitespace-nowrap text-nowrap border font-medium outline-none ring-blue-600 transition-all text-gray-900  text-sm rounded-lg size-8 border-none `}>
      {user ? (
        <li className="relative">
          <button
            className={`flex items-center px-2 sm:w-16 sm:px-4 space-x-2 justify-center focus:outline-none  ${toggle?" w-8 sm:w-24":" w-8 sm:w-8 "}`}
          >
            <span className="text-sm font-bold truncate">{toggle?user.user_name:user.user_name[0].toUpperCase()}</span>
          </button>

          {dropdownOpen && (
            <ul 
              ref={dropdownRef}
              className={`absolute p-4 w-52 bg-white border border-gray-200 rounded-lg shadow-lg z-[1050] ${sidebar?"translate-x-full right-0 bottom-0":"right-0"}`}>
              <li className="px-2 py-1">
                <h5 className="text-gray-700 font-bold mb-0">{user.user_id === 1? "Administrator" : user.user_name}</h5>
                <span className="text-gray-500 text-xs">{user.Group.group_name}</span>
              </li>
              <hr className="border-gray-300 my-2" />
              
              <li
                className="px-2 py-1 hover:bg-gray-100 cursor-pointer"
                onClick={() => navigate("/history")}
              >
                <i className="bi bi-arrow-clockwise mr-2"></i>
                <span>Chat History</span>
              </li>
              <li
                className="px-2 py-1 hover:bg-gray-100 cursor-pointer"
                onClick={() => navigate("/chat")}
              >
                <i className="bi bi-chat-dots mr-2"></i>
                <span>QA Chat Page</span>
              </li>

              {user.Permission.is_main_manual && <li
                className="px-2 py-1 hover:bg-gray-100 cursor-pointer"
                onClick={() => navigate("/pdf")}
              >
                <i className="bi bi-filetype-pdf mr-2"></i>
                <span>Mannual</span>
              </li>
              }
              {user.Permission.is_main_video && <li
                className="px-2 py-1 hover:bg-gray-100 cursor-pointer"
                onClick={() => navigate("/video")}
              >
                <i className="bi bi-camera-video mr-2"></i>
                <span>Video Guide</span>
              </li>
              }
              <hr className="border-gray-300 my-2" />

              {user.Permission.is_admin ? (
                <li
                  className="px-2 py-1 hover:bg-gray-100 cursor-pointer"
                  onClick={() => navigate("/dashboard")}
                >
                  <i className="bi bi-person mr-2"></i>
                  <span>Dashboard</span>
                </li>
              ) : (
                <li
                  className="px-2 py-1 hover:bg-gray-100 cursor-pointer"
                  onClick={() => {}}
                >
                  <i className="bi bi-person mr-2"></i>
                  <span>My Profile</span>
                </li>
              )}
              {/* <li
                className="px-2 py-1 hover:bg-gray-100 cursor-pointer"
                onClick={() => {}}
              >
                <i className="bi bi-chat-square-dots mr-2"></i>
                <span>Feedback</span>
              </li> */}
              <li
                className="px-2 py-1 hover:bg-gray-100 cursor-pointer"
                onClick={() => navigate("/email-send")}
              >
                <i className="bi bi-envelope-arrow-up mr-2"></i>
                <span>Service Request</span>
              </li>

              <li className="px-2 py-1 hover:bg-gray-100 cursor-pointer">
                <i className="bi bi-gear mr-2"></i>
                <span>Account Settings</span>
              </li>
              <li className="px-2 py-1 hover:bg-gray-100 cursor-pointer">
                <i className="bi bi-question-circle mr-2"></i>
                <span>Need Help?</span>
              </li>
              <hr className="border-gray-300  my-2" />
{/* 
              <li className="px-2 py-1">
                <div className="flex items-center">
                  <i className="bi bi-globe mr-2"></i>
                  <span className="mr-2">Language:</span>
                  <button
                    className={`btn-sm me-1 ${currentLanguage === "EN" ? "bg-blue-500 text-white" : "bg-gray-100"}`}
                    onClick={() => handleLanguageChange("EN")}
                  >
                    EN
                  </button>
                  <button
                    className={`btn-sm ${currentLanguage === "KO" ? "bg-blue-500 text-white" : "bg-gray-100"}`}
                    onClick={() => handleLanguageChange("KO")}
                  >
                    KR
                  </button>
                </div>
              </li> */}
              <hr className="border-gray-300  my-2" />

              <li
                className="px-2 py-1 hover:bg-gray-100 cursor-pointer"
                onClick={handleSignOut}
              >
                <i className="bi bi-box-arrow-right mr-2"></i>
                <span>Sign Out</span>
              </li>
            </ul>
          )}
        </li>
      ) : (
        <></>
      )}
      
      </div>
      {/* <span className="text-base font-medium truncate">{toggle?user.Group.group_name:""}</span> */}
    </div>
  );
}

export default NavAvatar;
