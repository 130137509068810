export default {
    sideButton: {
        toggleSidebar: {
            tooltip: "Toggle sidebar"
        },
        newChat: {
            name: "New Chat",
            tooltip: "Start a new chat"
        },
        history: {
            name: "Chat History",
            tooltip: "View previous chats"
        },
        troubleshooting:{
            name: "Troubleshooting",
            tooltip: "View manuals list"
        },
        maintenance:{
            name: "Maintenance",
            tooltip: "View manuals list"
        },
        generalinquery:{
            name: "General Inquiry",
            tooltip: "View manuals list"
        },
        manuals: {
            name: "Manuals",
            tooltip: "View manuals list"
        },
        video: {
            name: "Video Guide",
            tooltip: "View video list"
        },
        backtoMenu:{
            name: "Back to Menu",
            tooltip: "Back to Menu"
        },
        changeLang: {
            name: {
                en: "EN",
                ko: "KO"
            },
            tooltip: "Change language"
        },
        feedback: {
            name: "Feedback",
            tooltip: "Leave feedback"
        },
        service: {
            name: "Service Request",
            tooltip: "Request service"
        },
        pic: {
            name: "Person In Charge",
            tooltip: "View Person In Charge Infomation"
        }
    }
};